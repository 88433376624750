import type { ComponentProps, ReactElement } from "react";
import { forwardRef } from "react";
import clsx from "clsx";

import { Icon } from "@sunrise/icons";

import { Button as BaseButton } from "../../button";
import { Text } from "../../text";
import styles from "./button.module.css";

type Props = { showMore?: boolean } & Omit<
  ComponentProps<typeof BaseButton>,
  "variant"
>;

const Button = forwardRef<HTMLButtonElement, Props>(
  (
    { children, className, showMore = false, ...props }: Props,
    ref,
  ): ReactElement => {
    return (
      <BaseButton
        ref={ref}
        variant="none"
        {...props}
        className={clsx(
          styles.button,
          !props.icon && styles.withoutIcon,
          props.disabled && styles.disabled,
          className,
        )}
      >
        <Text size="medium" variant="body">
          {children}
        </Text>
        {showMore && <Icon className={styles.more} name="chevronRight" />}
      </BaseButton>
    );
  },
);

export { Button };
