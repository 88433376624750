import { atom } from "jotai";
import { atomFamily } from "jotai/utils";

import { isLegacyBackendAtom } from "@sunrise/backend-core";
import type { AssetId } from "@sunrise/backend-types-core";
import { currentLanguageAtom } from "@sunrise/i18n";
import type { Nullable } from "@sunrise/utils";

import { mapBackendAssetDetailsLegacy } from "./helpers/map-backend-asset-details.legacy";
import { assetDetailsByIdLegacyAtom } from "./legacy/asset-details-by-id.legacy.atom";
import { assetDetailsByIdNgAtom } from "./ng/asset-details-by-id.ng.atom";

export const assetDetailsByIdAtom = atomFamily((assetId: Nullable<AssetId>) => {
  const innerAtom = atom(async (get) => {
    if (get(isLegacyBackendAtom)) {
      const language = get(currentLanguageAtom);
      const legacyDetails = await get(assetDetailsByIdLegacyAtom(assetId));
      if (!legacyDetails.data) return null;
      return mapBackendAssetDetailsLegacy(legacyDetails.data, language);
    }

    return (await get(assetDetailsByIdNgAtom(assetId))).data;
  });

  innerAtom.debugLabel = `assetDetailsByIdAtom(${assetId})`;

  return innerAtom;
});
