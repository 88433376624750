import { useAtomValue } from "jotai";

import { useTranslator } from "@sunrise/translator";
import { deviceInfo } from "@sunrise/utils";
import { appVersionAtom } from "@sunrise/yallo-settings";
import { userAtom } from "@sunrise/yallo-user";
import { Text } from "@sunrise/yallo-web-components";

import styles from "./settings-profile.module.css";
import { ButtonLogoutFromAllDevices } from "./settings-profile-log-out-from-all-devices";

const mode = import.meta.env.MODE;
const buildDate = import.meta.env.VITE_APP_BUILD_DATE
  ? `(${new Date(
      Number(import.meta.env.VITE_APP_BUILD_DATE),
    ).toLocaleString()})`
  : "";

export const SettingsProfile = () => {
  const t = useTranslator();
  const user = useAtomValue(userAtom).data;
  const appVersion = useAtomValue(appVersionAtom);

  return (
    <div className={styles.container}>
      <dl>
        <div className={styles.row}>
          <dt>
            <Text size="medium" variant="body">
              {t("profile_subscription")}
            </Text>
          </dt>
          <dd>
            <Text size="medium" variant="body">
              {user?.account.plan.display_name ?? t("unknown")}
            </Text>
          </dd>
        </div>
        <div className={styles.row}>
          <dt>
            <Text size="medium" variant="body">
              {t("user_id")}
            </Text>
          </dt>
          <dd>
            <Text size="medium" variant="body">
              {user?.id ?? t("unknown")}
            </Text>
          </dd>
        </div>

        <hr className={styles.divider} />

        <div className={styles.row}>
          <dt>
            <Text size="medium" variant="body">
              {t("system_info_app_version")}
            </Text>
          </dt>
          <dd>
            <Text size="medium" variant="body">
              {appVersion} {mode} {buildDate}
            </Text>
          </dd>
        </div>
        <div className={styles.row}>
          <dt>
            <Text size="medium" variant="body">
              {t("settings_page_profile_browser")}
            </Text>
          </dt>
          <dd>
            <Text size="medium" variant="body">
              {deviceInfo.browserName} {deviceInfo.browserVersion}
            </Text>
          </dd>
        </div>
        <div className={styles.row}>
          <dt>
            <Text size="medium" variant="body">
              {t("settings_page_profile_operating_system")}
            </Text>
          </dt>
          <dd>
            <Text size="medium" variant="body">
              {deviceInfo.osVersion}
            </Text>
          </dd>
        </div>
      </dl>
      <ButtonLogoutFromAllDevices />
    </div>
  );
};
