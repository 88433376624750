const isBrowser =
  typeof window !== "undefined" &&
  typeof document !== "undefined" &&
  typeof navigator !== "undefined" &&
  navigator.userAgent;

type UserAgentRule = [string, RegExp];

const userAgentRules: UserAgentRule[] = [
  ["edge", /Edge\/([0-9._]+)/],
  ["edge-ios", /EdgiOS\/([0-9._]+)/],
  ["samsung", /SamsungBrowser\/([0-9.]+)/],
  ["edge-chromium", /EdgA?\/([0-9.]+)/],
  ["chromium-webview", /(?!Chrom.*OPR)wv\).*Chrom(?:e|ium)\/([0-9.]+)(:?\s|$)/],
  ["chrome", /(?!Chrom.*OPR)Chrom(?:e|ium)\/([0-9.]+)(:?\s|$)/],
  ["firefox", /Firefox\/([0-9.]+)(?:\s|$)/],
  ["opera", /Opera\/([0-9.]+)(?:\s|$)/],
  ["opera", /OPR\/([0-9.]+)(:?\s|$)/],
  ["ie", /Trident\/7\.0.*rv:([0-9.]+).*\).*Gecko$/],
  ["ie", /MSIE\s([0-9.]+);.*Trident\/[4-7].0/],
  ["ie", /MSIE\s(7.0)/],
  ["android", /Android\s([0-9.]+)/],
  ["ios", /Version\/([0-9._]+).*Mobile.*Safari.*/],
  ["safari", /Version\/([0-9._]+).*Safari/],
  ["ios-webview", /AppleWebKit\/([0-9.]+).*Mobile/],
  ["ios-webview", /AppleWebKit\/([0-9.]+).*Gecko\)$/],
];
const operatingSystemRules: UserAgentRule[] = [
  ["iOS", /iP(hone|od|ad)/],
  ["Android OS", /Android/],
  ["BlackBerry OS", /BlackBerry|BB10/],
  ["Windows Mobile", /IEMobile/],
  ["Amazon OS", /Kindle/],
  ["Windows XP", /(Windows NT 5.1)|(Windows XP)/],
  ["Windows Vista", /(Windows NT 6.0)/],
  ["Windows 7", /(Windows NT 6.1)/],
  ["Windows 8", /(Windows NT 6.2)/],
  ["Windows 8.1", /(Windows NT 6.3)/],
  ["Windows 10", /(Windows NT 10.0)/],
  ["Open BSD", /OpenBSD/],
  ["Sun OS", /SunOS/],
  ["Chrome OS", /CrOS/],
  ["Linux", /(Linux)|(X11)/],
  ["Mac OS", /(Mac_PowerPC)|(Macintosh)/],
];

/**
 * Get info about the device.
 *
 * NOTE: This works currently only for Web, but we should probably extend it so as to have a common place to get
 * device info for all platforms. (Combine with AppEnv as RuntimeEnv maybe?)
 *
 * Generally this should NOT be used for changing the content.
 * Use first media queries (essential for static rendered content), and then use useWindowSize().
 *
 * isMobileOrTablet is required for Mobile / Tablet devices to put video fullscreen in the case of
 * rotating to landscape.
 */
class DeviceInfo {
  public isIOS = false;
  public isChrome = false;
  public isFirefox = false;
  public isSafari = false;
  public isChromium = false;
  public isSeamonkey = false;
  public isOpera = false;
  public isIE = false;
  public isMobileOrTablet = false;
  public browserName?: string | null;
  public browserVersion?: string | null;
  public osVersion?: string | null;

  constructor() {
    this.updateDeviceInfo();
  }

  /**
   * Extract browser and OS info from the user agent.
   *
   * See:
   * https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent
   *
   */
  public updateDeviceInfo(): void {
    if (!isBrowser || !("navigator" in globalThis)) return;

    const userAgent = globalThis.navigator.userAgent;
    const lcua = userAgent.toLowerCase();

    const containsIphoneIpadIpod =
      lcua.includes("iphone") || lcua.includes("ipad") || lcua.includes("ipod");
    const containsMobi = lcua.includes("mobi");
    const containsFirefox = lcua.includes("firefox");
    const containsSeamonkey = lcua.includes("seamonkey");
    const containsChrome = lcua.includes("chrome");
    const containsChromium = lcua.includes("chromium");
    const containsSafari = lcua.includes("safari");
    const containsOperaOPR = lcua.includes("opera") || lcua.includes("opr");
    const containsTridentMSIE =
      lcua.includes("trident") || lcua.includes("msie");

    this.isSafari = containsSafari && !containsChrome && !containsChromium;
    this.isChrome = containsChrome;
    this.isChromium = containsChromium && !containsChrome;
    this.isFirefox = containsFirefox;
    this.isSeamonkey = containsSeamonkey && !containsFirefox;
    this.isOpera = containsOperaOPR;
    this.isIE = containsTridentMSIE;
    this.isMobileOrTablet = containsMobi;
    this.isIOS = containsIphoneIpadIpod;

    const agentResult = this.detectBrowserName(userAgent);
    if (agentResult) {
      const [browser, regex] = agentResult;
      this.browserName = browser;
      this.browserVersion = this.detectBrowserVersion(userAgent, regex);
    }
    this.osVersion = this.detectOsVersion(userAgent);
  }

  /**
   * Get browser name from User agent
   * NOTE: currently works for web only
   */
  private detectBrowserName(ua: string) {
    return userAgentRules.find(([, regex]) => {
      return regex.exec(ua);
    });
  }

  /**
   * Get browser version from User agent
   * NOTE: currently works for web only
   */
  private detectBrowserVersion(ua: string, regex: RegExp) {
    const match = regex.exec(ua);
    return match && match[1];
  }

  /**
   * Get OS version from User agent
   * NOTE: currently works for web only
   *
   */
  private detectOsVersion(ua: string) {
    const result = operatingSystemRules.find(([, regex]) => {
      return regex.exec(ua);
    });

    if (result) {
      const [system] = result;
      return system;
    }

    return;
  }
}

const deviceInfo = new DeviceInfo();

export { DeviceInfo, deviceInfo };
