import { atom } from "jotai";

import { isLegacyBackendAtom } from "@sunrise/backend-core";
import { recordingsCountQuery } from "@sunrise/backend-ng-recordings";

import { recordingStatsLegacyAtom } from "./legacy/recording-stats-legacy.atom";
import type { GenericRecordingCapacity } from "./types";

/**
 * Returns the recording capacity regardless of which backend is used.
 */
export const recordingCapacityAtom = atom<
  Promise<GenericRecordingCapacity | null>
>(async (get) => {
  if (get(isLegacyBackendAtom)) {
    const data = await get(recordingStatsLegacyAtom);
    if (!data) {
      return null;
    }

    return {
      used: data?.used_recordings,
      total: data?.capacity_recordings,
    };
  }

  const query = await get(recordingsCountQuery);

  if (!query.data) {
    return null;
  }

  return {
    used: query.data.single_recordings,
    total: query.data.user_limit,
  };
});
