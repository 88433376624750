import { atom } from "jotai";

import { EMPTY } from "./constants";
import { playerCurrentContentEpgAtom } from "./player-current-content-epg.atom";
import { playerCurrentContentRecordingAtom } from "./player-current-content-recordings.atom";
import { selectPlayerCurrentPlayRequest } from "./selectors";
import type { PlayerCurrentContent } from "./types";

/**
 * Atom to display what is currently playing in the player.
 * Will select data based on the PlayRequest type.
 *
 * I will only look at what is really playing out now and not look at what is seeked to.
 *
 * So whatever we seeked to is ignored here. This is purely returning where the player is positioned at right now.
 * For recordings there will be no difference between `playerCurrentContentAtom` and `playerCurrentContentShownAtom`.
 */
export const playerCurrentContentAtom = atom<Promise<PlayerCurrentContent>>(
  async (get) => {
    const playRequest = get(selectPlayerCurrentPlayRequest);

    if (!playRequest) {
      return EMPTY;
    }

    if (playRequest.type === "recording") {
      return get(playerCurrentContentRecordingAtom);
    } else {
      return get(playerCurrentContentEpgAtom);
    }
  },
);
