import clsx from "clsx";

import type { Nullable } from "@sunrise/utils";
import { Link, Text } from "@sunrise/yallo-web-components";

import styles from "./guide-program.module.css";

type GuideProgramProps = {
  programWidth: number;
  programTitle: Nullable<string>;
  isActive?: boolean; // currently playing
  startInPx: number;
  onMouseEnter: () => void;
  href: string;
  isLive?: boolean;
};

export function GuideProgram({
  programTitle,
  programWidth,
  startInPx,
  onMouseEnter,
  href,
  isActive,
  isLive,
}: GuideProgramProps) {
  if (!programTitle) return null;
  return (
    <Link
      className={clsx([
        styles.container,
        isLive && styles.live,
        isActive && styles.active,
      ])}
      draggable={false}
      href={href}
      style={{
        left: `${startInPx}px`,
        width: `${programWidth}px`,
      }}
      variant="filled"
      onMouseEnter={onMouseEnter}
    >
      <Text
        className={styles.label}
        size={isActive ? "large" : "medium"}
        title={programTitle}
        variant={isActive ? "label" : "body"}
      >
        {programTitle}
      </Text>
    </Link>
  );
}
