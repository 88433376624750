import areEqual from "fast-deep-equal";
import { atomWithReducer } from "jotai/utils";

/**
 * This is a stable atom that will only change the value if the new value is different from the old one.
 * Only use this sparringly as it will be a bit slower than regular atoms.
 */
export function atomWithCompare<Value>(
  initialValue: Value,
  equalFn: (prev: Value, next: Value) => boolean = areEqual,
) {
  return atomWithReducer(initialValue, (prev: Value, next: Value) => {
    if (equalFn(prev, next)) {
      return prev;
    }

    return next;
  });
}
