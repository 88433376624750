import { createRoot } from "react-dom/client";
import { queryClientAtom } from "jotai-tanstack-query";
import { isNil } from "lodash";

import "@sunrise/web-common-styles/base.css";
import "@sunrise/web-common-styles/reset.css";
import "@/styles/fonts.css";
import "@/styles/global.css";
import "./wdyr";

import { AppProvider } from "@/app-provider";
import { webStore } from "@/core";
import { ErrorBoundary } from "@/features/monitoring/error-boundary";
import { queryClient } from "@/modules/query-provider";
import { Root } from "@/root";
import {
  initIntegrations,
  initMonitoring,
  logVersion,
} from "@/utils/init-integrations";

window.addEventListener("DOMContentLoaded", () => {
  logVersion();
  initMonitoring();

  const appNode = document.getElementById("app");
  if (isNil(appNode)) throw new Error("App element does not exist");

  initIntegrations();

  createRoot(appNode).render(
    <ErrorBoundary>
      <AppProvider
        enableDevTools={!import.meta.env.PROD}
        queryClient={queryClient}
        queryClientAtom={queryClientAtom}
        storeInstance={webStore}
      >
        <Root />
      </AppProvider>
    </ErrorBoundary>,
  );
});
