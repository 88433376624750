import { jwtDecode } from "jwt-decode";

import { type Nullable } from "@sunrise/utils";

import { type JWTPayload, type JWTState } from "./jwt.types";

export function decodeJwt(token: string): JWTPayload {
  return jwtDecode<JWTPayload>(token);
}

export function isJWTExpired(token: string): boolean {
  const { exp } = decodeJwt(token);
  if (!exp) return true;

  return exp < Date.now() / 1000;
}

export function getJWTState(token: Nullable<string>): JWTState {
  try {
    return !token ? "missing" : isJWTExpired(token) ? "expired" : "valid";
  } catch {
    return "error";
  }
}
