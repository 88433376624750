import { atomWithSuspenseQuery } from "jotai-tanstack-query";

import { recordingsStaleTimeAtom } from "@sunrise/backend-ng-recordings";
import { queryKeys, type RecordingStats } from "@sunrise/backend-types";
import { hostsAtom, httpClientAtom } from "@sunrise/http-client";
import { selectIsLoggedIn, selectJwtUserToken } from "@sunrise/jwt";
import type { Nullable } from "@sunrise/utils";

import { fetchRecordingStats } from "../recordings.service";

export const recordingStatsQueryAtom = atomWithSuspenseQuery<
  Nullable<RecordingStats>
>((get) => {
  const host = get(hostsAtom).api;
  const privateApi = get(httpClientAtom).privateApi;

  if (!privateApi) throw new Error("missing privateApi");
  if (!host) throw new Error("Host is not set");

  const isLoggedIn = get(selectIsLoggedIn);

  return {
    queryKey: queryKeys.recordingStats(get(selectJwtUserToken), false),
    queryFn: async () => {
      if (!isLoggedIn) {
        return null;
      }

      return await fetchRecordingStats(host, privateApi);
    },
    staleTime: get(recordingsStaleTimeAtom),
  };
});
