import { useCallback, useEffect } from "react";
import { useAtomValue, useSetAtom } from "jotai";
import { useAtomCallback } from "jotai/utils";

import { isLegacyBackendAtom } from "@sunrise/backend-core";
import { hostsAtom } from "@sunrise/http-client";
import {
  actionJWTSetTokens,
  jwtAtom,
  selectCanLogin,
  selectIsLoggedIn,
} from "@sunrise/jwt";
import { isNil } from "@sunrise/utils";

import { anonymousLogin } from "./auth.service";
import { deviceTypeAtom } from "./device-type.atom";

export type AnonymousLoginReturn = {
  isLoggedIn: boolean;
  login: () => Promise<void>;
};

type UseAnonymousLoginProps = {
  autoLogin: boolean;
};

export function useAnonymousLogin({
  autoLogin,
}: UseAnonymousLoginProps): AnonymousLoginReturn {
  const dispatchJWT = useSetAtom(jwtAtom);
  const canLogin = useAtomValue(selectCanLogin);
  const isLoggedIn = useAtomValue(selectIsLoggedIn);
  const deviceType = useAtomValue(deviceTypeAtom);

  const hosts = useAtomValue(hostsAtom);
  const host = hosts.api;

  const login = useAtomCallback(
    useCallback(
      async (get) => {
        const resp = await anonymousLogin(
          hosts.api,
          deviceType,
          !get(isLegacyBackendAtom),
        );
        if (!resp?.client_jwt_token) throw new Error("Missing access token");
        if (!resp?.client_jwt_refresh_token)
          throw new Error("Missing refresh token");

        dispatchJWT(
          actionJWTSetTokens({
            accessToken: resp.client_jwt_token,
            refreshToken: resp.client_jwt_refresh_token,
            wsToken: resp.client_jwt_websocket_token ?? null,
          }),
        );
      },
      [dispatchJWT],
    ),
  );

  if (isNil(host)) throw new Error("Missing host");

  useEffect(() => {
    if (!autoLogin) return;
    if (isLoggedIn) return;
    if (!canLogin) return;
    void login();
  }, [dispatchJWT, isLoggedIn]);

  return {
    isLoggedIn,
    login,
  };
}
