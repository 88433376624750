import { isAxiosError } from "axios";

import {
  type CreateRecordingSchedule,
  endpoints,
  type LegacySingleRecording,
  type Recordings,
  type RecordingSchedule,
  type RecordingStats,
  type RecordingStatusResponse,
} from "@sunrise/backend-types";
import type {
  AssetId,
  EPGEntryId,
  RecordingId,
} from "@sunrise/backend-types-core";
import { type PrivateApiClient } from "@sunrise/http-client";
import { type Nullable } from "@sunrise/utils";

import { RecordingStatusError } from "./errors/recording-status-error";
import { RecordingsError } from "./errors/recordings-error";

export async function fetchSingleRecording(
  host: string,
  httpClient: PrivateApiClient,
  recordingId: RecordingId,
): Promise<Nullable<LegacySingleRecording>> {
  const { data } = await httpClient.get<LegacySingleRecording>(
    endpoints.singleRecording(host, recordingId),
  );
  return { ...data, type: "recording" };
}

export async function deleteSingleRecording(
  host: string,
  httpClient: PrivateApiClient,
  recordingId: RecordingId,
): Promise<void> {
  await httpClient.delete<LegacySingleRecording>(
    endpoints.singleRecording(host, recordingId),
  );
}

export async function fetchRecordingStatuses(
  host: string,
  httpClient: PrivateApiClient,
  pageSize = 1000,
): Promise<RecordingStatusResponse> {
  const url = new URL(endpoints.recordingStatus(host));

  url.searchParams.append("pagesize", pageSize.toString());

  try {
    const { data } = await httpClient.get<RecordingStatusResponse>(url.href);
    return data;
  } catch (err) {
    if (isAxiosError(err)) {
      throw new RecordingStatusError("failed to fetch recording statuses", {
        errorCode: err.response?.data?.message,
        origin: err,
      });
    }

    throw err;
  }
}

export async function fetchRecordingStats(
  host: string,
  httpClient: PrivateApiClient,
): Promise<RecordingStats> {
  const { data } = await httpClient.get<RecordingStats>(
    endpoints.recordingStats(host),
  );

  return data;
}

export async function createRecordingSchedule(
  host: string,
  httpClient: PrivateApiClient,
  epgEntryId: EPGEntryId,
  providerName: string,
  isSeries: boolean,
): Promise<RecordingSchedule> {
  try {
    const { data } = await httpClient.post<
      RecordingSchedule,
      CreateRecordingSchedule
    >(endpoints.recordingSchedules.create(host), {
      epg_entry: {
        id: epgEntryId,
      },
      provider_name: providerName,
      type: isSeries ? "asset" : "single",
    });

    return data;
  } catch (err) {
    if (
      isAxiosError(err) &&
      err.response?.status === 412 &&
      typeof err.response?.data === "object" &&
      err.response.data !== null &&
      "message" in err.response.data &&
      typeof err.response.data.message === "string"
    ) {
      throw new RecordingsError("failed to create recording", {
        errorCode: err.response.data.message,
        origin: err,
      });
    }

    throw err;
  }
}

export async function deleteRecordingSchedule(
  host: string,
  httpClient: PrivateApiClient,
  epgEntryId: EPGEntryId,
): Promise<void> {
  await httpClient.delete<RecordingSchedule, CreateRecordingSchedule>(
    endpoints.recordingSchedules.deleteByEpgEntryId(host, epgEntryId),
  );
}

export async function cancelRecordings(
  host: string,
  httpClient: PrivateApiClient,
  assetId: AssetId,
): Promise<void> {
  await httpClient.delete<RecordingSchedule, CreateRecordingSchedule>(
    endpoints.recordingSchedules.cancelByAssetId(host, assetId),
  );
}

export async function deleteRecordings(
  host: string,
  httpClient: PrivateApiClient,
  assetId: AssetId,
): Promise<void> {
  await httpClient.delete<RecordingSchedule, CreateRecordingSchedule>(
    endpoints.recordingGroupsByAssetId(host, assetId),
  );
}

export async function haveRecordingSchedules(
  host: string,
  httpClient: PrivateApiClient,
  assetId: AssetId,
): Promise<boolean> {
  const { data } = await httpClient.get<Recordings>(
    endpoints.recordingSchedules.haveRecordingSchedules(host, assetId),
  );

  return data.records > 0;
}

export async function fetchWhatIsNext(
  host: string,
  httpClient: PrivateApiClient,
  recordingId: RecordingId,
): Promise<Nullable<LegacySingleRecording>> {
  const { data } = await httpClient.get<LegacySingleRecording>(
    endpoints.whatIsNext(host, recordingId),
  );
  return data;
}
