import { useAtom, useAtomValue, useSetAtom } from "jotai/react";

import { debouncedQueryAtoms } from "@sunrise/search";
import {
  searchHistoryClearAllNgAtom,
  searchHistoryNgAtom,
} from "@sunrise/search-history";
import { useTranslator } from "@sunrise/translator";
import { Button, Spinner } from "@sunrise/yallo-web-components";

import styles from "./search-history.module.css";
import { SearchHistoryDeleteEntry } from "./search-history-delete-entry";
import { SearchPlaceholder } from "./search-placeholder";

export const SearchHistory = () => {
  const setQuery = useSetAtom(debouncedQueryAtoms.currentValueAtom);
  const t = useTranslator();
  const { data: history, isLoading } = useAtomValue(searchHistoryNgAtom);

  const [{ isPending: isClearingAllHistory }] = useAtom(
    searchHistoryClearAllNgAtom,
  );

  if (isLoading || isClearingAllHistory)
    return <Spinner className={styles.spinner} variant="small" isActive />;

  const hasHistory = (history?.length ?? 0) > 0;

  if (!hasHistory)
    return (
      <SearchPlaceholder
        key="placeholder"
        body={t("search_page_placeholder_body")}
        title={t("search_page_placeholder_title")}
      />
    );

  return (
    <ul className={styles.history}>
      {history?.map(({ id, query }) => (
        <li key={id} className={styles.line}>
          <Button
            className={styles.query}
            variant="none"
            onClick={() => setQuery(query)}
          >
            {query}
          </Button>
          <SearchHistoryDeleteEntry id={id}>{query}</SearchHistoryDeleteEntry>
        </li>
      ))}
    </ul>
  );
};
