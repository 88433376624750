import { atom } from "jotai";
import { atomEffect } from "jotai-effect";

import { isDefined, isNil, type Nullable } from "@sunrise/utils";

import { skipVideoAdButtonVisibleAtom } from "./skip-video-ad-button.visible.atom";
import { skipWhyAdsButtonVisibleAtom } from "./skip-why-ads-button-visible.atom";
import {
  selectAdMode,
  selectVideoAdsPod,
  videoAdsAtom,
  type VideoAdsAtomState,
} from "./video-ads.atom";

const setIntervalEffect = atomEffect((get, set) => {
  const data = get(_videoAdsUIAtom);
  set(skipVideoAdButtonVisibleAtom, data?.skip?.visible ?? false);
  set(skipWhyAdsButtonVisibleAtom, data?.skip?.visible ?? false);

  return () => {
    set(skipVideoAdButtonVisibleAtom, false);
    set(skipWhyAdsButtonVisibleAtom, false);
  };
});

const NO_SKIP_BUTTON = {
  available: false,
  visible: false,
  countdownInSeconds: null,
};

const _videoAdsUIAtom = atom((get) => {
  const mode = get(selectAdMode);

  const getPreAdsConfig = (mode: "live" | "regular") => {
    const ads = get(videoAdsAtom);
    const totalAds = ads.adConfig?.tag_count;
    const { currentAdRemainingTime } = ads;

    const timeLeft = cleanupTimeLeft(
      isDefined(currentAdRemainingTime) ? currentAdRemainingTime : null,
    );

    const message =
      mode === "regular" || ads.adConfig?.tag_count !== 5
        ? null
        : "ads_why_free";
    const counter =
      isDefined(ads.currentAdIndex) && totalAds && timeLeft
        ? {
            key: "ads_stats" as const,
            args: [ads.currentAdIndex + 1, totalAds, cleanupTimeLeft(timeLeft)],
          }
        : null;

    const skip = calculateSkipButtonRegular(ads, timeLeft);

    return {
      message,
      counter,
      skip,
    };
  };

  switch (mode) {
    case "live": {
      /**
       * For regular ads before live playout we want to:
       * - show a message when we have 5 ads playing.
       * - show a counter of 1 of x ads + a duration left on the playing ad.
       * - show a skip button if available and how many seconds left before it is available.
       */

      const { message, counter, skip } = getPreAdsConfig("live");

      return {
        message,
        counter,
        skip,
        why: counter
          ? {
              visible: true,
            }
          : undefined,
      };
    }
    case "replay": {
      /**
       * For replay alternative ads we want to:
       * - show the total duration left countdown.
       * That is all.
       */

      const ads = get(videoAdsAtom);
      const { adsDurations, currentAdRemainingTime, duration } = ads;

      const lastAdDuration = adsDurations[adsDurations.length - 1];

      const sumOfFinishedAdsTime = adsDurations
        .slice(0, -1)
        .reduce((a, b) => a + b, 0);

      // NOTE: if currentAdRemainingTime of last (current) ad is not set yet, we should set it to it's duration
      const lastAdRemainingTime = currentAdRemainingTime ?? lastAdDuration;

      const totalTimeLeft =
        duration &&
        lastAdDuration &&
        lastAdRemainingTime &&
        duration -
          (sumOfFinishedAdsTime + (lastAdDuration - lastAdRemainingTime));

      if (isNil(totalTimeLeft)) {
        return null;
      }

      return {
        counter: {
          key: "ads_continue_in" as const,
          args: [Math.ceil(totalTimeLeft)],
        },
      };
    }
    case "ffwd": {
      /**
       * For FFWD ads we want to:
       * - show a counter of 1 of x ads without a duration.
       */
      const pod = get(selectVideoAdsPod);

      if (!pod) {
        return null;
      }

      return {
        counter: {
          key: "ads_stats_fast_forward" as const,
          args: [pod.position, pod.totalAds],
        },
      };
    }
    case "recording":
    case "regular": {
      /**
       * For regular ads before live playout we want to:
       * - show a counter of 1 of x ads + a duration left on the playing ad.
       * - show a skip button if available and how many seconds left before it is available.
       */
      const { counter, skip } = getPreAdsConfig("regular");

      return {
        counter,
        skip,
        why:
          counter && mode === "recording"
            ? {
                visible: true,
              }
            : undefined,
      };
    }
    default:
      return null;
  }
});
_videoAdsUIAtom.debugPrivate = true;

/**
 * This atom returns the instructions of what UI to render.
 *
 * Doing it like this makes it easier to test and reason about.
 * It's perhaps not the most performant but the thing is that the UI
 * will always react to the entire videoAds atom and that we will be
 * re-rendering the whole ad UI anyway.
 */

export const videoAdsUIAtom = atom((get) => {
  get(setIntervalEffect);

  return get(_videoAdsUIAtom);
});

function cleanupTimeLeft(timeLeft: Nullable<number>): Nullable<number> {
  return !timeLeft || (timeLeft && timeLeft <= 1) ? null : Math.round(timeLeft);
}

function calculateSkipButtonRegular(
  { adConfig, duration }: VideoAdsAtomState,
  timeLeft: Nullable<number>,
) {
  const available = adConfig?.skippable ?? false;

  if (!available || duration === null || timeLeft === null) {
    return NO_SKIP_BUTTON;
  }

  // When the ad is shorter than the time in which we can skip, also hide the skip button.
  const availableAfter = adConfig?.skip_time_seconds;
  if (duration && availableAfter && duration <= availableAfter) {
    return NO_SKIP_BUTTON;
  }

  const countdownInSeconds =
    timeLeft && duration && availableAfter
      ? timeLeft - (duration - availableAfter)
      : 0;

  return {
    available,
    visible: countdownInSeconds <= 0,
    countdownInSeconds: (countdownInSeconds ?? 0) <= 0 ? 0 : countdownInSeconds,
  };
}
