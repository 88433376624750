import { atom } from "jotai";
import { atomFamily } from "jotai/utils";

import { isLegacyBackendAtom } from "@sunrise/backend-core";
import type { RecordingId } from "@sunrise/backend-types-core";

import { continueWatchingByRecordingIdAtom } from "./legacy/continue-watching-by-recording-id.atom";
import { continueWatchingItemLegacyToContinueWatchingStatus } from "./legacy/helpers/continue-watching-item-legacy-to-continue-watching-status";
import { continueWatchingEpgByEpgIdorRecordingIdAtom } from "./ng/continue-watching-status-by-epg-or-recording-id.atom";
import type { ContinueWatchingStatus } from "./types";

export const continueWatchingStatusByRecordingIdAtom = atomFamily(
  (recordingId: RecordingId) => {
    return atom<Promise<ContinueWatchingStatus | null>>(async (get) => {
      if (get(isLegacyBackendAtom)) {
        // NOTE: FW is not supported for recordings on legacy without mapping the recordingId to an epgId which we don't care for much.
        const status = get(continueWatchingByRecordingIdAtom(recordingId));

        return continueWatchingItemLegacyToContinueWatchingStatus(status);
      }

      const ng = await get(
        continueWatchingEpgByEpgIdorRecordingIdAtom({ recordingId }),
      );

      if (!ng.data || !("recordingId" in ng.data)) {
        return null;
      }

      // TODO: We need to wire up data coming from the ng socket
      //       and override the original data coming from the rest endpoints.

      return {
        recordingId: ng.data.recordingId,
        type: "recording",
        isFullyWatched: ng.data.fullyWatched,
        epgStartTime: ng.data.epgStartTime,
        playPositionSecond: ng.data.elapsedTime,
      };
    });
  },
);
