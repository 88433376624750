import type { ComponentProps, ReactElement } from "react";
import clsx from "clsx";

import { Title as BaseTitle } from "../../text";
import styles from "./title.module.css";

type Props = ComponentProps<typeof BaseTitle>;

const Title = ({ className, ...props }: Props): ReactElement => {
  return <BaseTitle className={clsx(styles.title, className)} {...props} />;
};

export { Title };
