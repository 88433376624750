import { isAfter, isBefore, isEqual } from "date-fns";

import type { Schedulable } from "@sunrise/backend-types";
import type { NullableProperties } from "@sunrise/utils";

/**
 * This is an important helper function and should be used instead of `isWithinInterval` from `date-fns` in most cases.
 * Because when a program starts at 13:30 and ends at 14:00, if we evaluate what is playing at 14:00 we should get `false`.
 * This is because the program has ended. But `isWithinInterval` would return `true` because 14:00 is within the interval.
 *
 * @param schedulable
 * @param time
 * @returns
 */
export function programIsPlayingAtTime(
  schedulable: NullableProperties<Schedulable>,
  time: Date,
): boolean {
  if (!schedulable.startTime || !schedulable.endTime) return false;
  return (
    (isAfter(time, schedulable.startTime) ||
      isEqual(time, schedulable.startTime)) &&
    isBefore(time, schedulable.endTime)
  );
}
