import { atom } from "jotai";
import { atomFamily } from "jotai/utils";

import type { ChannelId } from "@sunrise/backend-types-core";

import { availableChannelPackagesLegacyAtom } from "./queries/channel-packages.atom";

export const isChannelLockedLegacyAtom = atomFamily((id: ChannelId) => {
  const innerAtom = atom(async (get) => {
    const record = await get(availableChannelPackagesLegacyAtom);
    // NOTE: if backend failed (returned empty array), just assume channel is not locked. The backend would still return an error on stream request
    if (Object.keys(record).length === 0) {
      return false;
    }

    return !(id in record);
  });

  innerAtom.debugLabel = `isChannelLockedLegacyAtom(${id})`;

  return innerAtom;
});
