import fscreen from "fscreen";

import {
  actionPlayerSetFullscreenSupportedMode,
  actionPlayerToggleFullscreen,
  playerAtom,
  selectPlayerFullscreenSupportedMode,
  selectPlayerShouldBeFullscreen,
} from "@sunrise/player";
import type { Store } from "@sunrise/store";

/**
 * This is the fullscreen manager for web mode.
 * If web fullscreen is not supported, native fullscreen will be used (player only).
 * @param store
 */
export function initFullscreenManager(store: Store): void {
  // only if this function is called, we support fullscreen
  // TODO check if WEB, NATIVE or NONE (isIOS)
  store.set(
    playerAtom,
    actionPlayerSetFullscreenSupportedMode(
      fscreen.fullscreenEnabled ? "web" : "native",
    ),
  );

  const getBrowserFullscreenState = (): boolean => {
    return !!fscreen.fullscreenElement;
  };

  store.sub(selectPlayerShouldBeFullscreen, (): void => {
    if (store.get(selectPlayerFullscreenSupportedMode) !== "web") {
      return;
    }

    const shouldBeFullscreen = store.get(selectPlayerShouldBeFullscreen);
    const browserFullscreenState = getBrowserFullscreenState();
    if (shouldBeFullscreen && !browserFullscreenState) {
      fscreen.requestFullscreen(document.documentElement);
    } else if (!shouldBeFullscreen && browserFullscreenState) {
      fscreen.exitFullscreen();
    }
  });

  fscreen.addEventListener("fullscreenchange", () => {
    if (store.get(selectPlayerFullscreenSupportedMode) !== "web") {
      return;
    }

    const browserFullscreenState = getBrowserFullscreenState();
    const state = store.get(selectPlayerShouldBeFullscreen);
    if (state !== browserFullscreenState) {
      store.set(playerAtom, actionPlayerToggleFullscreen());
    }
  });
}
