import { atom } from "jotai";

import { clearSearchHistoryMutationAtom } from "./clear-search-history.mutation.atom";
import { hasSearchHistoryAtom } from "./has-search-history.atom";

export const clearSearchHistoryButtonAtom = atom(async (get) => {
  if (!hasSearchHistoryAtom) {
    return {
      visible: false,
      action: () => {
        return Promise.resolve();
      },
    };
  }

  return {
    visible: true,
    action: () => {
      return get(clearSearchHistoryMutationAtom).mutateAsync();
    },
  };
});
