import { atom } from "jotai";

import type { ChannelGroup } from "@sunrise/backend-types";

import { channelGroupsLegacyAtom } from "./queries/channel-groups.legacy.atom";
import { userChannelGroupsQueryLegacyAtom } from "./queries/user-channel-groups.legacy.atom";

/**
 * These are all the channel groups how the user sees them in the UI.
 * The user's channel groups before the static channel groups.
 *
 * @deprecated This is a legacy atom. Use `selectableChannelGroupsAtom` from `packages/yallo-channel-group/src/selectable-channel-groups.atom.ts` instead.
 */
export const selectableChannelGroupsLegacyAtom = atom<Promise<ChannelGroup[]>>(
  async (get) => {
    const [channelGroups, userChannelGroups] = await Promise.all([
      get(channelGroupsLegacyAtom),
      get(userChannelGroupsQueryLegacyAtom),
    ]);

    // User channel groups take priority over static channel groups
    return [...(userChannelGroups.data ?? []), ...(channelGroups.data ?? [])];
  },
);
