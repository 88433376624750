import { atomFamily } from "jotai/utils";
import { atomWithSuspenseQuery } from "jotai-tanstack-query";

import { ngEpgApiAtom } from "@sunrise/backend-ng-epg";
import { queryKeys } from "@sunrise/backend-types";
import type { AssetId } from "@sunrise/backend-types-core";
import { currentLanguageAtom } from "@sunrise/i18n";

/**
 * Available channels, seasons, availability with count for a specific asset
 *
 */
export const assetAvailableFiltersByAssetIdAtom = atomFamily(
  (assetId: AssetId) => {
    const inner = atomWithSuspenseQuery((get) => {
      const api = get(ngEpgApiAtom);
      const language = get(currentLanguageAtom);

      return {
        queryKey: queryKeys.assetFiltersByAssetId(assetId, language),
        queryFn: async () => {
          const { data } =
            await api.epg.getFiltersBySeriesAssetEpgV1AssetsAssetIdFiltersGet(
              assetId,
            );

          return data;
        },
      };
    });

    inner.debugLabel = `assetAvailableFiltersByAssetIdAtom((${assetId}))`;

    return inner;
  },
);
