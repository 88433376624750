import { add, addDays } from "date-fns";
import { atom } from "jotai";
import { atomFamily, selectAtom, unwrap } from "jotai/utils";
import { isEqual, isNil } from "lodash";

import {
  endpoints,
  type EPGEntry,
  type EPGEntryCollectionPerChannelPerDayResponse,
} from "@sunrise/backend-types";
import {
  type ChannelId,
  type EPGEntryId,
  type TimeDay,
} from "@sunrise/backend-types-core";
import { publicApi } from "@sunrise/http-client";
import { dateToTimeDay, nowAtom } from "@sunrise/time";
import type { Nullable } from "@sunrise/utils";

import { programIsPlayingAtTime } from "../helpers/program-is-playing-at-time";
import { selectEpgCollectionPerDayAtom } from "../select-epg-collection-per-day.atom";
import type { MappedEpg } from "../types";

export async function fetchSingleChannelEPGsPerEntireDay(
  host: string,
  day: TimeDay,
  channelId: ChannelId,
): Promise<EPGEntry[]> {
  const { data } =
    await publicApi.get<EPGEntryCollectionPerChannelPerDayResponse>(
      endpoints.epgCollectionPerChannelPerDay(host, day, channelId),
    );

  return data.result;
}

/**
 * Fetches single {@link EPGEntry}
 */
export async function fetchEPGEntry(
  host: string,
  id: EPGEntryId,
): Promise<EPGEntry> {
  const { data } = await publicApi.get<EPGEntry>(endpoints.epgEntry(host, id));
  return data;
}

/**
 * An atom which returns a function so we can ask what is playing at a specific time.
 * The problem is that the EPG data we get from the backend is not always correct.
 * When we ask for the EPG data on a specific day, it will not contain the first item of the day.
 */
export const getEpgEntryPlayingAtTimeOnChannel = atomFamily(
  (param: { channelId: ChannelId }) => {
    const innerAtom = atom((get) => {
      async function findForTime(time: Date, dayOffset = 0) {
        const day = dateToTimeDay(addDays(time, dayOffset));

        return (
          await get(
            selectEpgCollectionPerDayAtom({
              day,
              channelId: param.channelId,
            }),
          )
        ).data?.find((it) => {
          return programIsPlayingAtTime(
            {
              startTime: new Date(it.actualStart),
              endTime: new Date(it.actualEnd),
            },
            time,
          );
        });
      }

      return async (time: Date) => {
        return (
          (await findForTime(time)) ?? (await findForTime(time, -1)) ?? null
        );
      };
    });
    innerAtom.debugLabel = `getEpgEntryPlayingAtTimeOnChannel(${param.channelId})`;
    return innerAtom;
  },
  isEqual,
);

/**
 * @returns Nth EPG entry per single channel per day
 */
const selectNthEPGEntryPerDay = atomFamily(
  (param: { day: TimeDay; channelId: ChannelId; nth: number }) => {
    const inner = selectAtom(
      unwrap(
        selectEpgCollectionPerDayAtom({
          day: param.day,
          channelId: param.channelId,
        }),
      ),
      (s) => s?.data?.[param.nth],
    );
    inner.debugLabel = `selectNthEPGEntryPerDay(${param.day}, ${param.channelId}, ${param.nth})`;
    return inner;
  },
  isEqual,
);

/**
 * Atom that returns current live EPG.
 *
 * @throws if no EPGs found for given time frame or if no live EPG found
 */
export const currentLiveEPGEntryAtom = atomFamily((channelId: ChannelId) => {
  const innerAtom = atom(
    async (get): Promise<Nullable<[entry: MappedEpg, index: number]>> => {
      // subscribe to now so we can recompute atom when it changes
      const now = get(nowAtom);

      const day = dateToTimeDay(now);
      const egpEntries = await get(
        selectEpgCollectionPerDayAtom({
          channelId,
          day,
        }),
      );

      const matchedIdx = egpEntries.data?.findIndex((it) => {
        return programIsPlayingAtTime(
          {
            startTime: new Date(it.actualStart),
            endTime: new Date(it.actualEnd),
          },
          now,
        );
      });

      if (isNil(matchedIdx)) {
        return;
      }

      const epg = get(
        selectNthEPGEntryPerDay({
          channelId,
          day,
          nth: matchedIdx,
        }),
      );

      return epg ? [epg, matchedIdx] : null;
    },
  );
  innerAtom.debugLabel = `currentLiveEPGEntryAtom(${channelId})`;
  return innerAtom;
});

/**
 * Atom that returns next live EPG.
 *
 * @throws if no EPGs found for given time frame or if no live EPG found
 */
export const nextLiveEPGEntryAtom = atomFamily((channelId: ChannelId) => {
  const innerAtom = atom(async (get): Promise<Nullable<MappedEpg>> => {
    const [_, liveEgpIdx] =
      (await get(currentLiveEPGEntryAtom(channelId))) ?? [];

    const now = get(nowAtom);
    const today = dateToTimeDay(now);

    const nextLiveEpgEntry = isNil(liveEgpIdx)
      ? null
      : get(
          selectNthEPGEntryPerDay({
            channelId,
            nth: liveEgpIdx + 1,
            day: today,
          }),
        );

    if (!isNil(nextLiveEpgEntry)) return nextLiveEpgEntry;

    const tomorrow = dateToTimeDay(add(now, { days: 1 }));
    return get(
      selectNthEPGEntryPerDay({
        channelId,
        nth: 0,
        day: tomorrow,
      }),
    );
  });
  innerAtom.debugLabel = `nextLiveEPGEntryAtom(${channelId})`;
  return innerAtom;
});
