/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** AssetType */
export enum AssetType {
  Series = "series",
  Seriesepisode = "seriesepisode",
  Seriesseason = "seriesseason",
  Movie = "movie",
  Program = "program",
  Unknown = "unknown",
}

/** BaseRecordingSchema */
export interface BaseRecordingSchema {
  /** Type */
  type?: "recording";
  /**
   * Recording Item ID
   * The id of the recording item
   */
  id: string;
  asset: ReducedAssetSchema;
  /**
   * EPG entry ID
   * The id of the epg entry used to create the recording. Unavailable when the start date is outside the epg window.
   */
  epg_entry_id: string;
  /**
   * EPG Start Time
   * The start time of the EPG
   * @format date-time
   */
  epg_start: TimeISOString;
  /**
   * EPG End Time
   * The end time of the EPG
   * @format date-time
   */
  epg_end: TimeISOString;
  /**
   * Recording status
   * Status of the recording
   */
  status: RecordingStatus;
  /**
   * Channel name
   * name of the channel
   * @default ""
   */
  channel_name?: string;
  /**
   * Channel logo
   * URL of the channel's logo
   * @default ""
   */
  channel_logo?: string;
  /**
   * Channel ID
   * The original id of the channel. When the channel is deleted, this attribute will return null.
   */
  channel_id?: string | null;
  /**
   * Padding Start Minutes
   * The padding start minutes of the recording
   */
  padding_start_minutes: number;
  /**
   * Padding End Minutes
   * The padding end minutes of the recording
   */
  padding_end_minutes: number;
  /**
   * Recordings Stream Service URL
   * The recording URL of the stream service
   */
  stream_service_url: string | null;
  /**
   * Download Stream Service URL
   * The download URL of the stream service
   */
  stream_service_download_url: string | null;
}

/** ContinueWatchingRecordingSchema */
export interface ContinueWatchingRecordingSchema {
  /**
   * CW ID
   * The id of the continue watching
   */
  id: string;
  /** @default "ContinueWatching.ContinueWatchingRecording" */
  type?: ContinueWatchingType;
  /**
   * Fully Watched
   * Shows if the continue watching is fully watched
   * @default false
   */
  fully_watched?: boolean;
  recording: BaseRecordingSchema;
  /**
   * Time watched
   * The amount of time the continue watching recording is watched
   */
  readonly progress: string;
}

/** ContinueWatchingReplaySchema */
export interface ContinueWatchingReplaySchema {
  /**
   * CW ID
   * The id of the continue watching
   */
  id: string;
  /** @default "ContinueWatching.ContinueWatchingLive.ContinueWatchingTimeshift.ContinueWatchingReplay" */
  type?: ContinueWatchingType;
  /**
   * Fully Watched
   * Shows if the continue watching is fully watched
   * @default false
   */
  fully_watched?: boolean;
  epg_entry: EpgEntrySchema;
  /**
   * Time watched
   * The amount of time the continue watching recording is watched
   */
  readonly progress: string;
  /**
   * Stream Service URL
   * The url for the stream of the continue watching
   */
  readonly stream_service_url: string;
}

/** ContinueWatchingStatusSchema */
export interface ContinueWatchingStatusSchema {
  /**
   * Elapsed Time
   * Iso duration string, relative to epg_entry_start
   */
  elapsed_time: string;
  /**
   * Epg Entry Start
   * Available only for replay objects
   */
  epg_entry_start?: TimeISOString | null;
  /**
   * Epg Entry Id
   * Available only in replay objects
   */
  epg_entry_id: string | null;
  /**
   * Recording Id
   * Id of the recording. Available only for recording objects
   */
  recording_id: string | null;
  /** Fully Watched */
  readonly fully_watched: boolean;
}

/** ContinueWatchingType */
export enum ContinueWatchingType {
  ContinueWatchingContinueWatchingRecording = "ContinueWatching.ContinueWatchingRecording",
  ContinueWatchingContinueWatchingLiveContinueWatchingTimeshiftContinueWatchingReplay = "ContinueWatching.ContinueWatchingLive.ContinueWatchingTimeshift.ContinueWatchingReplay",
}

/** EpgEntrySchema */
export interface EpgEntrySchema {
  /**
   * EPG ID
   * The id of the EPG
   */
  id: string;
  /**
   * Start
   * The start time of the epg
   * @format date-time
   */
  start: TimeISOString;
  /**
   * End
   * The end time of the epg
   * @format date-time
   */
  end: TimeISOString;
  asset: ReducedAssetSchema;
  channel: ReducedChannelSchema;
  /**
   * Replay Stream Service URL
   * The replay URL of the stream service
   */
  stream_service_url: string;
}

/** HTTPValidationError */
export interface HTTPValidationError {
  /** Detail */
  detail?: ValidationError[];
}

/** Page[Union[ContinueWatchingReplaySchema, ContinueWatchingRecordingSchema]] */
export interface PageUnionContinueWatchingReplaySchemaContinueWatchingRecordingSchema {
  /** Items */
  items: (ContinueWatchingReplaySchema | ContinueWatchingRecordingSchema)[];
  /** Total */
  total: number | null;
  /** Page */
  page: number | null;
  /** Size */
  size: number | null;
  /** Pages */
  pages?: number | null;
}

/** RecordingStatus */
export enum RecordingStatus {
  All = "all",
  Recorded = "recorded",
  Planned = "planned",
  Canceled = "canceled",
  Deleted = "deleted",
}

/** ReducedAssetSchema */
export interface ReducedAssetSchema {
  /**
   * Asset ID
   * The id of the asset
   */
  id: string;
  /**
   * Title
   * The title of the asset
   */
  title: string;
  /**
   * Subtitle
   * The subtitle of the asset
   * @default ""
   */
  subtitle?: string | null;
  /**
   * Poster
   * The poster url of the asset
   */
  poster_url: string;
  /**
   * Season Number
   * The season number of the series episode
   */
  season_number?: number | null;
  /**
   * Episode Number
   * The episode number of the series episode
   */
  episode_number?: number | null;
  /**
   * Blurhash of the Poster
   * @default "eCF6B#-:0JInxr?@s;nmIoWUIko1%NocRk.8xbIUaxR*^+s;RiWAWU"
   */
  poster_blurhash?: string;
  /**
   * Asset Type
   * The type of the asset (movie, series episode...)
   * @default "unknown"
   */
  type?: AssetType | null;
  /**
   * Asset Recording Available
   * Is it possible to record this asset as a series?
   */
  readonly asset_recording_available: boolean;
}

/** ReducedChannelSchema */
export interface ReducedChannelSchema {
  /**
   * Channel ID
   * The id of the channel
   */
  id: string;
  /**
   * Channel Name
   * The name of the channel
   */
  name: string;
  /**
   * Channel Logo
   * The logo of the channel
   */
  logo: string;
  /**
   * Stream Service URL
   * The url of the stream service
   */
  stream_service_url: string;
}

/** ValidationError */
export interface ValidationError {
  /** Location */
  loc: (string | number)[];
  /** Message */
  msg: string;
  /** Error Type */
  type: string;
}

import { HttpClient, RequestParams } from "@sunrise/backend-ng-core";
import type { TimeISOString } from "@sunrise/backend-types-core";

/**
 * @title FastAPI
 * @version 0.1.0
 */
export class GeneratedApi {
  http: HttpClient;

  constructor(http: HttpClient) {
    this.http = http;
  }

  continueWatching = {
    /**
     * @description Returns a list of all continue watching entries of a user with the id from the jwt payload
     *
     * @tags all entries
     * @name GetContinueWatchingForUserContinueWatchingV1ContinueWatchingEntriesGet
     * @summary Get Continue Watching For User
     * @request GET:/continue-watching/v1/continue-watching-entries
     * @secure
     */
    getContinueWatchingForUserContinueWatchingV1ContinueWatchingEntriesGet: (
      query?: {
        /**
         * Page
         * Page number
         * @min 1
         * @default 1
         */
        page?: number;
        /**
         * Size
         * Page size
         * @min 1
         * @max 100
         * @default 50
         */
        size?: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<
        PageUnionContinueWatchingReplaySchemaContinueWatchingRecordingSchema,
        void | HTTPValidationError
      >({
        path: `/continue-watching/v1/continue-watching-entries`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns a list of all continue watching entries of a user with the id from the jwt payload
     *
     * @tags all entries
     * @name GetContinueWatchingStatusContinueWatchingV1ContinueWatchingStatusGet
     * @summary Get Continue Watching Status
     * @request GET:/continue-watching/v1/continue-watching-status
     * @secure
     */
    getContinueWatchingStatusContinueWatchingV1ContinueWatchingStatusGet: (
      query?: {
        /** Epg Entry Id */
        epg_entry_id?: string | null;
        /** Recording Id */
        recording_id?: string | null;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<ContinueWatchingStatusSchema, void | HTTPValidationError>({
        path: `/continue-watching/v1/continue-watching-status`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name HealthContinueWatchingV1HealthGet
     * @summary Health
     * @request GET:/continue-watching/v1/health
     */
    healthContinueWatchingV1HealthGet: (params: RequestParams = {}) =>
      this.http.request<any, any>({
        path: `/continue-watching/v1/health`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name RootContinueWatchingV1Get
     * @summary Root
     * @request GET:/continue-watching/v1
     */
    rootContinueWatchingV1Get: (params: RequestParams = {}) =>
      this.http.request<any, any>({
        path: `/continue-watching/v1`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
}
