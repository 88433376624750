import { atom } from "jotai";

import { isNil } from "@sunrise/utils";

import { playerDateTimeConverterAtom } from "./player-date-time-converter.atom";
import { selectPlayerCurrentTime } from "./selectors";

/**
 * It will return the Date mapped to the player's current position.
 *
 * This is needed because the store doesn't actually contain the correct position.
 * Also, all these calculations are only necessary at read time.
 *
 * This does NOT take seek into account.
 * So it is actually purely reporting what the player is currently reporting.
 */
export const playerCurrentDateTimeAtom = atom((get) => {
  const current = get(selectPlayerCurrentTime);

  if (isNil(current)) {
    return null;
  }

  const converter = get(playerDateTimeConverterAtom);
  if (!converter) {
    return null;
  }

  return converter.toDate(current);
});
