import { useCallback } from "react";
import { useAtomCallback } from "jotai/utils";

import { startupChannelIdAtom } from "@sunrise/yallo-user";

import {
  actionPlayerManagerPlayLiveChannelId,
  playerManagerAtom,
} from "../player-manager.atom";

export function usePlayStartupChannel(): (
  origin: "autostart" | "autorecover",
) => void {
  return useAtomCallback(
    useCallback((get, set, origin: "autostart" | "autorecover") => {
      const exec = async (): Promise<void> => {
        const channelId = await get(startupChannelIdAtom);

        if (!channelId) {
          return;
        }

        set(
          playerManagerAtom,
          actionPlayerManagerPlayLiveChannelId(channelId, origin),
        );
      };

      void exec();
    }, []),
  );
}
