import { forwardRef } from "react";

import { ButtonOrLink, type ButtonProps } from "./button-or-link";

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  function Button(props, ref) {
    return <ButtonOrLink ref={ref} as="button" {...props} />;
  },
);

export { Button };
