import { atom } from "jotai";

import { isNil } from "@sunrise/utils";

import { playerDateTimeConverterAtom } from "./player-date-time-converter.atom";
import {
  selectPlayerCurrentSeekTime,
  selectPlayerCurrentTime,
  selectPlayerInLinearSeekConfirmation,
} from "./selectors";

/**
 * It will return the Date mapped to the player's current position.
 *
 * This is needed because the store doesn't actually contain the correct position.
 * Also, all these calculations are only necessary at read time.
 *
 * This does take seek into account. When we confirmed the seek or when we are seeking,
 * it will pretend the player's time is still at the position of the seek until told otherwise.
 * If you want to use the raw value of the player you need to use `playerCurrentDateTimeAtom` instead.
 */
export const playerCurrentDateTimeShownAtom = atom((get) => {
  const seekConfirmationLinear = get(selectPlayerInLinearSeekConfirmation);

  if (seekConfirmationLinear) {
    return seekConfirmationLinear;
  }

  const currentTime = get(selectPlayerCurrentTime);
  const current = get(selectPlayerCurrentSeekTime) ?? currentTime;
  if (isNil(current)) {
    return null;
  }

  const converter = get(playerDateTimeConverterAtom);
  if (!converter) {
    return null;
  }

  return converter.toDate(current);
});
