import { useCallback } from "react";
import { useAtomValue } from "jotai";
import { useAtomCallback } from "jotai/utils";

import type {
  EPGEntryId,
  RecordingId,
  TimeISOString,
} from "@sunrise/backend-types-core";
import { useTranslator } from "@sunrise/translator";

import { manageEpgSingleProgramRecordingsActionsAtom } from "@/modules/recordings/manage-epg-single-program-recordings-actions.atom";
import { manageRecordingSingleProgramRecordingsActionsAtom } from "@/modules/recordings/manage-recording-single-program-recordings-actions.atom";

export function useRecordProgramHook(
  props:
    | {
        epgId: EPGEntryId;
        /**
         * This is to assist the atom so we do not have to load the epg details to determine if it is in the past or not.
         */
        startTime: TimeISOString;
        /**
         * This is to assist the atom so we do not have to load the epg details to determine if it is in the past or not.
         */
        endTime: TimeISOString;
        isSeriesEpisode: boolean;
        type: "epg";
      }
    | {
        type: "single";
        recordingId: RecordingId;
      },
) {
  const t = useTranslator();

  const baseAtom =
    props.type === "epg"
      ? manageEpgSingleProgramRecordingsActionsAtom({
          startTime: props.startTime,
          endTime: props.endTime,
          epgId: props.epgId,
          isSeriesEpisode: props.isSeriesEpisode,
        })
      : manageRecordingSingleProgramRecordingsActionsAtom(props.recordingId);

  /**
   * When we trigger the action we need to write to the actionAtom.
   * The actionAtom is already wired up to know the context and will undertake the expected action.
   * Aka, the one described in the action attribute that is returned from the baseAtom.
   */
  const onClick = useAtomCallback(
    useCallback(
      async (get, set) => {
        const { actionAtom } = await get(baseAtom);
        if (!actionAtom) {
          return;
        }

        set(actionAtom);
      },
      [baseAtom],
    ),
  );

  const { action, isLoading, actionAtom } = useAtomValue(baseAtom);
  if (!action) {
    return null;
  }

  const { iconName, label } = MAPPING[action];
  return {
    iconName,
    label: t(label),
    onClick,
    disabled: !actionAtom,
    isLoading,
  };
}

const MAPPING = {
  delete: {
    iconName: "deleteForever",
    label: "button_recordings_delete",
  },
  cancel: {
    iconName: "recording",
    label: "program_detail_page_button_cancel",
  },
  record: {
    iconName: "record",
    label: "program_detail_page_button_record",
  },
} as const;
