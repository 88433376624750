import type {
  PlayerDelayedBufferSettings,
  PlayerLiveBufferSettings,
} from "./player.types";
import type { PlayerCurrentContent } from "./types";

export const VIDEO_ELEMENT_ID = "video-player";

export const THROTTLE_TIME_IN_MS = 1000;

export const DEFAULT_PLAYER_LIVE_BUFFER_SETTINGS: PlayerLiveBufferSettings = {
  min: 2,
  max: 30,
  delay: 7,
};

export const DEFAULT_PLAYER_DELAYED_BUFFER_SETTINGS: PlayerDelayedBufferSettings =
  { min: 2, max: 30 };

export const EMPTY: Readonly<PlayerCurrentContent> = {
  epgId: null,
  assetId: null,
  channelId: null,
  channelName: null,
  recordingId: null,
  title: null,
  subtitle: null,
  channelLogo: null,
  schedule: null,
  image: null,
};
