import { subDays } from "date-fns";

import { REPLAY_DAYS_IN_PAST } from "../replay.constants";

export type ReplayWindow = { start: Date; end: Date };
export function getReplayWindow(now: Date): ReplayWindow {
  return {
    start: subDays(now, REPLAY_DAYS_IN_PAST),
    end: now,
  };
}
