import { atomEffect } from "jotai-effect";

import { unwrappedAllChannelsInChannelGroup } from "./atoms/unwrapped-all-channels-in-channel-group.atom";
import { actionGuideClearSelection } from "./store/grid-state.actions";
import { gridStateAtom } from "./store/grid-state.atom";
import { selectorGuideSelection } from "./store/grid-state.selectors";

/**
 * This effect will clean the selection in the guide if the selection is not present in the guide.
 * For example when the channel list is no longer in the list of available channels.
 */
export const guideCleanSelectionEffect = atomEffect((get, set) => {
  const selection = get(selectorGuideSelection);
  if (!selection) {
    return;
  }

  const channels = get(unwrappedAllChannelsInChannelGroup);

  if (
    channels &&
    channels.every((channel) => selection.channelId !== channel.id)
  ) {
    set(gridStateAtom, actionGuideClearSelection());
  }
});
