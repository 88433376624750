import type { TenantId, UserId } from "@sunrise/backend-types-core";
import { type Nullable } from "@sunrise/utils";

export type AuthenticationTokens = {
  refreshToken: Nullable<string>;
  accessToken: Nullable<string>;
};

export type LoginResponse = {
  user_id?: string;
  client_jwt_refresh_token?: string;
  client_jwt_token?: string;
  /**
   * An optional token only returned in the v2 login flow.
   * This token is needed to authenticate with the ng websocket.
   */
  client_jwt_websocket_token?: string;
  tenant_id?: TenantId;
  user?: {
    gender: string;
    id: UserId;
    age: number;
  };
};

export type AnonymousResponse = {
  id: string;
};

export type KaminoSettings = {
  backend_client_id: string;
  remote_auth_endpoint: string;
  other_clients: {
    client_id: string;
    client_name: string;
  }[];
  region: string;
  userpool_id: string;
  jwt_public_key: string;
};

export enum OAuthScope {
  PHONE = "phone",
  EMAIL = "email",
  OPENID = "openid",
  PROFILE = "profile",
  AWS_COGNITO_SIGNIN_USER_ADMIN = "aws.cognito.signin.user.admin",
}

export enum OAuthGrant {
  CODE = "code",
  TOKEN = "token",
}

export type PinResponse = {
  id: string;
  pin: string;
  qr_code_url: string;
  approve_url: string;
  access_token: string | null;
  refresh_token: string | null;
  redeemed: boolean;
  approved: boolean;
  expires_at: string;
};

export type LogoutResponse =
  | {
      status: "ok";
    }
  | { message: string };
