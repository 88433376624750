import { atom } from "jotai";

import { isSSR } from "@sunrise/utils";

/**
 * The user-agent that the browser indicates.
 */
export const userAgentAtom = atom(
  isSSR() ? undefined : window.navigator.userAgent,
);
