import { atomWithSuspenseQuery } from "jotai-tanstack-query";
import { isNil } from "lodash";

import type { AllChannelsResponse } from "@sunrise/backend-types";
import { hostsAtom } from "@sunrise/http-client";

import { fetchAllChannels } from "../channel.service";

const ONE_DAY_IN_MS = 1000 * 60 * 60 * 24;

/**
 * https://entwicklungspark.atlassian.net/wiki/spaces/WT/pages/2952790017/Static+JSON+files+generation+Python+backend
 *
 * @deprecated Legacy atom. We should no longer be grabbing all the channels all the time. There are methods to select a single channel or methods to grab just the channels inside your channel group.
 */
export const allChannelsQueryLegacyAtom =
  atomWithSuspenseQuery<AllChannelsResponse>((get) => {
    const host = get(hostsAtom).data;
    if (isNil(host)) throw new Error("Host is not set");

    return {
      queryKey: ["channels"],
      cacheTime: Infinity,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      staleTime: ONE_DAY_IN_MS,
      queryFn: async () => {
        try {
          return await fetchAllChannels(host);
        } catch (e) {
          console.error(e);
          return { result: [] };
        }
      },
    };
  });
allChannelsQueryLegacyAtom.debugLabel = "allChannelsQueryLegacyAtom";
