import { atom } from "jotai";

import { jwtAtom } from "@sunrise/jwt";

import { makePermissionsFromJWT } from "./make-permissions-from-jwt";
import type { PlayerManagerPermissions } from "./yallo-common-player-manager.types";

export const playerPermissionsAtom = atom<PlayerManagerPermissions>((get) => {
  const payload = get(jwtAtom);

  if (!payload.decodedPayload) {
    return {
      canPlayLive: false,
      canReplay: false,
      showAds: false,
      showReplayAlternativeAds: false,
      showReplayFfwdAds: false,
    };
  }
  return makePermissionsFromJWT(payload.decodedPayload);
});
