import type { ComponentProps, ReactElement } from "react";
import clsx from "clsx";

import { Icon } from "@sunrise/icons";

import { Link as BaseLink } from "../../button";
import { Text } from "../../text";
import styles from "./button.module.css";

type Props = { showMore?: boolean } & Omit<
  ComponentProps<typeof BaseLink>,
  "variant"
>;

const Link = ({
  children,
  className,
  showMore = false,
  ...props
}: Props): ReactElement => {
  return (
    <BaseLink
      variant="none"
      {...props}
      className={clsx(className, styles.button)}
    >
      <Text size="medium" variant="body">
        {children}
      </Text>
      {showMore && <Icon className={styles.more} name="chevronRight" />}
      {props.target === "_blank" && (
        <Icon className={styles.more} name="openInNew" />
      )}
    </BaseLink>
  );
};

export { Link };
