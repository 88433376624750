import { isNil } from "@sunrise/utils";

import type { GuideProgram } from "../guide.types";

/**
 * This returns the programs that are starting in this timeblock. Except when we pass isFirst, then we also include all the programs that started OR ended in the previous timeblock
 *
 * @param time
 * @param programs
 * @param durationInMinutes
 * @param isFirst
 * @returns
 */
export function getProgramsStartingAtTime(
  time: Date,
  durationInMinutes: number,
  programs: GuideProgram[],
  isFirst = false,
): GuideProgram[] {
  let firstIndex = programs.findIndex((program) => program.startTime >= time);
  if (firstIndex === -1) {
    return [];
  }

  // now we need to seek a bit back until we find the first program that did not start in the previous timeblock (only for isFirst)
  if (isFirst) {
    const previousBlockStart = new Date(
      time.getTime() - durationInMinutes * 60 * 1000,
    );
    const firstProgramInPreviousBlock = programs.find(
      (program) => program.endTime >= previousBlockStart,
    );
    if (!isNil(firstProgramInPreviousBlock)) {
      firstIndex = programs.indexOf(firstProgramInPreviousBlock);
    }
  }

  const start = programs.slice(firstIndex);
  const offset = new Date(time.getTime() + durationInMinutes * 60 * 1000);
  const lastIndex = start.findIndex((program) => program.startTime >= offset);

  if (lastIndex > -1) {
    return start.slice(0, lastIndex);
  }

  return start;
}
