import type { ContinueWatchingItemLegacy } from "@sunrise/backend-types";
import type { EPGEntryId, RecordingId } from "@sunrise/backend-types-core";
import type { Nullable } from "@sunrise/utils";

import type { ContinueWatchingStatus } from "../../types";

export function continueWatchingItemLegacyToContinueWatchingStatus(
  status: Nullable<ContinueWatchingItemLegacy>,
  fw: boolean = false,
  epgId: Nullable<EPGEntryId> = null,
): ContinueWatchingStatus | null {
  switch (status?.type) {
    case "recording":
      return {
        type: "recording",
        recordingId: status.recording.id as RecordingId,
        isFullyWatched: fw,
        playPositionSecond: status.play_position_second,
      };
    case "replay":
      return {
        epgId: status.epg_entry.id,
        type: "replay",
        isFullyWatched: fw,
        moment: status.moment,
        epgStartTime: status.epg_entry_start,
      };
    default:
      if (fw && epgId) {
        return {
          type: "replay",
          isFullyWatched: true,
          moment: null,
          epgStartTime: null,
          epgId,
        };
      }

      return null;
  }
}
