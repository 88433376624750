import { format } from "date-fns";

import { type Nullable } from "@sunrise/utils";
import type { MappedEpg } from "@sunrise/yallo-epg";

export function epgEntryToStartAndTitle(epgEntry: Nullable<MappedEpg>): {
  start: string;
  title: string;
} {
  if (!epgEntry)
    return {
      start: "",
      title: "",
    };

  return {
    start: format(new Date(epgEntry.actualStart).getTime(), "HH:mm"),
    title: epgEntry.asset.title ?? "",
  };
}
