import type { ComponentProps, ReactElement } from "react";
import { usePathname } from "wouter/use-browser-location";

import { Link, Text } from "@sunrise/yallo-web-components";

type Props = ComponentProps<typeof Link> & CommonProps;

function NavLink({ children, ...props }: Props): ReactElement {
  const pathname = usePathname();
  const isCurrent = props.href === pathname;
  const isParent =
    !isCurrent && props.href.split("/")[1] === pathname.split("/")[1];

  return (
    <Link
      {...props}
      aria-current={isCurrent ? "page" : undefined}
      data-parent={isParent ? true : false}
      variant="none"
    >
      {typeof children === "string" ? (
        <Text size="large" variant="label">
          {children}
        </Text>
      ) : (
        children
      )}
    </Link>
  );
}

export { NavLink };
