import { atom } from "jotai";
import { atomEffect } from "jotai-effect";

import { isLegacyBackendAtom } from "@sunrise/backend-core";
import type {
  EPGEntryId,
  RecordingGroupId,
  RecordingId,
} from "@sunrise/backend-types-core";
import { selectWsToken } from "@sunrise/jwt";
import type { Nullable } from "@sunrise/utils";

import { socketConnectedAtom } from "./socket-connected.atom";
import { socketUrlAtom } from "./socket-url.atom";

type RecordingStatusUpdate = {
  object_id: RecordingId;
  event_type: "created" | "updated";
  status: string;
  object_type: "recording";
  payload: {
    status: "recorded" | "planned";
    epg_entry_id?: Nullable<EPGEntryId>;
  };
};

type RecordingDeleted = Omit<
  RecordingStatusUpdate,
  "payload" | "event_type"
> & { event_type: "deleted" };

type RecordingGroupUpdate = {
  object_id: RecordingGroupId;
  event_type: "created" | "updated";
  status: string;
  object_type: "recording_group";
  payload: {
    status: "recorded" | "planned" | "mixed";
  };
};

type SocketMessages = {
  ["recordings"]: {
    payload: RecordingStatusUpdate | RecordingDeleted | RecordingGroupUpdate;
  };
};

/**
 * Just a very basic websocket instance for internal use.
 * Will be set whenever the socketUrlAtom or selectWsToken or socketConnectionCountAtom changes.
 */
const _socketAtomInternal = atom<WebSocket | null>(null);

function buildSocket(url: string, token: string): WebSocket {
  const u = new URL(`${url}/event/v1/websocket`);
  u.searchParams.set("authorization", token);
  return new WebSocket(u.href);
}

_socketAtomInternal.debugPrivate = true;

/**
 * Will return an layer over the socket on which consumers can register listeners.
 *
 * It will only start up when there's a valid JWT websocket token.
 */
export const socketAtom = atom((get) => {
  const socket = get(_socketAtomInternal);

  get(trackReconnectSocketOnErrorEffect);

  if (!socket) return null;

  return {
    /**
     * Allows registering a listener for a specific namespace.
     *
     * The listener will be required to filter out further messages in this namespace.
     */
    on<K extends keyof SocketMessages>(
      namespace: K,
      cb: (data: SocketMessages[K]["payload"]) => void,
    ) {
      const listener = (event: MessageEvent) => {
        const data = JSON.parse(event.data);
        if (data.namespace === namespace) {
          cb(data);
        }
      };
      socket.addEventListener("message", listener);

      return () => {
        socket.removeEventListener("message", listener);
      };
    },
  };
});

/**
 * Socket should rebuild itself whenever this number increases.
 */
const _socketConnectionCountAtom = atom(0);
_socketConnectionCountAtom.debugPrivate = true;
/**
 * When the socket errors, we should reconnect it automatically.
 * We do the reconnect with a delay that increases with each error.
 * To a maximum of 1 minute.
 *
 * The reset happens by setting the socketConnectionCountAtom.
 * Which is something the socket creation depends on and so it will
 * recreate itself because of the the updated count.
 */
const trackReconnectSocketOnErrorEffect = atomEffect((get, set) => {
  const url = get(socketUrlAtom);
  const token = get(selectWsToken);

  if (get(isLegacyBackendAtom)) {
    set(_socketAtomInternal, null);
    return;
  }

  if (!url || !token) {
    if (process.env["NODE_ENV"] === "development") {
      console.warn("ng socket needs a reauthentication before it can start");
    }
    set(_socketAtomInternal, null);
    return;
  }

  get(_socketConnectionCountAtom);
  let timeoutForError: ReturnType<typeof setTimeout> | null = null;

  const socket = buildSocket(url, token);
  set(_socketAtomInternal, socket);

  socket.addEventListener("open", function () {
    set(socketConnectedAtom, true);
  });

  socket.addEventListener("close", function () {
    set(socketConnectedAtom, false);
  });

  /**
   * Random number between 1 and 5 so some clients have slower reconnects.
   */
  const backoffMultiplier = 1000 + Math.random() * 4000;
  socket.addEventListener("error", function () {
    const count = get(_socketConnectionCountAtom);
    // Spread reconnects over 3m.
    const delay = Math.min(count + 1, 180);
    timeoutForError = setTimeout(() => {
      set.recurse(_socketConnectionCountAtom, count + 1);
    }, delay * backoffMultiplier);
  });

  return () => {
    set(socketConnectedAtom, false);
    if (timeoutForError) {
      clearTimeout(timeoutForError);
    }
    socket.close();
  };
});
