import { type Atom } from "jotai";

import type { Store } from "./store";

/**
 * Returns a promise that resolves when the atom changes to a value that matches the matcher.
 *
 * @param store
 *   The store on which to subscribe.
 * @param atom
 *   The atom to watch for changes.
 * @param matcher
 *   A function that receives the state of the atom and then returns true when the promise should resolve.
 */
export function untilAtomChanged<T>(
  store: Store,
  atom: Atom<T>,
  matcher: (v: T) => boolean,
): Promise<void> {
  return new Promise<void>((resolve) => {
    const sub = store.sub(atom, () => {
      const v = store.get(atom);

      if (matcher(v)) {
        sub();
        resolve();
      }
    });
  });
}
