import type { ReactElement } from "react";
import clsx from "clsx";

import styles from "./separator.module.css";

type SeparatorProps = Pick<CommonProps, "className" | "data-testid">;

function Separator({
  className,
  "data-testid": dataTestId,
}: SeparatorProps): ReactElement {
  return (
    <div className={clsx([styles.root, className])} data-testid={dataTestId} />
  );
}

export { Separator };
