import type { ReactNode } from "react";
import clsx from "clsx";

import type { ChannelRecommendation } from "@sunrise/yallo-recommendations";

import { Button } from "../button";
import { ChannelLogo } from "../logo";
import styles from "./channel-box.module.css";

type ChannelBoxProps = {
  onClick?: () => void;
  className?: string;
  variant: "search" | "home";
} & Omit<ChannelRecommendation, "channelId" | "type">;

export function ChannelBox({
  channelLogo,
  channelName,
  onClick,
  className,
  variant,
}: ChannelBoxProps): ReactNode {
  const Wrapper = onClick ? Button : "div";

  return (
    <Wrapper
      className={clsx([
        styles.wrapper,
        onClick && styles.button,
        styles[variant],
        className,
      ])}
      variant="none"
      onClick={onClick}
    >
      <div className={styles.container}>
        <div
          className={styles.logoBackground}
          style={{
            backgroundImage: `url(${channelLogo})`,
          }}
        />
        <ChannelLogo
          className={styles.logo}
          logo={channelLogo}
          name={channelName}
        />
      </div>
    </Wrapper>
  );
}
