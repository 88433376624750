import type { Atom } from "jotai";
import { atom } from "jotai";
import { atomFamily } from "jotai/utils";

import type { Channel } from "@sunrise/backend-types";
import type { ChannelId } from "@sunrise/backend-types-core";
import type { Nullable } from "@sunrise/utils";

import { allChannelsQueryLegacyAtom } from "./queries/all-channels.query.legacy.atom";

/**
 * An atom to get the details of a specific channelId.
 *
 * It is important that we look through all the channels here and not just the channels in the currently selected channel group.
 *
 * @deprecated
 */
export const channelByIdLegacyAtom = atomFamily<
  Nullable<ChannelId>,
  Atom<Promise<Nullable<Channel>>>
>((channelId: Nullable<ChannelId>) => {
  const innerAtom = atom(async (get) => {
    return (await get(allChannelsQueryLegacyAtom)).data.result.find(
      (channel) => channel.id === channelId,
    );
  });
  innerAtom.debugLabel = `channelByIdLegacyAtom(${channelId})`;
  return innerAtom;
});
