import { type JWTPayload } from "@sunrise/jwt";

import { type PlayerManagerPermissions } from "./yallo-common-player-manager.types";

export function makePermissionsFromJWT(
  jwt: JWTPayload,
): PlayerManagerPermissions {
  return {
    canReplay: jwt.feature_set.features.can_replay,
    showAds: jwt.feature_set.features.show_ads,
    canPlayLive: jwt.feature_set.features.can_play_live,
    showReplayAlternativeAds:
      jwt.feature_set.features.show_replay_alternative_ads,
    showReplayFfwdAds:
      jwt.feature_set.features.show_fast_forward_alternative_ads,
  };
}
