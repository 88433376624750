import type { InfiniteData } from "@tanstack/query-core";
import { atomWithInfiniteQuery } from "jotai-tanstack-query";

import type { PageWithItems } from "@sunrise/backend-ng-core";
import { queryKeys } from "@sunrise/backend-types";
import { selectJwtUserToken } from "@sunrise/jwt";
import { isNil } from "@sunrise/utils";

import { recordingStatusToType } from "./helpers/recording-status-to-type";
import { ngRecordingsApiAtom } from "./ng-recordings-api.atom";
import { recordingsStaleTimeAtom } from "./recordings-stale-time.atom";
import type { RecordingStatusForRecording } from "./types";

/**
 * Returns the recording statuses for all recordings.
 */
export const recordingStatusesQueryAtom = atomWithInfiniteQuery<
  PageWithItems<RecordingStatusForRecording>,
  unknown,
  InfiniteData<PageWithItems<RecordingStatusForRecording>>,
  ReturnType<typeof queryKeys.recordingsStatus>,
  number
>((get) => {
  const api = get(ngRecordingsApiAtom);

  const queryKey = queryKeys.recordingsStatus(get(selectJwtUserToken), true);

  return {
    initialPageParam: 1,
    queryKey,
    queryFn: async ({ pageParam = 1 }) => {
      const { data } =
        await api.recording.getRecordingsStatusRecordingV1RecordingsStatusGet({
          page: pageParam,
          // We can apparently ask for a maximum of 100 items per page instead of 50 on the other pages. So let's do that.
          size: 100,
        });

      return {
        ...data,
        items: data.items.map(recordingStatusToType),
      };
    },
    getNextPageParam: (lastPage) => {
      if (isNil(lastPage) || !lastPage.pages || !lastPage.page)
        return undefined;

      const { page, pages } = lastPage;
      return page < pages ? page + 1 : undefined;
    },
    staleTime: get(recordingsStaleTimeAtom),
    gcTime: get(recordingsStaleTimeAtom),
  };
});
