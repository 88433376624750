import { atom } from "jotai";

import { socketAuthenticatedAtom } from "@sunrise/yallo-websocket";

import { RECORDING_DATA_STALE_TIME_IN_MS } from "./constants";

/**
 * This can be infinite since we should automatically update this through the socket.
 * Secondly, we manually invalidate the cache whenever we detect a change in the stats.
 *
 * NOTE: On disconnect of the socket, we do not automatically update the staleTime.
 *       It's just that the next request will then update the staleTime.
 */
export const recordingsStaleTimeAtom = atom((get) => {
  const socketAuthenticated = get(socketAuthenticatedAtom);
  return socketAuthenticated
    ? RECORDING_DATA_STALE_TIME_IN_MS * 10
    : RECORDING_DATA_STALE_TIME_IN_MS;
});
