import { type ReactNode, useCallback } from "react";
import { useAtomValue } from "jotai";
import { useAtomCallback } from "jotai/utils";

import {
  actionDialogClose,
  dialogAtom,
  selectCurrentlyOpenedDialog,
} from "@sunrise/dialogs";
import { Dialog } from "@sunrise/yallo-web-components";

import type { DialogProps } from "@/components/dialogs";
import { ActionsDialog } from "@/components/dialogs";

import { DialogContainer } from "./dialog-container";

const TEST_ID = "dialog";

/**
 * This dialog will check the type of the DialogAtom and render the necessary contents inside.
 *
 * For now we support 1 dialog types here:
 * - ActionsDialog
 */
export function GenericDialog(): ReactNode {
  const dialog = useAtomValue(selectCurrentlyOpenedDialog);

  const doCloseModalId = useAtomCallback(
    useCallback((_, set, id: string) => {
      set(dialogAtom, actionDialogClose({ id }));
    }, []),
  );

  const doClose = useCallback(() => {
    if (!dialog) {
      return;
    }

    doCloseModalId(dialog.id);
  }, [dialog, doCloseModalId]);

  const testId = TEST_ID;

  const content = dialog
    ? getContent({ dialog, "data-testid": testId, doClose })
    : null;

  return (
    <Dialog
      closable={
        !dialog?.backBehaviour || dialog.backBehaviour === "close-modal"
      }
      open={!!content}
      onClose={doClose}
    >
      {dialog ? (
        <DialogContainer data-testid={`${testId}.content`} dialog={dialog}>
          {content}
        </DialogContainer>
      ) : null}
    </Dialog>
  );
}

function getContent(options: DialogProps): ReactNode {
  switch (options.dialog.type) {
    case "actions":
      return <ActionsDialog {...options} dialog={options.dialog} />;
    default:
      return null;
  }
}
