import {
  type ReactElement,
  startTransition,
  useCallback,
  useState,
} from "react";
import { useAtomValue } from "jotai";
import { useAtomCallback } from "jotai/utils";

import { logButtonClickAtom } from "@sunrise/analytics";
import { type Language, languages } from "@sunrise/backend-types-core";
import { currentLanguageAtom } from "@sunrise/i18n";
import { useTranslator } from "@sunrise/translator";
import { SlideOut } from "@sunrise/yallo-web-components";

type Props = CommonProps;

function LanguageSwitcher({
  "data-testid": dataTestId = "language-switcher",
}: Props): ReactElement | null {
  const currentLanguage = useAtomValue(currentLanguageAtom);
  // NOTE: with startTransition the atom doesn't update instantly and radio checked state is behind
  const [selectedLanguage, setSelectedLanguage] = useState(currentLanguage);
  const t = useTranslator();

  const changeLocale = useAtomCallback(
    useCallback(async (get, set, nextLanguage: Language): Promise<void> => {
      setSelectedLanguage(nextLanguage);
      const log = get(logButtonClickAtom);

      await log.invoke({
        type: "change_language",
        client_language: nextLanguage,
      });

      startTransition(() => {
        set(currentLanguageAtom, nextLanguage);
      });
    }, []),
  );

  return (
    <div data-testid={dataTestId}>
      {languages.map((language) => (
        <SlideOut.Radio
          key={language}
          aria-label={t(`language_${language}`)}
          checked={selectedLanguage === language}
          data-testid={`${dataTestId}.${language}`}
          labelPosition="end"
          onChange={() => changeLocale(language)}
        >
          {t(`language_${language}`)}
        </SlideOut.Radio>
      ))}
    </div>
  );
}

export { LanguageSwitcher };
