import { featureAtom } from "@sunrise/feature-flags";
import { isSmartTV } from "@sunrise/utils";

/**
 * This is a simple boolean to determine if we prefer recordings over replay.
 * Else it may become terribly difficult to test recordings.
 *
 * @deprecated
 *   Only on TV OS's do we have the mechanism to prefer recordings over replay.
 *   With the NG rewrite of tizen we will also need to play out either replay or recordings depending on where we are in the app.
 */
export const preferRecordingsAtom = featureAtom(
  "prefer-recordings",
  isSmartTV(),
  {
    description: `This is a simple boolean to determine if we prefer recordings over replay.
    Else it may become terribly difficult to test recordings. In the end we decided to stick with recording playout over replays. Maybe we can remove this flag?`,
  },
);
