import { atomEffect } from "jotai-effect";

import { actionGuideLeaveGrid } from "./store/grid-state.actions";
import { gridStateAtom } from "./store/grid-state.atom";

/**
 * When we leave the grid we need to make sure to indicate that we did not yet handle scrolling to the coordinates.
 * So that next time we revisit the grid we are sent to the previous coordinates.
 */
export const guideCleanGridOnExitEffect = atomEffect((_, set) => {
  return () => {
    set(gridStateAtom, actionGuideLeaveGrid());
  };
});
