import { GrowthBook } from "@growthbook/growthbook-react";
import { atom } from "jotai";
import { atomEffect } from "jotai-effect";

import { selectCurrentUserId } from "@sunrise/jwt";

import { semanticVersionToNumber } from "./growthbook.utils";
import { growthbookConfigAtom } from "./growthbook-config.atom";

/**
 * Run an external effect to set this.
 */
export const growthBookAttributesAtom = atom<Partial<{
  deviceId: string;
  loggedIn: boolean;
  platform: string;
  tenant: string;
  mobile: boolean;
  version: string;
  deviceType: string;
}> | null>(null);

const NOOP = () => {
  // noop
};

const _growthbookAtom = atom<null | GrowthBook>((get) => {
  const config = get(growthbookConfigAtom);

  if (!config) {
    return null;
  }

  get(updateAtomsOnReRenderEffect);
  get(updateGrowthbookParametersEffect);

  return new GrowthBook({
    ...config,
    backgroundSync: false,
    // TODO: emit something to Sentry or similar.
    // onFeatureUsage(key, result) {
    // },
  });
});

const growthbookRenderCountAtom = atom(0);

/**
 * Growthbook atom which will emit whenever there's a change in the growthbook instance.
 */
export const growthbookAtom = atom<null | {
  count: number;
  instance: GrowthBook;
}>((get) => {
  const instance = get(_growthbookAtom);
  const count = get(growthbookRenderCountAtom);

  if (!instance) {
    return null;
  }

  return { count, instance };
});

const updateAtomsOnReRenderEffect = atomEffect((get, set) => {
  const gb = get(_growthbookAtom);
  if (!gb) {
    return;
  }

  gb.setRenderer(() => {
    set(growthbookRenderCountAtom, (c) => c + 1);
  });

  const doInit = async () => {
    return await gb.init({
      streaming: true,
    });
  };

  doInit()
    .then(() => {
      set(growthbookRenderCountAtom, (c) => c + 1);
    })
    .catch((e) => {
      console.error("Failed to init growthbook", e);
    });

  return () => {
    gb.setRenderer(NOOP);
  };
});

const updateGrowthbookParametersEffect = atomEffect((get) => {
  const gb = get(_growthbookAtom);
  if (!gb) {
    return;
  }

  const userId = get(selectCurrentUserId);
  const attributes = get(growthBookAttributesAtom);

  const newAttributes = {
    id: userId,
    tenant: "yallo",
    mobile: false,
    ...attributes,
    version: attributes?.version
      ? semanticVersionToNumber(attributes.version)
      : undefined,
  };

  gb.setAttributes({
    ...gb.getAttributes(),
    ...newAttributes,
  });
});
