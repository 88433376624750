import { useAtom, useAtomValue } from "jotai/react";

import {
  searchHistoryClearAllNgAtom,
  searchHistoryNgAtom,
} from "@sunrise/search-history";
import { useTranslator } from "@sunrise/translator";
import { Button, Title } from "@sunrise/yallo-web-components";

import styles from "./search-history.module.css";

export const SearchHistoryHeader = () => {
  const t = useTranslator();
  const [{ mutate: clearAllHistory, isPending: isClearingAllHistory }] =
    useAtom(searchHistoryClearAllNgAtom);
  const { data: history, isLoading } = useAtomValue(searchHistoryNgAtom);

  if (isLoading || history?.length === 0) return;

  return (
    <div className={styles.historyHeader}>
      <Title className={styles.title} level="h3" size="small" variant="title">
        {t("search_page_history_title")}:
      </Title>

      <Button
        className={styles.clearAll}
        disabled={isClearingAllHistory}
        variant="none"
        onClick={() => clearAllHistory()}
      >
        {t("search_page_history_clear_all")}
      </Button>
    </div>
  );
};
