import type { MutableRefObject } from "react";
import { useCallback, useRef, useState } from "react";

import { calculatePercentageOffsetInElementBounds } from "@sunrise/utils";
import { useSeekbar } from "@sunrise/yallo-player-controls";

type useSeekbarDragReturn = {
  isDragging: boolean;
  onPointerDown: React.PointerEventHandler;
};

/**
 * This hook handles pointer down and drag behaviour on the seekbar
 * If you pointer down on the seekbar it will immediately go to that position in the timeline
 * If you pointer down and hold you can drag it will go into seek mode and confirm when the pointer is released
 * If you drag the thumb and press the `Escape` key it will cancel the gesture
 * @param progressBarRef - A ref to the HTML element on which the offset is calculated. Its x and width will be used to determine the percentage
 * @returns useSeekbarDragReturn - An object containing onPointerDown handler that starts monitoring for pointerDown and drag events and isDraggingDown to indicate that the user is currently interacting with the seekbar
 */
function useSeekbarDrag(
  progressBarRef: MutableRefObject<HTMLDivElement | null>,
): useSeekbarDragReturn {
  // const ref = useRef<HTMLDivElement | null>(null);
  const hasDragged = useRef(false);
  const { seek } = useSeekbar();
  const [isDragging, setIsDragging] = useState(false);

  const handleDrag = useCallback(
    (event: PointerEvent): void => {
      if (!progressBarRef.current) return;
      const percentage = calculatePercentageOffsetInElementBounds(
        progressBarRef.current,
        event.clientX,
      );
      seek.toPercentage(percentage);
      hasDragged.current = true;
    },
    [seek, progressBarRef],
  );

  const startDrag = (event: React.PointerEvent<HTMLDivElement>): void => {
    event.preventDefault();
    hasDragged.current = false;
    setIsDragging(true);
    document.addEventListener("pointermove", handleDrag);
    document.addEventListener("pointerup", stopDrag);
    document.addEventListener("keydown", cancelDrag);
  };

  const cancelDrag = (event: KeyboardEvent): void => {
    if (event.code === "Escape") {
      seek.reset();
      document.removeEventListener("keydown", cancelDrag);
      document.removeEventListener("pointermove", handleDrag);
      document.removeEventListener("pointerup", stopDrag);
      setIsDragging(false);
    }
  };

  const stopDrag = (event: PointerEvent): void => {
    if (!hasDragged.current) {
      if (progressBarRef.current) {
        const percentage = calculatePercentageOffsetInElementBounds(
          progressBarRef.current,
          event.clientX,
        );
        seek.toPercentage(percentage, true);
      }
    } else {
      seek.confirm();
    }

    setIsDragging(false);
    document.removeEventListener("pointermove", handleDrag);
    document.removeEventListener("pointerup", stopDrag);
    dispatchEvent(new MouseEvent("mouseup"));
  };

  return {
    isDragging,
    onPointerDown: startDrag,
  };
}

export { useSeekbarDrag };
