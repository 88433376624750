import { featureAtom } from "@sunrise/feature-flags";

/**
 * Indicates whether the Feedback Button in the settings is displayed or not.
 */
export const feedbackEnabledAtom = featureAtom<boolean>(
  "feedback-button",
  false,
  {
    description:
      "Indicates whether the Feedback Button in the settings is displayed or not.",
  },
);
