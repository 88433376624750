import type { Atom } from "jotai";
import { selectAtom } from "jotai/utils";

export const atomPerInterval = <T>(
  atom: Atom<T>,
  narrow: (v: Date) => Date,
) => {
  return selectAtom(
    atom,
    (state) => (state instanceof Date ? narrow(state) : state),
    (a, b) =>
      (a instanceof Date && a?.getTime()) ===
      (b instanceof Date && b?.getTime()),
  );
};
