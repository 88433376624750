import type { ComponentProps, ReactElement } from "react";

import { Toggle } from "./toggle";

type Props = Omit<ComponentProps<typeof Toggle>, "variant" | "type">;

function Switch(props: Props): ReactElement {
  return <Toggle {...props} type="checkbox" variant="switch" />;
}

export { Switch };
