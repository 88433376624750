import { atomWithQuery } from "jotai-tanstack-query";

import type { HTTPValidationError } from "@sunrise/backend-ng-channel";
import type { SearchHistoryEntrySchema } from "@sunrise/backend-ng-search";
import { ngSearchApiAtom } from "@sunrise/backend-ng-search";
import { queryKeys } from "@sunrise/backend-types";
import { selectJwtUserToken } from "@sunrise/jwt";

export const searchHistoryNgQueryAtom = atomWithQuery<
  SearchHistoryEntrySchema[],
  HTTPValidationError,
  SearchHistoryEntrySchema[],
  ReturnType<typeof queryKeys.searchHistory>
>((get) => {
  const api = get(ngSearchApiAtom);

  return {
    queryKey: queryKeys.searchHistory(get(selectJwtUserToken), true),
    queryFn: async () => {
      const { data } =
        await api.search.getSearchHistorySearchV1SearchHistoryEntriesGet({
          size: 20,
        });

      return data.items;
    },
  };
});
