import type { ReactNode } from "react";
import { useAtomValue } from "jotai";

import { assetDetailsByIdAtom } from "@sunrise/details";
import {
  playerCurrentEpgItemAtom,
  selectPlayerCurrentPlayRequest,
} from "@sunrise/player";
import { SlideOut } from "@sunrise/yallo-web-components";

import { useRecordProgramHook } from "../../details/use-record-program-button";

/**
 * Analog to RecordProgramButton and UnifiedDetails, this is the Recording button in the more-options menu.
 * The component will output a record, delete or cancel recording button when applicable.
 */
export function PlayerRecordProgramButton(): ReactNode {
  const playRequest = useAtomValue(selectPlayerCurrentPlayRequest);
  const epgEntry = useAtomValue(playerCurrentEpgItemAtom);
  const asset = useAtomValue(assetDetailsByIdAtom(epgEntry?.asset.id));
  const isSeriesEpisode = asset?.type === "seriesepisode";

  let recordProgramButtonProps:
    | Parameters<typeof useRecordProgramHook>[0]
    | null = null;

  if (playRequest?.type !== "recording" && epgEntry) {
    recordProgramButtonProps = {
      type: "epg",
      epgId: epgEntry.id,
      startTime: epgEntry.actualStart,
      endTime: epgEntry.actualEnd,
      isSeriesEpisode,
    };
  }

  if (playRequest?.type === "recording") {
    recordProgramButtonProps = {
      type: "single",
      recordingId: playRequest.recordingId,
    };
  }

  if (recordProgramButtonProps) {
    return <RecordProgramButton {...recordProgramButtonProps} />;
  }
}

function RecordProgramButton(
  props: Parameters<typeof useRecordProgramHook>[0],
) {
  const result = useRecordProgramHook(props);

  if (!result) {
    return null;
  }

  const { iconName, onClick, disabled, isLoading, label } = result;

  return (
    <SlideOut.Button
      disabled={disabled || isLoading}
      icon={iconName}
      onClick={onClick}
    >
      {label}
    </SlideOut.Button>
  );
}
