import { atomWithSuspenseQuery } from "jotai-tanstack-query";

import {
  type EPGSearchEntry,
  queryKeys,
  type SearchQuery,
  type SearchResult,
} from "@sunrise/backend-types";
import { hostsAtom, httpClientAtom } from "@sunrise/http-client";
import { isNil } from "@sunrise/utils";

import { searchEpgs } from "./search.service";
import { searchActiveFilterAtom } from "./search-active-filter.atom";
import { selectCurrentSearchQuery } from "./select-current-search-query";
import { selectSearchEnabled } from "./select-search-enabled";

export const searchEpgQueryAtom = atomWithSuspenseQuery<
  SearchResult<EPGSearchEntry>,
  unknown,
  SearchResult<EPGSearchEntry>,
  [string, string, string]
>((get) => {
  const hosts = get(hostsAtom);
  const host = hosts.api;
  if (isNil(host)) throw new Error("api host is not defined");

  const privateApi = get(httpClientAtom).privateApi;
  if (isNil(privateApi)) throw new Error("Private api is not set");

  const query = get(selectCurrentSearchQuery);
  const filter = get(searchActiveFilterAtom);
  const enabled = get(selectSearchEnabled);

  return {
    queryKey: queryKeys.searchEpgItems(filter, query),
    queryFn: async ({ queryKey: [, , text] }) => {
      if (!enabled) return { result: [], hits: 0 };

      const query: SearchQuery = {
        text: text as string,
        filter,
      };

      return searchEpgs(host, privateApi, query);
    },
  };
});
