import type { ReactNode } from "react";
import type { QueryClient } from "@tanstack/query-core";
import type { queryClientAtom } from "jotai-tanstack-query";

import type { Store } from "@sunrise/store";
import { StoreProvider } from "@sunrise/store";
import { TranslationProvider } from "@sunrise/translator";

import { QueryProvider } from "@/modules/query-provider";

export function AppProvider(props: {
  children: ReactNode;
  queryClient: QueryClient;
  enableDevTools: boolean;
  queryClientAtom: typeof queryClientAtom;
  storeInstance: Store;
}): ReactNode {
  return (
    <QueryProvider
      enableDevTools={props.enableDevTools}
      queryClientInstance={props.queryClient}
    >
      <StoreProvider
        enableDevTools={props.enableDevTools}
        store={props.storeInstance}
      >
        <TranslationProvider>{props.children}</TranslationProvider>
      </StoreProvider>
    </QueryProvider>
  );
}
