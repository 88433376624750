import type { ComponentProps, ReactNode } from "react";
import { useCallback, useId, useRef, useState } from "react";
import type { FixedSizeList } from "react-window";

import type { RecommendationListId } from "@sunrise/backend-types-core";
import { useTranslator } from "@sunrise/translator";
import { Button, Title } from "@sunrise/yallo-web-components";

import type { ContinueWatching } from "./continue-watching";
import styles from "./home-row-wrapper.module.css";
import type { Recommended } from "./recommended";
import type { ScrollableRow } from "./scrollable-row";

type HomeRowWrapperProps = {
  recommendationListId: RecommendationListId;
  title: string;
  renderChildren: typeof ContinueWatching | typeof Recommended;
  "data-testid"?: string;
} & Pick<ComponentProps<typeof ScrollableRow>, "numbered" | "displayType">;

export function HomeRowWrapper({
  recommendationListId,
  numbered,
  displayType,
  title,
  renderChildren,
  "data-testid": testId,
}: HomeRowWrapperProps): ReactNode {
  const t = useTranslator();

  const listRef = useRef<FixedSizeList<string>>(null);
  const outerRef = useRef<HTMLDivElement>(null);

  const arrowsRef = useRef<HTMLDivElement>(null);
  const arrowLeft = useRef<HTMLButtonElement>(null);
  const arrowRight = useRef<HTMLButtonElement>(null);
  const listScrollAmount = useRef(0);
  const parentWidth = useRef(0);
  const listWidth = useRef(0);
  const rowRightEdge = 32; // 32px is the right padding of the row

  const [arrowTargets, setArrowsTargets] = useState({
    left: 0,
    right: 0,
  });

  function toggleArrowVisibility() {
    if (!arrowLeft.current || !arrowRight.current || !arrowsRef.current) return;
    parentWidth.current = outerRef.current?.scrollWidth ?? 0;
    listWidth.current = outerRef.current?.clientWidth ?? 0;

    const isRightDisabled = !(
      listWidth.current + listScrollAmount.current <
      parentWidth.current - rowRightEdge
    );
    const isLeftDisabled = listScrollAmount.current <= 0;
    arrowLeft.current.toggleAttribute("disabled", isLeftDisabled);
    arrowRight.current.toggleAttribute("disabled", isRightDisabled);
  }

  // setTimeout ensures we get the _latest_ value, if you use window snapping it returns the previous value and might not show the arrows when needed
  const handleResize = useCallback(
    () => setTimeout(toggleArrowVisibility, 0),
    [],
  );

  const handleOnScroll: ComponentProps<typeof renderChildren>["onScroll"] =
    useCallback(({ scrollOffset }) => {
      listScrollAmount.current = scrollOffset;
      toggleArrowVisibility();
    }, []);

  const handleOnItemsRendered: ComponentProps<
    typeof renderChildren
  >["onItemsRendered"] = useCallback((props) => {
    setArrowsTargets((prev) => {
      if (
        prev.left === props.visibleStartIndex &&
        prev.right === props.visibleStopIndex
      ) {
        return prev;
      }

      return {
        right: props.visibleStopIndex ?? 0,
        left: props.visibleStartIndex ?? 0,
      };
    });
  }, []);

  const titleId = useId();

  const children = renderChildren({
    numbered,
    displayType,
    onScroll: handleOnScroll,
    onResize: handleResize,
    onItemsRendered: handleOnItemsRendered,
    listRef,
    outerRef,
    recommendationListId,
  });

  if (!children) return;

  return (
    <section aria-labelledby={titleId} data-testid={testId}>
      <header className={styles.rowHeader}>
        <Title
          className={styles.title}
          id={titleId}
          level="h2"
          size="small"
          variant="headline"
        >
          {title}
        </Title>

        <div ref={arrowsRef} className={styles.arrows}>
          <Button
            ref={arrowLeft}
            icon="arrowLeft"
            variant="text"
            hideLabel
            onClick={() => {
              listRef.current?.scrollToItem(arrowTargets.left, "end");
            }}
          >
            {t("home_row_previous_button")}
          </Button>

          <Button
            ref={arrowRight}
            icon="arrowRight"
            variant="text"
            hideLabel
            onClick={() => {
              listRef.current?.scrollToItem(arrowTargets.right, "start");
            }}
          >
            {t("home_row_next_button")}
          </Button>
        </div>
      </header>
      {children}
    </section>
  );
}
