import { useCallback, useEffect } from "react";
import { useAtomValue } from "jotai";
import { useAtomCallback } from "jotai/utils";

import type { ChannelId } from "@sunrise/backend-types-core";

import { visibleChannelsAtom } from "../guide-visible-data.atom";
import { unwrappedChannelsForGuideAtom } from "../unwrapped-channels-for-guide.atom";

export function useGridVisibleDataChangedChannels({
  firstChannelIndexVisible,
  lastChannelIndexVisible,
  bufferCount,
}: {
  firstChannelIndexVisible: number | null;
  lastChannelIndexVisible: number | null;
  bufferCount: number;
}) {
  const channels = useAtomValue(unwrappedChannelsForGuideAtom);

  const onVisibleDataChanged = useAtomCallback(
    useCallback((_, set, channelIds: ChannelId[]) => {
      set(visibleChannelsAtom, channelIds);
    }, []),
  );

  /**
   * We need to communicate what data is currently visible to the hook.
   * So it can load in the correct data.
   *
   * This effect depends mostly on firstChannelIndexVisible and firstTimeVisible
   */
  useEffect(() => {
    if (!onVisibleDataChanged || !channels) {
      return;
    }

    const channelIndex = Math.max(
      (firstChannelIndexVisible ?? 0) - bufferCount,
      0,
    );

    const endChannelIndex =
      Math.max(lastChannelIndexVisible ?? 0, channelIndex) + bufferCount;

    onVisibleDataChanged(
      channels.slice(channelIndex, endChannelIndex).map((c) => c.id),
    );
  }, [
    onVisibleDataChanged,
    channels,
    firstChannelIndexVisible,
    lastChannelIndexVisible,
  ]);
}
