import { atom } from "jotai";

import type { AssetId, EPGEntryId } from "@sunrise/backend-types-core";
import type { Page } from "@sunrise/yallo-websocket";

type PageContentNoAsset = {
  id: Exclude<Page, "detail_page" | "series_recording_page">;
};

type PageContentAsset = {
  id: Extract<Page, "detail_page" | "series_recording_page">;
  assetId: AssetId;
  epgEntryId?: EPGEntryId;
};

export type PageContent = PageContentNoAsset | PageContentAsset;

export const pageContentAtom = atom<PageContent | null>(null);
pageContentAtom.debugLabel = "pageContentAtom";
