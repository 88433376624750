import type { ReactElement } from "react";
import { useMediaQuery } from "usehooks-ts";

import { useThumbnail } from "@sunrise/player";

import styles from "./thumbnail.module.css";

const Thumbnail = (): ReactElement | null => {
  const useLargeThumbnail = useMediaQuery("(min-width: 500px)");
  const { thumbnail, imageBase64 } = useThumbnail({
    width: useLargeThumbnail ? 450 : 210,
  });

  if (!thumbnail) return null;

  return (
    <div
      className={styles.thumbnail}
      style={{
        inlineSize: `${thumbnail.width}px`,
        blockSize: `${thumbnail.height}px`,
        backgroundImage: `url(${imageBase64 ? imageBase64 : thumbnail.url})`,
        backgroundSize: `${thumbnail.fullWidth}px ${thumbnail.fullHeight}px`,
        backgroundPosition: `${thumbnail.x}px ${thumbnail.y}px`,
      }}
    ></div>
  );
};

export { Thumbnail };
