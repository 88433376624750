import { useState } from "react";
import * as Slider from "@radix-ui/react-slider";

import { useTranslator } from "@sunrise/translator";
import { usePlayerVolume } from "@sunrise/yallo-player-controls";
import { Button } from "@sunrise/yallo-web-components";

import styles from "./volume-control.module.css";

export function VolumeControl() {
  const t = useTranslator();
  const { isMuted, toggleMute, setVolume, currentVolume } = usePlayerVolume();

  const [isSliderOpen, setIsSliderOpen] = useState(false);

  const handleMouseEnter = () => setIsSliderOpen(true);
  const handleMouseLeave = () => setIsSliderOpen(false);
  const handleVolumeChange = (value: number[]) => {
    const [volume] = value;
    setVolume(volume);
  };

  const muteIcon = isMuted ? "volumeOff" : "volumeUp";

  return (
    <div
      className={styles.volumeControl}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Button aria-pressed={isMuted} icon={muteIcon} variant="text" hideLabel>
        {t("mute_button")}
      </Button>

      {isSliderOpen && (
        <form
          className={styles.form}
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Slider.Root
            className={styles.sliderRoot}
            dir="ltr"
            max={100}
            orientation="vertical"
            step={1}
            value={[currentVolume]}
            onValueChange={handleVolumeChange}
          >
            <Slider.Track className={styles.sliderTrack}>
              <Slider.Range className={styles.sliderRange} />
            </Slider.Track>
            <Slider.Thumb aria-label="volume" className={styles.sliderThumb} />
          </Slider.Root>
          <Button
            aria-pressed={isMuted}
            className={styles.muteButton}
            icon={muteIcon}
            variant="none"
            hideLabel
            onClick={toggleMute}
          >
            {t("mute_button")}
          </Button>
        </form>
      )}
    </div>
  );
}
