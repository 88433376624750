import { atomWithStorage } from "jotai/utils";

/**
 * null means there's no preference.
 *
 * Whenever the user changes the volume manually, this atom should be updated.
 * Whenever the player (or a player) starts, it should check this atom and set the volume accordingly.
 *
 * This is split to a separate atom because later on the highlights video player should normally share the same volume preference.
 */
export const desiredVolumeAtom = atomWithStorage<null | {
  /**
   * A number from 0 to 100.
   */
  level: number;
  muted: boolean;
  /**
   * Timestamp when this was last set.
   * It's relevant because we might want to show the player's actual volume if the player's actual volume was changed after this.
   */
  at: number;
}>("desiredVolume", null);
