/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** CastMember */
export interface CastMember {
  /** Character */
  character?: string | null;
  /** Full Name */
  full_name?: string | null;
  /** Asset Id */
  asset_id?: string | null;
}

/** ChampionsHockeyLeagueAssetSchema */
export interface ChampionsHockeyLeagueAssetSchema {
  /** Id of the asset */
  id: string;
  /** Type */
  type: "championshockeyleague";
  /**
   * Asset Title
   * The title of the asset
   */
  title: string | null;
  /**
   * Asset Subtitle
   * The subtitle of the asset
   */
  subtitle: string | null;
  /** Age Recommendation */
  age_recommendation: number | null;
  /**
   * Production Year
   * The production year of the asset
   */
  production_year: number | null;
  /**
   * Asset Plot
   * The plot of the asset
   */
  plot: string | null;
  /**
   * Asset Genres
   * The genres of the asset
   */
  genres: ReducedGenreSchema[] | null;
  /**
   * Asset cast
   * The cast of the asset
   */
  cast: CastMember[] | null;
  /**
   * Asset Poster
   * The poster url of the asset
   */
  poster_url?: string;
  /**
   * Blurhash of the Poster
   * @default "eCF6B#-:0JInxr?@s;nmIoWUIko1%NocRk.8xbIUaxR*^+s;RiWAWU"
   */
  poster_blurhash?: string;
  /**
   * Asset Description
   * The description of the asset
   * @default ""
   */
  description?: string | null;
  /** Asset Recording Available */
  readonly asset_recording_available: boolean;
  /** Directors */
  readonly directors: string[] | null;
}

/** CrewMember */
export interface CrewMember {
  /** Job */
  job?: string | null;
  /** Full Name */
  full_name?: string | null;
  /** Asset Id */
  asset_id?: string | null;
}

/** DocumentaryProgramAssetSchema */
export interface DocumentaryProgramAssetSchema {
  /** Id of the asset */
  id: string;
  /** Type */
  type: "documentary";
  /**
   * Asset Title
   * The title of the asset
   */
  title: string | null;
  /**
   * Asset Subtitle
   * The subtitle of the asset
   */
  subtitle: string | null;
  /** Age Recommendation */
  age_recommendation: number | null;
  /**
   * Production Year
   * The production year of the asset
   */
  production_year: number | null;
  /**
   * Asset Plot
   * The plot of the asset
   */
  plot: string | null;
  /**
   * Asset Genres
   * The genres of the asset
   */
  genres: ReducedGenreSchema[] | null;
  /**
   * Asset cast
   * The cast of the asset
   */
  cast: CastMember[] | null;
  /**
   * Asset Poster
   * The poster url of the asset
   */
  poster_url?: string;
  /**
   * Blurhash of the Poster
   * @default "eCF6B#-:0JInxr?@s;nmIoWUIko1%NocRk.8xbIUaxR*^+s;RiWAWU"
   */
  poster_blurhash?: string;
  /**
   * Asset Description
   * The description of the asset
   * @default ""
   */
  description?: string | null;
  /** Asset Recording Available */
  readonly asset_recording_available: boolean;
  /** Directors */
  readonly directors: string[] | null;
}

/** ESportAssetSchema */
export interface ESportAssetSchema {
  /** Id of the asset */
  id: string;
  /** Type */
  type: "esport";
  /**
   * Asset Title
   * The title of the asset
   */
  title: string | null;
  /**
   * Asset Subtitle
   * The subtitle of the asset
   */
  subtitle: string | null;
  /** Age Recommendation */
  age_recommendation: number | null;
  /**
   * Production Year
   * The production year of the asset
   */
  production_year: number | null;
  /**
   * Asset Plot
   * The plot of the asset
   */
  plot: string | null;
  /**
   * Asset Genres
   * The genres of the asset
   */
  genres: ReducedGenreSchema[] | null;
  /**
   * Asset cast
   * The cast of the asset
   */
  cast: CastMember[] | null;
  /**
   * Asset Poster
   * The poster url of the asset
   */
  poster_url?: string;
  /**
   * Blurhash of the Poster
   * @default "eCF6B#-:0JInxr?@s;nmIoWUIko1%NocRk.8xbIUaxR*^+s;RiWAWU"
   */
  poster_blurhash?: string;
  /**
   * Asset Description
   * The description of the asset
   * @default ""
   */
  description?: string | null;
  /** Asset Recording Available */
  readonly asset_recording_available: boolean;
  /** Directors */
  readonly directors: string[] | null;
}

/** FormulaEAssetSchema */
export interface FormulaEAssetSchema {
  /** Id of the asset */
  id: string;
  /** Type */
  type: "formulae";
  /**
   * Asset Title
   * The title of the asset
   */
  title: string | null;
  /**
   * Asset Subtitle
   * The subtitle of the asset
   */
  subtitle: string | null;
  /** Age Recommendation */
  age_recommendation: number | null;
  /**
   * Production Year
   * The production year of the asset
   */
  production_year: number | null;
  /**
   * Asset Plot
   * The plot of the asset
   */
  plot: string | null;
  /**
   * Asset Genres
   * The genres of the asset
   */
  genres: ReducedGenreSchema[] | null;
  /**
   * Asset cast
   * The cast of the asset
   */
  cast: CastMember[] | null;
  /**
   * Asset Poster
   * The poster url of the asset
   */
  poster_url?: string;
  /**
   * Blurhash of the Poster
   * @default "eCF6B#-:0JInxr?@s;nmIoWUIko1%NocRk.8xbIUaxR*^+s;RiWAWU"
   */
  poster_blurhash?: string;
  /**
   * Asset Description
   * The description of the asset
   * @default ""
   */
  description?: string | null;
  /** Asset Recording Available */
  readonly asset_recording_available: boolean;
  /** Directors */
  readonly directors: string[] | null;
}

/** GermanNationalLeagueAssetSchema */
export interface GermanNationalLeagueAssetSchema {
  /** Id of the asset */
  id: string;
  /** Type */
  type: "germanhockeyleague";
  /**
   * Asset Title
   * The title of the asset
   */
  title: string | null;
  /**
   * Asset Subtitle
   * The subtitle of the asset
   */
  subtitle: string | null;
  /** Age Recommendation */
  age_recommendation: number | null;
  /**
   * Production Year
   * The production year of the asset
   */
  production_year: number | null;
  /**
   * Asset Plot
   * The plot of the asset
   */
  plot: string | null;
  /**
   * Asset Genres
   * The genres of the asset
   */
  genres: ReducedGenreSchema[] | null;
  /**
   * Asset cast
   * The cast of the asset
   */
  cast: CastMember[] | null;
  /**
   * Asset Poster
   * The poster url of the asset
   */
  poster_url?: string;
  /**
   * Blurhash of the Poster
   * @default "eCF6B#-:0JInxr?@s;nmIoWUIko1%NocRk.8xbIUaxR*^+s;RiWAWU"
   */
  poster_blurhash?: string;
  /**
   * Asset Description
   * The description of the asset
   * @default ""
   */
  description?: string | null;
  /** Asset Recording Available */
  readonly asset_recording_available: boolean;
  /** Directors */
  readonly directors: string[] | null;
}

/** HTTPValidationError */
export interface HTTPValidationError {
  /** Detail */
  detail?: ValidationError[];
}

/** HomeMadeDocumentaryProgramAssetSchema */
export interface HomeMadeDocumentaryProgramAssetSchema {
  /** Id of the asset */
  id: string;
  /** Type */
  type: "homemade";
  /**
   * Asset Title
   * The title of the asset
   */
  title: string | null;
  /**
   * Asset Subtitle
   * The subtitle of the asset
   */
  subtitle: string | null;
  /** Age Recommendation */
  age_recommendation: number | null;
  /**
   * Production Year
   * The production year of the asset
   */
  production_year: number | null;
  /**
   * Asset Plot
   * The plot of the asset
   */
  plot: string | null;
  /**
   * Asset Genres
   * The genres of the asset
   */
  genres: ReducedGenreSchema[] | null;
  /**
   * Asset cast
   * The cast of the asset
   */
  cast: CastMember[] | null;
  /**
   * Asset Poster
   * The poster url of the asset
   */
  poster_url?: string;
  /**
   * Blurhash of the Poster
   * @default "eCF6B#-:0JInxr?@s;nmIoWUIko1%NocRk.8xbIUaxR*^+s;RiWAWU"
   */
  poster_blurhash?: string;
  /**
   * Asset Description
   * The description of the asset
   * @default ""
   */
  description?: string | null;
  /** Asset Recording Available */
  readonly asset_recording_available: boolean;
  /** Directors */
  readonly directors: string[] | null;
}

/** MovieAssetSchema */
export interface MovieAssetSchema {
  /** Id of the asset */
  id: string;
  /** Type */
  type: "movie";
  /**
   * Asset Title
   * The title of the asset
   */
  title: string | null;
  /**
   * Asset Subtitle
   * The subtitle of the asset
   */
  subtitle: string | null;
  /** Age Recommendation */
  age_recommendation: number | null;
  /**
   * Production Year
   * The production year of the asset
   */
  production_year: number | null;
  /**
   * Asset Plot
   * The plot of the asset
   */
  plot: string | null;
  /**
   * Asset Genres
   * The genres of the asset
   */
  genres: ReducedGenreSchema[] | null;
  /**
   * Asset cast
   * The cast of the asset
   */
  cast: CastMember[] | null;
  /**
   * Asset Poster
   * The poster url of the asset
   */
  poster_url?: string;
  /**
   * Blurhash of the Poster
   * @default "eCF6B#-:0JInxr?@s;nmIoWUIko1%NocRk.8xbIUaxR*^+s;RiWAWU"
   */
  poster_blurhash?: string;
  /**
   * Asset Description
   * The description of the asset
   * @default ""
   */
  description?: string | null;
  /** Asset Recording Available */
  readonly asset_recording_available: boolean;
  /** Directors */
  readonly directors: string[] | null;
}

/** NationalLeagueAssetSchema */
export interface NationalLeagueAssetSchema {
  /** Id of the asset */
  id: string;
  /** Type */
  type: "nationalhockeyleague";
  /**
   * Asset Title
   * The title of the asset
   */
  title: string | null;
  /**
   * Asset Subtitle
   * The subtitle of the asset
   */
  subtitle: string | null;
  /** Age Recommendation */
  age_recommendation: number | null;
  /**
   * Production Year
   * The production year of the asset
   */
  production_year: number | null;
  /**
   * Asset Plot
   * The plot of the asset
   */
  plot: string | null;
  /**
   * Asset Genres
   * The genres of the asset
   */
  genres: ReducedGenreSchema[] | null;
  /**
   * Asset cast
   * The cast of the asset
   */
  cast: CastMember[] | null;
  /**
   * Asset Poster
   * The poster url of the asset
   */
  poster_url?: string;
  /**
   * Blurhash of the Poster
   * @default "eCF6B#-:0JInxr?@s;nmIoWUIko1%NocRk.8xbIUaxR*^+s;RiWAWU"
   */
  poster_blurhash?: string;
  /**
   * Asset Description
   * The description of the asset
   * @default ""
   */
  description?: string | null;
  /** Asset Recording Available */
  readonly asset_recording_available: boolean;
  /** Directors */
  readonly directors: string[] | null;
}

/** NoProgramScheduledAssetSchema */
export interface NoProgramScheduledAssetSchema {
  /** Id of the asset */
  id: string;
  /** Type */
  type: "noprogramscheduled";
  /**
   * Asset Title
   * The title of the asset
   */
  title: string | null;
  /**
   * Asset Subtitle
   * The subtitle of the asset
   */
  subtitle: string | null;
  /** Age Recommendation */
  age_recommendation: number | null;
  /**
   * Production Year
   * The production year of the asset
   */
  production_year: number | null;
  /**
   * Asset Plot
   * The plot of the asset
   */
  plot: string | null;
  /**
   * Asset Genres
   * The genres of the asset
   */
  genres: ReducedGenreSchema[] | null;
  /**
   * Asset cast
   * The cast of the asset
   */
  cast: CastMember[] | null;
  /**
   * Asset Poster
   * The poster url of the asset
   */
  poster_url?: string;
  /**
   * Blurhash of the Poster
   * @default "eCF6B#-:0JInxr?@s;nmIoWUIko1%NocRk.8xbIUaxR*^+s;RiWAWU"
   */
  poster_blurhash?: string;
  /**
   * Asset Description
   * The description of the asset
   * @default ""
   */
  description?: string | null;
  /** Asset Recording Available */
  readonly asset_recording_available: boolean;
  /** Directors */
  readonly directors: string[] | null;
}

/** OtherIceHockeySchema */
export interface OtherIceHockeySchema {
  /** Id of the asset */
  id: string;
  /** Type */
  type: "othericehockey";
  /**
   * Asset Title
   * The title of the asset
   */
  title: string | null;
  /**
   * Asset Subtitle
   * The subtitle of the asset
   */
  subtitle: string | null;
  /** Age Recommendation */
  age_recommendation: number | null;
  /**
   * Production Year
   * The production year of the asset
   */
  production_year: number | null;
  /**
   * Asset Plot
   * The plot of the asset
   */
  plot: string | null;
  /**
   * Asset Genres
   * The genres of the asset
   */
  genres: ReducedGenreSchema[] | null;
  /**
   * Asset cast
   * The cast of the asset
   */
  cast: CastMember[] | null;
  /**
   * Asset Poster
   * The poster url of the asset
   */
  poster_url?: string;
  /**
   * Blurhash of the Poster
   * @default "eCF6B#-:0JInxr?@s;nmIoWUIko1%NocRk.8xbIUaxR*^+s;RiWAWU"
   */
  poster_blurhash?: string;
  /**
   * Asset Description
   * The description of the asset
   * @default ""
   */
  description?: string | null;
  /** Asset Recording Available */
  readonly asset_recording_available: boolean;
  /** Directors */
  readonly directors: string[] | null;
}

/** OtherSportAssetSchema */
export interface OtherSportAssetSchema {
  /** Id of the asset */
  id: string;
  /** Type */
  type: "othersport";
  /**
   * Asset Title
   * The title of the asset
   */
  title: string | null;
  /**
   * Asset Subtitle
   * The subtitle of the asset
   */
  subtitle: string | null;
  /** Age Recommendation */
  age_recommendation: number | null;
  /**
   * Production Year
   * The production year of the asset
   */
  production_year: number | null;
  /**
   * Asset Plot
   * The plot of the asset
   */
  plot: string | null;
  /**
   * Asset Genres
   * The genres of the asset
   */
  genres: ReducedGenreSchema[] | null;
  /**
   * Asset cast
   * The cast of the asset
   */
  cast: CastMember[] | null;
  /**
   * Asset Poster
   * The poster url of the asset
   */
  poster_url?: string;
  /**
   * Blurhash of the Poster
   * @default "eCF6B#-:0JInxr?@s;nmIoWUIko1%NocRk.8xbIUaxR*^+s;RiWAWU"
   */
  poster_blurhash?: string;
  /**
   * Asset Description
   * The description of the asset
   * @default ""
   */
  description?: string | null;
  /** Asset Recording Available */
  readonly asset_recording_available: boolean;
  /** Directors */
  readonly directors: string[] | null;
}

/** Page[Annotated[Union[SeriesAssetSchema, MovieAssetSchema, ProgramAssetSchema, ChampionsHockeyLeagueAssetSchema, OtherIceHockeySchema, SwissNationalLeagueAssetSchema, SwedishNationalLeagueAssetSchema, GermanNationalLeagueAssetSchema, NationalLeagueAssetSchema, OtherSportAssetSchema, FormulaEAssetSchema, DocumentaryProgramAssetSchema, HomeMadeDocumentaryProgramAssetSchema, NoProgramScheduledAssetSchema, UnknownAssetSchema, SeriesEpisodeAssetSchema, SeriesSeasonAssetSchema, SportsAssetSchema, ESportAssetSchema, WintersportsAssetSchema, TalkAssetSchema, PersonSchema], FieldInfo(annotation=NoneType, required=True, discriminator='type')]] */
export interface PageAnnotatedUnionSeriesAssetSchemaMovieAssetSchemaProgramAssetSchemaChampionsHockeyLeagueAssetSchemaOtherIceHockeySchemaSwissNationalLeagueAssetSchemaSwedishNationalLeagueAssetSchemaGermanNationalLeagueAssetSchemaNationalLeagueAssetSchemaOtherSportAssetSchemaFormulaEAssetSchemaDocumentaryProgramAssetSchemaHomeMadeDocumentaryProgramAssetSchemaNoProgramScheduledAssetSchemaUnknownAssetSchemaSeriesEpisodeAssetSchemaSeriesSeasonAssetSchemaSportsAssetSchemaESportAssetSchemaWintersportsAssetSchemaTalkAssetSchemaPersonSchemaFieldInfoAnnotationNoneTypeRequiredTrueDiscriminatorType {
  /** Items */
  items: (
    | ({
        type: "championshockeyleague";
      } & ChampionsHockeyLeagueAssetSchema)
    | ({
        type: "documentary";
      } & DocumentaryProgramAssetSchema)
    | ({
        type: "esport";
      } & ESportAssetSchema)
    | ({
        type: "formulae";
      } & FormulaEAssetSchema)
    | ({
        type: "germanhockeyleague";
      } & GermanNationalLeagueAssetSchema)
    | ({
        type: "homemade";
      } & HomeMadeDocumentaryProgramAssetSchema)
    | ({
        type: "movie";
      } & MovieAssetSchema)
    | ({
        type: "nationalhockeyleague";
      } & NationalLeagueAssetSchema)
    | ({
        type: "noprogramscheduled";
      } & NoProgramScheduledAssetSchema)
    | ({
        type: "othericehockey";
      } & OtherIceHockeySchema)
    | ({
        type: "othersport";
      } & OtherSportAssetSchema)
    | ({
        type: "person";
      } & PersonSchema)
    | ({
        type: "program";
      } & ProgramAssetSchema)
    | ({
        type: "series";
      } & SeriesAssetSchema)
    | ({
        type: "seriesepisode";
      } & SeriesEpisodeAssetSchema)
    | ({
        type: "seriesseason";
      } & SeriesSeasonAssetSchema)
    | ({
        type: "sports";
      } & SportsAssetSchema)
    | ({
        type: "swedishhockeyleague";
      } & SwedishNationalLeagueAssetSchema)
    | ({
        type: "swissnationalleague";
      } & SwissNationalLeagueAssetSchema)
    | ({
        type: "talk";
      } & TalkAssetSchema)
    | ({
        type: "unknown";
      } & UnknownAssetSchema)
    | ({
        type: "wintersports";
      } & WintersportsAssetSchema)
  )[];
  /** Total */
  total: number | null;
  /** Page */
  page: number | null;
  /** Size */
  size: number | null;
  /** Pages */
  pages?: number | null;
}

/** PersonSchema */
export interface PersonSchema {
  /** Id of the asset */
  id: string;
  /**
   * Type
   * @default "person"
   */
  type?: "person";
  /** Full Name */
  full_name?: string | null;
  /** Picture */
  picture?: string | null;
}

/** ProgramAssetSchema */
export interface ProgramAssetSchema {
  /** Id of the asset */
  id: string;
  /** Type */
  type: "program";
  /**
   * Asset Title
   * The title of the asset
   */
  title: string | null;
  /**
   * Asset Subtitle
   * The subtitle of the asset
   */
  subtitle: string | null;
  /** Age Recommendation */
  age_recommendation: number | null;
  /**
   * Production Year
   * The production year of the asset
   */
  production_year: number | null;
  /**
   * Asset Plot
   * The plot of the asset
   */
  plot: string | null;
  /**
   * Asset Genres
   * The genres of the asset
   */
  genres: ReducedGenreSchema[] | null;
  /**
   * Asset cast
   * The cast of the asset
   */
  cast: CastMember[] | null;
  /**
   * Asset Poster
   * The poster url of the asset
   */
  poster_url?: string;
  /**
   * Blurhash of the Poster
   * @default "eCF6B#-:0JInxr?@s;nmIoWUIko1%NocRk.8xbIUaxR*^+s;RiWAWU"
   */
  poster_blurhash?: string;
  /**
   * Asset Description
   * The description of the asset
   * @default ""
   */
  description?: string | null;
  /** Asset Recording Available */
  readonly asset_recording_available: boolean;
  /** Directors */
  readonly directors: string[] | null;
}

/** ReducedGenreSchema */
export interface ReducedGenreSchema {
  /**
   * Genre Name
   * The name of the genre
   */
  name?: string | null;
}

/** SeriesAssetSchema */
export interface SeriesAssetSchema {
  /** Id of the asset */
  id: string;
  /** Type */
  type: "series";
  /**
   * Asset Title
   * The title of the asset
   */
  title: string | null;
  /**
   * Asset Subtitle
   * The subtitle of the asset
   */
  subtitle: string | null;
  /** Age Recommendation */
  age_recommendation: number | null;
  /**
   * Production Year
   * The production year of the asset
   */
  production_year: number | null;
  /**
   * Asset Plot
   * The plot of the asset
   */
  plot: string | null;
  /**
   * Asset Genres
   * The genres of the asset
   */
  genres: ReducedGenreSchema[] | null;
  /**
   * Asset cast
   * The cast of the asset
   */
  cast: CastMember[] | null;
  /**
   * Asset Poster
   * The poster url of the asset
   */
  poster_url?: string;
  /**
   * Blurhash of the Poster
   * @default "eCF6B#-:0JInxr?@s;nmIoWUIko1%NocRk.8xbIUaxR*^+s;RiWAWU"
   */
  poster_blurhash?: string;
  /**
   * Asset Description
   * The description of the asset
   * @default ""
   */
  description?: string | null;
  /** Asset Recording Available */
  readonly asset_recording_available: boolean;
  /** Directors */
  readonly directors: string[] | null;
}

/** SeriesEpisodeAssetSchema */
export interface SeriesEpisodeAssetSchema {
  /** Id of the asset */
  id: string;
  /** Type */
  type: "seriesepisode";
  /**
   * Asset Title
   * The title of the asset
   */
  title: string | null;
  /**
   * Asset Subtitle
   * The subtitle of the asset
   */
  subtitle: string | null;
  /** Age Recommendation */
  age_recommendation: number | null;
  /**
   * Production Year
   * The production year of the asset
   */
  production_year: number | null;
  /**
   * Asset Plot
   * The plot of the asset
   */
  plot: string | null;
  /**
   * Asset Genres
   * The genres of the asset
   */
  genres: ReducedGenreSchema[] | null;
  /**
   * Asset cast
   * The cast of the asset
   */
  cast: CastMember[] | null;
  /**
   * Asset Poster
   * The poster url of the asset
   */
  poster_url?: string;
  /**
   * Blurhash of the Poster
   * @default "eCF6B#-:0JInxr?@s;nmIoWUIko1%NocRk.8xbIUaxR*^+s;RiWAWU"
   */
  poster_blurhash?: string;
  /**
   * Asset Description
   * The description of the asset
   * @default ""
   */
  description?: string | null;
  /** Number of the episode in the season */
  episode_number?: number | null;
  /** Number of the season */
  season_number?: number | null;
  /** Series season ID of this episode */
  series_season: string;
  /** The series ID of this episode */
  series: string;
  /** Asset Recording Available */
  readonly asset_recording_available: boolean;
  /** Directors */
  readonly directors: string[] | null;
}

/** SeriesSeasonAssetSchema */
export interface SeriesSeasonAssetSchema {
  /** Id of the asset */
  id: string;
  /** Type */
  type: "seriesseason";
  /**
   * Asset Title
   * The title of the asset
   */
  title: string | null;
  /**
   * Asset Subtitle
   * The subtitle of the asset
   */
  subtitle: string | null;
  /** Age Recommendation */
  age_recommendation: number | null;
  /**
   * Production Year
   * The production year of the asset
   */
  production_year: number | null;
  /**
   * Asset Plot
   * The plot of the asset
   */
  plot: string | null;
  /**
   * Asset Genres
   * The genres of the asset
   */
  genres: ReducedGenreSchema[] | null;
  /**
   * Asset cast
   * The cast of the asset
   */
  cast: CastMember[] | null;
  /**
   * Asset Poster
   * The poster url of the asset
   */
  poster_url?: string;
  /**
   * Blurhash of the Poster
   * @default "eCF6B#-:0JInxr?@s;nmIoWUIko1%NocRk.8xbIUaxR*^+s;RiWAWU"
   */
  poster_blurhash?: string;
  /**
   * Asset Description
   * The description of the asset
   * @default ""
   */
  description?: string | null;
  /** Season number of the season asset */
  season_number?: number | null;
  /** The series ID of this season */
  series: string;
  /** Asset Recording Available */
  readonly asset_recording_available: boolean;
  /** Directors */
  readonly directors: string[] | null;
}

/** SportsAssetSchema */
export interface SportsAssetSchema {
  /** Id of the asset */
  id: string;
  /** Type */
  type: "sports";
  /**
   * Asset Title
   * The title of the asset
   */
  title: string | null;
  /**
   * Asset Subtitle
   * The subtitle of the asset
   */
  subtitle: string | null;
  /** Age Recommendation */
  age_recommendation: number | null;
  /**
   * Production Year
   * The production year of the asset
   */
  production_year: number | null;
  /**
   * Asset Plot
   * The plot of the asset
   */
  plot: string | null;
  /**
   * Asset Genres
   * The genres of the asset
   */
  genres: ReducedGenreSchema[] | null;
  /**
   * Asset cast
   * The cast of the asset
   */
  cast: CastMember[] | null;
  /**
   * Asset Poster
   * The poster url of the asset
   */
  poster_url?: string;
  /**
   * Blurhash of the Poster
   * @default "eCF6B#-:0JInxr?@s;nmIoWUIko1%NocRk.8xbIUaxR*^+s;RiWAWU"
   */
  poster_blurhash?: string;
  /**
   * Asset Description
   * The description of the asset
   * @default ""
   */
  description?: string | null;
  /** Asset Recording Available */
  readonly asset_recording_available: boolean;
  /** Directors */
  readonly directors: string[] | null;
}

/** SwedishNationalLeagueAssetSchema */
export interface SwedishNationalLeagueAssetSchema {
  /** Id of the asset */
  id: string;
  /** Type */
  type: "swedishhockeyleague";
  /**
   * Asset Title
   * The title of the asset
   */
  title: string | null;
  /**
   * Asset Subtitle
   * The subtitle of the asset
   */
  subtitle: string | null;
  /** Age Recommendation */
  age_recommendation: number | null;
  /**
   * Production Year
   * The production year of the asset
   */
  production_year: number | null;
  /**
   * Asset Plot
   * The plot of the asset
   */
  plot: string | null;
  /**
   * Asset Genres
   * The genres of the asset
   */
  genres: ReducedGenreSchema[] | null;
  /**
   * Asset cast
   * The cast of the asset
   */
  cast: CastMember[] | null;
  /**
   * Asset Poster
   * The poster url of the asset
   */
  poster_url?: string;
  /**
   * Blurhash of the Poster
   * @default "eCF6B#-:0JInxr?@s;nmIoWUIko1%NocRk.8xbIUaxR*^+s;RiWAWU"
   */
  poster_blurhash?: string;
  /**
   * Asset Description
   * The description of the asset
   * @default ""
   */
  description?: string | null;
  /** Asset Recording Available */
  readonly asset_recording_available: boolean;
  /** Directors */
  readonly directors: string[] | null;
}

/** SwissNationalLeagueAssetSchema */
export interface SwissNationalLeagueAssetSchema {
  /** Id of the asset */
  id: string;
  /** Type */
  type: "swissnationalleague";
  /**
   * Asset Title
   * The title of the asset
   */
  title: string | null;
  /**
   * Asset Subtitle
   * The subtitle of the asset
   */
  subtitle: string | null;
  /** Age Recommendation */
  age_recommendation: number | null;
  /**
   * Production Year
   * The production year of the asset
   */
  production_year: number | null;
  /**
   * Asset Plot
   * The plot of the asset
   */
  plot: string | null;
  /**
   * Asset Genres
   * The genres of the asset
   */
  genres: ReducedGenreSchema[] | null;
  /**
   * Asset cast
   * The cast of the asset
   */
  cast: CastMember[] | null;
  /**
   * Asset Poster
   * The poster url of the asset
   */
  poster_url?: string;
  /**
   * Blurhash of the Poster
   * @default "eCF6B#-:0JInxr?@s;nmIoWUIko1%NocRk.8xbIUaxR*^+s;RiWAWU"
   */
  poster_blurhash?: string;
  /**
   * Asset Description
   * The description of the asset
   * @default ""
   */
  description?: string | null;
  /** Asset Recording Available */
  readonly asset_recording_available: boolean;
  /** Directors */
  readonly directors: string[] | null;
}

/** TalkAssetSchema */
export interface TalkAssetSchema {
  /** Id of the asset */
  id: string;
  /** Type */
  type: "talk";
  /**
   * Asset Title
   * The title of the asset
   */
  title: string | null;
  /**
   * Asset Subtitle
   * The subtitle of the asset
   */
  subtitle: string | null;
  /** Age Recommendation */
  age_recommendation: number | null;
  /**
   * Production Year
   * The production year of the asset
   */
  production_year: number | null;
  /**
   * Asset Plot
   * The plot of the asset
   */
  plot: string | null;
  /**
   * Asset Genres
   * The genres of the asset
   */
  genres: ReducedGenreSchema[] | null;
  /**
   * Asset cast
   * The cast of the asset
   */
  cast: CastMember[] | null;
  /**
   * Asset Poster
   * The poster url of the asset
   */
  poster_url?: string;
  /**
   * Blurhash of the Poster
   * @default "eCF6B#-:0JInxr?@s;nmIoWUIko1%NocRk.8xbIUaxR*^+s;RiWAWU"
   */
  poster_blurhash?: string;
  /**
   * Asset Description
   * The description of the asset
   * @default ""
   */
  description?: string | null;
  /** Asset Recording Available */
  readonly asset_recording_available: boolean;
  /** Directors */
  readonly directors: string[] | null;
}

/** UnknownAssetSchema */
export interface UnknownAssetSchema {
  /** Id of the asset */
  id: string;
  /** Type */
  type: "unknown";
  /**
   * Asset Title
   * The title of the asset
   */
  title: string | null;
  /**
   * Asset Subtitle
   * The subtitle of the asset
   */
  subtitle: string | null;
  /** Age Recommendation */
  age_recommendation: number | null;
  /**
   * Production Year
   * The production year of the asset
   */
  production_year: number | null;
  /**
   * Asset Plot
   * The plot of the asset
   */
  plot: string | null;
  /**
   * Asset Genres
   * The genres of the asset
   */
  genres: ReducedGenreSchema[] | null;
  /**
   * Asset cast
   * The cast of the asset
   */
  cast: CastMember[] | null;
  /**
   * Asset Poster
   * The poster url of the asset
   */
  poster_url?: string;
  /**
   * Blurhash of the Poster
   * @default "eCF6B#-:0JInxr?@s;nmIoWUIko1%NocRk.8xbIUaxR*^+s;RiWAWU"
   */
  poster_blurhash?: string;
  /**
   * Asset Description
   * The description of the asset
   * @default ""
   */
  description?: string | null;
  /** Asset Recording Available */
  readonly asset_recording_available: boolean;
  /** Directors */
  readonly directors: string[] | null;
}

/** ValidLanguages */
export enum ValidLanguages {
  En = "en",
  De = "de",
  Fr = "fr",
  It = "it",
}

/** ValidationError */
export interface ValidationError {
  /** Location */
  loc: (string | number)[];
  /** Message */
  msg: string;
  /** Error Type */
  type: string;
}

/** WintersportsAssetSchema */
export interface WintersportsAssetSchema {
  /** Id of the asset */
  id: string;
  /** Type */
  type: "wintersports";
  /**
   * Asset Title
   * The title of the asset
   */
  title: string | null;
  /**
   * Asset Subtitle
   * The subtitle of the asset
   */
  subtitle: string | null;
  /** Age Recommendation */
  age_recommendation: number | null;
  /**
   * Production Year
   * The production year of the asset
   */
  production_year: number | null;
  /**
   * Asset Plot
   * The plot of the asset
   */
  plot: string | null;
  /**
   * Asset Genres
   * The genres of the asset
   */
  genres: ReducedGenreSchema[] | null;
  /**
   * Asset cast
   * The cast of the asset
   */
  cast: CastMember[] | null;
  /**
   * Asset Poster
   * The poster url of the asset
   */
  poster_url?: string;
  /**
   * Blurhash of the Poster
   * @default "eCF6B#-:0JInxr?@s;nmIoWUIko1%NocRk.8xbIUaxR*^+s;RiWAWU"
   */
  poster_blurhash?: string;
  /**
   * Asset Description
   * The description of the asset
   * @default ""
   */
  description?: string | null;
  /** Asset Recording Available */
  readonly asset_recording_available: boolean;
  /** Directors */
  readonly directors: string[] | null;
}

import { HttpClient, RequestParams } from "@sunrise/backend-ng-core";

/**
 * @title FastAPI
 * @version 0.1.0
 */
export class GeneratedApi {
  http: HttpClient;

  constructor(http: HttpClient) {
    this.http = http;
  }

  asset = {
    /**
     * @description Returns paged assets
     *
     * @tags assets
     * @name PagedAssetAssetV1AssetsGet
     * @summary Paged Asset
     * @request GET:/asset/v1/assets
     * @secure
     */
    pagedAssetAssetV1AssetsGet: (
      query?: {
        /**
         * Page
         * Page number
         * @min 1
         * @default 1
         */
        page?: number;
        /**
         * Size
         * Page size
         * @min 1
         * @max 100
         * @default 50
         */
        size?: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<
        PageAnnotatedUnionSeriesAssetSchemaMovieAssetSchemaProgramAssetSchemaChampionsHockeyLeagueAssetSchemaOtherIceHockeySchemaSwissNationalLeagueAssetSchemaSwedishNationalLeagueAssetSchemaGermanNationalLeagueAssetSchemaNationalLeagueAssetSchemaOtherSportAssetSchemaFormulaEAssetSchemaDocumentaryProgramAssetSchemaHomeMadeDocumentaryProgramAssetSchemaNoProgramScheduledAssetSchemaUnknownAssetSchemaSeriesEpisodeAssetSchemaSeriesSeasonAssetSchemaSportsAssetSchemaESportAssetSchemaWintersportsAssetSchemaTalkAssetSchemaPersonSchemaFieldInfoAnnotationNoneTypeRequiredTrueDiscriminatorType,
        void | HTTPValidationError
      >({
        path: `/asset/v1/assets`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns a specific asset by id
     *
     * @tags assets
     * @name AssetDetailAssetV1AssetsAssetIdGet
     * @summary Asset Detail
     * @request GET:/asset/v1/assets/{asset_id}
     * @secure
     */
    assetDetailAssetV1AssetsAssetIdGet: (assetId: string, params: RequestParams = {}) =>
      this.http.request<
        | ({
            type: "series";
          } & SeriesAssetSchema)
        | ({
            type: "movie";
          } & MovieAssetSchema)
        | ({
            type: "program";
          } & ProgramAssetSchema)
        | ({
            type: "championshockeyleague";
          } & ChampionsHockeyLeagueAssetSchema)
        | ({
            type: "othericehockey";
          } & OtherIceHockeySchema)
        | ({
            type: "swissnationalleague";
          } & SwissNationalLeagueAssetSchema)
        | ({
            type: "swedishhockeyleague";
          } & SwedishNationalLeagueAssetSchema)
        | ({
            type: "germanhockeyleague";
          } & GermanNationalLeagueAssetSchema)
        | ({
            type: "nationalhockeyleague";
          } & NationalLeagueAssetSchema)
        | ({
            type: "othersport";
          } & OtherSportAssetSchema)
        | ({
            type: "formulae";
          } & FormulaEAssetSchema)
        | ({
            type: "documentary";
          } & DocumentaryProgramAssetSchema)
        | ({
            type: "homemade";
          } & HomeMadeDocumentaryProgramAssetSchema)
        | ({
            type: "noprogramscheduled";
          } & NoProgramScheduledAssetSchema)
        | ({
            type: "unknown";
          } & UnknownAssetSchema)
        | ({
            type: "seriesepisode";
          } & SeriesEpisodeAssetSchema)
        | ({
            type: "seriesseason";
          } & SeriesSeasonAssetSchema)
        | ({
            type: "sports";
          } & SportsAssetSchema)
        | ({
            type: "esport";
          } & ESportAssetSchema)
        | ({
            type: "wintersports";
          } & WintersportsAssetSchema)
        | ({
            type: "talk";
          } & TalkAssetSchema)
        | ({
            type: "person";
          } & PersonSchema),
        void | HTTPValidationError
      >({
        path: `/asset/v1/assets/${assetId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name HealthAssetV1HealthGet
     * @summary Health
     * @request GET:/asset/v1/health
     */
    healthAssetV1HealthGet: (params: RequestParams = {}) =>
      this.http.request<any, any>({
        path: `/asset/v1/health`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name RootAssetV1Get
     * @summary Root
     * @request GET:/asset/v1
     */
    rootAssetV1Get: (params: RequestParams = {}) =>
      this.http.request<any, any>({
        path: `/asset/v1`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
}
