import { atom } from "jotai";

import type { ChannelListItem } from "@sunrise/backend-types";
import { selectedChannelGroupWithPaginatedChannels } from "@sunrise/yallo-channel-group";

/**
 * This atom represents the user's channels in their selected channel group.
 * This list should be shown on the TV page and the Guide page.
 *
 * It's pre-sorted on the backend's order attribute.
 */
export const channelsOfCurrentSelectedGroupAtom = atom<
  Promise<{ channels: ChannelListItem[]; fetchNextPage?: () => void }>
>(async (get) => {
  const channels =
    (await get(selectedChannelGroupWithPaginatedChannels))?.channels.map(
      (channel) => ({
        id: channel.id,
        stream: channel.stream,
        channelNumber: channel.order,
        channelLogo: channel.logo,
        channelName: channel.name,
      }),
    ) ?? [];

  return {
    channels,
    fetchNextPage: (await get(selectedChannelGroupWithPaginatedChannels))
      ?.fetchNextPage,
  };
});
channelsOfCurrentSelectedGroupAtom.debugLabel =
  "channelsOfCurrentSelectedGroupAtom";
