import { atom } from "jotai";

import type {
  RecordingGroupId,
  RecordingId,
} from "@sunrise/backend-types-core";

export type MarkedRecordings = Record<RecordingId | RecordingGroupId, boolean>;

/**
 * Describes all the recordings the UI has flagged as deleted on the backend.
 * We do this as soon as we send stuff to the backend for deletion so they disappear instantly.
 *
 * The socket will then remove items from this object as soon as the backend has actually marked them as deleted.
 * So we can stop hiding them in the UI like this.
 */
export const recordingsMarkedForDeletionAtom = atom<MarkedRecordings>({});
