import type { ReactNode } from "react";
import clsx from "clsx";

import type { Nullable } from "@sunrise/utils";

import styles from "./progress-bar.module.css";

type Props = {
  liveProgress: Nullable<number>;
  replayProgress?: Nullable<number>;
  variant: "box" | "programBox" | "player" | "search";
} & CommonProps &
  ProgressBarLabelProps;

export type ProgressBarLabelProps = {
  liveProgressTitle?: string;
  watchedProgressTitle?: string;
};

function ProgressBar({
  liveProgress,
  replayProgress,
  variant,
  className,
  liveProgressTitle,
  watchedProgressTitle,
}: Props): ReactNode {
  if (!liveProgress) return null;

  return (
    <div className={clsx([styles.bar, styles[variant], className])}>
      <div
        aria-label={liveProgressTitle}
        aria-valuenow={liveProgress}
        className={styles.liveProgress}
        role="progressbar"
        style={{ width: `${liveProgress}%` }}
      />
      {replayProgress && (
        <div
          aria-label={watchedProgressTitle}
          aria-valuenow={replayProgress}
          className={styles.replayProgress}
          role="progressbar"
          style={{ width: `${replayProgress}%` }}
        />
      )}
    </div>
  );
}

export { ProgressBar };
