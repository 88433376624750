import { atomPerMinute } from "@sunrise/time";

import { playerCurrentDateTimeShownAtom } from "./player-current-date-time-shown.atom";

export const selectPlayerCurrentDateTimeShownPerMinute = atomPerMinute(
  playerCurrentDateTimeShownAtom,
);

selectPlayerCurrentDateTimeShownPerMinute.debugLabel =
  "selectPlayerCurrentDateTimeShownPerMinute";
