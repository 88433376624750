import { atom } from "jotai";

import { RecordingItemFilter } from "../types";

/**
 * Controls which filter to apply for the recordings overview.
 */
export const recordingsOverviewFilterAtom = atom<RecordingItemFilter>(
  RecordingItemFilter.All,
);
