import type { ReactElement } from "react";
import { useEffect } from "react";
import { useAtom } from "jotai/react";
import { useResetAtom } from "jotai/utils";
import { useIntersectionObserver } from "usehooks-ts";

import { headerTitleAtom } from "./scroll-down-header-title.atom";

type HeaderTitleProps = { title: string; children: ReactElement } & Pick<
  CommonProps,
  "className"
>;

export function ScrollDownHeaderTitle({
  className,
  children,
  title,
}: HeaderTitleProps) {
  const [, setPageTitle] = useAtom(headerTitleAtom);
  const resetPageTitle = useResetAtom(headerTitleAtom);
  const { ref, isIntersecting } = useIntersectionObserver();

  useEffect(
    function setPageTitleOnScroll() {
      setPageTitle({ title, visible: !isIntersecting });
      return () => resetPageTitle();
    },
    [isIntersecting, resetPageTitle, setPageTitle, title],
  );

  return (
    <div ref={ref} className={className}>
      {children}
    </div>
  );
}
