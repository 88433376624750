import { clsx } from "clsx";

import styles from "./search-result.module.css";

export function SearchSkeletonResult() {
  return (
    <div className={clsx(styles.container, styles.skeleton)}>
      <div className={clsx(styles.container, styles.epgInformation)}>
        <div className={styles.meta}>
          <div className={styles.header}>
            <div className={styles.title}></div>
            <div className={styles.subtitle}></div>
          </div>
          <div className={styles.airTime} />
        </div>
      </div>
      <div className={styles.logo} />
    </div>
  );
}
