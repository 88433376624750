import { isSSR } from "./is-ssr";

export function isTitanOS(): boolean {
  if (isSSR()) {
    return false;
  }

  // NOTE: alternative check for TitanOS
  /*const userAgent = window.navigator.userAgent.toLowerCase();

  return (
    userAgent.includes("titanos") ||
    userAgent.includes("whaletv") ||
    userAgent.includes("wh1.0")
  );*/

  return "SmartTvA_API" in window;
}
