import type { ComponentProps, ReactNode } from "react";

import { usePlayChannel } from "@sunrise/yallo-common-player-manager";
import type { ChannelRecommendation } from "@sunrise/yallo-recommendations";
import { ChannelBox as BaseChannelBox } from "@sunrise/yallo-web-components";

type ChannelProps = ChannelRecommendation & {
  className?: string;
  variant?: ComponentProps<typeof BaseChannelBox>["variant"];
};
export function Channel({
  variant = "home",
  ...props
}: ChannelProps): ReactNode {
  const { play } = usePlayChannel({
    channelId: props.channelId,
  });

  return <BaseChannelBox onClick={play} {...props} variant={variant} />;
}
