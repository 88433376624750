import { atom } from "jotai";
import { atomFamily } from "jotai/utils";

import { isLegacyBackendAtom } from "@sunrise/backend-core";
import { recordingByRecordingIdQueryAtom } from "@sunrise/backend-ng-recordings";
import type { RecordingId } from "@sunrise/backend-types-core";
import { currentLanguageAtom } from "@sunrise/i18n";

import { recordingByRecordingIdLegacyQueryAtom } from "./legacy/queries/recording-by-recording-id.legacy.query.atom";
import { legacyRecordingToGenericRecording } from "./utils/legacy-recording-to-generic-recording";
import { ngRecordingToGenericRecording } from "./utils/ng-recording-to-generic-recording";

/**
 *
 * TODO: Would be cool if we could look up the recordings in the recordings pages as well before we try to look it up on the API.
 */
export const recordingByRecordingIdAtom = atomFamily(
  (recordingId: RecordingId) => {
    const inner = atom(async (get) => {
      if (get(isLegacyBackendAtom)) {
        const query = await get(
          recordingByRecordingIdLegacyQueryAtom(recordingId),
        );

        if (!query.data) {
          return null;
        }

        return legacyRecordingToGenericRecording(
          query.data,
          get(currentLanguageAtom),
        );
      }

      const query = await get(recordingByRecordingIdQueryAtom(recordingId));

      // NOTE: We are filtering out the recording group id for now as we can't map it to a generic recording yet
      if (!query.data || query.data.type === "recording_group") {
        return null;
      }

      return ngRecordingToGenericRecording(query.data);
    });

    inner.debugLabel = `recordingByRecordingIdQueryAtom(${recordingId})`;

    return inner;
  },
);
