import type { ChannelId } from "@sunrise/backend-types-core";
import { atomWithCompare } from "@sunrise/utils";

type VisibleData = {
  /**
   * What is the earliest visible timeblock in the grid.
   */
  startTime: Date;
  /**
   * What is the latest visible timeblock in the grid.
   */
  endTime: Date;
};

/**
 * A 'stable' atom which will only propagate changes when the dates in the object have changed.
 */
export const visibleTimingsAtom = atomWithCompare<VisibleData | null>(null);
/**
 * A 'stable' atom which will only propagate when the actual contents of the array have changed.
 */
export const visibleChannelsAtom = atomWithCompare<ChannelId[] | null>(null);
