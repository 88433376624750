import { useCallback, useEffect } from "react";
import { addDays, addMinutes, subMinutes } from "date-fns";
import { useAtomValue, useSetAtom } from "jotai";

import type { TimeISOString } from "@sunrise/backend-types-core";

import { visibleTimingsAtom } from "../guide-visible-data.atom";
import { guideWindowAtom } from "../guide-window.atom";

export function useGridVisibleDataChangedTime({
  firstTimeVisible,
  lastTimeVisible,
  bufferInMinutes,
}: {
  firstTimeVisible: TimeISOString | null;
  lastTimeVisible: TimeISOString | null;
  bufferInMinutes: number;
}) {
  const { startTime, endTime } = useAtomValue(guideWindowAtom);

  const onVisibleDataChangedSet = useSetAtom(visibleTimingsAtom);
  const onVisibleDataChanged = useCallback(
    (startTime: Date, endTime: Date) => {
      onVisibleDataChangedSet({ startTime, endTime });
    },
    [onVisibleDataChangedSet],
  );

  /**
   * We need to communicate what data is currently visible to the hook.
   * So it can load in the correct data.
   *
   * This effect depends mostly on firstChannelIndexVisible and firstTimeVisible
   */
  useEffect(() => {
    if (!onVisibleDataChanged || !firstTimeVisible) {
      return;
    }

    const initialStartTime = new Date(firstTimeVisible);

    const initialEndTime = lastTimeVisible
      ? new Date(lastTimeVisible)
      : addDays(initialStartTime, 1);

    onVisibleDataChanged(
      subMinutes(initialStartTime, bufferInMinutes),
      addMinutes(initialEndTime, bufferInMinutes),
    );
  }, [onVisibleDataChanged, startTime, endTime, firstTimeVisible]);
}
