import type { PropsWithChildren } from "react";
import clsx from "clsx";

import styles from "./actions.module.css";

const Actions = ({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) => (
  <div className={clsx(styles.actions, className)}>{children}</div>
);

export { Actions };
