import type { Language } from "@sunrise/backend-types-core";
import { getLocalizedValue } from "@sunrise/i18n";

import type { MappedEpg } from "../types";
import type { fetchEPGEntry } from "./epg.service";

export function mapBackendEpgLegacy(
  epg: Awaited<ReturnType<typeof fetchEPGEntry>>,
  language: Language,
): MappedEpg {
  return {
    id: epg.id,
    actualStart: epg.actual_start,
    actualEnd: epg.actual_end,
    channel: {
      id: epg.channel.id,
      name: null,
      logo: null,
    },
    asset: {
      id: epg.asset.id,
      title:
        getLocalizedValue(epg.asset.title, language) ??
        epg.asset.original_title,
      subtitle: getLocalizedValue(epg.asset.subtitle, language),
      episodeNumber:
        epg.asset.type === "seriesepisode" ? epg.asset.episode : null,
      seasonNumber:
        epg.asset.type === "seriesepisode" ? epg.asset.raw_season : null,
      posterBlurHash: null,
      type: epg.asset.type,
      ageRecomendation: epg.asset.age_recommendation
        ? `PG-${epg.asset.age_recommendation}`
        : null,
      productionYear: epg.asset.production_year,
      genres: [],
      posterPath: epg.asset.poster_path,
      plot: epg.asset.plot ? getLocalizedValue(epg.asset.plot, language) : "",
      seasons: null,
      cast: [],
      directors: [],
      seriesId: null,
    },
  };
}
