import { atom } from "jotai";

import { deviceInfo, isSmartTV } from "@sunrise/utils";

import { dashManualThumbnailGeneratorAtom } from "./dash-manual-thumbnail-generator.atom";
import { hls7ManualThumbnailGeneratorAtom } from "./hls7-manual-thumbnail-generator.atom";
import { preferredThumbnailGeneratorAtom } from "./preferred-thumbnail-generator.atom";
import { shakaThumbnailGeneratorAtom } from "./shaka-thumbnail-generator.atom";

/**
 * When the thumbnail is returned in the form of a sprite.
 */
type ThumbnailSprite = {
  url: string;
  fullWidth: number;
  fullHeight: number;
  x: number;
  y: number;
  width: number;
  height: number;
};

export type Thumbnail = ThumbnailSprite;

export type ThumbnailGenerator = {
  generate: (timestamp: number) => Promise<Thumbnail | null>;
  name: string;
};

// NOTE: the padding is used to help matching the right position of the thumbnail in the requested stream position
export const THUMBNAIL_POSITION_PADDING = 1; // in seconds

/**
 * An atom that contains a function on how to generate a thumbnail for a given timestamp in the current stream.
 */
export const thumbnailGeneratorAtom = atom<Promise<ThumbnailGenerator | null>>(
  async (get) => {
    const preference = get(preferredThumbnailGeneratorAtom);

    switch (preference) {
      case "dash-manual":
        return get(dashManualThumbnailGeneratorAtom);
      case "hls7-manual":
        return get(hls7ManualThumbnailGeneratorAtom);
      case "shaka":
        return get(shakaThumbnailGeneratorAtom);
      case "auto": {
        const isNativeHls = deviceInfo.isSafari || deviceInfo.isIOS;
        if (!isNativeHls || isSmartTV()) {
          return (
            get(shakaThumbnailGeneratorAtom) ??
            get(dashManualThumbnailGeneratorAtom)
          );
        } else {
          return get(hls7ManualThumbnailGeneratorAtom);
        }
      }
      default:
        return null;
    }
  },
);
