import type {
  AssetId,
  EPGEntryId,
  RecordingGroupId,
  RecordingId,
} from "@sunrise/backend-types-core";

import type {
  BaseRecordingGroupSchema,
  BaseRecordingSchema,
} from "../GeneratedApi";
import type { NgRecording } from "../types";

export function backendRecordingToNgRecording(
  item:
    | ({
        type: "recording";
      } & BaseRecordingSchema)
    | ({
        type: "recording_group";
      } & BaseRecordingGroupSchema),
): NgRecording {
  if (item.type === "recording") {
    return {
      ...item,
      id: item.id as RecordingId,
      epg_end: new Date(item.epg_end),
      epg_start: new Date(item.epg_start),
      epg_entry_id: item.epg_entry_id as EPGEntryId,
      asset: {
        ...item.asset,
        id: item.asset.id as AssetId,
      },
    };
  }

  return {
    ...item,
    id: item.id as RecordingGroupId,
  };
}
