import { atomFamily } from "jotai/utils";
import { atomWithSuspenseQuery } from "jotai-tanstack-query";
import { isNil } from "lodash";

import { ngEpgApiAtom } from "@sunrise/backend-ng-epg";
import { queryKeys } from "@sunrise/backend-types";
import type { EPGEntryId } from "@sunrise/backend-types-core";
import { currentLanguageAtom } from "@sunrise/i18n";
import { type Nullable } from "@sunrise/utils";

import type { MappedEpg } from "../types";
import { mapBackendEpgNg } from "./map-backend-epg.ng";
/**
 * Fetches and caches {@link MappedEpg} that's being previewed,
 * not necessary currently playing.
 *
 */
export const epgEntryByIdNgAtom = atomFamily((epgId: EPGEntryId) =>
  atomWithSuspenseQuery<Nullable<MappedEpg>>((get) => {
    const ngApi = get(ngEpgApiAtom);
    const language = get(currentLanguageAtom);

    return {
      enabled: !isNil(epgId),
      queryKey: queryKeys.epgById(epgId, language),
      queryFn: async () => {
        if (!epgId) return null;
        const entry =
          await ngApi.epg.epgEntryDetailEpgV1EpgEntriesEpgEntryIdGet(epgId);

        if (entry.status === 404) {
          return null;
        }

        return mapBackendEpgNg(entry.data);
      },
      staleTime: Infinity,
      cacheTime: Infinity,
    };
  }),
);
