import type { ReactElement } from "react";
import { useRef } from "react";

import { useSeekbar } from "@sunrise/yallo-player-controls";
import { Button, Text } from "@sunrise/yallo-web-components";

import styles from "./player-seekbar.module.css";
import { useSeekbarDrag } from "./use-seekbar-drag";
import { useSeekbarHover } from "./use-seekbar-hover";

type PlayerSeekbarProps = {
  setShowDurationLeft: (value: boolean) => void;
  showDurationLeft: boolean;
};

function PlayerSeekbar({
  setShowDurationLeft,
  showDurationLeft,
}: PlayerSeekbarProps): ReactElement {
  const seekbarRef = useRef<HTMLDivElement | null>(null);
  const {
    progress,
    liveProgress,
    elapsedWithoutSeek,
    durationLeftWithoutSeek,
    progressWithoutSeek,
  } = useSeekbar();
  const { isDragging, ...seekbarDrag } = useSeekbarDrag(seekbarRef);
  const seekbarHover = useSeekbarHover(seekbarRef);
  const toggleTimeNotation = (): void => setShowDurationLeft(!showDurationLeft);

  return (
    <div className={styles.wrapper}>
      <Button
        className={styles.currentTime}
        variant="none"
        onClick={toggleTimeNotation}
      >
        <Text size="small" variant="label">
          {showDurationLeft
            ? `-${durationLeftWithoutSeek}`
            : elapsedWithoutSeek}
        </Text>
      </Button>
      <div
        ref={seekbarRef}
        className={styles.seekbar}
        draggable={true}
        style={{
          "--progress": `${progressWithoutSeek ?? 0}%`,
          "--thumb-progress": `${
            (isDragging ? progress : progressWithoutSeek) ?? 0
          }%`,
          "--liveProgress": `${liveProgress ?? 0}%`,
        }}
        {...seekbarDrag}
        {...seekbarHover}
      >
        <div className={styles.progress}>
          {progress !== null && <div className={styles.elapsed}></div>}
        </div>
        {progress !== null && <div className={styles.thumb}></div>}
      </div>
    </div>
  );
}

export { PlayerSeekbar };
