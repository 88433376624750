import { isNil } from "@sunrise/utils";

/**
 * Is the URL considered critical and worthy to be retried manually by the user.
 */
export function isRetryableError(url: string | undefined): boolean {
  if (isNil(url)) return false;
  return (
    url.includes("/me") ||
    url.includes("/refresh") ||
    url.includes("/kamino/v2/pins/")
  );
}
