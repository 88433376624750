import { atomWithSuspenseQuery } from "jotai-tanstack-query";

import { queryKeys } from "@sunrise/backend-types";
import { hostsAtom, httpClientAtom } from "@sunrise/http-client";
import { selectIsLoggedIn, selectJwtUserToken } from "@sunrise/jwt";

import { fetchFullyWatched } from "./continue-watching-service";
import { continueWatchingStaleTimeAtom } from "./continue-watching-stale-time.atom";

export const fullyWatchedQueryAtom = atomWithSuspenseQuery((get) => {
  const host = get(hostsAtom).api;
  const privateApi = get(httpClientAtom).privateApi;

  if (!privateApi) throw new Error("missing privateApi");
  if (!host) throw new Error("Host is not set");

  const isLoggedIn = get(selectIsLoggedIn);

  return {
    queryKey: queryKeys.fullyWatched(get(selectJwtUserToken)),
    queryFn: async () => {
      if (!isLoggedIn) {
        return [];
      }

      return await fetchFullyWatched(privateApi, host);
    },
    staleTime: get(continueWatchingStaleTimeAtom),
  };
});
