import type { PropsWithChildren, ReactElement } from "react";
import clsx from "clsx";

import type { Icons } from "@sunrise/icons";
import { Icon } from "@sunrise/icons";

import { Text } from "../text";
import { VisuallyHidden } from "../visually-hidden";
import styles from "./tag.module.css";

type TagProps = {
  title?: string;
  icon?: Icons;
  hideLabel?: boolean;
} & PropsWithChildren &
  CommonProps;

function Tag({
  children,
  className,
  icon,
  title,
  hideLabel,
}: TagProps): ReactElement {
  const value = (
    <Text
      aria-label={title}
      className={styles.label}
      size="small"
      title={title}
      variant="label"
    >
      {children}
    </Text>
  );

  return (
    <div className={clsx(className, styles.tag)}>
      {!!icon && <Icon className={styles.icon} name={icon} />}
      {hideLabel ? <VisuallyHidden>{value}</VisuallyHidden> : value}
    </div>
  );
}

export { Tag };
