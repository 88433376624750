import type { MutableRefObject } from "react";

import { calculatePercentageOffsetInElementBounds } from "@sunrise/utils";
import { useSeekbar } from "@sunrise/yallo-player-controls";

type useSeekbarDragReturn = {
  onPointerEnter: React.PointerEventHandler;
};

export function useSeekbarHover(
  seekbarRef: MutableRefObject<HTMLDivElement | null>,
): useSeekbarDragReturn {
  const { seek } = useSeekbar();

  const handlePointerOver = (): void => {
    if (!seekbarRef.current) return;
    document.addEventListener("pointermove", handlePointerMove);
    seekbarRef.current.addEventListener("pointerleave", handlePointerLeave);
  };

  const handlePointerMove = (event: PointerEvent): void => {
    if (!seekbarRef.current) return;
    const percentage = calculatePercentageOffsetInElementBounds(
      seekbarRef.current,
      event.clientX,
    );
    seek.toPercentage(percentage);
  };

  const handlePointerLeave = async (event: PointerEvent): Promise<void> => {
    if (!seekbarRef.current) return;
    document.removeEventListener("pointermove", handlePointerMove);
    seekbarRef.current.removeEventListener("pointerleave", handlePointerLeave);
    if (event.button <= 0) seek.reset(); // NOTE: no button can be -1
  };

  return {
    onPointerEnter: handlePointerOver,
  };
}
