import type { Stream } from "@sunrise/backend-types";

import { createDateToCurrentTimeConverter } from "./create-date-to-current-time-converter";

/**
 * When we want to set a new current time in a stream, we need to make sure it's a valid one.
 * This is because if we set a time that is too high or too low the player can act oddly.
 *
 * If the date is not valid => null
 * If the date is below the lower clipping point, return the lowest possible currentTime.
 * If the date is above the upper clipping point, return the highest possible currentTime.
 */
export function ensureValidCurrentTimeForCurrentStream(
  stream: Stream,
  currentTime: number,
  now: Date,
): null | number {
  if (!("offset" in stream)) {
    return currentTime;
  }

  if (currentTime < 0 || currentTime === 0) {
    return 0;
  } else {
    const converter = createDateToCurrentTimeConverter(stream.offset);

    const newDate = converter.toDate(currentTime);
    if (!newDate) {
      return null;
    }

    if (newDate > now) {
      currentTime = converter.fromDate(now);
    }

    return currentTime;
  }
}
