import { atomWithSuspenseQuery } from "jotai-tanstack-query";
import { isNil } from "lodash";

import {
  type ChannelGroup,
  type ChannelGroupsResponse,
  endpoints,
} from "@sunrise/backend-types";
import type { Language } from "@sunrise/backend-types-core";
import { hostsAtom, publicApi } from "@sunrise/http-client";
import { currentLanguageAtom } from "@sunrise/i18n";
import { type Nullable } from "@sunrise/utils";
import { settingsAtom } from "@sunrise/yallo-settings";

/**
 * This atom stores the channel groups that are available for the current tenant.
 * It does include custom channel groups.
 *
 * @deprecated Legacy atom.
 */
export const channelGroupsLegacyAtom = atomWithSuspenseQuery<
  Nullable<ChannelGroup[]>,
  unknown,
  Nullable<ChannelGroup[]>,
  Nullable<ChannelGroup[]>,
  [string, Language]
>((get) => ({
  queryKey: ["channel-groups", get(currentLanguageAtom)],
  queryFn: async ({ queryKey: [, lang] }) => {
    const settings = await get(settingsAtom);

    const tenantId = settings?.tenant.id;
    if (isNil(tenantId)) {
      throw new Error("TenantId is not defined");
    }

    const host = get(hostsAtom).data;
    if (isNil(host)) {
      throw new Error("Host is not defined");
    }

    const staticChannelGroups = await publicApi.get<ChannelGroupsResponse>(
      endpoints.staticChannelGroups(host, tenantId, lang),
    );
    return staticChannelGroups.data.result;
  },
  cacheTime: Infinity,
  staleTime: Infinity,
  refetchOnMount: false,
}));

channelGroupsLegacyAtom.debugLabel = "channelGroupsLegacyAtom";
