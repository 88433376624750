import type { ReactNode } from "react";
import clsx from "clsx";

import type { Nullable } from "@sunrise/utils";

import type { AirTimeLabelProps } from "../air-time";
import { AirTime } from "../air-time";
import { ChannelLogo } from "../logo";
import { ProgramTitleAndSubtitle } from "../program-title-and-subtitle";
import { ProgressBar } from "../progress-bar";
import styles from "./simplified-program-box.module.css";

export type SimplifiedProgramBoxProps = {
  channelLogo: Nullable<string>;
  channelName: Nullable<string>;
  title: Nullable<string>;
  startDate: Nullable<Date>;
  endDate: Nullable<Date>;
  formattedStart: Nullable<string>; // formatted string 13:00
  formattedEnd: Nullable<string>; // formatted string 15:00
  airTime: Nullable<string>;
  actions: ReactNode;
  liveProgress?: Nullable<number>;
  replayProgress?: Nullable<number>;
  recordingState?: Nullable<RecordingState>;
  redirectUrl?: Nullable<string>;
  isLive?: boolean;
};

function SimplifiedProgramBox({
  channelLogo,
  channelName,
  liveProgress,
  replayProgress,
  recordingState: _recordingState,
  startDate,
  endDate,
  formattedStart,
  formattedEnd,
  airTime,
  title,
  actions,
  redirectUrl,
  startTimeLabel,
  endTimeLabel,
  isLive,
}: SimplifiedProgramBoxProps & AirTimeLabelProps): ReactNode {
  return (
    <article
      aria-label={`${title}, ${airTime} on ${channelName}`}
      className={clsx([styles.container])}
    >
      <ChannelLogo
        className={styles.logo}
        logo={channelLogo}
        name={channelName}
      />

      <div className={styles.inner}>
        <ProgramTitleAndSubtitle
          className={styles.title}
          redirectUrl={redirectUrl}
          size="small"
          title={title}
        />

        <ProgressBar
          className={styles.progressBar}
          liveProgress={liveProgress}
          replayProgress={replayProgress}
          variant="box"
        />

        <AirTime
          className={styles.airtime}
          endDate={endDate}
          endTimeLabel={endTimeLabel}
          formattedEnd={formattedEnd}
          formattedStart={formattedStart}
          isLive={isLive}
          relativeDate={airTime}
          size="small"
          startDate={startDate}
          startTimeLabel={startTimeLabel}
        />
      </div>
      <div className={styles.actionsContainer}>{actions}</div>
    </article>
  );
}

export { SimplifiedProgramBox };
