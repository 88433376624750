import { atomWithSuspenseQuery } from "jotai-tanstack-query";

import { ngRecordingsApiAtom } from "../ng-recordings-api.atom";

/**
 * Returns the available sorting options for the recordings overview on the NG backend.
 */
export const recordingsOverviewSortOptionsQueryAtom = atomWithSuspenseQuery(
  (get) => {
    const api = get(ngRecordingsApiAtom);

    return {
      queryKey: ["recordings-sort-options"],
      queryFn: async () => {
        const { data } =
          await api.recording.getSortingOptionsRecordingV1SortingOptionsGet();

        return data;
      },
      gcTime: Infinity,
      staleTime: Infinity,
      networkMode: "offlineFirst",
      refetchOnMount: false,
    };
  },
);
