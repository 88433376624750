import { atom } from "jotai";
import { unwrap } from "jotai/utils";

import { unwrappedAllChannelsInChannelGroup } from "./atoms/unwrapped-all-channels-in-channel-group.atom";
import { channelToEmptyGuideChannel } from "./utils/channel-to-empty-guide-channel";

const channelsForGuideAtom = atom((get) => {
  const channels = get(unwrappedAllChannelsInChannelGroup);

  if (!channels) {
    return null;
  }

  return channels.map(channelToEmptyGuideChannel);
});

/**
 * A pretty stable atom that just delivers a base skeleton of all the channels that should be loaded.
 */
export const unwrappedChannelsForGuideAtom = unwrap(channelsForGuideAtom);
