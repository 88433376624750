import { atom } from "jotai";

import { desiredVolumeAtom } from "./desired-volume.atom";
import { selectPlayerVolume } from "./selectors";

const DEFAULT_VOLUME = 100;

const _internalVolumeAtom = atom((get) => {
  const playerVolume = get(selectPlayerVolume);
  const volume = get(desiredVolumeAtom);

  if (playerVolume && !volume) {
    return playerVolume;
  }

  if (volume && !playerVolume) {
    return volume;
  }

  if (volume && playerVolume) {
    return volume.at > playerVolume.at ? volume : playerVolume;
  }

  // This is just a temporary default which should be immediately overridden when the videoElement instantiates.
  // Just so the UI does not have to provide defaults.
  return volume ?? playerVolume ?? { level: DEFAULT_VOLUME, muted: false };
});
_internalVolumeAtom.debugPrivate = true;

/**
 * The atom to use in the main player's UI to show the current volume and mute state.
 * It will return a fluent value that will be updated when the user changes the volume.
 * It will also return the player's new volume / mute state should it change in the background.
 */
export const playerVolumeAtom = atom(
  (get) => {
    const volume = get(_internalVolumeAtom);

    if (volume.muted) {
      return {
        level: 0,
        muted: true,
      };
    }

    return volume;
  },
  (get, set, value: SetLevel | ToggleMute) => {
    switch (value.type) {
      case "set-level": {
        if (value.level < 0 || value.level > 100) {
          return;
        }
        set(desiredVolumeAtom, {
          at: Date.now(),
          level: value.level,
          muted: value.level === 0,
        });
        return;
      }
      case "toggle-mute": {
        const current = get(_internalVolumeAtom);
        set(desiredVolumeAtom, {
          at: Date.now(),
          muted: !current.muted,
          // When toggling mute and we are going from muted to unmuted, we should restore the volume to the last known level.
          // If the last known level is 0 (muted), we should restore it to the default volume.
          level:
            current.muted && current.level === 0
              ? DEFAULT_VOLUME
              : current.level,
        });
        return;
      }
    }
  },
);

type SetLevel = {
  type: "set-level";
  level: number;
};

type ToggleMute = {
  type: "toggle-mute";
};
