import type { ReactElement, ReactNode } from "react";
import clsx from "clsx";

import type { Nullable } from "@sunrise/utils";
import type { SimplifiedRecordingStatus } from "@sunrise/yallo-recordings";

import { Title } from "../text";
import { VisuallyHidden } from "../visually-hidden";
import { RecTag } from "./rec-tag";
import { Tag } from "./tag";
import styles from "./tag-group.module.css";

type Props = {
  recordingStatus?: Nullable<SimplifiedRecordingStatus>;
  rating?: ReactNode;
  ageRecommendation: Nullable<string>;
  productionYear: Nullable<string>;
  genres: Nullable<string[]>;
} & CommonProps &
  TagGroupLabelProps;

export type TagGroupLabelProps = {
  titleTagGroup?: string;
  titleAgeRecommendation?: string;
  titleProductionYear?: string;
  titleGenres?: string;
};

const TagGroup = ({
  className,
  ageRecommendation,
  productionYear,
  genres = [],
  titleTagGroup,
  titleAgeRecommendation,
  titleProductionYear,
  titleGenres,
  rating,
  recordingStatus,
}: Props): ReactElement | null => {
  return (
    <div className={clsx(styles.tags, className)}>
      <VisuallyHidden>
        <Title level="h2" size="medium" variant="title">
          {titleTagGroup}
        </Title>
      </VisuallyHidden>
      {recordingStatus && <RecTag active={recordingStatus === "recorded"} />}
      {rating}
      {!!ageRecommendation && (
        <Tag title={titleAgeRecommendation}>{ageRecommendation}</Tag>
      )}
      {!!productionYear && (
        <Tag title={titleProductionYear}>{productionYear}</Tag>
      )}
      {!!genres?.length && (
        <ul aria-label={titleGenres} className={styles.genres}>
          {genres.map((genre) => (
            <li key={genre} className={styles.tag}>
              <Tag>{genre}</Tag>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export { TagGroup };
