import type { ChannelId } from "@sunrise/backend-types-core";

import { type GuideProgram } from "../guide.types";
import type {
  ActionGuideJumpToDate,
  ActionGuideLeaveGrid,
  ActionGuideReset,
  ActionGuideResetOffset,
  ActionGuideResetSelection,
  ActionGuideSetGridFocused,
  ActionGuideSetOffset,
  ActionGuideSetOffsetHandled,
  ActionGuideSetSelection,
} from "./grid-state.types";

/**
 * Call this when you want to set the offset to a specific value and you want the grid to no longer care about the selection.
 *
 * NOTE: Changes to grid priority.
 */
export function actionGuideSetOffsetForDate(
  x: number,
  y: number,
): ActionGuideSetOffset {
  return {
    type: "guide/set-offset",
    payload: {
      x,
      y,
      offsetPriority: "select-date",
      handled: false,
    },
  };
}

/**
 * Call this when you are changing the offset because the selection changed.
 *
 * NOTE: Changes to selection priority.
 */
export function actionGuideSetOffsetForSelection(
  x: number,
  y: number,
): ActionGuideSetOffset {
  return {
    type: "guide/set-offset",
    payload: {
      x,
      y,
      offsetPriority: "selection",
      handled: false,
    },
  };
}

/**
 * Call this when offset is simply updated by scrolling
 */
export function actionGuideSetOffsetForGrid(
  x: number,
  y: number,
): ActionGuideSetOffset {
  return {
    type: "guide/set-offset",
    payload: {
      x,
      y,
      offsetPriority: "grid",
      handled: true,
    },
  };
}

export function actionGuideLeaveGrid(): ActionGuideLeaveGrid {
  return {
    type: "guide/leave-grid",
  };
}

export function actionGuideSetOffsetHandled(
  x: number,
  y: number,
): ActionGuideSetOffsetHandled {
  return {
    type: "guide/set-offset-handled",
    payload: {
      x,
      y,
    },
  };
}

/**
 * When we reset the offset it is like a jump in the grid. We assume we will want grid priotity.
 */
export function actionGuideResetOffset(): ActionGuideResetOffset {
  return {
    type: "guide/reset-offset",
  };
}

/**
 * This will set the selection and switch to selection priority.
 */
export function actionGuideSetSelection(
  program: GuideProgram,
  channelId: ChannelId,
): ActionGuideSetSelection {
  return {
    type: "guide/set-selection",
    payload: {
      epgId: program.id,
      assetId: program.assetId,
      channelId,
      startTime: program.startTime,
      endTime: program.endTime,
      title: program.title,
      subtitle: program.subtitle,
      plot: program.plot,
      durationInMinutes: program.durationInMinutes,
      image: program.image,
    },
  };
}

export function actionGuideClearSelection(): ActionGuideSetSelection {
  return {
    type: "guide/set-selection",
    payload: null,
  };
}

/**
 * Use it to tell the store that we are focused or not.
 *
 * This needs to be global state because we want to remember to focus the scroll area
 * when we go from a detail page back to the guide.
 */
export function actionGuideSetGridFocused(
  isFocused: boolean,
): ActionGuideSetGridFocused {
  return {
    type: "guide/set-grid-focus",
    payload: {
      isFocused,
    },
  };
}

export function actionGuideJumpToDate(date: Date): ActionGuideJumpToDate {
  return {
    type: "guide/jump-to-date",
    payload: {
      date,
    },
  };
}

export function actionGuideReset(): ActionGuideReset {
  return {
    type: "guide/reset",
  };
}

export function actionGuideResetSelection(): ActionGuideResetSelection {
  return {
    type: "guide/reset-selection",
  };
}
