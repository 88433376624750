import { atomWithMutation, queryClientAtom } from "jotai-tanstack-query";

import type { AssetId } from "@sunrise/backend-types-core";
import { hostsAtom, httpClientAtom } from "@sunrise/http-client";
import { isNil } from "@sunrise/utils";

import { flushRecordingsState } from "../flush-recordings-state";
import { deleteRecordings } from "../recordings.service";

export const deleteRecordingsMutationAtom = atomWithMutation<
  void,
  {
    assetId: AssetId;
  },
  unknown
>((get) => {
  const host = get(hostsAtom).api;
  if (isNil(host)) throw new Error("Host is not set");

  const privateApi = get(httpClientAtom).privateApi;
  if (isNil(privateApi)) throw new Error("Private api is not set");

  const queryClient = get(queryClientAtom);

  return {
    mutationKey: ["delete-recordings"],
    mutationFn: ({ assetId }) => deleteRecordings(host, privateApi, assetId),
    onSettled: function () {
      flushRecordingsState(queryClient, get);
    },
  };
});
