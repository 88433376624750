import { atomWithSuspenseQuery } from "jotai-tanstack-query";
import { isNil } from "lodash";

import { type ChannelGroup, queryKeys } from "@sunrise/backend-types";
import { hostsAtom, httpClientAtom } from "@sunrise/http-client";
import { selectJwtUserToken } from "@sunrise/jwt";
import { type Nullable } from "@sunrise/utils";

import { fetchUserChannelGroupsLegacy } from "../fetch-user-channel-groups.legacy";

/**
 * @deprecated
 */
export const userChannelGroupsQueryLegacyAtom = atomWithSuspenseQuery<
  Nullable<ChannelGroup[]>,
  unknown,
  Nullable<ChannelGroup[]>,
  Nullable<ChannelGroup[]>,
  ReturnType<typeof queryKeys.channelGroups>
>((get) => {
  const host = get(hostsAtom).api;
  if (isNil(host)) throw new Error("Host is not set");

  const privateApi = get(httpClientAtom).privateApi;
  if (isNil(privateApi)) throw new Error("Private api is not set");

  const token = get(selectJwtUserToken);
  return {
    queryKey: queryKeys.channelGroups(token),
    queryFn: async () => {
      if (!token) return [];
      try {
        return await fetchUserChannelGroupsLegacy(privateApi, host);
      } catch (e) {
        // TODO: handle errors
        console.error(e);
        return [];
      }
    },
    cacheTime: Infinity,
    refetchOnMount: false,
  };
});

userChannelGroupsQueryLegacyAtom.debugLabel =
  "userChannelGroupsQueryLegacyAtom";
