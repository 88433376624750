import type { ReactNode } from "react";
import clsx from "clsx";

import { Icon } from "@sunrise/icons";
import { isDefined, type Nullable } from "@sunrise/utils";
import type { MappedDisplayType } from "@sunrise/yallo-recommendations";

import { makeProgramBoxCoverImgUrl } from "../../utils/image";
import { Button } from "../button";
import buttonStyles from "../button/button.module.css";
import { ChannelLogo } from "../logo";
import { ProgressBar } from "../progress-bar";
import { RecTag } from "../tag";
import { Text } from "../text";
import styles from "./program-cover.module.css";
import { RecordingDeleteWarning } from "./recording-delete-warning";

type ProgramCoverProps = {
  variant: MappedDisplayType;
  channelLogo: Nullable<string>;
  channelName: Nullable<string>;
  coverImageUrl: Nullable<string>;
  liveProgress?: Nullable<number>;
  replayProgress?: Nullable<number>;
  recordingState?: Nullable<"planned" | "recorded">;
  expiresIn?: Nullable<number>;
  topProgramNumber?: number;
  recTagLabel?: string;
  onPlay?: (() => void) | null | boolean;
  isDirectory?: boolean;
  className?: string;
  title: Nullable<string>;
};

function ProgramCover({
  channelLogo,
  channelName,
  coverImageUrl,
  expiresIn,
  variant = "box",
  liveProgress,
  replayProgress,
  recordingState,
  topProgramNumber,
  recTagLabel,
  onPlay,
  isDirectory,
  className,
  title,
}: ProgramCoverProps): ReactNode {
  const children = (
    <>
      <div
        className={clsx(styles.coverImage, {
          [styles.hasProgress]:
            isDefined(liveProgress) || isDefined(replayProgress),
        })}
      >
        {!!coverImageUrl && (
          <img
            alt=""
            decoding="async"
            loading="lazy"
            src={makeProgramBoxCoverImgUrl(
              coverImageUrl,
              variant === "horizontal" || !variant ? "box" : variant,
            )}
            data-cover
          />
        )}
      </div>

      <div className={styles.inner}>
        {variant !== "horizontal" && (
          <div className={styles.iconAndExpires}>
            {isDirectory ? (
              <Icon className={styles.directoryIcon} name="directory" />
            ) : (
              <ChannelLogo
                className={styles.channelLogo}
                logo={channelLogo}
                name={channelName}
              />
            )}
            <RecordingDeleteWarning
              className={styles.expiresIn}
              expiresIn={expiresIn}
            />
          </div>
        )}

        <ProgressBar
          className={styles.progress}
          liveProgress={liveProgress}
          replayProgress={replayProgress}
          variant={variant === "search" ? "search" : "programBox"}
        />
      </div>
      {recordingState && (
        <RecTag
          active={recordingState === "recorded"}
          className={clsx(
            styles.recordingTag,
            variant === "search" && styles.search,
          )}
          label={recTagLabel}
          variant={variant === "search" ? "search" : undefined}
        />
      )}
      {topProgramNumber && (
        <Text className={styles.topProgramNumber} size="large">
          {topProgramNumber}
        </Text>
      )}
      {onPlay ? (
        <div
          className={clsx(
            variant !== "search" && [
              buttonStyles.button,
              buttonStyles.outlined,
              buttonStyles.iconOnly,
            ],
            styles.play,
          )}
        >
          <Icon name="play" />
        </div>
      ) : null}
    </>
  );

  // When onPlay is true we want to pretend we have an onPlay but not trigger it from here.
  if (!onPlay || onPlay === true) {
    return (
      <div
        className={clsx([
          styles.container,
          variant && styles[variant],
          className,
        ])}
      >
        {children}
      </div>
    );
  }

  return (
    <Button
      aria-label={title ?? undefined}
      className={clsx([
        styles.container,
        variant && styles[variant],
        className,
      ])}
      variant="none"
      onClick={onPlay}
    >
      {children}
    </Button>
  );
}

export { ProgramCover };
