import { areIntervalsOverlapping } from "date-fns/areIntervalsOverlapping";
import { isBefore } from "date-fns/isBefore";
import { isNil } from "lodash";

import {
  actionPlayerSetAdConfig,
  ffwdAdsEnabledAtom,
  ffwdMarkersAtom,
  getAlternativeVideoAds,
  videoAdsAtom,
} from "@sunrise/ads";
import { hostsAtom, httpClientAtom } from "@sunrise/http-client";
import {
  playerCurrentEpgItemAtom,
  selectPlayerCurrentPlayRequest,
} from "@sunrise/player";
import { type Store, untilAtomChanged } from "@sunrise/store";
import { nowSecondAtom } from "@sunrise/time";
import type { PlayRequest } from "@sunrise/yallo-player-types";

import { playerPermissionsAtom } from "./player-permissions.atom";

/**
 * Plays out ads when seeking.
 * The return us always a promise that will complete when the ads are done playing out (if any).
 *
 * It'll also optionally return the Date to seek to when ads have completed playing.
 * That means we should seek to that point in time instead of what we were asked to seek to.
 *
 * @param store
 * @param playRequest
 * @param seekTime
 * @returns
 */
export async function yalloLinearSeekAds(
  store: Store,
  currentTime: Date,
  playRequest: PlayRequest,
  /**
   * When no time is passed, assume we are seeking to live.
   */
  seekTime?: Date,
): Promise<Date | void> {
  // markerFound is actually re-assigned. The linting rule is off.
  // eslint-disable-next-line prefer-const
  let markerFound: { start: Date; end: Date } | undefined;

  /**
   * When there is no more adConfig then the ads are done playing.
   */
  function createPromiseWhenAdsAreDone(): Promise<Date | void> {
    return untilAtomChanged(
      store,
      videoAdsAtom,
      (state) => !state.adConfig && !state.isPlaying,
    ).then(() => {
      if (!markerFound) return;
      if (!actualSeekTime) return;

      // When we seeked to before the end of the ad break, we should redirect the user to the end of the adbreak.
      return isBefore(actualSeekTime, markerFound.end)
        ? markerFound.end
        : undefined;
    });
  }

  // Normally, ads can't be playing when we seek.
  // But you never know so let's guard against it.
  const isPlaying = store.get(videoAdsAtom).isPlaying;

  if (isPlaying) {
    return createPromiseWhenAdsAreDone();
  }

  const { showReplayFfwdAds } = store.get(playerPermissionsAtom);
  if (!showReplayFfwdAds) {
    return;
  }
  const now = store.get(nowSecondAtom);
  // If we don't know the seek time, we want to set actualSeekTime to now only if we are seeking to live. Otherwise we shouldn't set it to anything, hence null.
  const actualSeekTime = seekTime ?? (playRequest.type === "live" ? now : null);
  if (!actualSeekTime) return;

  // When we seek backwards we should never perform an ad playout.
  // Or when FFWD ads are disabled.
  if (isBefore(actualSeekTime, currentTime) || !store.get(ffwdAdsEnabledAtom)) {
    return;
  }

  // When we are not working with the same channel it's an automatic skip of playing ads.
  const currentPlayRequest = store.get(selectPlayerCurrentPlayRequest);
  if (currentPlayRequest?.channelId !== playRequest.channelId) {
    return;
  }

  // When we go to live from replay.
  if (playRequest.type === "live" && currentPlayRequest.type === "replay") {
    // Check if the current EPG item is fully in the past, if so, do not seek for ad breaks.
    const currentEpgItem = await store.get(playerCurrentEpgItemAtom);

    if (currentEpgItem && isBefore(currentEpgItem.actualEnd, now)) {
      return;
    }
  }

  const markers = await store.get(ffwdMarkersAtom);

  // We look for the last marker where:
  // - we seek over a start of a marker
  // - we seek over the end of a marker
  // - we seek inside a marker
  // In short, we seek over a bit of the marker.
  markerFound = markers
    ?.slice()
    .reverse()
    .find((marker) => {
      return areIntervalsOverlapping(marker, {
        start: currentTime,
        end: actualSeekTime,
      });
    });

  if (!markerFound) {
    return;
  }

  const host = store.get(hostsAtom).api;
  // TODO: We may want to log/throw an error here.
  if (isNil(host)) return;

  const privateApi = store.get(httpClientAtom).privateApi;
  // TODO: We may want to log/throw an error here.
  if (!privateApi) return;

  // Do not override existing ad config. We need to keep playing out the ads.
  const adConfig = playRequest.channelId
    ? await getAlternativeVideoAds({
        privateApi,
        channelId: playRequest.channelId,
        host,
        startTime: markerFound.start,
        type: "fast-forward",
      })
    : null;

  if (adConfig && adConfig.tag_count > 0) {
    // We send the ads to the store, which should result in the ads kicking in.
    store.set(videoAdsAtom, actionPlayerSetAdConfig(adConfig));
    // Wait until ads are done playing out.
    return createPromiseWhenAdsAreDone();
  }

  return Promise.resolve();
}
