import { atomFamily } from "jotai/utils";
import { atomWithMutation, queryClientAtom } from "jotai-tanstack-query";

import type {
  GeneratedApi,
  SearchHistoryEntrySchema,
} from "@sunrise/backend-ng-search";
import { ngSearchApiAtom } from "@sunrise/backend-ng-search";
import { queryKeys } from "@sunrise/backend-types";
import { selectJwtUserToken } from "@sunrise/jwt";

export const searchHistoryDeleteEntryAtom = atomFamily(
  (searchHistoryEntryId: string) =>
    atomWithMutation<
      Awaited<
        ReturnType<
          GeneratedApi["search"]["deleteSearchHistoryEntrySearchV1SearchHistoryEntriesSearchHistoryEntryIdDelete"]
        >
      >,
      string
    >((get) => {
      const api = get(ngSearchApiAtom);

      return {
        mutationKey: ["search-history-delete-entry", searchHistoryEntryId],
        mutationFn: () =>
          api.search.deleteSearchHistoryEntrySearchV1SearchHistoryEntriesSearchHistoryEntryIdDelete(
            searchHistoryEntryId,
          ),
        onSuccess(_, searchHistoryEntryId) {
          const queryClient = get(queryClientAtom);
          const queryKey = queryKeys.searchHistory(
            get(selectJwtUserToken),
            true,
          );
          const searchHistory =
            queryClient.getQueryData<SearchHistoryEntrySchema[]>(queryKey);

          if (!searchHistory || searchHistory?.length === 0) return;

          queryClient.setQueryData(
            queryKey,
            searchHistory.filter((item) => item.id !== searchHistoryEntryId),
          );
        },
      };
    }),
);
