import { type Store } from "@sunrise/store";
import { nowSecondAtom } from "@sunrise/time";
import { type Nullable } from "@sunrise/utils";
import { ensureValidCurrentTimeForCurrentStream } from "@sunrise/yallo-stream";

import type { playerAtom } from "../player.atom";

export function createEnsureValidCurrentTime(
  store: Store,
  atom: typeof playerAtom,
) {
  /**
   * Ensures that the given currentTime is valid for the current stream.
   * If the passed in value is not valid, we will return null.
   *
   * A player can receive a new currentTime for a specific stream.
   * The problem is that we should not be setting the currentTime to something that is not in the stream.
   * For replay that means we can't go under the offset. Nor can we go over the now time.
   */
  return function ensureValidCurrentTime(
    currentTime: number | Date,
  ): Nullable<number> {
    const { playRequest, stream } = store.get(atom);

    // When we are live, we can not adjust the time.
    if (playRequest?.type === "live") {
      return null;
    }

    // When we have no playRequest or stream we can't know how to adjust. So let it pass.
    const actualCurrentTime =
      typeof currentTime === "number"
        ? currentTime
        : currentTime.getTime() / 1000;
    if (!playRequest || !stream) return actualCurrentTime;

    const now = store.get(nowSecondAtom);

    return ensureValidCurrentTimeForCurrentStream(
      stream,
      actualCurrentTime,
      now,
    );
  };
}
