import { type ReactElement } from "react";
import clsx from "clsx";

import { Tag } from "../tag";
import styles from "./tag.module.css";

type RecTagProps = {
  active?: boolean;
  label?: string;
  variant?: "search";
} & CommonProps;

function RecTag({
  active,
  label = "REC",
  className,
  variant,
}: RecTagProps): ReactElement {
  return (
    <Tag
      className={clsx([
        styles.rec,
        className,
        active && styles.recorded,
        variant && styles[variant],
      ])}
      hideLabel={variant === "search"}
    >
      {label}
    </Tag>
  );
}

export { RecTag };
