import { atom } from "jotai";
import { atomFamily } from "jotai/utils";

import type { ContinueWatchingItemLegacy } from "@sunrise/backend-types";
import type { RecordingId } from "@sunrise/backend-types-core";

import { continueWatchingKnownItemsLegacyAtom } from "./continue-watching-known-items.legacy.atom";

type ReturnType = Extract<
  ContinueWatchingItemLegacy,
  { type: "recording" }
> | null;
export const continueWatchingByRecordingIdAtom = atomFamily(
  (recordingId: RecordingId) => {
    return atom<ReturnType>((get) => {
      return (
        get(continueWatchingKnownItemsLegacyAtom)
          .filter((item) => item.type === "recording")
          .find((item) => item.recording.id === recordingId) ?? null
      );
    });
  },
);
