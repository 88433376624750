import type { ReactNode } from "react";
import { useCallback } from "react";
import { useAtomValue } from "jotai";
import { useAtomCallback } from "jotai/utils";

import {
  actionDialogClose,
  dialogAtom,
  selectCurrentlyOpenedDialog,
} from "@sunrise/dialogs";
import { useTranslatable } from "@sunrise/translator";
import { SlideOut } from "@sunrise/yallo-web-components";

import type { DialogProps } from "../dialog.types";
import { ListDialog } from "../list-dialog";

const TEST_ID = "side-dialog";

export function SideDialog(): ReactNode {
  const dialog = useAtomValue(selectCurrentlyOpenedDialog);
  const t = useTranslatable();

  const doCloseModalId = useAtomCallback(
    useCallback((_, set, id: string) => {
      set(dialogAtom, actionDialogClose({ id }));
    }, []),
  );

  const doClose = useCallback(
    (open?: boolean) => {
      if (!dialog || open) {
        return;
      }

      doCloseModalId(dialog.id);
    },
    [dialog, doCloseModalId],
  );

  const testId = TEST_ID;

  const content = dialog
    ? getContent({ dialog, "data-testid": testId, doClose })
    : null;

  return (
    <SlideOut.Root
      dismissible={dialog?.backBehaviour !== "blocked"}
      open={!!content}
      title={dialog && t(dialog.title)}
      onOpenChange={doClose}
    >
      {content}
    </SlideOut.Root>
  );
}

function getContent(options: DialogProps): ReactNode {
  switch (options.dialog.type) {
    case "list":
      return <ListDialog {...options} dialog={options.dialog} />;
    default:
      return null;
  }
}
