export function stringToUint16Array(string: string): Uint16Array {
  const buffer = new ArrayBuffer(string.length * 2); // 2 bytes for each char
  const array = new Uint16Array(buffer);
  for (let i = 0, strLen = string.length; i < strLen; i++) {
    array[i] = string.charCodeAt(i);
  }
  return array;
}

export function uint16ArrayToString(array: Uint16Array): string {
  const uint16array = new Uint16Array(array.buffer);
  const uint8Array = new Uint8Array(uint16array.length / 2);

  for (let i = 0; i < uint16array.length; i += 2) {
    if (i < uint16array.length - 1) {
      uint8Array.set(uint16array.subarray(i, i + 1), i / 2);
    } else {
      uint8Array.set(uint16array.subarray(i), i / 2);
    }
  }

  return String.fromCharCode.apply(null, uint8Array as unknown as number[]);
}

const keyStr =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";

// TODO: return new TextDecoder('base64').decode(input); once RN supports it.
export function toBase64FromUint8Array(input: Uint8Array): string {
  // const xy = new TextDecoder('base64').decode(input);

  let output = "";
  let chr1: number | undefined,
    chr2: number,
    chr3: number,
    enc1: number,
    enc2: number,
    enc3: number,
    enc4: number;
  let i = 0;

  while (i < input.length) {
    chr1 = input[i++];
    if (typeof chr1 === "undefined") {
      break;
    }
    chr2 = i < input.length ? input[i++] ?? Number.NaN : Number.NaN; // Not sure if the index
    chr3 = i < input.length ? input[i++] ?? Number.NaN : Number.NaN; // checks are needed here

    enc1 = chr1 >> 2;
    enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
    enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
    enc4 = chr3 & 63;

    if (isNaN(chr2)) {
      enc3 = enc4 = 64;
    } else if (isNaN(chr3)) {
      enc4 = 64;
    }
    output +=
      keyStr.charAt(enc1) +
      keyStr.charAt(enc2) +
      keyStr.charAt(enc3) +
      keyStr.charAt(enc4);
  }

  return output;
}

export function fromBase64ToUint8Array(input: string): Uint8Array {
  const raw = fromBase64(input);
  const rawLength = raw.length;
  const array = new Uint8Array(new ArrayBuffer(rawLength));

  for (let i = 0; i < rawLength; i++) {
    array[i] = raw.charCodeAt(i);
  }

  return array;
}

const haveAtob = typeof atob !== "undefined";

export function fromBase64(encodedData: string): string {
  return haveAtob
    ? atob(encodedData)
    : Buffer.from(encodedData, "base64").toString("ascii");
}
