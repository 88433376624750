import { atomFamily } from "jotai/utils";
import { atomWithSuspenseQuery } from "jotai-tanstack-query";
import { isNil } from "lodash";

import type { RatingOut } from "@sunrise/backend-ng-rating";
import { ngRatingApiAtom } from "@sunrise/backend-ng-rating";
import { queryKeys } from "@sunrise/backend-types";
import type { AssetId } from "@sunrise/backend-types-core";
import { type Nullable } from "@sunrise/utils";

export const assetRatingByIdNgAtom = atomFamily((assetId: Nullable<AssetId>) =>
  atomWithSuspenseQuery<Nullable<RatingOut>>((get) => {
    const api = get(ngRatingApiAtom);
    if (isNil(api)) {
      throw new Error("ngRatingApiAtom is not initialized");
    }

    return {
      enabled: !isNil(assetId),
      queryKey: queryKeys.assetRatingById(assetId),
      queryFn: async () => {
        if (!assetId) return null;

        const data = await api.rating.getRatingRatingV1AssetsAssetIdRatingGet(
          assetId,
          // TODO: we can use axios to determine which status codes are valid and allow 404 to not throw
        );

        return data.data;
      },
      staleTime: Infinity,
      cacheTime: Infinity,
      retry: false,
    };
  }),
);
