import { selectAtom, unwrap } from "jotai/utils";

import { currentChannelGroupAtom } from "./current-channel-group.atom";

/**
 * This returns the currentChannelchannelGroupId only if the channel group is found.
 *
 * @deprecated See currentChannelGroupAtom for the new way to get the current channel group.
 */
export const selectedChannelGroupIdAtom = selectAtom(
  unwrap(currentChannelGroupAtom),
  (group) => group?.id,
  (a, b) => a === b,
);
