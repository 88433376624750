import type { Atom } from "jotai";
import { atom } from "jotai";
import { atomFamily } from "jotai/utils";

import { isLegacyBackendAtom } from "@sunrise/backend-core";
import { recordingByRecordingIdQueryAtom } from "@sunrise/backend-ng-recordings";
import type {
  RecordingGroupId,
  RecordingId,
} from "@sunrise/backend-types-core";
import type { Nullable } from "@sunrise/utils";

import { recordingStatusLegacyAtom } from "./legacy/recording-status.legacy.atom";
import { recordingStatusSocketAtom } from "./recording-status-socket.atom";
import type { SimplifiedRecordingStatus } from "./types";
import { recordingStatusToSimplifiedRecording } from "./utils/recording-status-to-simplified-recording";

/**
 * This returns the SimplifiedRecordingStatus for a specific RecordingId or RecordingGroupId.
 * The recording status just indicates if there is a recording and if so if it is planned or recorded.
 */
export const recordingStatusByRecordingIdAtom = atomFamily<
  RecordingId | RecordingGroupId,
  Atom<Promise<Nullable<SimplifiedRecordingStatus>>>
>((recordingId) => {
  const innerAtom = atom(async (get) => {
    if (get(isLegacyBackendAtom)) {
      const data = await get(recordingStatusLegacyAtom);
      const found = data.find((status) => status.recordingId === recordingId);

      if (!found) {
        return null;
      }

      return recordingStatusToSimplifiedRecording(found.status);
    } else {
      const item = get(recordingStatusSocketAtom).find(
        (status) => status.recordingId === recordingId,
      );

      if (item) {
        return recordingStatusToSimplifiedRecording(item.status);
      }

      const query = await get(recordingByRecordingIdQueryAtom(recordingId));
      if (!query.data) {
        return null;
      }

      return recordingStatusToSimplifiedRecording(query.data.status);
    }
  });
  innerAtom.debugLabel = `recordingStatusByRecordingIdAtom(${recordingId})`;
  return innerAtom;
});
