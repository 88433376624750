import type { QueryClient } from "@tanstack/react-query";
import type { Getter } from "jotai/vanilla";
import { debounce } from "lodash";

import { isLegacyBackendAtom } from "@sunrise/backend-core";
import { queryKeys } from "@sunrise/backend-types";
import type { Language, UserToken } from "@sunrise/backend-types-core";
import { currentLanguageAtom } from "@sunrise/i18n";
import { selectJwtUserToken } from "@sunrise/jwt";
import {
  socketAuthenticatedAtom,
  socketConnectedAtom,
} from "@sunrise/yallo-websocket";

export const RECORDING_INVALIDATION_DELAY = 5000;

function flushStateInternal(
  client: QueryClient,
  accessToken: UserToken,
  isWebsocketEnabled: boolean,
  ng: boolean,
  language: Language,
): void {
  if (isWebsocketEnabled) {
    client.invalidateQueries({
      queryKey: queryKeys.recordingsPullBase(accessToken, ng, language),
    });
    // Also invalidate the "all" language for queries that are not language-specific.
    client.invalidateQueries({
      queryKey: queryKeys.recordingsPullBase(accessToken, ng, "all"),
    });
  } else {
    client.invalidateQueries({
      queryKey: queryKeys.recordingsBase(accessToken, ng, language),
      exact: false,
    });
    // Also invalidate the "all" language for queries that are not language-specific.
    client.invalidateQueries({
      queryKey: queryKeys.recordingsBase(accessToken, ng, "all"),
      exact: false,
    });
  }
}

const debounced = debounce(flushStateInternal, RECORDING_INVALIDATION_DELAY, {
  leading: false,
  trailing: true,
});

/**
 * When the websocket is enabled (meaning, we are authenticated on the websocket),
 * we will only invalidate parts of the query client cache that are not automatically pushed to us.
 *
 * If the websocket is not enabled, we will invalidate the whole cache for recordings so everything is refetched.
 *
 * @param client
 * @param get
 */
export function flushRecordingsState(
  client: QueryClient,
  get: Getter,
  instant = false,
): void {
  const at = get(selectJwtUserToken);
  if (!at) {
    return;
  }

  const language = get(currentLanguageAtom);
  const isLegacyBackend = get(isLegacyBackendAtom);

  if (instant) {
    flushStateInternal(
      client,
      at,
      isLegacyBackend ? get(socketAuthenticatedAtom) : get(socketConnectedAtom),
      !isLegacyBackend,
      language,
    );
    return;
  }

  debounced(
    client,
    at,
    get(socketAuthenticatedAtom),
    !isLegacyBackend,
    language,
  );
}
