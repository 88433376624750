import type { CSSProperties } from "react";
import clsx from "clsx";
import { useAtomValue } from "jotai/react";

import {
  type GuideChannel as GuideChannelType,
  selectorGuideSelection,
} from "@sunrise/yallo-guide";
import { Button, Text } from "@sunrise/yallo-web-components";

import { ChannelLogo } from "../channel-logo";
import styles from "./guide-channel.module.css";

type GuideChannelProps = {
  channel: GuideChannelType;
  style?: CSSProperties;
};

export function GuideChannel({ channel, style }: GuideChannelProps) {
  const selection = useAtomValue(selectorGuideSelection);

  return (
    <Button
      className={clsx([
        styles.channel,
        selection?.channelId === channel.id && styles.active,
      ])}
      style={style}
      variant="filled"
    >
      <div className={styles.inner}>
        <Text className={styles.number} size="small" variant="label">
          {channel.channelNumber}
        </Text>
        <ChannelLogo
          className={styles.logo}
          logo={channel.logo}
          name={channel.name}
        />
      </div>
    </Button>
  );
}
