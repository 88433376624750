import type { ChangeEventHandler } from "react";
import { useCallback, useRef } from "react";
import { useAtomValue, useSetAtom } from "jotai";

import { debouncedQueryAtoms } from "@sunrise/search";
import { useTranslator } from "@sunrise/translator";
import { Button } from "@sunrise/yallo-web-components";

import styles from "./search-dialog.module.css";

export function SearchInput() {
  const t = useTranslator();
  const value = useAtomValue(debouncedQueryAtoms.currentValueAtom);
  const inputRef = useRef<HTMLInputElement>(null);

  const setQuery = useSetAtom(debouncedQueryAtoms.currentValueAtom);
  const setDebouncedQuery = useSetAtom(debouncedQueryAtoms.debouncedValueAtom);
  const performSearch = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e): void => {
      setDebouncedQuery(e.target.value);
    },
    [setDebouncedQuery],
  );

  const clearInput = () => {
    setQuery("");
    inputRef.current?.focus();
  };

  return (
    <form
      className={styles.searchForm}
      role="search"
      onSubmit={(event) => event.preventDefault()}
    >
      <input
        ref={inputRef}
        autoComplete="off"
        className={styles.input}
        enterKeyHint="search"
        inputMode="search"
        name="search"
        placeholder={t("search_page_input_placeholder") ?? ""}
        spellCheck={false}
        type="text"
        value={value}
        autoFocus
        onChange={performSearch}
      />
      {value && (
        <Button
          className={styles.clearButton}
          icon="closeRound"
          type="reset"
          variant="text"
          hideLabel
          onClick={clearInput}
        >
          {t("search_page_clear_search_term")}
        </Button>
      )}
    </form>
  );
}
