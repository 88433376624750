import type { ReactElement } from "react";
import { useAtomValue } from "jotai/react";

import { playerCurrentEpgItemAtom } from "@sunrise/player";
import { useTranslator } from "@sunrise/translator";
import { usePlayerPlayingInfo } from "@sunrise/yallo-player-controls";
import {
  AirTime,
  ProgramTitleAndSubtitle,
} from "@sunrise/yallo-web-components";

import { ChannelLogo } from "@/components";
import { route } from "@/config/route";

import styles from "./player-info.module.css";
import { PlayerTags } from "./player-tags";

function PlayerInfo({ className }: CommonProps): ReactElement {
  const t = useTranslator();
  const {
    title,
    channelLogo,
    channelName,
    status,
    isLive,
    schedule,
    subtitle,
  } = usePlayerPlayingInfo(t);
  const epgEntry = useAtomValue(playerCurrentEpgItemAtom);

  return (
    <div className={className}>
      <ProgramTitleAndSubtitle
        redirectUrl={
          epgEntry
            ? route.details.root({
                assetId: epgEntry?.asset.id,
                epgId: epgEntry?.id,
              })
            : undefined
        }
        size="medium"
        subtitle={subtitle}
        title={title}
      />
      <div className={styles.schedule}>
        {channelLogo ? (
          <ChannelLogo
            className={styles.channelLogo}
            logo={channelLogo}
            name={channelName}
          />
        ) : null}
        <AirTime
          className={styles.statusSchedule}
          endDate={schedule.end}
          endTimeLabel={t("detailpage_end_time")}
          formattedEnd={schedule.formattedEnd}
          formattedStart={schedule.formattedStart}
          isLive={isLive}
          relativeDate={status}
          size="medium"
          startDate={schedule.start}
          startTimeLabel={t("detailpage_start_time")}
        />
      </div>
      <PlayerTags />
    </div>
  );
}

export { PlayerInfo };
