import { atomPerMinute } from "@sunrise/time";

import { playerCurrentDateTimeAtom } from "./player-current-date-time.atom";

export const selectPlayerCurrentDateTimePerMinute = atomPerMinute(
  playerCurrentDateTimeAtom,
);

selectPlayerCurrentDateTimePerMinute.debugLabel =
  "selectPlayerCurrentDateTimePerMinute";
