import { atom } from "jotai";

import { selectPlayerStreamSetAt } from "@sunrise/player";
import { selectProcessForegroundedAt } from "@sunrise/process-visibility";

/**
 * This atom is used to determine if the stream is considered stale.
 * When the stream is stale, we expect the player to reload the stream.
 * When the stream is stale, we also do not want to log & show player-related errors.
 */
export const streamIsConsideredStaleAtom = atom((get) => {
  const streamSetAt = get(selectPlayerStreamSetAt);
  const foregroundedAt = get(selectProcessForegroundedAt);

  if (!streamSetAt || !foregroundedAt) {
    return false;
  }

  return streamSetAt < foregroundedAt;
});
