import { max } from "date-fns";
import { subSeconds } from "date-fns/subSeconds";

import { PlayerContentType } from "@sunrise/backend-ng-events";
import {
  playerCurrentDateTimeAtom,
  selectPlayerCurrentPlayRequest,
} from "@sunrise/player";
import type {
  LinearSeekService,
  PlayerManagerGuard,
} from "@sunrise/player-manager";
import { type Store } from "@sunrise/store";
import { nowSecondAtom } from "@sunrise/time";
import { getEpgEntryPlayingAtTimeOnChannel } from "@sunrise/yallo-epg";
import type {
  LivePlayRequest,
  PlayRequest,
  ReplayPlayRequest,
} from "@sunrise/yallo-player-types";
import { isWithinReplayWindow } from "@sunrise/yallo-replay";

export const createYalloEPGSeekService: (
  store: Store,
  guard: PlayerManagerGuard<PlayRequest>,
) => LinearSeekService<PlayRequest> = (store: Store, guard) => {
  return {
    async evaluatePauseSeekTime() {
      const playRequest = store.get(selectPlayerCurrentPlayRequest);
      const date = store.get(playerCurrentDateTimeAtom);

      if (!playRequest || !date) {
        return null;
      }

      if (playRequest.type === "live") {
        const content = await store.get(
          getEpgEntryPlayingAtTimeOnChannel({
            channelId: playRequest.channelId,
          }),
        )(date);

        if (!content) {
          return null;
        }

        const pr: ReplayPlayRequest = {
          type: PlayerContentType.Replay,
          channelId: playRequest.channelId,
          epgId: content.id,
          startTime: new Date(content.actualStart),
        };

        // NOTE: This is almost checking the same request as canPause in PlayerManager.
        if (!(await guard.canPlay(pr, false))) {
          return null;
        }

        return {
          playRequest: pr,
          date,
        };
      }

      return { playRequest, date };
    },
    /**
     * Looks at what is currently playing and determines what seeking is possible inside.
     * It will also not emit any upsell errors while checking permissions.
     * But it will prevent seeking to a time which you are not permitted to seek to.
     *
     * For ongoing replay streams we can seek back as far as the start of the replay stream start and we can seek all the way to live.
     * For live streams, we can only start a replay for the currently playing item.
     *
     * The seek service should not be used to determine if any kind of item can be replayed in the past.
     *
     * @param date
     * @returns
     */
    async evaluateSeekTime(date) {
      const now = store.get(nowSecondAtom);
      const playRequest = store.get(selectPlayerCurrentPlayRequest);

      if (!playRequest) {
        return null;
      }

      if (playRequest.type === "recording") {
        return {
          playRequest,
          date,
        };
      }

      if (isLive(date, now)) {
        const pr: LivePlayRequest = {
          type: PlayerContentType.Live,
          channelId: playRequest.channelId,
        };

        if (!(await guard.canPlay(pr, true))) {
          return null;
        }

        return {
          playRequest: pr,
          date: now,
        };
      }

      const content = await store.get(
        getEpgEntryPlayingAtTimeOnChannel({ channelId: playRequest.channelId }),
      )(date);

      if (!content) {
        return null;
      }

      // If the current replay stream supports the newly selected program, just return that.
      // This makes it so we do not reload the stream.
      const startTime = new Date(content.actualStart);

      // Check if we are in the replay window.
      const interval = {
        start: startTime,
        end: new Date(content.actualEnd),
      };

      const replayWindowEval = isWithinReplayWindow(interval.start, now);

      if (
        playRequest.type === "replay" &&
        replayWindowEval.replayWindow.start <= date &&
        playRequest.startTime &&
        playRequest.startTime <= startTime
      ) {
        return { playRequest, date };
      }

      const newRequest: ReplayPlayRequest = {
        type: PlayerContentType.Replay,
        channelId: playRequest.channelId,
        epgId: content.id,
        startTime,
      };

      // guard also checks for the replay window
      if (!(await guard.canPlay(newRequest, false))) {
        return null;
      }

      const replayDate = max([date, replayWindowEval.replayWindow.start]);

      return {
        playRequest: newRequest,
        date: replayDate,
      };
    },
  };
};

function isLive(date: Date, now: Date): boolean {
  return date >= subSeconds(now, 5);
}
