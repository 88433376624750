import { atom } from "jotai";
import { atomFamily } from "jotai/utils";

import type { RecordingId } from "@sunrise/backend-types-core";
import { recordingStatusByRecordingIdAtom } from "@sunrise/yallo-recordings";

import { deleteRecordingAtom } from "./delete-recording.atom";
import type { RecordingActionButtonResult } from "./types";

export const manageRecordingSingleProgramRecordingsActionsAtom = atomFamily(
  (recordingId: RecordingId) => {
    const inner = atom<Promise<RecordingActionButtonResult>>(async (get) => {
      const status = await get(recordingStatusByRecordingIdAtom(recordingId));

      // When we do not have a status (which realistically can't happen when we ask for this atom)
      // Then we render out a response that results in no button being shown.
      if (!status) {
        return {
          action: null,
          actionAtom: null,
          isLoading: false,
        };
      }

      return deleteRecordingAtom(recordingId, status, get);
    });

    inner.debugLabel = `manageRecordingSingleProgramRecordingsActionsAtom(${recordingId})`;

    return inner;
  },
);
