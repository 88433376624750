import { atom } from "jotai";

import { recordingCapacityAtom } from "./recording-capacity.atom";

export const isAtMaxRecordingCapacityAtom = atom(async (get) => {
  const stats = await get(recordingCapacityAtom);
  if (!stats) {
    return false;
  }

  return stats.used >= stats.total;
});
