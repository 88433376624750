import { atomEffect } from "jotai-effect";

import { isLegacyBackendAtom } from "@sunrise/backend-core";
import { httpClientAtom } from "@sunrise/http-client";
import { selectIsLoggedIn } from "@sunrise/jwt";
import { legacySocketAtom } from "@sunrise/yallo-websocket";

import { freeUsageAtom } from "./free-usage.atom";

export const reactForUserToSocketEffect = atomEffect((get, set) => {
  if (!get(isLegacyBackendAtom)) {
    return;
  }

  const ws = get(legacySocketAtom);
  const isLoggedIn = get(selectIsLoggedIn);

  if (!ws || !isLoggedIn) {
    return;
  }

  ws.on("live-streaming-limit-reached", () => {
    set(freeUsageAtom, true);
  });

  ws.on("subscription_changed", () => {
    // refresh access token, with new subscription options
    const client = get(httpClientAtom);
    client.privateApi?.refreshToken();
  });

  return () => {
    set(freeUsageAtom, false);

    // TODO: add a ws.off to the socket.
  };
});
