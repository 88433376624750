import { atom } from "jotai";
import { atomFamily } from "jotai/utils";

import { isLegacyBackendAtom } from "@sunrise/backend-core";
import type { EPGEntryId } from "@sunrise/backend-types-core";

import { continueWatchingByEpgIdLegacyAtom } from "./legacy/continue-watching-by-epg-id.legacy.atom";
import { continueWatchingItemLegacyToContinueWatchingStatus } from "./legacy/helpers/continue-watching-item-legacy-to-continue-watching-status";
import { isFullyWatchedByEpgIdLegacyAtom } from "./legacy/is-fully-watched-by-epg-id.legacy.atom";
import { continueWatchingEpgByEpgIdorRecordingIdAtom } from "./ng/continue-watching-status-by-epg-or-recording-id.atom";
import type { ContinueWatchingStatus } from "./types";

/**
 * Returns if the user has watched this EPG entry or not.
 */
export const continueWatchingStatusByEpgIdAtom = atomFamily(
  (epgId: EPGEntryId) => {
    return atom<Promise<ContinueWatchingStatus | null>>(async (get) => {
      if (get(isLegacyBackendAtom)) {
        const [status, fw] = await Promise.all([
          get(continueWatchingByEpgIdLegacyAtom({ epgId })),
          get(isFullyWatchedByEpgIdLegacyAtom({ epgId })),
        ]);

        return continueWatchingItemLegacyToContinueWatchingStatus(
          status,
          fw,
          epgId,
        );
      } else {
        // TODO: We can also rewire it to seek throuh all the pages.
        const ng = await get(
          continueWatchingEpgByEpgIdorRecordingIdAtom({ epgId }),
        );

        if (!ng.data || !("moment" in ng.data)) {
          return null;
        }

        // TODO: We need to wire up data coming from the ng socket
        //       and override the original data coming from the rest endpoints.

        return {
          epgId,
          type: "replay",
          isFullyWatched: ng.data.fullyWatched,
          moment: ng.data.moment,
          epgStartTime: ng.data.epgStartTime,
        };
      }
    });
  },
);
