import type { NgSearchEpgResult } from "@sunrise/search";
import { useContinueWatchingProgressByEpgId } from "@sunrise/yallo-continue-watching";

import { route } from "@/config/route";
import { usePlay } from "@/hooks";

import { SearchEpisodeOrProgramResult } from "./search-episode-or-program-result";

export function SearchEpgResult({
  item,
  closeSearch,
}: {
  item: NgSearchEpgResult;
  closeSearch?: () => void;
}) {
  const { play } = usePlay({
    channelId: item.channelId,
    epgId: item.epgId,
    epgStart: item.epgStart,
    epgEnd: item.epgEnd,
  });

  const detailRoute = item.assetId
    ? route.details.root({
        epgId: item.epgId,
        assetId: item.assetId,
      })
    : undefined;

  const progress = useContinueWatchingProgressByEpgId({
    epgId: item.epgId,
    start: item.epgStart,
    end: item.epgEnd,
  });

  return (
    <SearchEpisodeOrProgramResult
      closeSearch={closeSearch}
      detailRoute={detailRoute}
      item={item}
      play={play}
      progress={progress}
    />
  );
}
