import { useMemo } from "react";
import { isBefore } from "date-fns";
import { useAtomValue } from "jotai";

import type { EPGEntryId, TimeISOString } from "@sunrise/backend-types-core";
import { getLiveProgress, nowAtom } from "@sunrise/time";
import type { Nullable } from "@sunrise/utils";

import { continueWatchingStatusByEpgIdAtom } from "./continue-watching-status-by-epg-id.atom";
import { getContinueWatchingProgress } from "./helpers/get-continue-watching-progress";

/**
 * Returns the progress of the current item so we can display it on a progress bar.
 *
 * TODO: Allow parameters for recordings as well. So we can use it on the simplified list.
 */
export const useContinueWatchingProgressByEpgId = ({
  epgId,
  start,
  end,
}: {
  epgId: EPGEntryId;
  start: Nullable<TimeISOString | Date>;
  end: Nullable<TimeISOString | Date>;
}) => {
  const status = useAtomValue(continueWatchingStatusByEpgIdAtom(epgId));

  const replayProgress = useMemo(() => {
    if (!status || !start || !end) {
      return null;
    }

    if (status.isFullyWatched) {
      return 100;
    }

    // When we have the cw item, we need to calculate the progress.
    switch (status?.type) {
      case "replay":
        return getContinueWatchingProgress({
          epgStartTime: new Date(start),
          epgEndTime: new Date(end),
          status,
          paddingTimesInMinutes: null,
        });
      case "recording":
      default:
        return null;
    }
  }, [start, end, status]);

  const now = useAtomValue(nowAtom);

  if (!end || !start) {
    return {
      replayProgress: null,
      liveProgress: null,
    };
  }

  return {
    replayProgress,
    liveProgress:
      replayProgress && isBefore(end, now)
        ? 100
        : getLiveProgress(start, end, now),
  };
};
