import { atomFamily } from "jotai/utils";
import { atomWithSuspenseQuery } from "jotai-tanstack-query";
import { isNil } from "lodash";

import type { AssetRating } from "@sunrise/backend-types";
import { queryKeys } from "@sunrise/backend-types";
import type { AssetId } from "@sunrise/backend-types-core";
import { hostsAtom, httpClientAtom } from "@sunrise/http-client";
import { type Nullable } from "@sunrise/utils";

import { fetchAssetRating } from "./queries/asset.service";

/**
 * @deprecated
 */
export const assetRatingByIdLegacyAtom = atomFamily(
  (assetId: Nullable<AssetId>) =>
    atomWithSuspenseQuery<Nullable<AssetRating>>((get) => {
      const host = get(hostsAtom).api;
      if (isNil(host)) throw new Error("Host is not set");

      const privateApi = get(httpClientAtom).privateApi;
      if (isNil(privateApi)) throw new Error("Private API is not set");

      return {
        enabled: !isNil(assetId),
        queryKey: queryKeys.assetRatingById(assetId),
        queryFn: async () => {
          if (!assetId) return null;

          return fetchAssetRating(privateApi, host, assetId);
        },
        staleTime: Infinity,
        cacheTime: Infinity,
        retry: false,
      };
    }),
);
