import type { PropsWithChildren } from "react";
import * as ToggleGroup from "@radix-ui/react-toggle-group";
import clsx from "clsx";

import styles from "./filter-group.module.css";

export const Group = ({
  children,
  ...props
}: PropsWithChildren<
  ToggleGroup.ToggleGroupSingleProps | ToggleGroup.ToggleGroupMultipleProps
>) => (
  <ToggleGroup.Root className={clsx(styles.root, props.className)} {...props}>
    {children}
  </ToggleGroup.Root>
);
