import type {
  InfiniteData,
  InfiniteQueryObserverResult,
} from "@tanstack/react-query";
import { type Atom, atom } from "jotai";

import type { PageWithItems } from "./types";

export type PagedAtomReturnType<TItem> = {
  items: TItem[];
  fetchNextPage: () => void;
  isFetchingNextPage: boolean;
  isLoadingInitialPage: boolean;
  page: number;
  totalPages: number;
};

/**
 * A helper which works on an infinite query and it exposes basic attributes.
 * The full list across all pages as well as a method to get more pages if available.
 */
export const pagedAtom = function <
  TInner extends Atom<
    InfiniteQueryObserverResult<
      InfiniteData<PageWithItems<unknown>, unknown>,
      unknown
    >
  >,
>(
  innerAtom: TInner,
): Atom<
  TInner extends Atom<
    InfiniteQueryObserverResult<
      InfiniteData<PageWithItems<infer TItem>, unknown>,
      unknown
    >
  >
    ? PagedAtomReturnType<TItem>
    : never
> {
  return atom((get) => {
    const data = get(innerAtom);

    const items = data.data?.pages.flatMap((page) => page.items) ?? [];
    const page = data.data?.pages.at(-1)?.page ?? 0;
    const totalPages = data.data?.pages.at(-1)?.pages ?? 0;

    const fetchNextPage = () => {
      if (data.hasNextPage && !data.isFetchingNextPage) {
        data.fetchNextPage();
      }
    };

    return {
      items,
      fetchNextPage,
      isFetchingNextPage: data.isFetchingNextPage,
      isLoadingInitialPage: data.isLoading,
      page,
      totalPages,
    } as TInner extends Atom<
      InfiniteQueryObserverResult<
        InfiniteData<PageWithItems<infer TItem>, unknown>,
        unknown
      >
    >
      ? PagedAtomReturnType<TItem>
      : never;
  });
};
