import { unwrap } from "jotai/utils";

import { selectedChannelGroupWithAllChannels } from "@sunrise/yallo-channel-group";

/**
 * A stable version of the unwrapped channels to be shown on the guide.
 */
export const unwrappedAllChannelsInChannelGroup = unwrap(
  selectedChannelGroupWithAllChannels,
);
