import { atomFamily } from "jotai/utils";
import { atomWithSuspenseQuery } from "jotai-tanstack-query";
import { isNil } from "lodash";

import type { RecordingId } from "@sunrise/backend-types-core";
import { hostsAtom, httpClientAtom } from "@sunrise/http-client";

import { fetchWhatIsNext } from "../../recordings.service";

export const whatIsNextLegacyQueryAtom = atomFamily(
  (recordingId: RecordingId) => {
    const innerAtom = atomWithSuspenseQuery((get) => {
      const host = get(hostsAtom).api;
      if (isNil(host)) throw new Error("Host is not set");
      const { privateApi } = get(httpClientAtom);
      if (!privateApi) throw new Error("missing privateApi");

      return {
        queryKey: ["whatIsNext", recordingId],
        queryFn: async () => {
          return fetchWhatIsNext(host, privateApi, recordingId);
        },
      };
    });

    innerAtom.debugLabel = `whatIsNextLegacyQueryAtom(${recordingId})`;

    return innerAtom;
  },
);
