import { BaseError } from "@sunrise/error";

import type { UpsellErrorCode } from "./upsell.types";

export class UpsellError extends BaseError {
  constructor(readonly code: UpsellErrorCode) {
    super(`UpsellError`, code, { errorCode: code });
  }

  override get shouldTrace(): boolean {
    return false;
  }
}
