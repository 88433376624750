import type { PropsWithChildren } from "react";
import { useAtom } from "jotai";

import { searchHistoryDeleteEntryAtom } from "@sunrise/search-history";
import { useTranslator } from "@sunrise/translator";
import { Button } from "@sunrise/yallo-web-components";

import styles from "./search-history.module.css";

type SearchHistoryDeleteEntryProps = { id: string } & PropsWithChildren;

export const SearchHistoryDeleteEntry = ({
  id,
  children,
}: SearchHistoryDeleteEntryProps) => {
  const t = useTranslator();
  const [{ mutate: deleteEntry, isPending: isDeletingEntry }] = useAtom(
    searchHistoryDeleteEntryAtom(id),
  );

  return (
    <Button
      className={styles.remove}
      disabled={isDeletingEntry}
      icon="deleteForever"
      loading={isDeletingEntry}
      variant="text"
      hideLabel
      onClick={() => deleteEntry(id)}
    >
      {t("search_page_history_clear_individual_entry", [children])}
    </Button>
  );
};
