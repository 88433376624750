import { useCallback } from "react";
import { isSameDay } from "date-fns";
import { useAtomValue } from "jotai";
import { useAtomCallback } from "jotai/utils";

import {
  actionDialogClose,
  actionDialogOpen,
  dialogAtom,
} from "@sunrise/dialogs";
import {
  datesRangeAtom,
  getDayOfWeekTranslation,
  nowAtom,
} from "@sunrise/time";
import { useTranslator } from "@sunrise/translator";
import { type Nullable } from "@sunrise/utils";
import { programIsPlayingAtTime } from "@sunrise/yallo-epg";

import { actionGuideJumpToDate } from "../store/grid-state.actions";
import { gridStateAtom } from "../store/grid-state.atom";
import {
  currentlyVisibleDateAtom,
  selectorGuideSelection,
} from "../store/grid-state.selectors";

export const GRID_DATE_SELECTOR_GRID_ID = "grid-date-selector" as const;

/**
 * A hook depending on the dialogs module that will return the currently selected date + a functions to trigger a dialog to change the selected date.
 *
 * @returns
 */
export function useGridDateSelector(props?: {
  getFocusKey?: () => string;
  showDialogTitle?: boolean;
  useRadioButtons?: boolean;
  showYear?: boolean;
}): {
  currentlySelectedDate: string;
  selectDate: () => Promise<void>;
  moveToNow: () => void;
  moveToPrimeTime: () => void;
} {
  const t = useTranslator();

  const showYear = props?.showYear ?? true;
  const { getFocusKey } = props ?? {};
  const currentSelectedDate = useAtomValue(currentlyVisibleDateAtom);
  const currentSelectedItem = useAtomValue(selectorGuideSelection);
  const showDialogTitle = props?.showDialogTitle;
  const useRadioButtons = props?.useRadioButtons;

  const now = useAtomValue(nowAtom);
  const currentDate = getDayOfWeekTranslation(
    currentSelectedDate ? currentSelectedDate : now,
    now,
    t,
    showYear,
  );

  const selectDate = useAtomCallback(
    useCallback(
      async (get, set): Promise<void> => {
        const now = get(nowAtom);
        const datesRange = get(datesRangeAtom);

        const rangeOfAvailableDates = async (): Promise<
          { label: string; value: Date }[]
        > => {
          const promises = datesRange.map(async (date) => {
            return {
              label: getDayOfWeekTranslation(date, now, t, showYear),
              value: date,
            };
          });

          return Promise.all(promises);
        };

        const range = await rangeOfAvailableDates();

        const handleSelectedDate = (value: Nullable<string>): void => {
          if (!value) return;
          set(gridStateAtom, actionGuideJumpToDate(new Date(value)));
          set(dialogAtom, actionDialogClose());
        };

        const activeRange = range.find((date) => {
          if (currentSelectedDate) {
            return isSameDay(currentSelectedDate, date.value);
          }

          if (currentSelectedItem) {
            return programIsPlayingAtTime(currentSelectedItem, date.value);
          }

          return false;
        });

        set(
          dialogAtom,
          actionDialogOpen({
            id: GRID_DATE_SELECTOR_GRID_ID,
            type: "list",
            title: showDialogTitle ? { key: "guide_select_date" } : undefined,
            buttonTextAlign: "left",
            onButtonClick: handleSelectedDate,
            sections: [
              {
                options: range.map((date) => ({
                  label: date.label,
                  value: date.value.toISOString(),
                })),
              },
            ],
            radioButtons: useRadioButtons,
            activeOptions: activeRange ? [activeRange.value.toISOString()] : [],
            lastFocusKey: getFocusKey?.() ?? "",
          }),
        );
      },
      [
        t,
        currentSelectedDate,
        currentSelectedItem,
        useRadioButtons,
        showDialogTitle,
        showYear,
        getFocusKey,
      ],
    ),
  );

  const moveToNow = useAtomCallback(
    useCallback((get, set) => {
      const now = get(nowAtom);
      set(gridStateAtom, actionGuideJumpToDate(now));
    }, []),
  );

  /**
   * Jumps to 20h on the currently active day.
   */
  const moveToPrimeTime = useAtomCallback(
    useCallback((get, set) => {
      const primeTime = new Date(get(currentlyVisibleDateAtom) ?? get(nowAtom));
      primeTime.setHours(20, 0, 0, 0);
      set(gridStateAtom, actionGuideJumpToDate(primeTime));
    }, []),
  );

  return {
    currentlySelectedDate: currentDate,
    selectDate,
    moveToNow,
    moveToPrimeTime,
  };
}
