import type { ReactNode } from "react";
import clsx from "clsx";

import type { Nullable } from "@sunrise/utils";

import { makeChannelImgUrl } from "../../utils/image";
import styles from "./channel-logo.module.css";

type ChannelLogoProps = {
  logo: Nullable<string>;
  name: Nullable<string>;
} & CommonProps;

function ChannelLogo({ logo, name, className }: ChannelLogoProps): ReactNode {
  if (!logo) return null;

  return (
    <img
      alt={name ?? ""}
      className={clsx([styles.channelLogo, className])}
      decoding="async"
      loading="lazy"
      src={makeChannelImgUrl(logo)}
    />
  );
}

export { ChannelLogo };
