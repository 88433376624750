import type { Translations } from "translations";
import { stringFormat } from "translations";

import type { Language } from "@sunrise/backend-types-core";
import type { TranslateFn } from "@sunrise/i18n";

export function createTranslator(
  language: Language,
  translations: Translations,
): TranslateFn {
  return (key: string, args: unknown[] = [], plural = false): string => {
    const value = translations[key.toLowerCase()];

    if (!value) {
      if (process.env["NODE_ENV"] === "test") {
        return (
          `t:${key}` + (args.length > 0 ? `(${JSON.stringify(args)})` : "")
        );
      }
      return key;
    }

    const template = plural ? value.translationPlural : value.translation;

    return template
      ? (stringFormat.format(template, ...args) ?? template)
      : `🌍 ${key}/${language}`;
  };
}
