import { isDefined } from "@sunrise/utils";

import type { ShakaLoadError } from "../player.types";

export function isShakaError(e: unknown): e is ShakaLoadError {
  return (
    isDefined(e) &&
    typeof e === "object" &&
    "code" in e &&
    "category" in e &&
    "severity" in e &&
    "message" in e
  );
}
