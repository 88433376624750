import type { ReactNode } from "react";

import { Spinner } from "../spinner";
import styles from "./page-spinner.module.css";

export function PageSpinner(): ReactNode {
  return (
    <div className={styles.root}>
      <Spinner isActive />
    </div>
  );
}
