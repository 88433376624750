import { atom } from "jotai";
import { atomFamily } from "jotai/utils";

import type { Nullable } from "./nullable.types";

export function namespacedAtom<T>() {
  const _namespacedAtom = atom<ReadonlyMap<string, T>>(new Map<string, T>());

  return atomFamily((id: string) => {
    return atom(
      (get) => {
        const internalValue = get(_namespacedAtom).get(id);
        return internalValue;
      },
      (get, set, { value }: { value: Nullable<T> }) => {
        const newMap = new Map(get(_namespacedAtom));

        if (!value) {
          newMap.delete(id);
        } else {
          newMap.set(id, value);
        }

        set(_namespacedAtom, newMap);
      },
    );
  });
}
