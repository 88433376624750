import type { NgSearchChannelResult } from "@sunrise/search";
import { usePlayChannel } from "@sunrise/yallo-common-player-manager";
import { Button, ChannelBox, Title } from "@sunrise/yallo-web-components";

import { ChannelItemEpgData } from "@/components/channel/channel-item-epg-data";

import styles from "./search-result.module.css";

export function SearchChannelResult({
  item,
  closeSearch,
}: {
  item: NgSearchChannelResult;
  closeSearch?: () => void;
}) {
  const { play } = usePlayChannel({
    channelId: item.channelId,
  });

  return (
    <Button
      className={styles.container}
      variant="none"
      onClick={() => {
        play();
        closeSearch?.();
      }}
    >
      <ChannelBox
        channelLogo={item.channelLogo}
        channelName={item.channelName}
        className={styles.logo}
        variant="search"
      />
      <div className={styles.meta}>
        <Title className={styles.title} level="h3" size="small">
          {item.channelName}
        </Title>

        <ChannelItemEpgData
          channelId={item.channelId}
          className={styles.programInfo}
          size="small"
        />
      </div>
    </Button>
  );
}
