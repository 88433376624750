import type { ReactElement } from "react";
import { useAtomValue } from "jotai";

import {
  playerCurrentTagsShownInPlayerAtom,
  showProgramTagsInPlayerFeatureAtom,
} from "@sunrise/player";

import { TagGroup } from "@/components/tag-group";

function PlayerTags(): ReactElement | null {
  const showProgramTagsInPlayer = useAtomValue(
    showProgramTagsInPlayerFeatureAtom,
  );
  const { ageRecommendation, productionYear, genres } = useAtomValue(
    playerCurrentTagsShownInPlayerAtom,
  );

  if (!showProgramTagsInPlayer) return null;

  if (
    genres.length < 1 &&
    ageRecommendation === null &&
    productionYear === null
  )
    return null;

  return (
    <TagGroup
      ageRecommendation={ageRecommendation?.toString()}
      genres={genres}
      productionYear={productionYear}
    />
  );
}

export { PlayerTags };
