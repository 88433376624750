/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** AssetType */
export enum AssetType {
  Series = "series",
  Seriesepisode = "seriesepisode",
  Seriesseason = "seriesseason",
  Movie = "movie",
  Program = "program",
  Unknown = "unknown",
}

/** BaseRecordingSchema */
export interface BaseRecordingSchema {
  /**
   * Recording Item ID
   * The id of the recording item
   */
  id: string;
  /**
   * Type
   * @default "recording"
   */
  type?: "recording";
  asset: ReducedAssetSchema;
  /**
   * EPG entry ID
   * The id of the epg entry used to create the recording. Unavailable when the start date is outside the epg window.
   */
  epg_entry_id: string;
  /**
   * EPG Start Time
   * The start time of the EPG
   * @format date-time
   */
  epg_start: TimeISOString;
  /**
   * EPG End Time
   * The end time of the EPG
   * @format date-time
   */
  epg_end: TimeISOString;
  /**
   * Recording status
   * Status of the recording
   */
  status: RecordingStatus;
  /**
   * Channel name
   * name of the channel
   * @default ""
   */
  channel_name?: string;
  /**
   * Channel logo
   * URL of the channel's logo
   * @default ""
   */
  channel_logo?: string;
  /**
   * Channel ID
   * The original id of the channel. When the channel is deleted, this attribute will return null.
   */
  channel_id?: string | null;
  /**
   * Padding Start Minutes
   * The padding start minutes of the recording
   */
  padding_start_minutes: number;
  /**
   * Padding End Minutes
   * The padding end minutes of the recording
   */
  padding_end_minutes: number;
}

/** ChannelSearchResultSchema */
export interface ChannelSearchResultSchema {
  /**
   * Search Result Type
   * Represents the type of the search result
   * @default "channel"
   */
  type?: "channel";
  entry: ReducedChannelSchema;
}

/** EpgEntrySchema */
export interface EpgEntrySchema {
  /**
   * EPG ID
   * The id of the EPG
   */
  id: string;
  /**
   * Start
   * The start time of the epg
   * @format date-time
   */
  start: TimeISOString;
  /**
   * End
   * The end time of the epg
   * @format date-time
   */
  end: TimeISOString;
  asset: ReducedAssetSchema;
  channel: ReducedChannelSchema;
}

/** EpgEntrySearchResultSchema */
export interface EpgEntrySearchResultSchema {
  /**
   * Search Result Type
   * Represents the type of the search result
   * @default "epg_entry"
   */
  type?: "epg_entry";
  entry: EpgEntrySchema;
}

/** HTTPValidationError */
export interface HTTPValidationError {
  /** Detail */
  detail?: ValidationError[];
}

/** Page[Annotated[Union[ChannelSearchResultSchema, EpgEntrySearchResultSchema, RecordingSearchResultSchema], FieldInfo(annotation=NoneType, required=True, discriminator='type')]] */
export interface PageAnnotatedUnionChannelSearchResultSchemaEpgEntrySearchResultSchemaRecordingSearchResultSchemaFieldInfoAnnotationNoneTypeRequiredTrueDiscriminatorType {
  /** Items */
  items: (
    | ({
        type: "channel";
      } & ChannelSearchResultSchema)
    | ({
        type: "epg_entry";
      } & EpgEntrySearchResultSchema)
    | ({
        type: "recording";
      } & RecordingSearchResultSchema)
  )[];
  /** Total */
  total: number | null;
  /** Page */
  page: number | null;
  /** Size */
  size: number | null;
  /** Pages */
  pages?: number | null;
}

/** Page[SearchHistoryEntrySchema] */
export interface PageSearchHistoryEntrySchema {
  /** Items */
  items: SearchHistoryEntrySchema[];
  /** Total */
  total: number | null;
  /** Page */
  page: number | null;
  /** Size */
  size: number | null;
  /** Pages */
  pages?: number | null;
}

/** RecordingSearchResultSchema */
export interface RecordingSearchResultSchema {
  /**
   * Search Result Type
   * Represents the type of the search result
   * @default "recording"
   */
  type?: "recording";
  entry: BaseRecordingSchema;
}

/** RecordingStatus */
export enum RecordingStatus {
  All = "all",
  Recorded = "recorded",
  Planned = "planned",
  Canceled = "canceled",
  Deleted = "deleted",
}

/** ReducedAssetSchema */
export interface ReducedAssetSchema {
  /**
   * Asset ID
   * The id of the asset
   */
  id: string;
  /**
   * Asset Type
   * The type of the asset (movie, series episode...)
   */
  type: AssetType;
  /**
   * Asset Title
   * The title of the asset
   */
  title: string;
  /**
   * Asset Subtitle
   * The subtitle of the asset
   * @default ""
   */
  subtitle?: string | null;
  /**
   * Asset Poster
   * The poster url of the asset
   */
  poster_url: string;
  /**
   * Blurhash of the Poster
   * @default "eCF6B#-:0JInxr?@s;nmIoWUIko1%NocRk.8xbIUaxR*^+s;RiWAWU"
   */
  poster_blurhash?: string;
  /**
   * Season Number
   * The season number of the series episode
   */
  season_number?: number | null;
  /**
   * Episode Number
   * The episode number of the series episode
   */
  episode_number?: number | null;
  /** Series */
  readonly series: string;
  /** Series Season */
  readonly series_season: string;
  /**
   * Asset Recording Available
   * Is it possible to record this asset as a series?
   */
  readonly asset_recording_available: boolean;
}

/** ReducedChannelSchema */
export interface ReducedChannelSchema {
  /**
   * Channel ID
   * The id of the channel
   */
  id: string;
  /**
   * Channel Name
   * The name of the channel
   */
  name: string;
  /**
   * Channel Logo
   * The logo of the channel
   */
  logo: string;
}

/** SearchFilters */
export enum SearchFilters {
  All = "all",
  Available = "available",
  Upcoming = "upcoming",
  Recorded = "recorded",
  Channels = "channels",
}

/** SearchHistoryEntrySchema */
export interface SearchHistoryEntrySchema {
  /**
   * Id of entry
   * The id of one search history entry.
   */
  id: string;
  /**
   * Query string
   * the searched for value
   */
  query: string;
}

/** ValidationError */
export interface ValidationError {
  /** Location */
  loc: (string | number)[];
  /** Message */
  msg: string;
  /** Error Type */
  type: string;
}

import { HttpClient, RequestParams } from "@sunrise/backend-ng-core";
import type { TimeISOString } from "@sunrise/backend-types-core";

/**
 * @title FastAPI
 * @version 0.1.0
 */
export class GeneratedApi {
  http: HttpClient;

  constructor(http: HttpClient) {
    this.http = http;
  }

  search = {
    /**
     * No description
     *
     * @name HealthSearchV1HealthGet
     * @summary Health
     * @request GET:/search/v1/health
     */
    healthSearchV1HealthGet: (params: RequestParams = {}) =>
      this.http.request<any, any>({
        path: `/search/v1/health`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * @description Returns the results of the given query. The users recordings are also queried.
     *
     * @tags search
     * @name SearchSearchV1SearchGet
     * @summary Search
     * @request GET:/search/v1/search
     * @secure
     */
    searchSearchV1SearchGet: (
      query: {
        /** Query */
        query: string;
        /**
         * Page
         * Page number
         * @min 1
         * @default 1
         */
        page?: number;
        /**
         * Size
         * Page size
         * @min 1
         * @max 100
         * @default 50
         */
        size?: number;
        /** @default "all" */
        search_filter?: SearchFilters;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<
        PageAnnotatedUnionChannelSearchResultSchemaEpgEntrySearchResultSchemaRecordingSearchResultSchemaFieldInfoAnnotationNoneTypeRequiredTrueDiscriminatorType,
        void | HTTPValidationError
      >({
        path: `/search/v1/search`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns the search history of a user on the specific device.
     *
     * @tags search-history-entries
     * @name GetSearchHistorySearchV1SearchHistoryEntriesGet
     * @summary Get Search History
     * @request GET:/search/v1/search-history-entries
     * @secure
     */
    getSearchHistorySearchV1SearchHistoryEntriesGet: (
      query?: {
        /**
         * Page
         * Page number
         * @min 1
         * @default 1
         */
        page?: number;
        /**
         * Size
         * Page size
         * @min 1
         * @max 100
         * @default 50
         */
        size?: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<PageSearchHistoryEntrySchema, void | HTTPValidationError>({
        path: `/search/v1/search-history-entries`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Deletes the search history of a user on the specific device.
     *
     * @tags search-history-entries
     * @name DeleteSearchHistorySearchV1SearchHistoryEntriesDelete
     * @summary Delete Search History
     * @request DELETE:/search/v1/search-history-entries
     * @secure
     */
    deleteSearchHistorySearchV1SearchHistoryEntriesDelete: (params: RequestParams = {}) =>
      this.http.request<void, void>({
        path: `/search/v1/search-history-entries`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * @description Deletes the search history entry of a user on the specific device.
     *
     * @tags search-history-entries
     * @name DeleteSearchHistoryEntrySearchV1SearchHistoryEntriesSearchHistoryEntryIdDelete
     * @summary Delete Search History Entry
     * @request DELETE:/search/v1/search-history-entries/{search_history_entry_id}
     * @secure
     */
    deleteSearchHistoryEntrySearchV1SearchHistoryEntriesSearchHistoryEntryIdDelete: (
      searchHistoryEntryId: string,
      params: RequestParams = {},
    ) =>
      this.http.request<void, void | HTTPValidationError>({
        path: `/search/v1/search-history-entries/${searchHistoryEntryId}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @name RootSearchV1Get
     * @summary Root
     * @request GET:/search/v1
     */
    rootSearchV1Get: (params: RequestParams = {}) =>
      this.http.request<any, any>({
        path: `/search/v1`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
}
