import { isBefore } from "date-fns";
import { useAtomValue } from "jotai";

import type { RecordingId } from "@sunrise/backend-types-core";
import { getLiveProgress, nowAtom } from "@sunrise/time";
import type { Nullable } from "@sunrise/utils";

import { continueWatchingStatusByRecordingIdAtom } from "./continue-watching-status-by-recording-id.atom";
import { getContinueWatchingProgress } from "./helpers/get-continue-watching-progress";

export function useContinueWatchingProgressByRecordingId({
  recordingId,
  start,
  end,
  paddingStartMinutes,
  paddingEndMinutes,
}: {
  recordingId: RecordingId;
  start: Nullable<Date>;
  end: Nullable<Date>;
  paddingStartMinutes?: Nullable<number>;
  paddingEndMinutes?: Nullable<number>;
}) {
  const status = useAtomValue(
    continueWatchingStatusByRecordingIdAtom(recordingId),
  );
  const now = useAtomValue(nowAtom);

  if (!start || !end) {
    return {
      replayProgress: 0,
      liveProgress: 0,
    };
  }

  if (!status) {
    return {
      replayProgress: 0,
      liveProgress: getLiveProgress(start, end, now),
    };
  }

  if (status.isFullyWatched) {
    return {
      replayProgress: 100,
      liveProgress: 100,
    };
  }

  const replayProgress = getContinueWatchingProgress({
    status,
    epgEndTime: end,
    epgStartTime: start,
    paddingTimesInMinutes: {
      end: paddingEndMinutes ?? 0,
      start: paddingStartMinutes ?? 0,
    },
  });

  return {
    replayProgress,
    liveProgress:
      replayProgress && isBefore(end, now)
        ? 100
        : getLiveProgress(start, end, now),
  };
}
