import { keepPreviousData } from "@tanstack/query-core";
import { addSeconds } from "date-fns";
import areEqual from "fast-deep-equal";
import { parse, toSeconds } from "iso8601-duration";
import { atomFamily } from "jotai/utils";
import { atomWithSuspenseQuery } from "jotai-tanstack-query";

import { ngContinueWatchingApiAtom } from "@sunrise/backend-ng-continue-watching";
import { queryKeys } from "@sunrise/backend-types";
import type {
  EPGEntryId,
  RecordingId,
  TimeISOString,
} from "@sunrise/backend-types-core";
import { selectJwtUserToken } from "@sunrise/jwt";
import type { Nullable } from "@sunrise/utils";

type RecordingStatus = {
  recordingId: RecordingId;
  fullyWatched: boolean;
  elapsedTime: number;
  epgStartTime: Nullable<TimeISOString>;
  epgEntryId: EPGEntryId | null;
};

type ReplayStatus = {
  epgEntryId: EPGEntryId;
  epgStartTime: TimeISOString;
  moment: TimeISOString;
  fullyWatched: boolean;
};

export const continueWatchingEpgByEpgIdorRecordingIdAtom = atomFamily(
  (props: { epgId: EPGEntryId } | { recordingId: RecordingId }) => {
    return atomWithSuspenseQuery<RecordingStatus | ReplayStatus | null>(
      (get) => {
        const ngApi = get(ngContinueWatchingApiAtom);

        const userToken = get(selectJwtUserToken);

        return {
          retry: false,
          queryKey: [
            ...queryKeys.userToken(userToken),
            "continue-watching-state",
            "epgId" in props ? props.epgId : props.recordingId,
          ],
          queryFn: async () => {
            const cw =
              await ngApi.continueWatching.getContinueWatchingStatusContinueWatchingV1ContinueWatchingStatusGet(
                "epgId" in props
                  ? { epg_entry_id: props.epgId }
                  : { recording_id: props.recordingId },
                {
                  validateStatus: function (status) {
                    return (status >= 200 && status < 300) || status === 404;
                  },
                },
              );

            if (cw.status === 404) {
              return null;
            }

            const seconds = toSeconds(parse(cw.data.elapsed_time));

            if (cw.data.recording_id) {
              return {
                elapsedTime: seconds,
                recordingId: cw.data.recording_id as RecordingId,
                fullyWatched: cw.data.fully_watched ?? false,
                epgEntryId: (cw.data.epg_entry_id as EPGEntryId) ?? null,
                epgStartTime: cw.data.epg_entry_start ?? null,
              } satisfies RecordingStatus;
            }

            // When we do not have an epg_entry_start in the case of a replay, we cannot calculate the moment.
            if (!cw.data.epg_entry_start || !cw.data.epg_entry_id) {
              return null;
            }

            return {
              epgEntryId: cw.data.epg_entry_id as EPGEntryId,
              epgStartTime: cw.data.epg_entry_start,
              moment: addSeconds(
                new Date(cw.data.epg_entry_start),
                seconds,
              ).toISOString(),
              fullyWatched: cw.data.fully_watched ?? false,
            };
          },
          gcTime: 60 * 1000 * 5, // 5 minutes
          staleTime: 60 * 1000 * 10, // 10 minutes
          refetchOnWindowFocus: "always",
          refetchOnMount: "always",
          placeholderData: keepPreviousData,
        };
      },
    );
  },
  areEqual,
);
