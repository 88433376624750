import type { Stream } from "@sunrise/backend-types";
import type { Nullable } from "@sunrise/utils";

export function getStreamStats(
  stream: Nullable<Stream>,
): Nullable<{ type: Nullable<string>; offset: Nullable<number> }> {
  if (!stream) {
    return null;
  }

  return {
    type: stream.type,
    offset: "offset" in stream ? stream.offset : null,
  };
}
