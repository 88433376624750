import { useCallback, useEffect, useRef } from "react";
import { useAtomValue, useSetAtom } from "jotai";

import {
  actionPlayerClearSeekTime,
  playerAtom,
  selectPlayerCurrentStreamModel,
} from "@sunrise/player";
import { isNil } from "@sunrise/utils";

import { SEEKBAR_STEP_TIME_IN_MS } from "../player-controls.constants";
import type { SeekbarReturn } from "../types";
import { useLinearSeekbar } from "./use-linear-seekbar";
import { useOnDemandSeekbar } from "./use-on-demand-seekbar";

const NOOP = () => {
  // noop
};
const ASYNC_NOOP = async () => {
  // noop
};

/**
 * Custom hook for managing the seekbar functionality.
 * Will result in a seek object that can be used to seek forward/backward in steps as well as confirm / reset the seek.
 * Also contains information to know what to display to the left/right and top of the seekbar as well as the position of all the progress bars we show.
 *
 * @param seekStepTimeInMs The time in milliseconds for each seek step.
 * @returns An object containing seekbar data and actions.
 */
export function useSeekbar(
  { seekStepTimeInMs }: { seekStepTimeInMs: number } = {
    seekStepTimeInMs: SEEKBAR_STEP_TIME_IN_MS,
  },
): SeekbarReturn {
  const model = useAtomValue(selectPlayerCurrentStreamModel);
  const linearData = useLinearSeekbar({
    seekStepTimeInMs,
    isEnabled: model === "linear",
  });
  const recordingSeekbarProgressData = useOnDemandSeekbar({
    seekStepTimeInMs,
    isEnabled: model === "on-demand",
  });

  const seekbarProgress = linearData ?? recordingSeekbarProgressData;

  const dispatchPlayer = useSetAtom(playerAtom);

  // We have a ref to the currentTime since we don't want to rebuild the actions all the time.
  const currentTimeRef = useRef(seekbarProgress?.currentTime);
  useEffect(() => {
    currentTimeRef.current = seekbarProgress?.currentTime;
  }, [seekbarProgress?.currentTime]);

  // Actions
  const reset = useCallback(
    () => dispatchPlayer(actionPlayerClearSeekTime()),
    [dispatchPlayer],
  );

  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);

  const confirm = useCallback(async () => {
    await seekbarProgress?.seek.confirm();
    reset();
  }, [seekbarProgress?.seek, reset]);

  if (isNil(seekbarProgress)) {
    return {
      progress: null,
      currentTime: null,
      elapsed: null,
      durationLeft: null,
      liveProgress: null,
      replayProgress: null,
      isSeeking: false,
      progressWithoutSeek: null,
      currentTimeWithoutSeek: null,
      durationLeftWithoutSeek: null,
      elapsedWithoutSeek: null,
      seek: {
        forward: ASYNC_NOOP,
        backward: ASYNC_NOOP,
        confirm: ASYNC_NOOP,
        reset: NOOP,
        toPercentage: ASYNC_NOOP,
      },
    };
  }

  return {
    ...seekbarProgress,
    seek: {
      ...seekbarProgress.seek,
      confirm,
      reset,
    },
  };
}
