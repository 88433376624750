import areEqual from "fast-deep-equal";
import { atom } from "jotai";
import { atomFamily } from "jotai/utils";

import type {
  EPGEntryId,
  RecordingGroupId,
  RecordingId,
} from "@sunrise/backend-types-core";
import type { SimplifiedRecordingStatus } from "@sunrise/yallo-recordings";
import {
  recordingStatusByEpgIdAtom,
  recordingStatusByRecordingIdAtom,
} from "@sunrise/yallo-recordings";

type Props =
  | {
      epgId: EPGEntryId;
    }
  | {
      recordingId: RecordingId;
    }
  | {
      recordingGroupId: RecordingGroupId;
    };

/**
 * Designed to be used on the unified details page.
 *
 * It will just return the simplified recording status and it'll attempt to give it realtime.
 */
export const recordingStatusAtom = atomFamily((args: Props) => {
  return atom<Promise<SimplifiedRecordingStatus | null>>(async (get) => {
    if ("epgId" in args) {
      const data = await get(recordingStatusByEpgIdAtom(args.epgId));
      return data?.status ?? null;
    } else {
      const recordingId =
        "recordingId" in args ? args.recordingId : args.recordingGroupId;

      return (await get(recordingStatusByRecordingIdAtom(recordingId))) ?? null;
    }
  });
}, areEqual);
