import type { AxiosError } from "axios";

import { endpoints } from "@sunrise/backend-types";

export function isNotKnownUserError(error: AxiosError): boolean {
  return !!(
    error.config?.url?.endsWith(endpoints.me("")) &&
    error.response?.status &&
    [404].includes(error.response.status)
  );
}
