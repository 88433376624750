import axios from "axios";

/**
 * Public API that ensures that the X-Tenant header is set to the correct value as well as the Content-Type.
 */
export const publicApi = axios.create();
publicApi.defaults.headers.common["X-Tenant"] = `yallo`;
publicApi.defaults.headers.common["Content-Type"] = `application/json`;

publicApi.defaults.validateStatus = function (status) {
  return (status >= 200 && status < 300) || status === 404;
};
