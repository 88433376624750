import { atom } from "jotai";

/**
 * This represents the current time which is updated every second.
 */
export const nowSecondAtom = atom(new Date());

nowSecondAtom.onMount = (setAtom) => {
  const intervalId = setInterval(() => {
    setAtom(new Date());
  }, 1000);

  return () => {
    clearInterval(intervalId);
  };
};
