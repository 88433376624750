import type { ComponentProps, ReactElement } from "react";

import { Icon, type Icons } from "@sunrise/icons";
import { useTranslator } from "@sunrise/translator";
import type { Link, TextSize } from "@sunrise/yallo-web-components";
import { Text } from "@sunrise/yallo-web-components";

import { route } from "@/config/route";

import { NavLink } from "../nav-link";
import styles from "./navigation.module.css";

type NavigationItem = {
  label: string;
  icon: Icons;
} & Omit<ComponentProps<typeof Link>, "children">;

const navigation: NavigationItem[] = [
  {
    href: route.home.root(),
    label: "menu_home",
    icon: "home",
  },
  {
    href: route.tv.root(),
    label: "menu_tv",
    icon: "liveTV",
  },
  {
    href: route.guide.root(),
    label: "menu_guide",
    icon: "guide",
  },
  {
    href: route.recordings.root(),
    label: "menu_recordings",
    icon: "recordings",
  },
];

type Props = {
  size?: TextSize;
} & CommonProps;

function Navigation({ className = "", size = "large" }: Props): ReactElement {
  const t = useTranslator();

  return (
    <nav aria-label="Main" className={className}>
      <ul className={styles.list}>
        {navigation.map(({ label, icon, ...props }) => (
          <li key={props.href}>
            <NavLink {...props} className={styles.navLink}>
              <div className={styles.icon}>
                <Icon height={24} name={icon} width={24} />
              </div>
              <div className={styles.label}>
                <Text size={size} variant="label">
                  {t(label)}
                </Text>
              </div>
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
}

export { Navigation };
