import { atom } from "jotai";
import { atomFamily } from "jotai/utils";

import {
  canSortDetailPageEpisodesByAiringDate,
  canSortDetailPageEpisodesByTitle,
} from "./asset-details-sort.feature.atom";
import { SortingOption } from "./asset-episodes-sorting-options";

export const assetEpisodeSortOptions: Record<
  "airingDate" | "title",
  SortingOption[]
> = {
  airingDate: [SortingOption.DateDescending, SortingOption.DateAscending],
  title: [SortingOption.TitleAscending, SortingOption.TitleDescending],
} as const;

export const assertDetailsEpisodeSortOptions = atom((get) => {
  const canSortByAiringDate = get(canSortDetailPageEpisodesByAiringDate);
  const canSortByTitle = get(canSortDetailPageEpisodesByTitle);
  if (!canSortByAiringDate && !canSortByTitle) return [];

  return [
    ...(canSortByAiringDate ? assetEpisodeSortOptions.airingDate : []),
    ...(canSortByTitle ? assetEpisodeSortOptions.title : []),
  ].filter(Boolean);
});

/**
 * An internal readonly map of id and selected sorting options.
 */
const _assetDetailsEpisodeSortAtom = atom<ReadonlyMap<string, SortingOption>>(
  new Map<string, SortingOption>(),
);

/**
 * Returns the sort value of a given AssetId|RecordingGroupId or the default sort value while checking
 * that it has not since been disabled by the feature flags
 */
export const assetDetailEpisodesSortAtom = atomFamily((id: string) =>
  atom(
    (get) => {
      const sortBy = get(_assetDetailsEpisodeSortAtom).get(id);

      if (!sortBy || !get(assertDetailsEpisodeSortOptions)?.includes(sortBy))
        return get(assertDetailsEpisodeSortOptions)[0];

      return sortBy;
    },
    (get, set, { sortBy }) => {
      set(
        _assetDetailsEpisodeSortAtom,
        new Map(get(_assetDetailsEpisodeSortAtom)).set(id, sortBy),
      );
    },
  ),
);
