import { BaseError, type BaseErrorOptions } from "@sunrise/error";

/**
 * A MissingTokensError means that we can't even attempt the authenticated request because the user no longer has valid login credentials.
 * That can also mean both their access token and the refresh token have expired. It will never be thrown if we have a valid refresh token and an invalid access token.
 *
 * TODO: Needs to inherit from some kind of BaseError that has a recover attribute. And perhaps a TechnicalError.
 */
export class MissingTokensError extends BaseError {
  constructor(message: string, options?: BaseErrorOptions) {
    super("MissingTokensError", message, options);
  }
}
