import { atom } from "jotai";

import { ngHttpClientAtom } from "@sunrise/backend-ng-core";

import { GeneratedApi } from "./GeneratedApi";

export const ngSearchApiAtom = atom((get) => {
  const client = get(ngHttpClientAtom);

  if (!client) {
    throw new Error("ngHttpClientAtom is not initialized");
  }

  return new GeneratedApi(client);
});
