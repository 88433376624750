import { atomWithMutation, queryClientAtom } from "jotai-tanstack-query";

import { recordingByRecordingIdQueryAtom } from "@sunrise/backend-ng-recordings";
import type { RecordingStatusUpdate } from "@sunrise/backend-types";
import { queryKeys } from "@sunrise/backend-types";
import type { EPGEntryId, RecordingId } from "@sunrise/backend-types-core";
import { hostsAtom, httpClientAtom } from "@sunrise/http-client";
import { selectJwtUserToken } from "@sunrise/jwt";
import { isNil } from "@sunrise/utils";

import { flushRecordingsState } from "./flush-recordings-state";
import { recordingByRecordingIdLegacyQueryAtom } from "./legacy/queries/recording-by-recording-id.legacy.query.atom";
import { recordingByEpgIdAtom } from "./recording-by-epg-id.atom";
import { recordingStatusByEpgIdAtom } from "./recording-status-by-epg-id.atom";
import { recordingStatusByRecordingIdAtom } from "./recording-status-by-recording-id.atom";
import { deleteSingleRecording } from "./recordings.service";

export const deleteSingleRecordingMutationAtom = atomWithMutation<
  void,
  {
    epgEntryId: EPGEntryId;
    recordingId: RecordingId;
  },
  unknown
>((get) => {
  const host = get(hostsAtom).api;
  if (isNil(host)) throw new Error("Host is not set");

  const privateApi = get(httpClientAtom).privateApi;
  if (isNil(privateApi)) throw new Error("Private api is not set");

  const queryClient = get(queryClientAtom);
  const queryKeyRecordingStatus = queryKeys.recordingsStatus(
    get(selectJwtUserToken),
    false,
  );

  return {
    mutationKey: ["delete-recording"],
    mutationFn: ({ recordingId }) =>
      deleteSingleRecording(host, privateApi, recordingId),
    onMutate: ({ epgEntryId, recordingId }) => {
      // NOTE: temporarily remove status
      let statuses = (queryClient.getQueryData(queryKeyRecordingStatus) ||
        []) as RecordingStatusUpdate[];
      statuses = statuses.filter((s) => s.recordingId !== recordingId);

      // TODO find better way to clear recordings
      recordingStatusByEpgIdAtom.remove(epgEntryId);
      recordingByEpgIdAtom.remove(epgEntryId);
      recordingByRecordingIdLegacyQueryAtom.remove(recordingId);
      recordingByRecordingIdQueryAtom.remove(recordingId);
      recordingStatusByRecordingIdAtom.remove(recordingId);
      queryClient.setQueryData(queryKeyRecordingStatus, statuses);
    },
    onSettled: function () {
      flushRecordingsState(queryClient, get);
    },
  };
});
