import { useAtomValue } from "jotai";

import type { Schedulable } from "@sunrise/backend-types";
import type { TranslateFn } from "@sunrise/i18n";
import { nowAtom } from "@sunrise/time";
import { type Nullable } from "@sunrise/utils";

import { getRelativeTimeForEpg } from "../helpers/get-relative-time-for-epg";

export type useReadableEpgSchedulingInfoReturn = {
  status: Nullable<string>;
  schedule: Nullable<string>;
  formattedStart: Nullable<string>;
  formattedEnd: Nullable<string>;
};

const EMPTY = {
  status: null,
  schedule: null,
  formattedStart: null,
  formattedEnd: null,
};

/**
 * @param currentTime The current time. If not given, it will use the current time from the store.
 * @param schedulable The schedulable for which we want to get the easily readable scheduling.
 *
 * @returns {Object} readableSchedulingInfo
 * @returns {string} readableSchedulingInfo.status - The relative day of the schedulable or a formatted date, e.g. `live`, `today` or `Fri. Feb 23 10:00 - 11:00`.
 * @returns {string} readableSchedulingInof.schedule - The timing for the given schedulable in an easy to read format. Something like `01:23 - 02:34 (1h 11m)` or `Starts in 1 hour`.
 */
export function useReadableEpgSchedulingInfo(
  schedulable: Nullable<Schedulable>,
  t: TranslateFn,
  currentTime?: Date,
): useReadableEpgSchedulingInfoReturn {
  const storeTime = useAtomValue(nowAtom);
  const now = currentTime ?? storeTime;

  if (!schedulable?.startTime || !schedulable?.endTime) {
    return EMPTY;
  }

  return getRelativeTimeForEpg(
    schedulable.startTime,
    schedulable.endTime,
    now,
    t,
  );
}
