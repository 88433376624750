import { useMediaQuery } from "usehooks-ts";

import { Text, Title } from "@sunrise/yallo-web-components";

import styles from "./search-placeholder.module.css";

type SearchPlaceholderProps = {
  title: string;
  body: string;
};

export const SearchPlaceholder = ({ title, body }: SearchPlaceholderProps) => {
  const useLargeType = useMediaQuery("(width > 650px)");

  return (
    <div className={styles.wrapper}>
      <Title
        className={styles.title}
        level="h2"
        size={useLargeType ? "large" : "small"}
        variant="headline"
      >
        {title}
      </Title>
      <Text size={useLargeType ? "large" : "small"} variant="title">
        {body}
      </Text>
    </div>
  );
};
