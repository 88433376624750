import { useCallback, useState } from "react";
import { useAtomCallback } from "jotai/utils";

import { isLegacyBackendAtom } from "@sunrise/backend-core";
import { hostsAtom } from "@sunrise/http-client";
import { actionJWTSetTokens, jwtAtom } from "@sunrise/jwt";
import type { Store } from "@sunrise/store";
import type { Nullable } from "@sunrise/utils";

import { authenticateWithToken } from "./auth.service";
import { CognitoService } from "./cognito.service";
import { deviceTypeAtom } from "./device-type.atom";

type credentialsLoginProps = {
  store: Store;
  cognitoAppClientName?: string;
};

export function useCredentialsLogin({
  store,
  cognitoAppClientName = "",
}: credentialsLoginProps) {
  const [error, setError] = useState<Nullable<string>>(null);
  const [isLoading, setIsLoading] = useState(false);

  const login = useAtomCallback(
    useCallback(
      async (get, set, username: string, password: string) => {
        const host = get(hostsAtom).api;
        const deviceTypeName = get(deviceTypeAtom);

        setIsLoading(true);
        setError(null);

        try {
          const cognitoService = new CognitoService(
            store,
            cognitoAppClientName,
          );
          const response = await cognitoService.loginWithCredentials(
            username,
            password,
          );

          if (response.isValid()) {
            const data = await authenticateWithToken(
              host,
              deviceTypeName,
              response.getIdToken().getJwtToken(),
              !get(isLegacyBackendAtom),
            );

            if (!data.client_jwt_refresh_token || !data.client_jwt_token) {
              setError("Received an invalid response from the backend");
              return false;
            }

            set(
              jwtAtom,
              actionJWTSetTokens({
                accessToken: data.client_jwt_token,
                refreshToken: data.client_jwt_refresh_token,
                wsToken: data.client_jwt_websocket_token ?? null,
              }),
            );
            setError(null);
            return true;
          }
          return false;
        } catch (error: unknown) {
          if (error instanceof Error) {
            setError(error.message);
            return false;
          }

          setError("An unknown error occurred");
          return false;
        } finally {
          setIsLoading(false);
        }
      },
      [store],
    ),
  );

  return {
    login,
    isLoading,
    error,
    clearError: useCallback(() => setError(null), []),
  };
}
