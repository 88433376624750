import React from "react";

import type { ActionsDialog as ActionsDialogType } from "@sunrise/dialogs";
import { useTranslatable } from "@sunrise/translator";
import { Button } from "@sunrise/yallo-web-components";

import type { DialogProps } from "@/components/dialogs/dialog.types";

import styles from "./actions-dialog.module.css";

export const ActionsDialog = ({
  dialog,
  "data-testid": testId,
  doClose,
}: DialogProps & { dialog: ActionsDialogType }) => {
  const t = useTranslatable();

  return (
    <div className={styles.actions} data-testid={`${testId}.actions`}>
      {"actions" in dialog &&
        dialog.actions.map((action, i) => {
          const key = action.key ?? "action-" + i;

          return (
            <Button
              key={key}
              className={styles.button}
              data-testid={`${testId}.button.${key}`}
              variant={action.primary ? "filled" : "outlined"}
              onClick={() => {
                void action.action();
                if (!action.closeDisabled) {
                  doClose?.();
                }
              }}
            >
              {t(action.label) ?? key ?? `button ${i}`}
            </Button>
          );
        })}
    </div>
  );
};
