import { atom } from "jotai";

import { areVideoAdsPlayingAtom } from "@sunrise/ads";
import { type PlayerState, selectPlayerState } from "@sunrise/player";
import {
  selectShouldShowWhatIsNext,
  showWhatIsNextSideBarAtom,
} from "@sunrise/what-is-next";

const NO_PLAYER_CONTROL_STATES: PlayerState[] = ["idle", "error", "stopped"];

export const shouldNotShowPlayerControlsAtom = atom((get) => {
  const playerState = get(selectPlayerState);
  if (NO_PLAYER_CONTROL_STATES.includes(playerState)) {
    return true;
  }

  const showNext = get(selectShouldShowWhatIsNext);
  const showNextSideBar = get(showWhatIsNextSideBarAtom);

  // When ads are playing out we also should not allow player-controls to be shown.
  return get(areVideoAdsPlayingAtom) || showNext || showNextSideBar;
});
