import { atom } from "jotai";

import { playerAtom } from "../player.atom";
import { formatAudioLabel } from "../utils/format-audio-label";
import { getStreamModelForPlayRequest } from "../utils/get-stream-model-for-play-request";

export const selectPlayerCurrentStream = atom((get) => {
  const playerAtomState = get(playerAtom);
  return playerAtomState.stream;
});

export const selectPlayerDesiredCurrentTime = atom((get) => {
  const playerAtomState = get(playerAtom);
  return playerAtomState.desiredCurrentTime;
});

export const selectPlayerShouldBePlaying = atom((get) => {
  const playerAtomState = get(playerAtom);
  return playerAtomState.shouldBePaused;
});

// TODO: don't use selectPlayerShouldBePaused() in react components because it will rerender infinitely
export const selectPlayerShouldBePaused = atom((get) => {
  const playerAtomState = get(playerAtom);
  return playerAtomState.shouldBePaused;
});

export const selectPlayerCurrentTime = atom((get) => {
  const playerAtomState = get(playerAtom);
  return playerAtomState.currentTime;
});

export const selectPlayerDuration = atom((get) => {
  const playerAtomState = get(playerAtom);
  return playerAtomState.duration;
});

/**
 * To determine if we are lying about the actual current time in the player for linear content.
 */
export const selectPlayerInLinearSeekConfirmation = atom((get) => {
  const playerAtomState = get(playerAtom);
  return playerAtomState.seekConfirmationLinear;
});

export const selectPlayerCurrentSeekTime = atom((get) => {
  const playerAtomState = get(playerAtom);
  return playerAtomState.seekTime;
});

/**
 * This returns the stream model that is applicable for the current PlayRequest.
 */
export const selectPlayerCurrentStreamModel = atom((get) => {
  const playerAtomState = get(playerAtom);
  return playerAtomState.playRequest
    ? getStreamModelForPlayRequest(playerAtomState.playRequest.type)
    : null;
});

export const selectPlayerCurrentStreamType = atom((get) => {
  const playRequest = get(selectPlayerCurrentPlayRequest);
  return playRequest?.type;
});

/**
 * This returns the player's seekTime or the player's current time if no seekTime is set.
 * It's to be used by the player controls to display the correct EPG information while we are seeking through the content.
 *
 * This will always return a number. It is up to the higher levels to determine what this number means.
 */
export const selectPlayerCurrentTimeIncludingSeekTime = atom((get) => {
  const playerAtomState = get(playerAtom);
  return playerAtomState.seekTime ?? playerAtomState.currentTime;
});

export const selectPlayerState = atom((get) => {
  const playerAtomState = get(playerAtom);
  return playerAtomState.state;
});

export const selectPlayerIsSuspended = atom((get) => {
  const playerAtomState = get(playerAtom);
  return playerAtomState.state === "suspended";
});

export const selectPlayerCurrentPlayRequest = atom((get) => {
  const playerAtomState = get(playerAtom);
  return playerAtomState.playRequest;
});

export const selectPlayerIsStopped = atom((get) => {
  const playerAtomState = get(playerAtom);
  return playerAtomState.state === "stopped";
});

export const selectPlayerIsAutoStopped = atom((get) => {
  const playerAtomState = get(playerAtom);
  return playerAtomState.isAutoStopped && playerAtomState.state === "idle";
});

export const selectPlayerIsPlaying = atom((get) => {
  const playerAtomState = get(playerAtom);
  return playerAtomState.state === "playing";
});

export const selectPlayerIsLoading = atom((get) => {
  const playerAtomState = get(playerAtom);
  return playerAtomState.state === "loading";
});

export const selectPlayerSubtitlesAndAudioSettings = atom((get) => {
  const playerAtomState = get(playerAtom);

  // add no subtitle option
  const subtitleTracks = [
    ...(playerAtomState.subtitleTracks ?? []).map((track) => ({
      label: `lang.${track.lang}`,
      value: track.id,
    })),
    { label: "text.none", value: null },
  ];

  // add 5.1 label for Dolby and Stereo label for Stereo when both exist for one language, if not no additions
  const audioTracks = (playerAtomState.audioTracks ?? []).map((track) => {
    return formatAudioLabel(
      `lang.${track.lang}`,
      track,
      playerAtomState.audioTracks ?? [],
    );
  });

  return {
    audioTracks,
    subtitleTracks,
  };
});

export const selectPlayerCurrentSubtitleTrack = atom((get) => {
  const playerAtomState = get(playerAtom);
  return (
    playerAtomState.subtitleTracks?.find(
      (t) => t.id === playerAtomState.currentSubtitleTrackId,
    ) ?? null
  );
});

export const selectPlayerCurrentAudioTrackId = atom((get) => {
  const playerAtomState = get(playerAtom);
  return playerAtomState.currentAudioTrackId;
});

export const selectPlayerCurrentSubtitleTrackId = atom((get) => {
  const playerAtomState = get(playerAtom);
  return playerAtomState.currentSubtitleTrackId;
});

export const selectPlayerCurrentAudioTrack = atom((get) => {
  const playerAtomState = get(playerAtom);
  return (
    playerAtomState.audioTracks?.find(
      (t) => t.id === playerAtomState.currentAudioTrackId,
    ) ?? null
  );
});

export const selectPlayerShouldBePictureInPicture = atom((get) => {
  const playerAtomState = get(playerAtom);
  return playerAtomState.shouldBePictureInPicture;
});

export const selectPlayerPictureInPictureSupported = atom((get) => {
  const playerAtomState = get(playerAtom);
  return playerAtomState.pictureInPictureSupported;
});

export const selectPlayerShouldBeFullscreen = atom((get) => {
  const playerAtomState = get(playerAtom);
  return playerAtomState.shouldBeFullscreen;
});

export const selectPlayerFullscreenSupportedMode = atom((get) => {
  const playerAtomState = get(playerAtom);
  return playerAtomState.fullscreenSupportedMode;
});

export const selectPlayerStreamSetAt = atom((get) => {
  const playerAtomState = get(playerAtom);
  return playerAtomState.streamSetAt;
});

export const selectPlayerCurrentError = atom((get) => {
  const playerAtomState = get(playerAtom);
  return playerAtomState.error;
});

/**
 * Returns the player's actual volume at all times. Never the desired volume.
 * Can be null when we do not yet know the player's volume.
 */
export const selectPlayerVolume = atom((get) => {
  const playerAtomState = get(playerAtom);
  return playerAtomState.volume;
});
