import { useCallback } from "react";
import { useAtomValue } from "jotai";
import { useAtomCallback } from "jotai/utils";

import {
  playerCurrentEpgItemAtom,
  playerDateTimeConverterAtom,
} from "@sunrise/player";
import {
  currentlyRequestedPlayRequestAtom,
  getPlayerManager,
} from "@sunrise/yallo-common-player-manager";

type usePlayNextReturn = {
  visible: boolean;
  playNext: () => Promise<void>;
};

export function usePlayNext(): usePlayNextReturn {
  const playRequest = useAtomValue(currentlyRequestedPlayRequestAtom);
  const visible = !!playRequest && playRequest.type === "replay";

  return {
    visible,
    playNext: useAtomCallback(
      useCallback(
        async (get) => {
          if (!playRequest || !visible) return;

          const converter = get(playerDateTimeConverterAtom);
          if (!converter) return;

          const epgEntry = await get(playerCurrentEpgItemAtom);
          if (!epgEntry) return;

          const time = converter.fromDate(new Date(epgEntry.actualEnd));

          await getPlayerManager().seekToInCurrentPlayRequest(
            time,
            "play-next",
          );
        },
        [visible, playRequest],
      ),
    ),
  };
}
