import type { NgSearchRecordingResult } from "@sunrise/search";
import { useContinueWatchingProgressByRecordingId } from "@sunrise/yallo-continue-watching";

import { route } from "@/config/route";
import { usePlayRecording } from "@/hooks/use-play-recording";

import { SearchEpisodeOrProgramResult } from "./search-episode-or-program-result";

export function SearchRecordingResult({
  item,
  closeSearch,
}: {
  item: NgSearchRecordingResult;
  closeSearch?: () => void;
}) {
  const { play, canPlay } = usePlayRecording({ recordingId: item.recordingId });

  const detailRoute = item.assetId
    ? route.recordings.single({
        assetId: item.assetId,
        recordingId: item.recordingId,
      })
    : undefined;

  const progress = useContinueWatchingProgressByRecordingId({
    recordingId: item.recordingId,
    start: new Date(item.epgStart),
    end: new Date(item.epgEnd),
  });

  return (
    <SearchEpisodeOrProgramResult
      closeSearch={closeSearch}
      detailRoute={detailRoute}
      item={item}
      play={canPlay ? play : undefined}
      progress={progress}
    />
  );
}
