import { atomWithSuspenseQuery } from "jotai-tanstack-query";

import { queryKeys } from "@sunrise/backend-types";
import { selectJwtUserToken } from "@sunrise/jwt";

import { ngRecordingsApiAtom } from "./ng-recordings-api.atom";
import { recordingsStaleTimeAtom } from "./recordings-stale-time.atom";

export const recordingsCountQuery = atomWithSuspenseQuery((get) => {
  const api = get(ngRecordingsApiAtom);

  return {
    queryKey: queryKeys.recordingStats(get(selectJwtUserToken), true),
    queryFn: async () => {
      return (
        await api.recording.getUserRecordingsCountRecordingV1RecordingsCountGet()
      ).data;
    },
    staleTime: get(recordingsStaleTimeAtom),
  };
});
