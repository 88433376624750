import { useCallback } from "react";
import { useAtom } from "jotai";

import { playerVolumeAtom } from "@sunrise/player";

type PlayerVolumeReturn = {
  toggleMute: () => void;
  isMuted: boolean;
  setVolume: (volume: number) => void;
  currentVolume: number;
};

/**
 * This returns the player's volume and mute state to show in the player's volume control.
 */
export function usePlayerVolume(): PlayerVolumeReturn {
  const [volume, setVolume] = useAtom(playerVolumeAtom);

  return {
    toggleMute: useCallback(() => {
      setVolume({ type: "toggle-mute" });
    }, [setVolume]),
    isMuted: volume.muted,
    /**
     * A value from 0 to 100.
     * When 0, the player is muted.
     * When higher than 0 the player will automatically unmute.
     */
    setVolume: useCallback(
      (level: number) => {
        setVolume({ type: "set-level", level });
      },
      [setVolume],
    ),
    currentVolume: volume?.level,
  };
}
