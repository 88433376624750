import type { ReactElement } from "react";

import { useSeekbar } from "@sunrise/yallo-player-controls";
import { Text } from "@sunrise/yallo-web-components";

import styles from "./player-thumbnail.module.css";
import { Thumbnail } from "./thumbnail";

type PlayerThumbnailProps = {
  showDurationLeft: boolean;
};

function PlayerThumbnail({
  showDurationLeft,
}: PlayerThumbnailProps): ReactElement {
  const { elapsed, durationLeft, isSeeking } = useSeekbar();

  return (
    <div className={styles.wrapper}>
      {isSeeking && (
        <div className={styles.preview}>
          <Thumbnail />
          <Text>{showDurationLeft ? `-${durationLeft}` : elapsed}</Text>
        </div>
      )}
    </div>
  );
}

export { PlayerThumbnail };
