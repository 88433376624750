import { atom } from "jotai";
import { atomEffect } from "jotai-effect";

import { currentLanguageAtom } from "@sunrise/i18n";
import { selectAccessToken } from "@sunrise/jwt";
import type { Nullable } from "@sunrise/utils";
import { appVersionAtom } from "@sunrise/yallo-settings";

import { deviceIdAtom } from "./device-id.atom";
import { deviceVersionAtom } from "./device-version.atom";
import { legacySocketConnectedAtom } from "./legacy-socket-connected.atom";
import { legacySocketUrlAtom } from "./legacy-socket-url.atom";
import { LegacyWebsocketService } from "./legacy-websocket.service";
import { socketAuthenticatedAtom } from "./socket-authenticated.atom";
import { keepAliveIntervalInMsAtom } from "./socket-keep-alive-in-ms.atom";
import { userAgentAtom } from "./user-agent.atom";

const socketInternalAtom = atom<Nullable<LegacyWebsocketService>>(null);
socketInternalAtom.debugPrivate = true;

/**
 * This should become a legacy atom.
 * This whole logging in and updating the language is no longer needed on the ng socket.
 *
 * @deprecated
 */
export const legacySocketAtom = atom<Nullable<LegacyWebsocketService>>(
  (get) => {
    get(manageSocketEffect);
    get(handleLoggedInEffect);
    get(updateLanguageEffect);

    return get(socketInternalAtom);
  },
);

const manageSocketEffect = atomEffect((get, set) => {
  const url = get(legacySocketUrlAtom);

  if (!url) {
    return;
  }

  const keepaliveIntervalInMs = get(keepAliveIntervalInMsAtom);

  const onAuthenticationChange = (authenticated: boolean) => {
    set(socketAuthenticatedAtom, authenticated);
  };
  const onConnectionChange = (connected: boolean) => {
    set(legacySocketConnectedAtom, connected);
  };

  const socket = new LegacyWebsocketService({
    url,
    keepaliveIntervalInMs,
    onAuthenticationChange,
    onConnectionChange,
  });

  set(socketInternalAtom, socket);

  return () => {
    socket.disconnect();
    set(socketInternalAtom, null);
  };
});

const NULL_ATOM = atom(null);

const handleLoggedInEffect = atomEffect((get) => {
  const socket = get(legacySocketAtom);

  if (!socket) {
    return;
  }

  const accessToken = get(selectAccessToken);

  if (!accessToken) {
    socket.disconnect();
    return;
  }

  Promise.all([
    get(appVersionAtom),
    get(userAgentAtom),
    get(deviceVersionAtom),
    get(get(deviceIdAtom) ?? NULL_ATOM),
  ]).then(([clientVersion, userAgent, deviceVersion, deviceId]) => {
    socket.authenticate({
      token: accessToken,
      deviceId: deviceId ?? undefined,
      userAgent,
      deviceVersion: deviceVersion ?? undefined,
      clientVersion: clientVersion ?? undefined,
    });
  });

  return () => {
    socket.disconnect();
  };
});

const updateLanguageEffect = atomEffect((get) => {
  const language = get(currentLanguageAtom);
  const ws = get(legacySocketAtom);

  if (!ws) {
    return;
  }

  ws.setLanguage(language);
});
