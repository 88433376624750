import { atom } from "jotai";
import { selectAtom } from "jotai/utils";

import { selectPlayerCurrentPlayRequest } from "@sunrise/player";

import { playerManagerAtom } from "./player-manager.atom";

/**
 * This returns the playrequest as soon as it is requested.
 * But when it errors it does fall back to the play request that is currently playing in the player.
 */
export const optimisticCurrentPlayRequestAtom = atom((get) => {
  const manager = get(playerManagerAtom);

  if (!manager.error && manager.playRequest) {
    return manager.playRequest;
  }

  return get(selectPlayerCurrentPlayRequest);
});

export const selectActiveChannelIdAtom = selectAtom(
  optimisticCurrentPlayRequestAtom,
  (playRequest) => playRequest?.channelId,
);
