import { atomWithSuspenseQuery } from "jotai-tanstack-query";

import {
  type ChannelSearchEntry,
  queryKeys,
  type SearchResult,
} from "@sunrise/backend-types";
import { hostsAtom, httpClientAtom } from "@sunrise/http-client";
import { isNil } from "@sunrise/utils";

import { searchChannels } from "./search.service";
import { selectCurrentSearchQuery } from "./select-current-search-query";
import { selectSearchEnabled } from "./select-search-enabled";

export const searchChannelQueryAtom = atomWithSuspenseQuery<
  SearchResult<ChannelSearchEntry>,
  unknown,
  SearchResult<ChannelSearchEntry>,
  [string, string]
>((get) => {
  const hosts = get(hostsAtom);
  const host = hosts.api;
  if (isNil(host)) throw new Error("api host is not defined");

  const privateApi = get(httpClientAtom).privateApi;
  if (isNil(privateApi)) throw new Error("Private api is not set");

  const query = get(selectCurrentSearchQuery);
  const enabled = get(selectSearchEnabled);

  return {
    queryKey: queryKeys.searchChannels(query),
    queryFn: async ({ queryKey: [, text] }) => {
      if (!enabled) return { result: [], hits: 0 };

      return searchChannels(host, privateApi, text as string);
    },
  };
});
