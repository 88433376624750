import {
  type ComponentProps,
  forwardRef,
  type ReactElement,
  type Ref,
} from "react";

import { BaseText } from "./base-text";
import { type TextElements } from "./types";

type Props = {
  as?: TextElements;
} & Omit<ComponentProps<typeof BaseText>, "as"> &
  CommonProps;

// TODO: The typing for the ref is not fully correct, as we accept headings, spans or paragraphs.
// I believe this is not currently possible with forwardRef and TypeScript, but once we upgrade to
// React v19, refs will be passed via props and we can do a nice union type here.
// https://react.dev/blog/2024/12/05/react-19#ref-as-a-prop
const Text = forwardRef(function Text(
  { children, as = "span", ...props }: Props,
  ref: Ref<HTMLHeadingElement>,
): ReactElement {
  return (
    <BaseText ref={ref} as={as} {...props}>
      {children}
    </BaseText>
  );
});

export { Text };
