import { useEffect, useState } from "react";
import { debounce, throttle } from "lodash";

/**
 * from https://github.com/uidotdev/usehooks
 * @param ms
 */
export const useIdle = (
  ms: number | undefined,
  target: HTMLElement | typeof window | null = window,
): boolean => {
  const [idle, setIdle] = useState(false);

  useEffect(() => {
    const debouncedSetIdle = debounce(() => setIdle(true), ms, {
      trailing: true,
      leading: false,
    });

    const handleEvent = throttle((): void => {
      setIdle(false);
      debouncedSetIdle();
    }, 200);

    const handleVisibilityChange = (): void => {
      if (!document.hidden) {
        handleEvent();
      }
    };

    if (!target) {
      return;
    }

    debouncedSetIdle();

    target.addEventListener("mouseenter", handleEvent);
    target.addEventListener("mousemove", handleEvent);
    target.addEventListener("mousedown", handleEvent);
    target.addEventListener("mouseup", handleEvent);
    target.addEventListener("resize", handleEvent);
    target.addEventListener("keydown", handleEvent);
    target.addEventListener("keyup", handleEvent);
    target.addEventListener("touchstart", handleEvent);
    target.addEventListener("touchend", handleEvent);
    target.addEventListener("wheel", handleEvent);
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      target.removeEventListener("mouseenter", handleEvent);
      target.removeEventListener("mousemove", handleEvent);
      target.removeEventListener("mousedown", handleEvent);
      target.removeEventListener("mouseup", handleEvent);
      target.removeEventListener("resize", handleEvent);
      target.removeEventListener("keydown", handleEvent);
      target.removeEventListener("keyup", handleEvent);
      target.removeEventListener("touchstart", handleEvent);
      target.removeEventListener("touchend", handleEvent);
      target.removeEventListener("wheel", handleEvent);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      debouncedSetIdle.cancel();
    };
  }, [ms, target]);

  return idle;
};
