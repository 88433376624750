import areEqual from "fast-deep-equal";
import { atom } from "jotai";
import { atomFamily } from "jotai/utils";

import type { ContinueWatchingItemLegacy } from "@sunrise/backend-types";
import type { EPGEntryId, RecordingId } from "@sunrise/backend-types-core";
import type { Nullable } from "@sunrise/utils";

import { continueWatchingInfiniteQueryLegacyAtom } from "./continue-watching-infinite-query.legacy.atom";
import { continueWatchingKnownItemsLegacyAtom } from "./continue-watching-known-items.legacy.atom";

type Filter = {
  epgId: EPGEntryId;
  recordingId: RecordingId | null;
};

/**
 * Should return the continue watching item for the given EPG entry or recording.
 *
 * @deprecated
 *   Removed when we remove the legacy continue watching atoms.
 */
export const continueWatchingByEpgIdOrRecordingIdLegacyAtom = atomFamily(
  ({ epgId, recordingId }: Filter) => {
    const innerAtom = atom(async (get) => {
      // TODO: should return an atom which has all the data as well as a function to call the next page if needed.
      // TODO: depend on a leaner atom that contains just references & progress since that is all we care about. And that should be able to ask for more data as well.
      const allItems = get(continueWatchingKnownItemsLegacyAtom);

      let found: Nullable<ContinueWatchingItemLegacy> = null;
      if (recordingId) {
        found = allItems.find(
          (item) =>
            item.type === "recording" && item.recording.id === recordingId,
        );
      } else {
        found = allItems.find(
          (item) => item.epg_entry.id === epgId && item.type === "replay",
        );
      }

      if (!found) {
        const paged = get(continueWatchingInfiniteQueryLegacyAtom);
        if (paged.hasNextPage && !paged.isFetchingNextPage) {
          paged.fetchNextPage();
        }
      }

      return found ?? null;
    });
    innerAtom.debugLabel = `continueWatchingByEpgIdOrRecordingIdLegacyAtom(${epgId},${recordingId})`;
    return innerAtom;
  },
  areEqual,
);
