import { atomFamily } from "jotai/utils";
import { atomWithSuspenseQuery } from "jotai-tanstack-query";

import { queryKeys } from "@sunrise/backend-types";
import type {
  RecordingGroupId,
  RecordingId,
} from "@sunrise/backend-types-core";
import { currentLanguageAtom } from "@sunrise/i18n";
import { selectJwtUserToken } from "@sunrise/jwt";

import { backendRecordingToNgRecording } from "./helpers/backend-recording-to-ng-recording";
import { ngRecordingsApiAtom } from "./ng-recordings-api.atom";
import { recordingsStaleTimeAtom } from "./recordings-stale-time.atom";
import type { NgRecording } from "./types";

export const recordingByRecordingIdQueryAtom = atomFamily(
  (recordingId: RecordingId | RecordingGroupId) => {
    const inner = atomWithSuspenseQuery<NgRecording>((get) => {
      const api = get(ngRecordingsApiAtom);
      const language = get(currentLanguageAtom);

      return {
        // NOTE: We still depend on the access token since we want the data to reload when the user's token changes.
        queryKey: queryKeys.recordingByRecordingId(
          get(selectJwtUserToken),
          true,
          language,
          recordingId,
        ),
        queryFn: async () => {
          const { data } =
            await api.recording.getRecordingRecordingV1RecordingsRecordingIdGet(
              recordingId,
            );

          return backendRecordingToNgRecording(data);
        },
        staleTime: get(recordingsStaleTimeAtom),
        suspense: true,
      };
    });

    inner.debugLabel = `recordingByRecordingIdQueryAtom(${recordingId})`;

    return inner;
  },
);
