import { type ReactNode } from "react";
import type { createStore } from "jotai";
import { Provider } from "jotai";
import { DevTools, useAtomsDevtools } from "jotai-devtools";

import "jotai-devtools/styles.css";

export type Store = ReturnType<typeof createStore>;

type StoreProps = {
  children: ReactNode;
  enableDevTools?: boolean;
  store?: Store;
};

export function StoreProvider({
  children,
  enableDevTools = false,
  store,
}: StoreProps): JSX.Element {
  // hooks into redux dev tools
  useAtomsDevtools("atoms", {
    store,
    enabled: enableDevTools,
  });

  return (
    <Provider store={store}>
      {enableDevTools ? <DevTools store={store} /> : null}
      {children}
    </Provider>
  );
}
