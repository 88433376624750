import { useMemo } from "react";

import { isDefined } from "./is-defined";
import type { Nullable } from "./nullable.types";

/**
 * Small helper to ensure we always return a stable date object.
 * The problem is that date instances are not equal to one another by reference when they are created twice with the same date.
 *
 * This will ensure that a new date object is returned but it will always be the same date object unless the actual time changes.
 */
export function useStableDate(value: Nullable<string>): Nullable<Date> {
  return useMemo(() => {
    if (!isDefined(value)) return value;

    return new Date(value);
  }, [value]);
}
