import {
  type ChannelSearchEntry,
  endpoints,
  type EPGSearchEntry,
  type SearchQuery,
  type SearchResult,
} from "@sunrise/backend-types";
import type { PrivateApiClient } from "@sunrise/http-client";

export async function searchEpgs(
  host: string,
  httpClient: PrivateApiClient,
  query: SearchQuery,
) {
  const url = new URL(endpoints.searchEpgEntries(host, query.text));

  url.searchParams.append("epg_search_type", "epg_available");
  url.searchParams.append(
    "epg_availability",
    query.filter === "future" ? "upcoming" : query.filter,
  );

  const { data } = await httpClient.get<SearchResult<EPGSearchEntry>>(url.href);
  return data;
}

export async function searchChannels(
  host: string,
  httpClient: PrivateApiClient,
  query: string,
) {
  const url = new URL(endpoints.searchChannels(host, query));

  const { data } = await httpClient.get<SearchResult<ChannelSearchEntry>>(
    url.href,
  );
  return data;
}
