import { getTime, isAfter, isBefore, millisecondsToMinutes } from "date-fns";
import { atom } from "jotai";
import { unwrap } from "jotai/utils";
import { atomEffect } from "jotai-effect";

import type { ChannelId } from "@sunrise/backend-types-core";
import {
  playerCurrentContentAtom,
  selectPlayerCurrentPlayRequest,
} from "@sunrise/player";
import { dateToTimeDay, nowAtom } from "@sunrise/time";
import { selectedChannelGroupWithAllChannels } from "@sunrise/yallo-channel-group";
import type { MappedEpg } from "@sunrise/yallo-epg";
import {
  programIsPlayingAtTime,
  selectEpgCollectionPerDayAtom,
} from "@sunrise/yallo-epg";
import { startupChannelIdAtom } from "@sunrise/yallo-user";

import { guideNeedsAutoSelectionAtom } from "./atoms/guide-needs-auto-selection.atom";
import type { GuideProgram } from "./guide.types";
import { guideWindowAtom } from "./guide-window.atom";
import { actionGuideSetSelection } from "./store/grid-state.actions";
import { gridStateAtom } from "./store/grid-state.atom";
import { epgEntryToGuideProgram } from "./utils/epg-entry-to-guide-program";

/**
 * Returns the selection that should be selected in the guide.
 * Initial selection should be the currently playing content. This should work for recording playouts that are still associated with epgs as well as regular epg-based playouts.
 * If this can not be found, we would fall back to the channel that is supposed to be playing right now.
 * If this selection can not be made then we would fall back to the first channel in the list that has something playing right now.
 */
const shouldBeSelectedAtom = atom<
  Promise<{
    program: GuideProgram;
    channelId: ChannelId;
  } | null>
>(async (get) => {
  const playRequest = get(selectPlayerCurrentPlayRequest);
  const channels = await get(selectedChannelGroupWithAllChannels);

  if (playRequest) {
    if (channels && channels.some((c) => c.id === playRequest.channelId)) {
      // when it is in the guide window, we can select it.
      const window = get(guideWindowAtom);

      // current content (also when recording playout is active)
      const current = await get(playerCurrentContentAtom);

      // When it's not complete information we do not want to return it as the selection.
      // Normally it should all be complete though.
      if (
        !current ||
        !current.epgId ||
        !current.schedule ||
        !current.assetId ||
        !current.schedule ||
        !playRequest.channelId ||
        isBefore(current.schedule.endTime, window.startTime) ||
        isAfter(current.schedule.startTime, window.endTime)
      ) {
        return null;
      }

      return {
        program: {
          id: current.epgId,
          assetId: current.assetId,
          title: current.title,
          subtitle: current.subtitle,
          plot: null,
          startTime: current.schedule.startTime,
          endTime: current.schedule.endTime,
          image: current.image,
          durationInMinutes: Math.floor(
            millisecondsToMinutes(
              getTime(current.schedule.startTime) -
                getTime(current.schedule.endTime),
            ),
          ),
        },
        channelId: playRequest.channelId,
      };
    }
  }

  const knownChannelIds = channels
    .map((c) => c.id)
    .filter((v) => v !== null && typeof v !== "undefined");

  // We want to prioritize the channel of the current playRequest if we have any. Else we should take the channelId we would normally start.
  const startupId = await get(startupChannelIdAtom);
  const currentContentChannelId = playRequest?.channelId;
  const channelIds = [
    ...[currentContentChannelId, startupId]
      .filter((id) => id !== null && typeof id !== "undefined")
      .filter((id) => channels.some((c) => c.id === id)),
    ...knownChannelIds,
  ];

  const now = get(nowAtom);
  const day = dateToTimeDay(now);

  // find the first channel that has something airing right now.
  const check = async (channelId: ChannelId): Promise<null | MappedEpg> => {
    const data = await get(selectEpgCollectionPerDayAtom({ day, channelId }));
    return (
      data.data?.find((v) =>
        programIsPlayingAtTime(
          {
            startTime: new Date(v.actualStart),
            endTime: new Date(v.actualEnd),
          },
          now,
        ),
      ) ?? null
    );
  };

  for (const channelId of channelIds) {
    const result = await check(channelId);

    if (result) {
      return {
        program: epgEntryToGuideProgram(result),
        channelId,
      };
    }
  }

  return null;
});

/**
 * This effect will make an automatic selection in the program guide if no selection is present yet.
 * It will also only ever do that when we are not in date-offset priority mode.
 * Because when we are in date-offset the grid should be able to scroll past the current selection.
 *
 * Ideally the guide should always have the currently playing program selected or the program
 * that is supposed to be playing.
 */
export const guideAutoSelectionEffect = atomEffect((get, set) => {
  // Check what is supposed to be active.
  const needed = get(guideNeedsAutoSelectionAtom);
  if (!needed) {
    return;
  }
  const selection = get(unwrap(shouldBeSelectedAtom));

  if (selection) {
    set(
      gridStateAtom,
      actionGuideSetSelection(selection.program, selection.channelId),
    );
  }
});
