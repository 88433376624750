import { useEffect } from "react";
import { useAtomValue } from "jotai";

import { shouldPlayerAutostartAtom } from "../should-player-autostart.atom";
import { usePlayStartupChannel } from "./use-play-startup-channel";

export function useAutostart(): void {
  const startupChannelPlay = usePlayStartupChannel();
  const shouldAutoStart = useAtomValue(shouldPlayerAutostartAtom);

  useEffect(
    function setStreamOnLoad() {
      if (shouldAutoStart) {
        startupChannelPlay("autostart");
      }
    },
    [shouldAutoStart, startupChannelPlay],
  );
}
