import type { ReactElement } from "react";

import { type Icons, icons } from "./icons";

type Props = {
  name: Icons;
  width?: number;
  height?: number;
} & CommonProps;

function Icon({
  className = "",
  name,
  "data-testid": dataTestId,
  width = 24,
  height = 24,
}: Props): ReactElement | null {
  const Component = icons[name];
  if (!Component) return null;

  return (
    <Component
      aria-hidden="true"
      className={className}
      data-testid={dataTestId}
      height={height}
      width={width}
    />
  );
}

export { Icon };
