import { atomEffect } from "jotai-effect";

import { isLegacyBackendAtom } from "@sunrise/backend-core";

import { legacySocketAtom } from "./legacy-socket.atom";

export const socketLogUnhandledEffect = atomEffect((get) => {
  if (!get(isLegacyBackendAtom)) {
    return;
  }

  const ws = get(legacySocketAtom);

  if (!ws) {
    return;
  }

  ws.on("unhandled", (parsed) => {
    console.log("unhandled message from server:", parsed);
  });
});
