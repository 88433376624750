import areEqual from "fast-deep-equal";
import { atom } from "jotai";
import { atomFamily } from "jotai/utils";

import type { MappedAsset } from "@sunrise/asset";
import type {
  AssetId,
  ChannelId,
  EPGEntryId,
  RecordingGroupId,
  RecordingId,
  TimeISOString,
} from "@sunrise/backend-types-core";
import type { Nullable } from "@sunrise/utils";
import { channelByIdAtom } from "@sunrise/yallo-channel-group";
import { epgEntryByIdAtom } from "@sunrise/yallo-epg";
import { recordingByIdNgAtom } from "@sunrise/yallo-recordings";

import { assetDetailsByIdAtom } from "./asset-details-by-id.atom";
import { getPrefixAndSubtitle } from "./helpers/get-prefix-and-subtitle";

export type CoreDetailsProps =
  | { recordingGroupId: RecordingGroupId; assetId: AssetId }
  | { recordingId: RecordingId; assetId: AssetId }
  | {
      recordingId: RecordingId;
      recordingGroupId: RecordingGroupId;
      assetId: AssetId;
    }
  | { epgId: EPGEntryId; assetId: AssetId }
  | { assetId: AssetId };

type CoreDetailReturn = {
  title: string;
  subtitle?: string | ReturnType<typeof getPrefixAndSubtitle>;
  description?: string;
  channelId?: Nullable<ChannelId>;
  channelName?: Nullable<string>;
  channelLogo?: Nullable<string>;
  start?: Nullable<TimeISOString>;
  end?: Nullable<TimeISOString>;
  posterBlurHash?: Nullable<string>;
  posterPath?: Nullable<string>;
  seriesId?: Nullable<AssetId>;
  type?: MappedAsset["type"] | ("recording" | "recording_group");
};

export const coreDetailsAtom = atomFamily(
  (args: CoreDetailsProps) =>
    atom<Promise<Nullable<CoreDetailReturn>>>(async (get) => {
      // details
      if ("epgId" in args) {
        const asset = await get(assetDetailsByIdAtom(args.assetId));
        if (!asset) return null;

        const commonFields = {
          id: asset.id,
          description: asset.plot ?? undefined,
          posterBlurHash: asset.posterBlurHash ?? null,
          posterPath: asset.posterPath,
          seriesId: asset.seriesId, // TODO
          type: asset.type,
        };

        if (args.epgId && args.epgId !== "series") {
          const { epgId } = args;
          const epg = await get(epgEntryByIdAtom(epgId));
          const channel = epg.data
            ? await get(channelByIdAtom(epg.data.channel.id))
            : null;

          if (!channel || !epg.data) return null;

          const prefixAndSubtitle = getPrefixAndSubtitle(
            asset?.seasonNumber,
            asset?.episodeNumber,
            asset?.subtitle ?? epg.data?.asset.subtitle ?? "",
          );

          return {
            ...commonFields,
            title: asset.title ?? epg.data?.asset.title ?? "",
            subtitle:
              prefixAndSubtitle ??
              asset.subtitle ??
              epg.data?.asset.subtitle ??
              undefined,
            channelId: channel.id,
            channelName: channel.name,
            channelLogo: channel.logo,
            start: epg.data.actualStart,
            end: epg.data.actualEnd,
          };
        }

        // epg series
        else {
          const prefixAndSubtitle = getPrefixAndSubtitle(
            asset?.seasonNumber,
            asset?.episodeNumber,
            asset?.subtitle ?? "",
          );

          const result = {
            ...commonFields,
            title: asset.title ?? "",
            subtitle: prefixAndSubtitle ?? asset.subtitle,
          };

          return result;
        }
      }

      if (!("recordingId" in args || "recordingGroupId" in args)) {
        return;
      }

      // Single recording and Recording Group
      const recordingId =
        "recordingId" in args ? args.recordingId : args.recordingGroupId;
      const recording = (await get(recordingByIdNgAtom(recordingId))).data;

      if (!recording || !recording.id) return null;

      const title = recording.asset.title;
      const subtitle = recording.asset.subtitle
        ? recording.asset.subtitle !== title
          ? recording.asset.subtitle
          : undefined
        : undefined;
      const isSingleRecording = recording.type === "recording";

      return {
        title,
        subtitle,
        channelId: isSingleRecording
          ? ((recording.channel_id as ChannelId) ?? null)
          : null,
        channelName: isSingleRecording ? recording.channel_name : null,
        channelLogo: isSingleRecording ? recording.channel_logo : null,
        description: recording.asset.description ?? undefined,
        start: isSingleRecording ? recording.epg_start : null,
        end: isSingleRecording ? recording.epg_end : null,
        posterBlurHash: recording.asset.poster_blurhash ?? null,
        posterPath: recording.asset.poster_url ?? null,
        seriesId: null,
        type: recording.type,
      };
    }),
  areEqual,
);
