import type { PropsWithChildren } from "react";
import * as Select from "@radix-ui/react-select";
import * as ToggleGroup from "@radix-ui/react-toggle-group";
import clsx from "clsx";

import { Icon } from "@sunrise/icons";

import buttonStyles from "../button/button.module.css";
import styles from "./filter-chip.module.css";

export const Chip = ({
  children,
  ...props
}: ToggleGroup.ToggleGroupItemProps) => (
  <ToggleGroup.Item
    className={clsx(buttonStyles.button, buttonStyles.outlined, styles.button)}
    {...props}
  >
    {children}
  </ToggleGroup.Item>
);

type FilterChipExpandableProps = {
  buttonText?: string;
};

export const Expandable = ({
  children,
  buttonText = "",
}: PropsWithChildren<FilterChipExpandableProps>) => (
  <Select.Root>
    <Select.Trigger
      className={clsx(
        buttonStyles.button,
        buttonStyles.outlined,
        styles.button,
      )}
    >
      {buttonText} <Icon className={styles.chevronDownIcon} name="dropDown" />
    </Select.Trigger>
    <Select.Portal>
      <Select.Content className={styles.selectContent} position="popper">
        <Select.ScrollUpButton>
          <Icon className={styles.chevronDownIcon} name="chevronUp" />
        </Select.ScrollUpButton>
        <Select.Viewport className={styles.selectViewport}>
          {children}
        </Select.Viewport>
      </Select.Content>
    </Select.Portal>
  </Select.Root>
);
