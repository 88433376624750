import type { Nullable } from "./nullable.types";

const URL_WITH_PROTOCOL_REGEX = /(https?:\/\/[^\s)\]]+)/g;
const REMOVE_PROTOCOL_REGEX = /(^\w+:|^)\/\//;

export type TextOrLinkPart = { text: string } & (
  | {
      type: "text";
    }
  | { type: "url"; url: string }
);

export const getTextWithLinkParts = (
  text: Nullable<string>,
): TextOrLinkPart[] => {
  if (!text) {
    return [];
  }

  const urlRegex = new RegExp(URL_WITH_PROTOCOL_REGEX);
  const parts = text.split(urlRegex).filter((part) => !!part);

  return parts.map((part) => {
    if (urlRegex.test(part)) {
      return {
        type: "url",
        url: part,
        text: beautifyUrl(part),
      };
    }
    return {
      type: "text",
      text: part,
    };
  });
};

const beautifyUrl = (url: string): string => {
  const regex = new RegExp(REMOVE_PROTOCOL_REGEX);
  return url.replace(regex, "");
};
