export function parseEnvValueAsFeatureValue(value?: string) {
  if (typeof value === "undefined" || value === "null") {
    return null;
  }

  if (value === "true") {
    return true;
  }

  if (value === "false") {
    return false;
  }

  // Try to parse as a number
  const numberValue = Number(value);
  if (!isNaN(numberValue)) {
    return numberValue;
  }

  // When it looks like an object or array, parse it.
  if (
    (value?.startsWith("{") && value?.endsWith("}")) ||
    (value?.startsWith("[") && value?.endsWith("]"))
  ) {
    try {
      return JSON.parse(value);
    } catch (_e) {
      console.error("Could not parse value as JSON", value);
      return null;
    }
  }

  return value ?? null;
}
