import { atomFamily } from "jotai/utils";

import { pagedAtom } from "@sunrise/backend-ng-core";
import type { EPGEntryId } from "@sunrise/backend-types-core";

import { alternateAiringsByEpgIdAtom } from "./alternate-airings-by-epg-id.atom";

/**
 * Alternate airings of an episode of a seriesepisode or program
 *
 */
export const alternateAiringsByEpgIdPagedAtom = atomFamily(
  (epgId: EPGEntryId) => pagedAtom(alternateAiringsByEpgIdAtom(epgId)),
);
