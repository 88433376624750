import type { ReactElement } from "react";

import { useTranslatable } from "@sunrise/translator";
import { getTextWithLinkParts, type TextOrLinkPart } from "@sunrise/utils";
import { Link, Text } from "@sunrise/yallo-web-components";

import styles from "./settings-terms-of-use.module.css";

export const SettingsTermsOfUse = () => {
  const t = useTranslatable();
  const parts = getTextWithLinkParts(t({ key: "settings_terms_of_use_text" }));

  return (
    <div className={styles.container}>
      {parts.map(
        (part: TextOrLinkPart, index: number): ReactElement =>
          part.type === "url" ? (
            <Link key={index} href={part.url} target="_blank" variant="none">
              {part.text}
            </Link>
          ) : (
            <Text key={index}>{part.text}</Text>
          ),
      )}
    </div>
  );
};
