import type { RecordingStatusUpdate } from "@sunrise/backend-types";
import { atomWithArrayUpdates } from "@sunrise/utils";

/**
 * An atom to represent the socket's knowledge of the recording statuses.
 * Whenever we write a status to it we will either add it or update it.
 *
 * The knowledge also contains recordingIds that have been deleted or cancelled.
 * We still want to emit that data because other atoms that depend on this will need
 * to know to remove that data from other sources as well.
 *
 * The ng websocket updates will be able to satisfy this API. So we can depend on it as-is for now.
 *
 * TODO: Replace this with 2 atoms that are both maps. One map per epgEntryId, one map per recordingId.
 *       We need the socket system to push updates to both maps so that the maps are not rebuilt for every socket update but just updated with what's new.
 *       If there's a record by id and the value is null it means the recording is known to be deleted.
 *       If there's no record by id there's no info and we need to look up the status through another mechanism.
 */
export const recordingStatusSocketAtom =
  atomWithArrayUpdates<RecordingStatusUpdate>([], {
    compareFn: (a, b) =>
      !!(a.recordingId === b.recordingId || (a.epgId && a.epgId === b.epgId)),
    debugLabel: "recordingStatusSocket",
  });
