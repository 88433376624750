import type { MappedChannel } from "@sunrise/yallo-channel-group";

import type { GuideChannel } from "../guide.types";

export function channelToEmptyGuideChannel(
  channel: MappedChannel,
): Readonly<GuideChannel> {
  return {
    id: channel.id,
    name: channel.name,
    logo: channel.logo,
    channelNumber: channel.order,
    items: [],
  };
}
