import type { Atom } from "jotai";
import { atom } from "jotai";
import { loadable } from "jotai/utils";
import { atomEffect } from "jotai-effect";

/**
 * The idea is that the underling promise's output will never flip to undefined while it is loading.
 * We should return the last known result while it is loading.
 */
export const createStableAtom = <T>(sourceAtom: Atom<Promise<T>>) => {
  const value = atom<T | null>(null);

  const updateEffect = atomEffect((get, set) => {
    const promise = get(sourceAtom);
    if (!promise) {
      set(value, null);
      return;
    }
    const result = get(loadable(sourceAtom));
    switch (result.state) {
      case "hasData":
        set(value, result.data);
        break;
      case "hasError":
        set(value, null);
        break;
    }
  });

  return atom((get) => {
    get(updateEffect);

    return get(value);
  });
};
