import clsx from "clsx";

import { useTranslator } from "@sunrise/translator";
import type { Nullable } from "@sunrise/utils";

import { Text } from "../text";
import styles from "./recording-delete-warning.module.css";

type RecordingDeleteWarningProps = {
  expiresIn?: Nullable<number>; // days
} & Pick<CommonProps, "className">;

export const RecordingDeleteWarning = ({
  expiresIn,
  className,
}: RecordingDeleteWarningProps) => {
  const t = useTranslator();

  const expiresSoon = expiresIn != null && expiresIn <= 10;
  if (!expiresSoon) return;

  return (
    <Text
      className={clsx(styles.text, className)}
      size="small"
      variant="label"
      underline
    >
      {expiresIn > 1
        ? t("recordings_deleted_days", [expiresIn])
        : t("recordings_deleted_day", [expiresIn])}
    </Text>
  );
};
