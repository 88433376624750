import { atom } from "jotai";

import {
  selectorGuideSelection,
  selectorOffsetPriority,
} from "../store/grid-state.selectors";

/**
 * This atom will determine if the guide needs an automatic selection.
 *
 * This will only happen when there's no selection present and we are not in date-offset priority mode.
 */
export const guideNeedsAutoSelectionAtom = atom((get) => {
  const selection = get(selectorGuideSelection);

  if (selection) {
    return;
  }

  return get(selectorOffsetPriority) !== "select-date";
});
