import {
  DEFAULT_LANGUAGE,
  type Language,
  languages,
} from "@sunrise/backend-types-core";

export function getBrowserLanguage(language: string): Language {
  const userLanguage = language.split("-")[0] as Language | undefined;

  if (userLanguage && languages.includes(userLanguage)) {
    return userLanguage;
  }

  return DEFAULT_LANGUAGE as Language;
}
