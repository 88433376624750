import { atom } from "jotai";

import type { Nullable } from "@sunrise/utils";
import type { MappedEpg } from "@sunrise/yallo-epg";
import {
  epgEntryByIdAtom,
  getEpgEntryPlayingAtTimeOnChannel,
} from "@sunrise/yallo-epg";
import { recordingByRecordingIdAtom } from "@sunrise/yallo-recordings";

import { selectPlayerCurrentDateTimePerMinute } from "./select-player-current-date-time-per-minute";
import { selectPlayerCurrentPlayRequest } from "./selectors";

/**
 * Returns the EPG item that is currently playing inside the player.
 * Works for EPG/linear-based streams and recordings.
 *
 * This does NOT take seeking into account.
 *
 * TODO: We need to scope the output to only return whant the UI needs. We should not return a complete EpgEntry object.
 *       Instead we should return data which can also come from the recording endpoint.
 */
export const playerCurrentEpgItemAtom = atom<Promise<Nullable<MappedEpg>>>(
  async (get) => {
    const playRequest = get(selectPlayerCurrentPlayRequest);
    if (!playRequest) return null;

    if (playRequest.type === "recording") {
      const recording = await get(
        recordingByRecordingIdAtom(playRequest.recordingId),
      );

      if (!recording || recording.type === "group" || !recording.epgEntryId)
        return null;

      // TODO: This should not happen when we have a recording.
      return (await get(epgEntryByIdAtom(recording.epgEntryId))).data;
    }

    const currentTime = get(selectPlayerCurrentDateTimePerMinute);
    // There's a guard to not blow up stuff when we somehow get an incorrect date.
    // Stuff from 1970 is probably not something that aired.
    if (!currentTime || currentTime.getFullYear() === 1970) return null;

    return get(
      getEpgEntryPlayingAtTimeOnChannel({ channelId: playRequest.channelId }),
    )(currentTime);
  },
);
