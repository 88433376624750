import { addDays } from "date-fns";
import { atomFamily } from "jotai/utils";
import { atomWithSuspenseQuery } from "jotai-tanstack-query";
import { isNil } from "lodash";

import { ngEpgApiAtom } from "@sunrise/backend-ng-epg";
import { queryKeys } from "@sunrise/backend-types";
import type {
  ChannelId,
  TimeDay,
  TimeISOString,
} from "@sunrise/backend-types-core";
import { currentLanguageAtom } from "@sunrise/i18n";
import { dateToTimeDay } from "@sunrise/time";
import type { Nullable } from "@sunrise/utils";

import type { MappedEpg } from "../types";
import { mapBackendEpgNg } from "./map-backend-epg.ng";

const PAGE_SIZE = 50;

/**
 * Loads all the pages immediately from the backend and resolves as soon as all pages are downloaded.
 */
export const epgsCollectionByChannelIdNgAtom = atomFamily(
  ({ channelId, day }: { channelId: ChannelId; day: TimeDay }) =>
    atomWithSuspenseQuery<Nullable<MappedEpg[]>>((get) => {
      const ngApi = get(ngEpgApiAtom);
      const language = get(currentLanguageAtom);

      return {
        enabled: !isNil(channelId),
        queryKey: queryKeys.channelEpgCollection(channelId, language, day),
        queryFn: async () => {
          if (!channelId) return null;

          const getPage = async (page: number) => {
            return ngApi.epg.epgEntryByChannelEpgV1ChannelsChannelIdEpgEntriesGet(
              channelId,
              {
                page,
                size: PAGE_SIZE,
                // from BE perspective, these are date-time params (that is, full iso spec) but internally
                // we make an alternate representation just to express that it's formatted using only
                // the yyy-mm-dd initial part. The two are compatible, so it's safe to to this casting here for now
                start: day as unknown as TimeISOString,
                end: dateToTimeDay(
                  addDays(new Date(day), 1),
                ) as unknown as TimeISOString,
              },
            );
          };

          let limit = false;
          let page = 1;
          const results = [];
          do {
            const query = await getPage(page);
            limit = query.data.pages ? query.data.pages <= page : true;
            results.push(...query.data.items.map(mapBackendEpgNg));
            page += 1;
          } while (!limit);

          return results;
        },
        staleTime: Infinity,
        cacheTime: Infinity,
      };
    }),
);
