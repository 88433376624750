import { areEqual } from "react-window";
import { atomFamily } from "jotai/utils";
import { atomWithMutation } from "jotai-tanstack-query";
import { isNil } from "lodash";

import { hostsAtom, httpClientAtom } from "@sunrise/http-client";

import { logout } from "./auth.service";

export const logoutFromKaminoMutationAtom = atomFamily(
  ({ logoutAllDevices }: { logoutAllDevices: boolean }) =>
    atomWithMutation((get) => {
      return {
        mutationKey: ["log-out-from-kamino", logoutAllDevices],
        mutationFn: async () => {
          const host = get(hostsAtom).api;
          const httpClient = get(httpClientAtom);

          if (isNil(host)) throw new Error("Host is not set");
          if (isNil(httpClient.privateApi))
            throw new Error("PrivateApi not initialized");

          return await logout(host, httpClient.privateApi, logoutAllDevices);
        },
      };
    }),
  areEqual,
);
