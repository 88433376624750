/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** EmptyAdEventSchema */
export interface EmptyAdEventSchema {
  /**
   * Client Timestamp
   * Timestamp in milliseconds attached by the client when creating the event/state
   */
  client_timestamp?: number | null;
  /** Remote User */
  remote_user?: string | null;
  /** Device Type Id */
  device_type_id?: string | null;
  /** Token Tracking Id */
  token_tracking_id?: string | null;
  /** Device Ads Id */
  device_ads_id?: string | null;
  /** Object Type */
  object_type: "video_ad_event";
  /** Ad Id */
  ad_id: string;
  /** Event Type */
  event_type: "empty";
}

/** EndAdEventSchema */
export interface EndAdEventSchema {
  /**
   * Client Timestamp
   * Timestamp in milliseconds attached by the client when creating the event/state
   */
  client_timestamp?: number | null;
  /** Remote User */
  remote_user?: string | null;
  /** Device Type Id */
  device_type_id?: string | null;
  /** Token Tracking Id */
  token_tracking_id?: string | null;
  /** Device Ads Id */
  device_ads_id?: string | null;
  /** Object Type */
  object_type: "video_ad_event";
  /** Ad Id */
  ad_id: string;
  /** Event Type */
  event_type: "end";
}

/** ErrorAdEventSchema */
export interface ErrorAdEventSchema {
  /**
   * Client Timestamp
   * Timestamp in milliseconds attached by the client when creating the event/state
   */
  client_timestamp?: number | null;
  /** Remote User */
  remote_user?: string | null;
  /** Device Type Id */
  device_type_id?: string | null;
  /** Token Tracking Id */
  token_tracking_id?: string | null;
  /** Device Ads Id */
  device_ads_id?: string | null;
  /** Object Type */
  object_type: "video_ad_event";
  /** Ad Id */
  ad_id: string;
  /** Event Type */
  event_type: "error";
}

/** HTTPValidationError */
export interface HTTPValidationError {
  /** Detail */
  detail?: ValidationError[];
}

/** PauseStateEventSchema */
export interface PauseStateEventSchema {
  /**
   * Client Timestamp
   * Timestamp in milliseconds attached by the client when creating the event/state
   */
  client_timestamp?: number | null;
  /** Remote User */
  remote_user?: string | null;
  /** Device Type Id */
  device_type_id?: string | null;
  /** Token Tracking Id */
  token_tracking_id?: string | null;
  /** Device Ads Id */
  device_ads_id?: string | null;
  /**
   * Epg Entry Id
   * The ID of the epg entry attached to the state
   */
  epg_entry_id?: string | null;
  /**
   * Recording Id
   * The ID of the recording attached to the state
   */
  recording_id?: string | null;
  /**
   * Player Audio Language
   * The language of the content
   */
  player_audio_language?: string | null;
  /**
   * Player Subtitle Language
   * Player subtitle language
   */
  player_subtitle_language?: string | null;
  /** Content type of what the client is playing */
  player_content_type: PlayerContentType;
  /**
   * Player Content Provider
   * Provider of content, e.g. Zattoo
   */
  player_content_provider: string;
  /**
   * Player Position
   * Position of the player in ISO format
   */
  player_position: string;
  /**
   * User language
   * @default "de"
   */
  user_language?: ValidLanguages | null;
  /** Object Type */
  object_type: "player_state";
  /** Event Type */
  event_type: "pause";
}

/** PlayStateEventSchema */
export interface PlayStateEventSchema {
  /**
   * Client Timestamp
   * Timestamp in milliseconds attached by the client when creating the event/state
   */
  client_timestamp?: number | null;
  /** Remote User */
  remote_user?: string | null;
  /** Device Type Id */
  device_type_id?: string | null;
  /** Token Tracking Id */
  token_tracking_id?: string | null;
  /** Device Ads Id */
  device_ads_id?: string | null;
  /**
   * Epg Entry Id
   * The ID of the epg entry attached to the state
   */
  epg_entry_id?: string | null;
  /**
   * Recording Id
   * The ID of the recording attached to the state
   */
  recording_id?: string | null;
  /**
   * Player Audio Language
   * The language of the content
   */
  player_audio_language?: string | null;
  /**
   * Player Subtitle Language
   * Player subtitle language
   */
  player_subtitle_language?: string | null;
  /** Content type of what the client is playing */
  player_content_type: PlayerContentType;
  /**
   * Player Content Provider
   * Provider of content, e.g. Zattoo
   */
  player_content_provider: string;
  /**
   * Player Position
   * Position of the player in ISO format
   */
  player_position: string;
  /**
   * User language
   * @default "de"
   */
  user_language?: ValidLanguages | null;
  /** Object Type */
  object_type: "player_state";
  /** Event Type */
  event_type: "play";
}

/** PlayerContentType */
export enum PlayerContentType {
  Recording = "recording",
  Replay = "replay",
  Live = "live",
}

/** SkippedAdEventSchema */
export interface SkippedAdEventSchema {
  /**
   * Client Timestamp
   * Timestamp in milliseconds attached by the client when creating the event/state
   */
  client_timestamp?: number | null;
  /** Remote User */
  remote_user?: string | null;
  /** Device Type Id */
  device_type_id?: string | null;
  /** Token Tracking Id */
  token_tracking_id?: string | null;
  /** Device Ads Id */
  device_ads_id?: string | null;
  /** Object Type */
  object_type: "video_ad_event";
  /** Ad Id */
  ad_id: string;
  /** Event Type */
  event_type: "skipped";
}

/** StartAdEventSchema */
export interface StartAdEventSchema {
  /**
   * Client Timestamp
   * Timestamp in milliseconds attached by the client when creating the event/state
   */
  client_timestamp?: number | null;
  /** Remote User */
  remote_user?: string | null;
  /** Device Type Id */
  device_type_id?: string | null;
  /** Token Tracking Id */
  token_tracking_id?: string | null;
  /** Device Ads Id */
  device_ads_id?: string | null;
  /** Object Type */
  object_type: "video_ad_event";
  /** Ad Id */
  ad_id: string;
  /** Event Type */
  event_type: "start";
}

/** StopStateEventSchema */
export interface StopStateEventSchema {
  /**
   * Client Timestamp
   * Timestamp in milliseconds attached by the client when creating the event/state
   */
  client_timestamp?: number | null;
  /** Remote User */
  remote_user?: string | null;
  /** Device Type Id */
  device_type_id?: string | null;
  /** Token Tracking Id */
  token_tracking_id?: string | null;
  /** Device Ads Id */
  device_ads_id?: string | null;
  /**
   * Epg Entry Id
   * The ID of the epg entry attached to the state
   */
  epg_entry_id?: string | null;
  /**
   * Recording Id
   * The ID of the recording attached to the state
   */
  recording_id?: string | null;
  /**
   * Player Audio Language
   * The language of the content
   */
  player_audio_language?: string | null;
  /**
   * Player Subtitle Language
   * Player subtitle language
   */
  player_subtitle_language?: string | null;
  /** Content type of what the client is playing */
  player_content_type: PlayerContentType;
  /**
   * Player Content Provider
   * Provider of content, e.g. Zattoo
   */
  player_content_provider: string;
  /**
   * Player Position
   * Position of the player in ISO format
   */
  player_position: string;
  /**
   * User language
   * @default "de"
   */
  user_language?: ValidLanguages | null;
  /** Object Type */
  object_type: "player_state";
  /** Event Type */
  event_type: "stop";
}

/** ValidLanguages */
export enum ValidLanguages {
  En = "en",
  De = "de",
  Fr = "fr",
  It = "it",
}

/** ValidationError */
export interface ValidationError {
  /** Location */
  loc: (string | number)[];
  /** Message */
  msg: string;
  /** Error Type */
  type: string;
}

import { ContentType, HttpClient, RequestParams } from "@sunrise/backend-ng-core";

/**
 * @title FastAPI
 * @version 0.1.0
 */
export class GeneratedApi {
  http: HttpClient;

  constructor(http: HttpClient) {
    this.http = http;
  }

  event = {
    /**
     * No description
     *
     * @tags events
     * @name PostEventEventV1EventsPost
     * @summary Post Event
     * @request POST:/event/v1/events
     * @secure
     */
    postEventEventV1EventsPost: (
      data:
        | StartAdEventSchema
        | EndAdEventSchema
        | EmptyAdEventSchema
        | SkippedAdEventSchema
        | ErrorAdEventSchema
        | StopStateEventSchema
        | PlayStateEventSchema
        | PauseStateEventSchema,
      params: RequestParams = {},
    ) =>
      this.http.request<void, void | HTTPValidationError>({
        path: `/event/v1/events`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name HealthEventV1HealthGet
     * @summary Health
     * @request GET:/event/v1/health
     */
    healthEventV1HealthGet: (params: RequestParams = {}) =>
      this.http.request<any, any>({
        path: `/event/v1/health`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name RootEventV1Get
     * @summary Root
     * @request GET:/event/v1
     */
    rootEventV1Get: (params: RequestParams = {}) =>
      this.http.request<any, any>({
        path: `/event/v1`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
}
