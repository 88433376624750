import { atom } from "jotai";

import type { SimpleStream, Stream } from "@sunrise/backend-types";
import { nowAtom } from "@sunrise/time";
import type { Nullable } from "@sunrise/utils";

import { selectPlayerCurrentStream } from "../selectors";
import type { Thumbnail, ThumbnailGenerator } from "./thumbnail-generator.atom";
import { THUMBNAIL_POSITION_PADDING } from "./thumbnail-generator.atom";

// ms - If the requested thumbnail is too close to the live edge, we need to add a padding to make sure the thumbnail is not blank.
const THUMBNAIL_LIVE_EDGE = 1_000;

export const hls7ManualThumbnailGeneratorAtom = atom<
  Promise<ThumbnailGenerator | null>
>(async (get) => {
  const stream = get(selectPlayerCurrentStream);

  if (!stream) {
    return null;
  }

  return createHls7ManualThumbnailGenerator(stream, () => get(nowAtom));
});

/**
 * This can accurately return thumbnails even for:
 * - live streams.
 * - part of the loaded replay stream.
 * - recorded streams.
 *
 * This is responsible for parsing the .m3u8 manifest file and extract the thumbnails (if available).
 * The thumbnails are then stored in a Map<timestamp_in_ms, HlsThumbnail>.
 *  * It follows the spec documented:
 * {@link https://developer.roku.com/docs/developer-program/media-playback/trick-mode/hls-and-dash.md}
 *
 * @param stream
 * @param playRequest
 * @param getNowDate
 * @returns
 */
async function createHls7ManualThumbnailGenerator(
  stream: Nullable<Stream>,
  getNowDate: () => Date,
): Promise<ThumbnailGenerator | null> {
  if (!stream || (stream.type !== "hls7" && stream.type !== "hls7_fairplay")) {
    return null;
  }

  try {
    const config = await parseManifestConfig(stream.url, stream.provider);

    if (!config) {
      return null;
    }

    const tileWidth = config.width / config.tilesX;
    const tileHeight = config.height / config.tilesY;
    const totalTiles = config.tilesX * config.tilesY;

    return {
      name: "hls7-manual",
      generate: async (offsetPosition: number): Promise<Thumbnail | null> => {
        if (offsetPosition < 0) {
          // TODO: theoretically we could load the live manifest and then calculate the thumbnail from there.
          return null;
        }

        let position = offsetPosition * 1_000;

        // NOTE: Make sure the thumbnail at LIVE position will not be blank sometimes, else add position padding
        if (getNowDate().getTime() - position < THUMBNAIL_LIVE_EDGE) {
          position -= THUMBNAIL_LIVE_EDGE;
        } else {
          position += THUMBNAIL_POSITION_PADDING * 1_000;
        }

        const matrixPosition = position - (position % config.duration);

        const computedUrl = config.url.replace(
          "$Time$",
          matrixPosition.toString(),
        );

        const tileDuration = config.duration / totalTiles;
        const tilePosition = Math.floor(
          (position - matrixPosition) / tileDuration,
        );

        let tileX = 0;
        let tileY = 0;

        if (totalTiles > 1) {
          tileX = (tilePosition % config.tilesX) * tileWidth;
          tileY = Math.floor(tilePosition / config.tilesX) * tileHeight;
        }

        return {
          url: computedUrl,
          fullHeight: config.height,
          fullWidth: config.width,
          width: tileWidth,
          height: tileHeight,
          x: tileX,
          y: tileY,
        };
      },
    };
  } catch {
    return null;
  }
}

async function getManifestFile(src: string): Promise<string> {
  const response = await fetch(src);
  return response.text();
}

async function parseManifestConfig(
  manifestUrl: string,
  provider: SimpleStream["provider"],
) {
  try {
    const baseManifestUrl = manifestUrl.substring(
      0,
      manifestUrl.lastIndexOf("/"),
    );
    const videoManifest = await getManifestFile(manifestUrl);
    const imageTrack = /#EXT-X-IMAGE-STREAM-INF:.*URI="(.*)"/.exec(
      videoManifest,
    )?.[1];

    if (!imageTrack) {
      return;
    }
    const isAbsoluteUrl = imageTrack.indexOf("https://");
    const imageManifestUrl =
      isAbsoluteUrl > 0 ? imageTrack : `${baseManifestUrl}/${imageTrack}`;

    if (provider === "greenstreams") {
      const imageTrackBase = imageTrack.substring(
        0,
        imageTrack.lastIndexOf("/"),
      );
      return parseThumbnailManifest(
        `${baseManifestUrl}/${imageTrackBase}`,
        imageManifestUrl,
        provider,
      );
    } else {
      return parseThumbnailManifest(
        baseManifestUrl,
        imageManifestUrl,
        provider,
      );
    }
  } catch {
    return null;
  }
}

async function parseThumbnailManifest(
  baseManifestUrl: string,
  imageManifestUrl: string,
  provider: SimpleStream["provider"],
) {
  const imageManifest = await getManifestFile(imageManifestUrl);
  const regexpNames = /#EXT-X-TILES:(.*)[\r\n]+(.*)/g;
  try {
    const match = regexpNames.exec(imageManifest);
    const tileProps = match?.[1] ?? "";
    const tileUri = match?.[2] ?? "";

    const url = `${baseManifestUrl}/${tileUri}`;

    let templateUrl;
    if (provider === "greenstreams") {
      templateUrl = url.replace(/-([\d+]*?).hls/, "-$Time$.hls");
    } else {
      templateUrl = url.replace(/ts_(.*?)_/, "ts_$Time$_");
    }

    const resolutionString = /RESOLUTION=(.*?)($|,)/.exec(tileProps)?.[1];
    const layoutString = /LAYOUT=(.*?)($|,)/.exec(tileProps)?.[1];
    const durationString = /DURATION=(.*?)($|,)/.exec(tileProps)?.[1];
    if (!resolutionString || !layoutString || !durationString) {
      // todo throw error
      return;
    }

    const [width, height] = resolutionString
      .split("x")
      .map((value) => parseFloat(value));
    const [tilesX, tilesY] = layoutString
      .split("x")
      .map((value) => parseFloat(value));

    let duration;
    if (provider === "greenstreams") {
      duration = Math.floor(parseFloat(durationString));
    } else {
      duration = Math.floor(parseFloat(durationString) * 1000);
    }

    return {
      url: templateUrl,
      duration: duration,
      width: width * tilesX,
      height: height * tilesY,
      tilesX: tilesX,
      tilesY: tilesY,
    };
  } catch (e) {
    console.warn(e);
    return null;
  }
}
