import { atomFamily } from "jotai/utils";
import { atomWithSuspenseQuery } from "jotai-tanstack-query";
import { isNil } from "lodash";

import { queryKeys } from "@sunrise/backend-types";
import type { ChannelId, TimeDay } from "@sunrise/backend-types-core";
import { hostsAtom } from "@sunrise/http-client";
import { currentLanguageAtom } from "@sunrise/i18n";
import type { Nullable } from "@sunrise/utils";

import type { MappedEpg } from "../types";
import { fetchSingleChannelEPGsPerEntireDay } from "./epg.service";
import { mapBackendEpgLegacy } from "./map-backend-epg.legacy";

export const channelEpgCollectionByIdLegacyAtom = atomFamily(
  ({ day, channelId }: { day: TimeDay; channelId: ChannelId }) =>
    atomWithSuspenseQuery<Nullable<MappedEpg[]>>((get) => {
      const host = get(hostsAtom).data;
      if (isNil(host)) throw new Error("Host is not set");
      const language = get(currentLanguageAtom);

      return {
        enabled: !isNil(channelId),
        queryKey: queryKeys.channelEpgCollection(channelId, language, day),
        queryFn: async () => {
          if (!channelId) return null;

          const collectionOfEpg = await fetchSingleChannelEPGsPerEntireDay(
            host,
            day,
            channelId,
          );

          return collectionOfEpg.map((entry) =>
            mapBackendEpgLegacy(entry, language),
          );
        },
        staleTime: Infinity,
        cacheTime: Infinity,
      };
    }),
);
