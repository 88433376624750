import type { ComponentProps, PropsWithChildren } from "react";
import { forwardRef } from "react";
import { clsx } from "clsx";

import { Icon } from "@sunrise/icons";

import { Text } from "../text";
import { VisuallyHidden } from "../visually-hidden";
import { Button } from "./button";
import styles from "./button.module.css";

type DropdownButtonProps = PropsWithChildren<{
  active?: boolean;
}> &
  Omit<ComponentProps<typeof Button>, "iconPosition" | "variant">;

const DropdownButton = forwardRef<HTMLButtonElement, DropdownButtonProps>(
  (props, ref) => {
    const { className, children, hideLabel, ...buttonProps } = props;

    const child = (
      <Text className={styles.label} size="large" variant="label">
        {children}
      </Text>
    );
    return (
      <Button
        ref={ref}
        {...buttonProps}
        className={clsx(className, styles.dropdown)}
        variant="chip"
      >
        {hideLabel ? <VisuallyHidden>{child}</VisuallyHidden> : child}
        <Icon className={styles.dropdownIcon} name="dropDown" />
      </Button>
    );
  },
);

export { DropdownButton };
