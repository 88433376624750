import { atom } from "jotai";

import { preferredBackendAtom } from "./preferred-backend.atom";

/**
 * This is a helper atom we should always use when we are adding code that should be deleted as part of the legacy removal process.
 */
export const isLegacyBackendAtom = atom<boolean>(
  (get) => get(preferredBackendAtom) === "legacy",
);
