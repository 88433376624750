import { atomWithMutation, queryClientAtom } from "jotai-tanstack-query";

import { ngSearchApiAtom } from "@sunrise/backend-ng-search";
import { queryKeys } from "@sunrise/backend-types";
import { selectJwtUserToken } from "@sunrise/jwt";

export const searchHistoryClearAllNgAtom = atomWithMutation((get) => {
  const api = get(ngSearchApiAtom);

  return {
    mutationKey: ["search-history-clear-all"],
    mutationFn: () =>
      api.search.deleteSearchHistorySearchV1SearchHistoryEntriesDelete(),
    onSuccess() {
      get(queryClientAtom).setQueryData(
        queryKeys.searchHistory(get(selectJwtUserToken), true),
        [],
      );
    },
  };
});
