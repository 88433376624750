import { addMinutes, subMinutes } from "date-fns";

import type { Schedulable } from "@sunrise/backend-types";
import type { ChannelId } from "@sunrise/backend-types-core";

import type { Coordinates } from "../guide.types";

export type ViewportNavigationDirection = "left" | "right" | "up" | "down";

/**
 * A class that can be used to calculate if a channel or program is in the viewport.
 * We can also ask it to give an appropriate offset to scroll to a channel or program given the direction we are travelling.
 */
export class ViewportCalculator {
  constructor(
    protected readonly channelToPixels: (channelId: ChannelId) => number,
    protected readonly timeToPixels: (time: Date) => number,
    protected readonly pixelsToTime: (pixels: number) => Date,
    protected readonly width: number,
    protected readonly height: number,
    protected readonly channelHeight: number,
    protected readonly coordinates: Coordinates,
    /**
     * This is how many minutes we lose on the left side of the grid due to the channelBar being rendered on top of the programs.
     */
    protected readonly bufferSizeInMinutes = 0,
  ) {}

  isChannelStartInViewport(channelId: ChannelId): boolean {
    const channelsPx = this.channelToPixels(channelId);

    return (
      channelsPx >= this.coordinates.y &&
      channelsPx < this.coordinates.y + (this.height - this.channelHeight)
    );
  }

  isProgramStartInViewport(program: Schedulable): boolean {
    return this.isTimeInViewport(program.startTime);
  }

  isProgramInViewport(program: Schedulable): boolean {
    return (
      this.isTimeInViewport(program.startTime) ||
      (this.isTimeBeforeViewport(program.startTime) &&
        this.isTimeAfterViewport(program.endTime))
    );
  }

  isTimeInViewport(time: Date, percent?: number): boolean {
    return this.isXInViewport(this.timeToPixels(time), percent ?? 0);
  }

  protected isXInViewport(x: number, paddingPercent?: number): boolean {
    const padding = paddingPercent ? (this.width * paddingPercent) / 100 : 0;

    return (
      x >= this.coordinates.x + padding &&
      x < this.coordinates.x + this.width - padding
    );
  }

  protected isTimeBeforeViewport(time: Date): boolean {
    return this.isXBeforeViewport(this.timeToPixels(time));
  }

  protected isXBeforeViewport(x: number): boolean {
    return x < this.coordinates.x;
  }

  protected isTimeAfterViewport(time: Date): boolean {
    return this.isXAfterViewport(this.timeToPixels(time));
  }

  protected isXAfterViewport(x: number): boolean {
    return x > this.coordinates.x + this.width;
  }

  /**
   *
   * @param program
   * @returns A date which is in the viewport on which we can scroll up / down.
   */
  getVerticalScrollAxis(program: Schedulable): Date {
    const date = new Date(
      program.startTime.getTime() +
        (program.endTime.getTime() - program.startTime.getTime()) / 2,
    );
    const pxl = this.timeToPixels(date);

    let time = date;
    // When the selected point is after the viewport, assume the start is actually in the viewport.
    if (
      this.isXAfterViewport(pxl) &&
      this.isTimeInViewport(program.startTime)
    ) {
      time = program.startTime;
    }

    // When the selected point is before the viewport, assume the end is actually in the viewport.
    if (this.isXBeforeViewport(pxl) && this.isTimeInViewport(program.endTime)) {
      time = program.endTime;
    }

    if (!this.isTimeInViewport(time)) {
      return this.pixelsToTime(this.coordinates.x + this.width / 2);
    }

    return time;
  }

  isInViewportForNavigationDirection(
    channelId: ChannelId,
    program: Schedulable,
    direction?: ViewportNavigationDirection,
  ): boolean {
    switch (direction) {
      case "up":
      case "down": {
        const channelPx = this.channelToPixels(channelId);

        const channelInViewPort = this.isChannelStartInViewport(channelId);

        if (direction === "up" && channelPx === this.coordinates.y) {
          // If it touches the top edge we do not consider the channel in view.
          return false;
        }

        if (
          direction === "down" &&
          channelPx + this.channelHeight * 2 >= this.coordinates.y + this.height
        ) {
          // If it is near the bottom edge we do not consider the channel in view.
          return false;
        }

        return channelInViewPort;
      }
      case "left":
      case "right": {
        // We are not in the viewport if the program's start time + buffer is not in the viewport.
        const startTime = addMinutes(
          program.startTime,
          this.bufferSizeInMinutes,
        );
        const isStartInViewport = this.isTimeInViewport(startTime);

        if (isStartInViewport) {
          return true;
        }

        const endTime = subMinutes(program.endTime, this.bufferSizeInMinutes);
        const isEndInViewport = this.isTimeInViewport(endTime);

        if (isEndInViewport) {
          return true;
        }

        // When both are outside the viewport, it is technically also in the viewport.
        // But only if the start is before the viewport and the end is after the viewport.
        return (
          this.isTimeBeforeViewport(startTime) &&
          this.isTimeAfterViewport(endTime)
        );
      }
      default:
        return (
          this.isChannelStartInViewport(channelId) &&
          this.isProgramStartInViewport(program)
        );
    }
  }

  /**
   * Function to determine what the new y coordinates should be offset-wise
   * given the channel we want to have in view and the direction we are going.
   *
   * The outcome should not be for the channelId that is in the direction we are going but it should be for the channel passed.
   * The direction just determines where the offset should be.
   * If we go up the offset should be more to the bottom.
   * If we go down the offset should be more at the top.
   *
   * NOTE: This does not care if the channel is in view or not.
   */
  private getVerticalGridOffset(
    channel: ChannelId,
    direction: "up" | "down",
  ): number {
    const channelPx = this.channelToPixels(channel);

    // For channels that are in the top, we want to keep them in view as long as possible.
    if (channelPx <= this.height - this.channelHeight * 2) {
      return 0;
    }

    return direction === "up"
      ? channelPx - this.channelHeight
      : channelPx - this.height + this.channelHeight * 2;
  }

  /**
   * Function to determine what the new x coordinates should be offset-wise.
   * Given the length of the current program and the direction we are going.
   *
   * When going right, we should naively try to put the end of the program + the buffer on the left side of the viewport.
   *
   * NOTE: This does not care if the program is actually in view or not.
   */
  private getHorizontalGridOffset(
    program: Schedulable,
    direction: "left" | "right",
  ): number {
    const scrollToOffsetPx =
      direction === "right"
        ? this.timeToPixels(
            subMinutes(program.startTime, this.bufferSizeInMinutes),
          )
        : this.timeToPixels(
            addMinutes(program.endTime, this.bufferSizeInMinutes),
          ) - this.width;

    if (scrollToOffsetPx < this.width * 0.7) {
      return 0;
    }

    return scrollToOffsetPx;
  }

  private getCenteredOffsetForProgram(program: Schedulable): number {
    const startXWithBuffer = this.timeToPixels(
      subMinutes(program.startTime, this.bufferSizeInMinutes),
    );
    const endX = this.timeToPixels(program.endTime);
    const diffWithStartBuffer = endX - startXWithBuffer;

    if (startXWithBuffer < this.width * 0.7) {
      return 0;
    }

    // When the program is short enough to fit in the grid w/ the buffer on it, we can center it.
    // Else, just put it on the left side of the grid with the buffer on it.
    if (diffWithStartBuffer > this.width) {
      return startXWithBuffer;
    }

    const startX = this.timeToPixels(program.startTime);
    const diff = endX - startX;
    return startX + (diff - this.width) / 2;
  }

  public getCenteredOffsetForDate(date: Date): number {
    return Math.max(this.timeToPixels(date) - this.width / 2, 0);
  }

  public getCenteredOffsetForOffset(offset: number): number {
    return Math.max(offset + this.width / 2, offset);
  }

  private getCenteredOffsetForChannel(channelId: ChannelId): number {
    const channelPx = this.channelToPixels(channelId);

    if (channelPx <= this.height - this.channelHeight * 2) {
      return 0;
    }

    return channelPx - this.height / 2;
  }

  /**
   * Will attempt to center the content in the grid.
   * It will also set an offset of 0 y if we are in one of the top channels.
   *
   * @param channelId
   * @param program
   */
  private getCenteredOffset(
    channelId: ChannelId,
    program: Schedulable,
  ): Coordinates {
    return {
      x: this.getCenteredOffsetForProgram(program),
      y: this.getCenteredOffsetForChannel(channelId),
    };
  }

  repositionOffsetForSelection(
    channelId: ChannelId,
    program: Schedulable,
    direction?: ViewportNavigationDirection,
  ): Coordinates {
    switch (direction) {
      case "left":
      case "right":
        return {
          x: this.getHorizontalGridOffset(program, direction),
          y: this.coordinates.y,
        };
      case "up":
      case "down":
        return {
          x: this.coordinates.x,
          y: this.getVerticalGridOffset(channelId, direction),
        };
      default:
        // When we know the width of the grid, the width of the program, the channel and the neight of the grid we can attempt to center it.
        return this.getCenteredOffset(channelId, program);
    }
  }

  /**
   * Do we need to scroll the viewport instead of select the next program in the given direction.
   * We do consider programs that are less or equal to 60min duration SHORT and adjust accordingly.
   */
  isProgramOverNextViewport(
    program: Schedulable,
    direction: "left" | "right",
  ): boolean {
    const duration =
      (program.endTime.getTime() - program.startTime.getTime()) / 1000 / 60;
    const isShortProgram = duration <= 60;

    if (direction === "right") {
      // when going right, we need to check if the program's end time with 15min (half) buffer
      // is after grid's edge (x coordinate + whole grid width) coordinate for short programs
      // or x coordinate AND 50% of next grid width for long programs (edge + 150% of width).
      return (
        this.timeToPixels(
          addMinutes(program.endTime, this.bufferSizeInMinutes / 2),
        ) >=
        this.coordinates.x + (isShortProgram ? this.width : this.width * 1.5)
      );
    }

    if (this.timeToPixels(program.startTime) <= 0) {
      return false;
    }

    // when going left, we need to check if the program's start time with 15min (half) buffer
    // is before x coordinate for short programs
    // or x coordinate AND 15% of previous grid width for long programs (edge - 15%).
    return (
      this.timeToPixels(
        subMinutes(program.startTime, this.bufferSizeInMinutes / 2),
      ) <=
      (isShortProgram
        ? this.coordinates.x
        : this.coordinates.x - this.width * 0.15)
    );
  }

  /**
   * Should we scroll over the viewport a bit to the left or the right, what would the new coordinates be.
   * We always scroll with 50% of the viewport width. Which is basically a little bit than half
   * since we also have the bufferSizeInMinutes which we are not taking into account here.
   */
  getCoordinatesForNextViewport(direction: "left" | "right"): Coordinates {
    return {
      x:
        this.coordinates.x +
        (direction === "right" ? 1 : -1) * (this.width * 0.5),
      y: this.coordinates.y,
    };
  }
}
