import { atomEffect } from "jotai-effect";

import { isLegacyBackendAtom } from "@sunrise/backend-core";
import type { EPGEntryId, RecordingId } from "@sunrise/backend-types-core";
import { selectIsLoggedIn } from "@sunrise/jwt";
import { legacySocketAtom, socketAtom } from "@sunrise/yallo-websocket";

import { flushRecordingsCacheAtom } from "./flush-recordings-cache.atom";
import { recordingStatsLegacyAtom } from "./legacy/recording-stats-legacy.atom";
import { recordingStatusLegacyAtom } from "./legacy/recording-status.legacy.atom";
import { recordingStatusSocketAtom } from "./recording-status-socket.atom";
import { recordingsMarkedForDeletionAtom } from "./recordings-marked-for-deletion.atom";

export const reactForRecordingsToSocketEffect = atomEffect((get, set) => {
  const cleanUpRecordingMarkedForDeletion = (recordingId: RecordingId) => {
    const current = get(recordingsMarkedForDeletionAtom);

    set(
      recordingsMarkedForDeletionAtom,
      // Remove the recordingId from the object.
      Object.fromEntries(
        Object.entries(current).filter(([k, _]) => k !== recordingId),
      ),
    );
  };

  if (get(isLegacyBackendAtom)) {
    const ws = get(legacySocketAtom);
    const isLoggedIn = get(selectIsLoggedIn);

    if (!ws || !isLoggedIn) {
      return;
    }

    // Emit the recording capacity (recording stats) to the store.
    ws.on("recording-capacity", (capacity) => {
      set(recordingStatsLegacyAtom, {
        used_recordings: capacity.used,
        available_recordings: capacity.available,
        capacity_recordings: capacity.capacity,
      });
    });

    // // Pass recording status updates to the store.
    ws.on("recording-status", (status) => {
      set(recordingStatusLegacyAtom, {
        epgId: status.epgEntryId as EPGEntryId,
        recordingId: status.recordingId as RecordingId,
        status: status.status,
      });

      // recording deletion was completed, update the marked atom
      // NOTE: By doing this we need to make sure to supporess recordings
      //       from the backend when they are marked as deleted in the recordingStatusAtom as well.
      if (status.status === "deleted") {
        cleanUpRecordingMarkedForDeletion(status.recordingId as RecordingId);
      }
    });

    return () => {
      set(recordingStatsLegacyAtom, null);
      set(recordingStatusLegacyAtom, null);

      // TODO: add a ws.off to the socket.
    };
  } else {
    const socket = get(socketAtom);
    if (!socket) {
      return;
    }

    const off = socket.on("recordings", (data) => {
      // Only look at recording updates.
      if (data.object_type === "recording") {
        if (data.event_type === "deleted") {
          // When it is deleted for sure, we can remove it from the marked atom.
          cleanUpRecordingMarkedForDeletion(data.object_id);

          // Update the recording status of the recording and try to inject the epgId if it's available.
          const previousItem = get(recordingStatusSocketAtom).find(
            (v) => v.recordingId === data.object_id,
          );
          set(recordingStatusSocketAtom, {
            recordingId: data.object_id,
            status: "deleted",
            epgId: previousItem?.epgId ?? null,
          });
        } else {
          // In other cases, just update the recording status from what we receive in the payload.
          set(recordingStatusSocketAtom, {
            recordingId: data.object_id,
            status: data.payload.status,
            epgId: data.payload.epg_entry_id ?? null,
          });
        }
      }

      // TODO: Look at recording group updates as well.

      set(flushRecordingsCacheAtom);
    });

    return () => {
      off();
    };
  }
});
