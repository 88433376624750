import type {
  RecordingGroupId,
  RecordingId,
} from "@sunrise/backend-types-core";

import {
  RecordingGroupStatus,
  RecordingStatus,
  type RecordingStatusSchema,
} from "../GeneratedApi";
import type { RecordingStatusForRecording } from "../types";

export function recordingStatusToType(
  status: RecordingStatusSchema,
): RecordingStatusForRecording {
  switch (status.status) {
    case RecordingGroupStatus.AllRecorded:
    case RecordingGroupStatus.AllPlanned:
    case RecordingGroupStatus.Mixed:
      return {
        recordingId: status.recording_id as RecordingGroupId,
        status: status.status as RecordingGroupStatus,
        type: "group",
      };
    case RecordingStatus.All:
    case RecordingStatus.Planned:
    case RecordingStatus.Recorded:
      return {
        recordingId: status.recording_id as RecordingId,
        status: status.status as RecordingStatus,
        type: "single",
      };
  }

  return {
    recordingId: status.recording_id as RecordingId | RecordingGroupId,
    status: status.status,
    type: "unknown",
  };
}
