import {
  type AnonymousResponse,
  endpoints,
  type LoginResponse,
  type LogoutResponse,
} from "@sunrise/backend-types";
import type { PrivateApiClient } from "@sunrise/http-client";
import { publicApi } from "@sunrise/http-client";
import type { Nullable } from "@sunrise/utils";
import { isNil } from "@sunrise/utils";
import { getClientID } from "@sunrise/yallo-websocket";

const NG_LOGIN_PARAM = "v2_login";

export async function fetchRefreshAuthTokens(
  host: string,
  refreshToken: string,
  ng = false,
): Promise<LoginResponse> {
  const url = new URL(endpoints.refreshToken(host));

  url.searchParams.set("refresh_token", refreshToken);
  if (ng) {
    url.searchParams.set(NG_LOGIN_PARAM, "true");
  }

  // NOTE: It is SUPER important to use publicApi here. Else the automatic token refresh will kick in on the refresh endpoint itself.
  return (
    await publicApi.get<LoginResponse>(url.href, {
      headers: {
        "X-Device-Ads-ID": getClientID(),
      },
    })
  ).data;
}

export async function invalidateTokens(
  privateApi: PrivateApiClient,
  host: string,
  type: "access_token_only" | "all",
): Promise<void> {
  const url =
    type === "access_token_only"
      ? endpoints.invalidateAccessToken?.(host)
      : endpoints.invalidateAllTokens?.(host);

  if (!url) {
    return;
  }

  await privateApi.get(url);
}

export async function anonymousLogin(
  host: Nullable<string>,
  deviceTypeName: string,
  ng = false,
) {
  if (isNil(host)) throw new Error("api host is not defined");

  const { data: user } = await publicApi.post<AnonymousResponse>(
    endpoints.anonymous(host),
  );

  const url = new URL(
    endpoints.login.withUserId(host, user.id, deviceTypeName),
  );
  if (ng) {
    url.searchParams.set(NG_LOGIN_PARAM, "true");
  }
  const { data: login } = await publicApi.get<LoginResponse>(url.href, {
    headers: {
      "X-Device-Ads-ID": getClientID(),
    },
  });

  return login;
}

export async function authenticateWithToken(
  host: Nullable<string>,
  deviceTypeName: string,
  token: string,
  ng = false,
) {
  if (isNil(host)) throw new Error("api host is not defined");

  const url = new URL(endpoints.login.withToken(host, token, deviceTypeName));
  if (ng) {
    url.searchParams.set(NG_LOGIN_PARAM, "true");
  }

  const { data: login } = await publicApi.get<LoginResponse>(url.href, {
    headers: {
      "X-Device-Ads-ID": getClientID(),
    },
  });

  return login;
}

export async function logout(
  host: Nullable<string>,
  privateApi: PrivateApiClient,
  fromAllDevices?: boolean,
) {
  if (isNil(host)) throw new Error("api host is not defined");

  const url = new URL(
    endpoints.logout[fromAllDevices ? "allDevices" : "currentDevice"](host),
  );

  const { data: logout } = await privateApi.get<LogoutResponse>(url.href);

  return logout;
}
