import { useEffect } from "react";
import { setDefaultOptions } from "date-fns";
import { useAtomValue } from "jotai";

import { currentLanguageAtom } from "./i18n.store";

export const useBindDateFnsLocaleToLanguageAtom = () => {
  const language = useAtomValue(currentLanguageAtom);

  useEffect(() => {
    const loader = async () => {
      let locale;

      switch (language) {
        case "en":
          locale = (await import(`date-fns/locale/en-US`)).enUS;
          break;
        case "fr":
          locale = (await import(`date-fns/locale/fr`)).fr;
          break;
        case "it":
          locale = (await import(`date-fns/locale/it`)).it;
          break;
        case "de":
        default:
          locale = (await import(`date-fns/locale/de`)).de;
          break;
      }
      setDefaultOptions({ locale });
    };

    loader();
  });
};
