import { type ReactNode, Suspense } from "react";
import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";
import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react";

import {} from "@sunrise/error";

export type SuspenseErrorWrapperProps = {
  children: ReactNode;
  doLog?: boolean;
};

export function SuspendedErrorWrapper({
  doLog = true,
  children,
}: SuspenseErrorWrapperProps): ReactNode {
  return (
    <Suspense>
      {doLog ? (
        <SentryErrorBoundary
          fallback={() => {
            return <></>;
          }}
        >
          {children}
        </SentryErrorBoundary>
      ) : (
        <ReactErrorBoundary fallback={null}>{children}</ReactErrorBoundary>
      )}
    </Suspense>
  );
}
