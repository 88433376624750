import { atomFamily } from "jotai/utils";
import {
  atomWithMutation,
  atomWithQuery,
  queryClientAtom,
} from "jotai-tanstack-query";
import { isNil } from "lodash";

import type { RecordingStatusUpdate } from "@sunrise/backend-types";
import { queryKeys } from "@sunrise/backend-types";
import type {
  AssetId,
  EPGEntryId,
  RecordingId,
} from "@sunrise/backend-types-core";
import { hostsAtom, httpClientAtom } from "@sunrise/http-client";
import { selectJwtUserToken } from "@sunrise/jwt";

import { flushRecordingsState } from "../flush-recordings-state";
import { recordingStatusByEpgIdAtom } from "../recording-status-by-epg-id.atom";
import {
  createRecordingSchedule,
  deleteRecordingSchedule,
  haveRecordingSchedules,
} from "../recordings.service";

export const recordingSchedulesMutationAtom = atomWithMutation<
  void,
  {
    epgEntryId: EPGEntryId;
    providerName: string;
    isSeries: boolean;
  }
>((get) => {
  const host = get(hostsAtom).api;
  if (isNil(host)) throw new Error("Host is not set");

  const privateApi = get(httpClientAtom).privateApi;
  if (isNil(privateApi)) throw new Error("Private api is not set");

  const queryClient = get(queryClientAtom);
  const queryKeyRecordingStatus = queryKeys.recordingsStatus(
    get(selectJwtUserToken),
    false,
  );

  return {
    // NOTE: We may need to create an atomFamily out of this mutation if we need to track the status of multiple recording creations.
    //       Because right now every recording creation will overwrite the previous one.
    //       But we will probably deprecate this when switching to ng.
    mutationKey: ["recording-schedules"],
    mutationFn: async ({ epgEntryId, providerName, isSeries }) => {
      await createRecordingSchedule(
        host,
        privateApi,
        epgEntryId,
        providerName,
        isSeries,
      );
    },
    onMutate: ({ epgEntryId }) => {
      // NOTE: temporarily add new planned status. For new past recordings we are also using planned initially.
      const statuses = (queryClient.getQueryData(queryKeyRecordingStatus) ||
        []) as RecordingStatusUpdate[];

      statuses.push({
        recordingId: "" as RecordingId,
        status: "planned",
        epgId: epgEntryId,
      });

      recordingStatusByEpgIdAtom.remove(epgEntryId);
      queryClient.setQueryData(queryKeyRecordingStatus, statuses);
    },
    onSettled: function (_, err) {
      if (err) {
        // Make sure to remove any temporary status when we error.
        const statuses = queryClient.getQueryData(queryKeyRecordingStatus) as
          | RecordingStatusUpdate[]
          | null;
        if (statuses) {
          queryClient.setQueryData(
            queryKeyRecordingStatus,
            statuses.filter((s) => !!s.recordingId),
          );
        }
      }
      flushRecordingsState(queryClient, get);
    },
  };
});

export const deleteRecordingScheduleMutationAtom = atomWithMutation<
  void,
  {
    epgEntryId: EPGEntryId;
  },
  unknown
>((get) => {
  const host = get(hostsAtom).api;
  if (isNil(host)) throw new Error("Host is not set");

  const privateApi = get(httpClientAtom).privateApi;
  if (isNil(privateApi)) throw new Error("Private api is not set");

  const queryClient = get(queryClientAtom);
  const queryKey = queryKeys.recordingsStatus(get(selectJwtUserToken), false);

  return {
    mutationKey: ["delete-recording-schedule"],
    mutationFn: ({ epgEntryId }) =>
      deleteRecordingSchedule(host, privateApi, epgEntryId),
    onMutate: ({ epgEntryId }) => {
      // NOTE: temporarily remove status for current epgId
      let statuses = (queryClient.getQueryData(queryKey) ||
        []) as RecordingStatusUpdate[];
      statuses = statuses.filter((s) => !s.epgId || s.epgId !== epgEntryId);

      recordingStatusByEpgIdAtom.remove(epgEntryId);
      queryClient.setQueryData(queryKey, statuses);
    },
    onSettled: function () {
      flushRecordingsState(queryClient, get);
    },
  };
});

export const haveRecordingSchedulesAtom = atomFamily((assetId: AssetId) =>
  atomWithQuery<boolean>((get) => {
    const hosts = get(hostsAtom);
    const host = hosts.api;
    if (isNil(host)) throw new Error("api host is not defined");

    const privateApi = get(httpClientAtom).privateApi;
    if (isNil(privateApi)) throw new Error("Private api is not set");

    return {
      queryKey: queryKeys.haveRecordingSchedules(
        get(selectJwtUserToken),
        assetId,
      ),
      queryFn: async () => {
        if (isNil(assetId)) return false;

        return haveRecordingSchedules(host, privateApi, assetId);
      },
    };
  }),
);
