import type { ReactElement } from "react";
import { atom, useAtomValue } from "jotai";
import { Router as Wouter } from "wouter";

import { playerAnalyticsRunEffect } from "@sunrise/analytics";
import { useAnonymousLogin } from "@sunrise/auth";
import { useBindDateFnsLocaleToLanguageAtom } from "@sunrise/i18n";
import { selectIsLoggedIn } from "@sunrise/jwt";
import { useAutostart } from "@sunrise/yallo-common-player-manager";
import {
  playerSaveProgressEffect,
  reactForContinueWatchingToSocketEffect,
} from "@sunrise/yallo-continue-watching";
import { reactForRecordingsToSocketEffect } from "@sunrise/yallo-recordings";
import { reactForUserToSocketEffect } from "@sunrise/yallo-user";
import { socketLogUnhandledEffect } from "@sunrise/yallo-websocket";

import { Header } from "@/components";
import { GenericDialog, SideDialog } from "@/components/dialogs";
import { PlayerUI } from "@/components/players/player-ui";
import { updateGrowthbookAttributesEffect } from "@/modules/growthbook/update-growthbook-attributes.effect";
import { Router } from "@/router";

const isDevOrLocal =
  import.meta.env.MODE === "development" ||
  import.meta.env.MODE === "localhost";
const NULL_ATOM = atom(null);

export function Root(): ReactElement | null {
  useAtomValue(playerSaveProgressEffect);

  // Need to listen to the run effect for the player analytics to kick in.
  useAtomValue(playerAnalyticsRunEffect);
  // Need to listen to this for now for the modules to kick in on the socket.
  useAtomValue(reactForRecordingsToSocketEffect);
  useAtomValue(reactForUserToSocketEffect);
  useAtomValue(reactForContinueWatchingToSocketEffect);

  useAtomValue(isDevOrLocal ? socketLogUnhandledEffect : NULL_ATOM);
  useAtomValue(updateGrowthbookAttributesEffect);

  useAnonymousLogin({
    autoLogin: true,
  });
  useAutostart();

  useBindDateFnsLocaleToLanguageAtom();

  const isLoggedIn = useAtomValue(selectIsLoggedIn);

  if (!isLoggedIn) return null;

  return (
    <>
      <Wouter>
        <Header />
        <Router />
      </Wouter>
      <PlayerUI />
      <GenericDialog />
      <SideDialog />
    </>
  );
}
