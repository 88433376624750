import { featureAtom } from "@sunrise/feature-flags";

export const canSortDetailPageEpisodesByAiringDate = featureAtom(
  "program-detail-page-episodes-programs-sort-by-airing-date-enabled",
  false,
  {
    description:
      "Allow users to sort episodes/recordings on a detail page by airing date",
  },
);

export const canSortDetailPageEpisodesByTitle = featureAtom(
  "program-detail-page-episodes-programs-sort-by-title-enabled",
  false,
  {
    description:
      "Allow users to sort episodes/recordings on a detail page by title",
  },
);
