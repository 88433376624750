import type { ComponentProps } from "react";

import { Channel } from "./channel/channel";
import { EpgItem } from "./epg/epg-item";
import { RecordingItemGroup } from "./recordings/recording-item-group";
import { RecordingItemSingle } from "./recordings/recording-item-single";

type PlayableItemBox =
  | ({ source: "recordingSingle" } & ComponentProps<typeof RecordingItemSingle>)
  | ({ source: "recordingGroup" } & ComponentProps<typeof RecordingItemGroup>)
  | ({ source: "epg" } & ComponentProps<typeof EpgItem>)
  | ({ source: "channel" } & ComponentProps<typeof Channel>);

export const PlayableItemBox = (props: PlayableItemBox) => {
  if (props.source == "epg") {
    return <EpgItem {...props} />;
  }

  if (props.source == "recordingSingle") {
    return <RecordingItemSingle {...props} />;
  }

  if (props.source == "recordingGroup") {
    return <RecordingItemGroup {...props} />;
  }

  if (props.source == "channel") {
    return <Channel {...props} />;
  }
};
