import type { MappedAsset } from "@sunrise/asset";
import type { Asset } from "@sunrise/backend-types";
import type { Language } from "@sunrise/backend-types-core";
import { getLocalizedValue } from "@sunrise/i18n";
import { isDefined } from "@sunrise/utils";

export function mapBackendAssetDetailsLegacy(
  asset: Asset,
  language: Language,
): MappedAsset {
  return {
    id: asset.id,
    title: getLocalizedValue(asset.title, language) ?? asset.original_title,
    subtitle:
      getLocalizedValue(asset.subtitle, language) ?? asset.original_subtitle,
    ageRecomendation: asset.age_recommendation
      ? `PG-${asset.age_recommendation}`
      : null,
    productionYear: asset.production_year,
    posterPath:
      (asset.poster_url && getLocalizedValue(asset.poster_url, language)) ||
      asset.poster_path,
    posterBlurHash: null,
    episodeNumber: asset.type === "seriesepisode" ? asset.episode : null,
    seasonNumber: asset.type === "seriesepisode" ? asset.raw_season : null,
    plot: asset.plot ? getLocalizedValue(asset.plot, language) : null,
    type: asset.type,
    genres: (asset.genre ?? [])
      .map((genre) =>
        genre.name ? getLocalizedValue(genre.name, language) : "",
      )
      .filter(Boolean)
      .filter(isDefined),
    seasons:
      asset.type === "series"
        ? asset.seasons.map((season) => ({
            id: season.id,
            season: season.season,
            episodes: season.episodes,
          }))
        : null,
    cast: [],
    directors: [],
    seriesId: asset.type === "seriesepisode" ? asset.series.id : null,
  };
}
