import { atom } from "jotai";
import { atomFamily } from "jotai/utils";

import type { RecordingId } from "@sunrise/backend-types-core";
import { currentLanguageAtom } from "@sunrise/i18n";

import { whatIsNextLegacyQueryAtom } from "../legacy/queries/what-is-next.legacy.query.atom";
import { legacyRecordingToGenericRecording } from "../utils/legacy-recording-to-generic-recording";

/**
 * Returns the next recording the user could play out for the given recordingId.
 * In the future it'll be able to return EPG / replay items as well but for now it's just used for recordings.
 */
export const whatIsNextAtom = atomFamily((recordingId: RecordingId) => {
  const innerAtom = atom(async (get) => {
    const query = await get(whatIsNextLegacyQueryAtom(recordingId));

    if (!query.data) {
      return null;
    }

    const item = legacyRecordingToGenericRecording(
      query.data,
      get(currentLanguageAtom),
    );

    if (item.type === "group") {
      return null;
    }

    return item;
  });

  innerAtom.debugLabel = `whatIsNextAtom(${recordingId})`;

  return innerAtom;
});
