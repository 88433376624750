import { atom } from "jotai";

/**
 * Make sure to override this for your relevant platform before the atom is actually used.
 *
 * https://entwicklungspark.atlassian.net/wiki/spaces/WT/pages/2904915969/Device+Type
 */
export const deviceTypeAtom = atom<
  | "tizen"
  | "webclient"
  | "webclient_android"
  | "webclient_ios"
  | "webclient_macos_safari"
  | "embedded_player_ios"
  | "embedded_player_android"
  | "embedded_player_macos_safari"
  | "embedded_player"
>("webclient");
