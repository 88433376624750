import { Suspense, useRef } from "react";
import { useVirtualizer } from "@tanstack/react-virtual";
import { clsx } from "clsx";
import { useAtomValue } from "jotai";

import {
  debouncedQueryAtoms,
  searchResultsForTermPagedAtom,
} from "@sunrise/search";
import { useTranslator } from "@sunrise/translator";
import { useInfiniteScroll } from "@sunrise/utils";
import { Spinner } from "@sunrise/yallo-web-components";

import { SearchPlaceholder } from "./search-placeholder";
import styles from "./search-results.module.css";
import { SearchChannelResult } from "./search-results/search-channel-result";
import { SearchEpgResult } from "./search-results/search-epg-result";
import { SearchRecordingResult } from "./search-results/search-recording-result";
import { SearchSkeletonResult } from "./search-results/search-skeleton-result";
type SearchResultsProps = {
  closeSearch?: () => void;
};

export const SearchResults = ({ closeSearch }: SearchResultsProps) => {
  const t = useTranslator();
  const searchTerm = useAtomValue(debouncedQueryAtoms.debouncedValueAtom);
  const listRef = useRef<HTMLDivElement>(null);

  const {
    items,
    isLoadingInitialPage,
    fetchNextPage,
    isFetchingNextPage,
    page,
    totalPages,
  } = useAtomValue(searchResultsForTermPagedAtom(searchTerm));
  const infiniteScroll = useInfiniteScroll<HTMLLIElement>({
    loadMore: fetchNextPage,
  });

  const rowVirtualizer = useVirtualizer({
    count: items.length,
    estimateSize: () => 104,
    overscan: 5,
    getScrollElement: () => listRef.current,
    measureElement: (el) => el.getBoundingClientRect().height,
  });

  if (isLoadingInitialPage)
    return (
      <Spinner
        className={styles.initialSpinner}
        isActive={isLoadingInitialPage}
        variant="small"
      />
    );

  if (!items || items.length === 0)
    return (
      <SearchPlaceholder
        key="empty"
        body={t("search_page_no_results_body")}
        title={t("search_page_no_results_title")}
      />
    );

  return (
    <>
      <div ref={listRef} className={styles.virtualScroller}>
        <ol
          className={styles.searchResultsList}
          style={{
            height: `${rowVirtualizer.getTotalSize()}px`,
          }}
        >
          {rowVirtualizer.getVirtualItems().map((virtualRow) => {
            const item = items[virtualRow.index];

            if (!item) return null;
            const ref =
              virtualRow.index === items.length - 1
                ? infiniteScroll.setLastRef
                : undefined;

            return (
              <li
                key={virtualRow.key}
                ref={ref}
                className={styles.result}
                style={{
                  height: `${virtualRow.size}px`,
                  transform: `translateY(${virtualRow.start}px)`,
                }}
              >
                <Suspense fallback={<SearchSkeletonResult />}>
                  <div
                    ref={rowVirtualizer.measureElement}
                    data-index={virtualRow.index}
                  >
                    {item.type === "channel" ? (
                      <SearchChannelResult
                        closeSearch={closeSearch}
                        item={item}
                      />
                    ) : item.type === "epg" ? (
                      <SearchEpgResult closeSearch={closeSearch} item={item} />
                    ) : item.type === "recordingSingle" ? (
                      <SearchRecordingResult
                        closeSearch={closeSearch}
                        item={item}
                      />
                    ) : null}
                  </div>
                </Suspense>
              </li>
            );
          })}
        </ol>
      </div>
      <Spinner
        className={clsx(styles.paginationSpinner)}
        isActive={isFetchingNextPage}
        label={t(`global_loading_page_current_of_total`, [page, totalPages])}
        variant="small"
      />
    </>
  );
};
