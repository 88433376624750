import { atomWithReducer, selectAtom } from "jotai/utils";

import { PlayerContentType } from "@sunrise/backend-ng-events";
import type {
  ChannelId,
  EPGEntryId,
  RecordingId,
} from "@sunrise/backend-types-core";
import { type LoadOptions } from "@sunrise/player-manager";
import { type Nullable } from "@sunrise/utils";
import type { PlayRequest } from "@sunrise/yallo-player-types";

type PlayOrigin = "autostart" | "autorecover";

type PlayerManagerAtomState = {
  /**
   * We need to refer to this to know which content is loading. For zapping purposes we want to instantly confirm that we are switching the channel.
   * But if we want to know exactly what we are playing in the player we need to look at the playRequest in the player.
   */
  playRequest: Nullable<PlayRequest>;
  /**
   * We need to store the loadOptions associated with the playRequest.
   * Some if it we need to eventually pass to the player after the loading of the stream is done.
   */
  loadOptions: Nullable<LoadOptions>;
  error: Nullable<Error>;
  /**
   * This will contain the playRequest that was last handled.
   * It's important to know this if we want to know if the current playRequest has already been handled or not.
   * To know this we can check by reference if the `playRequest` is equal to `handled`. If so, handled.
   */
  handled: Nullable<PlayRequest>;
};

export function makePlayerManagerAtomDefaultState(
  state?: Partial<PlayerManagerAtomState>,
): PlayerManagerAtomState {
  return {
    playRequest: state?.playRequest ?? null,
    loadOptions: state?.loadOptions ?? null,
    error: state?.error ?? null,
    handled: state?.handled ?? null,
  };
}

type ActionPlayLiveChannel = {
  type: "player-manager/play-live-channel";
  payload: {
    channelId: ChannelId;
    origin?: PlayOrigin;
    startPaused?: boolean;
  };
};

type ActionPlayReplay = {
  type: "player-manager/play-replay";
  payload: {
    channelId: ChannelId;
    epgId: EPGEntryId;
    startTime: Date;
    isAtStart?: boolean;
    startPaused?: boolean;
    origin?: PlayOrigin;
  };
};

type ActionLoadPlayRequest = {
  type: "player-manager/load-play-request";
  payload: {
    playRequest: PlayRequest;
    loadOptions: Nullable<LoadOptions>;
  };
};

type ActionPlayRecording = {
  type: "player-manager/play-recording";
  payload: {
    recordingId: RecordingId;
    channelId: ChannelId | null;
    startAtSeconds?: number;
    startPaused?: boolean;
    origin?: PlayOrigin;
  };
};

type ActionReset = {
  type: "player-manager/reset";
};

type ActionSetError = {
  type: "player-manager/set-error";
  payload: {
    error: Error;
  };
};

type ActionSuccess = {
  type: "player-manager/success";
};

type PlayerManagerAction =
  | ActionPlayLiveChannel
  | ActionPlayReplay
  | ActionLoadPlayRequest
  | ActionReset
  | ActionSetError
  | ActionSuccess
  | ActionPlayRecording;

export const playerManagerAtom = atomWithReducer<
  PlayerManagerAtomState,
  PlayerManagerAction
>(makePlayerManagerAtomDefaultState(), playerManagerAtomReducer);

export function playerManagerAtomReducer(
  ps: PlayerManagerAtomState,
  action: PlayerManagerAction,
): PlayerManagerAtomState {
  switch (action.type) {
    case "player-manager/play-live-channel":
      return {
        playRequest: {
          channelId: action.payload.channelId,
          type: PlayerContentType.Live,
        },
        loadOptions:
          action.payload.origin || action.payload.startPaused
            ? {
                originatingAction: action.payload.origin,
                ensurePaused: action.payload.startPaused,
              }
            : null,
        error: null,
        handled: null,
      };
    case "player-manager/play-replay":
      return {
        playRequest: {
          channelId: action.payload.channelId,
          type: PlayerContentType.Replay,
          epgId: action.payload.epgId,
          startTime: action.payload.startTime,
        },
        // When we play from the start we want to indicate that to the player.
        // Because it will trigger additional advertising.
        loadOptions:
          action.payload.isAtStart ||
          action.payload.startPaused ||
          action.payload.origin
            ? {
                originatingAction: action.payload.isAtStart
                  ? "play-from-start"
                  : action.payload.origin,
                ensurePaused: action.payload.startPaused,
              }
            : null,
        error: null,
        handled: null,
      };
    case "player-manager/play-recording":
      return {
        playRequest: {
          type: PlayerContentType.Recording,
          recordingId: action.payload.recordingId,
          channelId: action.payload.channelId,
          startAtSeconds: action.payload.startAtSeconds,
        },
        loadOptions:
          action.payload.startPaused || action.payload.origin
            ? {
                ensurePaused: action.payload.startPaused,
                originatingAction: action.payload.origin,
              }
            : null,
        error: null,
        handled: null,
      };
    case "player-manager/load-play-request":
      return {
        // NOTE: We need to ensure it is ALWAYS a new object.
        //       If we do not it is possible we receive the same play request object again and we'd not load it. We would show a perpetual loader.
        playRequest: { ...action.payload.playRequest },
        loadOptions: action.payload.loadOptions,
        error: null,
        handled: null,
      };
    case "player-manager/reset":
      return makePlayerManagerAtomDefaultState();
    case "player-manager/set-error":
      return makePlayerManagerAtomDefaultState({
        error: action.payload.error,
        // NOTE: It's important we keep the associated playRequest when we error. Because features like zapping rely on knowing which channel was intended to play out.
        //       If it can't play out it still needs to know the last playout attempt.
        playRequest: ps.playRequest,
        loadOptions: ps.loadOptions,
        handled: ps.playRequest,
      });
    case "player-manager/success":
      return {
        ...ps,
        handled: ps.playRequest,
      };
    default:
      return ps;
  }
}

/*
 *
 * ACTIONS
 *
 */

export function actionPlayerManagerPlayLiveChannelId(
  channelId: ChannelId,
  origin?: PlayOrigin,
  startPaused?: boolean,
): ActionPlayLiveChannel {
  return {
    type: "player-manager/play-live-channel",
    payload: {
      channelId,
      origin,
      startPaused,
    },
  };
}

export function actionPlayerManagerPlayReplay(
  epgId: EPGEntryId,
  channelId: ChannelId,
  startTime: Date,
  isAtStart?: boolean,
  startPaused?: boolean,
  origin?: PlayOrigin,
): ActionPlayReplay {
  return {
    type: "player-manager/play-replay",
    payload: {
      epgId,
      channelId,
      startTime,
      isAtStart,
      startPaused,
      origin,
    },
  };
}

export function actionPlayerManagerPlayRecording(
  recordingId: RecordingId,
  channelId: ChannelId | null,
  startAtSeconds?: number,
  startPaused?: boolean,
  origin?: PlayOrigin,
): ActionPlayRecording {
  return {
    type: "player-manager/play-recording",
    payload: {
      recordingId,
      channelId,
      startAtSeconds,
      startPaused,
      origin,
    },
  };
}

/**
 * When the PlayerManager delegated everything for the incoming PlayRequest, we can reset the state.
 */
export function actionPlayerManagerReset(): ActionReset {
  return {
    type: "player-manager/reset",
  };
}

export function actionPlayerManagerSetError(error: Error): ActionSetError {
  return {
    type: "player-manager/set-error",
    payload: {
      error,
    },
  };
}

export function actionPlayerManagerLoadPlayRequest(
  playRequest: PlayRequest,
  loadOptions: Nullable<LoadOptions>,
): ActionLoadPlayRequest {
  return {
    type: "player-manager/load-play-request",
    payload: {
      playRequest,
      loadOptions,
    },
  };
}

export function actionPlayerManagerSuccess(): ActionSuccess {
  return {
    type: "player-manager/success",
  };
}

/*
 * SELECTORS
 *
 */

export const selectPlayerManagerCurrentPlayRequest = selectAtom(
  playerManagerAtom,
  (ps) => ps.playRequest,
);

export const selectPlayerManagerCurrentOptions = selectAtom(
  playerManagerAtom,
  (ps) => ps.loadOptions,
);

export const selectPlayerManagerCurrentError = selectAtom(
  playerManagerAtom,
  (ps) => ps.error,
);

export const selectPlayerManagerLoadOrigin = selectAtom(
  playerManagerAtom,
  (ps) => ps.loadOptions?.originatingAction,
);

export const selectPlayerManagerIsHandled = selectAtom(
  playerManagerAtom,
  (ps) => !!(ps.handled && ps.handled === ps.playRequest),
);

export const selectRecordingId = selectAtom(playerManagerAtom, (ps) => {
  if (ps.playRequest?.type === "recording") {
    return ps.playRequest.recordingId;
  }

  return null;
});

export const selectPlayerManagerIsLoading = selectAtom(
  playerManagerAtom,
  (ps) => {
    if (!ps.playRequest) {
      return false;
    }

    return !ps.handled || ps.handled !== ps.playRequest;
  },
);
