import { atomFamily } from "jotai/utils";

import { pagedAtom } from "@sunrise/backend-ng-core";
import type { AssetId } from "@sunrise/backend-types-core";
import { episodesEpgsBySeriesAssetIdNgAtom } from "@sunrise/yallo-epg";

/**
 * Episodes of a series
 *
 */
export const assetEpisodesByAssetIdPagedAtom = atomFamily((assetId: AssetId) =>
  pagedAtom(episodesEpgsBySeriesAssetIdNgAtom(assetId)),
);
