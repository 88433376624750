import { useSetAtom } from "jotai";

import { actionDialogOpen, dialogAtom } from "@sunrise/dialogs";
import { useTranslator } from "@sunrise/translator";
import { Button } from "@sunrise/yallo-web-components";

import { useLogout } from "@/hooks";

export function ButtonLogoutFromAllDevices() {
  const t = useTranslator();
  const { canUserLogout, logout } = useLogout(true);
  const setDialogAtom = useSetAtom(dialogAtom);

  if (!canUserLogout) return null;

  const logOutFromAllDevices = () => {
    setDialogAtom(
      actionDialogOpen({
        title: { key: "dialog_confirm_log_out_from_all_devices_title" },
        description: {
          key: "dialog_confirm_log_out_from_all_devices_description",
        },
        lastFocusKey: "",
        type: "actions",
        id: "confirm-log-out-from-all-devices",
        actions: [
          {
            label: { key: "dialog_button_cancel" },
            key: "cancel-log-out-from-all-devices",
            action: () => {},
          },
          {
            label: { key: "dialog_confirm_log_out_from_all_devices_confirm" },
            key: "confirm-log-out-from-all-devices",
            primary: true,
            action: () => logout(),
          },
        ],
      }),
    );
  };

  return (
    <Button variant="outlined" onClick={logOutFromAllDevices}>
      {t("settings_page_profile_log_out_from_all_devices")}
    </Button>
  );
}
