import { atom } from "jotai";

import { ngHttpClientAtom } from "@sunrise/backend-ng-core";

import { Api } from "./Api";

export const ngUpsellApiAtom = atom((get) => {
  const client = get(ngHttpClientAtom);

  if (!client) {
    throw new Error("ngHttpClientAtom is not initialized");
  }

  return new Api(client);
});
