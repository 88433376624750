import { atom } from "jotai";
import { atomEffect } from "jotai-effect";

import { isLegacyBackendAtom } from "@sunrise/backend-core";
import {
  playerAtom,
  playerCurrentContentAtom,
  playerCurrentDateTimeAtom,
} from "@sunrise/player";
import { selectProcessIsBackgrounded } from "@sunrise/process-visibility";
import { channelByIdAtom } from "@sunrise/yallo-channel-group";
import { legacySocketAtom } from "@sunrise/yallo-websocket";

import { PlayerAnalyticsService } from "./player-analytics.service";
import { playerAnalyticsShouldReactToVisibilityChangeAtom } from "./player-analytics-should-react-to-visibility-change.atom";

const playerAnalyticsInstanceAtomInternal = atom<PlayerAnalyticsService | null>(
  null,
);

const playerAnalyticsServiceBuildInstanceEffect = atomEffect((get, set) => {
  // We do not need to be connected to all the modules here. So we can pass by with just connecting to the socketAtom.
  const socket = get(legacySocketAtom);

  if (!socket) {
    set(playerAnalyticsInstanceAtomInternal, null);
    return;
  }

  // NOTE: Not entirely happy with this.
  // It'll be better if we rewire the analytics service to be entirely driven by the atoms.
  const instance = new PlayerAnalyticsService(
    (state) => socket.logPlayerState(state),
    () => get.peek(playerCurrentContentAtom),
    () => get.peek(playerAtom),
    (id) => get.peek(channelByIdAtom(id)),
    () => get.peek(playerCurrentDateTimeAtom),
  );
  set(playerAnalyticsInstanceAtomInternal, instance);
});

playerAnalyticsServiceBuildInstanceEffect.debugPrivate = true;

const playerAnalyticsUpdatePlayerState = atomEffect((get) => {
  const service = get(playerAnalyticsInstanceAtomInternal);
  if (!service) {
    return;
  }

  const playerState = get(playerAtom);
  service.onPlayerStateUpdate(playerState);
});

const playerAnalyticsStopOnVisibilityChangedEffect = atomEffect((get) => {
  const reactToVisibilityChange = get(
    playerAnalyticsShouldReactToVisibilityChangeAtom,
  );

  if (!reactToVisibilityChange) {
    return;
  }

  const service = get(playerAnalyticsInstanceAtomInternal);
  if (!service) {
    return;
  }

  const isBackgrounded = get(selectProcessIsBackgrounded);
  service.onVisibilityChange(!isBackgrounded);
});

export const playerAnalyticsRunEffect = atom((get) => {
  if (!get(isLegacyBackendAtom)) {
    return;
  }

  get(playerAnalyticsServiceBuildInstanceEffect);
  get(playerAnalyticsStopOnVisibilityChangedEffect);
  get(playerAnalyticsUpdatePlayerState);
});
