import { atom } from "jotai";
import { atomFamily } from "jotai/utils";

import type { ChannelGroupId } from "@sunrise/backend-types-core";

import { selectableChannelGroupsAtom } from "./selectable-channel-groups.atom";

export const selectableChannelGroupByChannelGroupIdAtom = atomFamily(
  (channelGroupId: ChannelGroupId) => {
    const inner = atom(async (get) => {
      const groups = await get(selectableChannelGroupsAtom);
      return groups.find((group) => group.id === channelGroupId) ?? null;
    });

    inner.debugLabel = `selectableChannelGroupByChannelGroupIdAtom(${channelGroupId})`;

    return inner;
  },
);
