import clamp from "lodash/clamp";

export function calculatePercentageOffsetInElementBounds(
  element: HTMLDivElement,
  offsetX: number,
): number {
  const { x, width } = element.getBoundingClientRect();
  const value = ((offsetX - x) / width) * 100;
  return clamp(value, 0, 100);
}
