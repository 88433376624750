import type { AssetType } from "@sunrise/backend-ng-recordings";
import type { PageAnnotatedUnionChannelSearchResultSchemaEpgEntrySearchResultSchemaRecordingSearchResultSchemaFieldInfoAnnotationNoneTypeRequiredTrueDiscriminatorType as SearchResultsType } from "@sunrise/backend-ng-search";
import type {
  AssetId,
  ChannelId,
  EPGEntryId,
  RecordingId,
  TimeISOString,
} from "@sunrise/backend-types-core";
import type { Nullable } from "@sunrise/utils";
import type { SimplifiedRecordingStatus } from "@sunrise/yallo-recordings";

export type NgSearchResult =
  | NgSearchEpgResult
  | NgSearchRecordingResult
  | NgSearchChannelResult;

export function mapBackendSearchResults(
  result: SearchResultsType["items"][0],
): Nullable<NgSearchResult> {
  if (result.type === "channel") {
    return {
      type: "channel",
      channelId: result.entry.id as ChannelId,
      channelName: result.entry.name,
      channelLogo: result.entry.logo,
    };
  }

  if (["epg_entry", "recording"].includes(result.type)) {
    const shared = {
      title: result.entry.asset.title,
      subtitle: result.entry.asset.subtitle,
      seasonNumber: result.entry.asset.season_number,
      episodeNumber: result.entry.asset.episode_number,
      coverImageUrl: result.entry.asset.poster_url,
      assetId: result.entry.asset.id as AssetId,
      forceLiveProgress: true,
    };

    if (result.type === "epg_entry") {
      return {
        ...shared,
        type: "epg",
        epgId: result.entry.id as EPGEntryId,
        epgStart: result.entry.start,
        epgEnd: result.entry.end,
        channelId: result.entry.channel.id as ChannelId,
        channelName: result.entry.channel.name,
        channelLogo: result.entry.channel.logo,
        assetType: result.entry.asset.type,
      };
    }

    if (result.type === "recording") {
      return {
        ...shared,
        type: "recordingSingle",
        recordingId: result.entry.id as RecordingId,
        channelId: result.entry.channel_id as ChannelId,
        channelLogo: result.entry.channel_logo,
        channelName: result.entry.channel_name,
        recordingStatus:
          result.entry.status === "recorded" ? "recorded" : "planned",
        epgStart: result.entry.epg_start,
        epgEnd: result.entry.epg_end,
      };
    }
  }

  return null;
}

type NGSearchEpgAndRecordingResult = {
  channelLogo: Nullable<string>;
  channelName: Nullable<string>;
  coverImageUrl: Nullable<string>;
  title: Nullable<string>;
  subtitle?: Nullable<string>;
  seasonNumber?: Nullable<number>;
  episodeNumber?: Nullable<number>;
  epgStart: TimeISOString;
  epgEnd: TimeISOString;
  channelId: ChannelId;
  assetId: Nullable<AssetId>;
  forceLiveProgress?: boolean;
};

export type NgSearchEpgResult = NGSearchEpgAndRecordingResult & {
  epgId: EPGEntryId;
  forceLiveProgress?: boolean;
  assetType: Nullable<AssetType>;
  type: "epg";
};

export type NgSearchRecordingResult = NGSearchEpgAndRecordingResult & {
  type: "recordingSingle";
  recordingStatus: Nullable<SimplifiedRecordingStatus>;
  recordingId: RecordingId;
};

export type NgSearchChannelResult = {
  channelId: ChannelId;
  channelLogo: Nullable<string>;
  channelName: Nullable<string>;
  type: "channel";
};
