import type { ComponentProps, ReactElement } from "react";

import { useTranslator } from "@sunrise/translator";
import {
  TagGroup as BaseTagGroup,
  type TagGroupLabelProps,
} from "@sunrise/yallo-web-components";

type Props = Omit<
  ComponentProps<typeof BaseTagGroup>,
  keyof TagGroupLabelProps
>;

const TagGroup = (props: Props): ReactElement => {
  const t = useTranslator();

  return (
    <BaseTagGroup
      {...props}
      titleAgeRecommendation={t("asset_age_recommendation")}
      titleGenres={t("asset_genres")}
      titleProductionYear={t("asset_production_year")}
      titleTagGroup={t("detailpage_tags")}
    />
  );
};

export { TagGroup };
