import type { ComponentProps, ReactElement } from "react";
import clsx from "clsx";

import { Radio as BaseRadio } from "../../form/toggle/radio";
import styles from "./radio.module.css";

type Props = ComponentProps<typeof BaseRadio>;

const Radio = ({ className, ...props }: Props): ReactElement => {
  return <BaseRadio className={clsx(styles.radio, className)} {...props} />;
};

export { Radio };
