import type { ComponentProps, ReactElement } from "react";

import { useTranslator } from "@sunrise/translator";
import { ChannelLogo as BaseChannelLogo } from "@sunrise/yallo-web-components";

type Props = ComponentProps<typeof BaseChannelLogo>;

const ChannelLogo = (props: Props): ReactElement => {
  const t = useTranslator();

  return (
    <BaseChannelLogo
      {...props}
      name={
        props.name
          ? t("global_channel_logo_content_description", [props.name])
          : ""
      }
    />
  );
};

export { ChannelLogo };
