import areEqual from "fast-deep-equal";
import { atom } from "jotai";
import { atomFamily } from "jotai/utils";

import type { AssetId } from "@sunrise/backend-types-core";

import { assetDetailsByIdAtom } from "./asset-details-by-id.atom";
import { getSeasonEpisodePrefix } from "./helpers/get-season-episode-prefix";

/**
 * Only call this with an assetId that is part of the seriesAssetId.
 *
 * This is legacy as well. The NG backend needs to provide an alternative here.
 */
export const seasonAndEpisodeByAssetAndSeriesAssetIdAtom = atomFamily(
  ({
    assetId,
    seriesAssetId,
  }: {
    assetId: AssetId;
    seriesAssetId: AssetId;
  }) => {
    const inner = atom(async (get) => {
      const seriesAsset = await get(assetDetailsByIdAtom(seriesAssetId));

      if (seriesAsset && seriesAssetId && seriesAsset.type === "series") {
        const season = seriesAsset.seasons?.find((s) =>
          s.episodes.some((e) => e.id === assetId),
        );
        const episode = season?.episodes.find((e) => e.id === assetId);

        if (!season || !episode) {
          return null;
        }

        return getSeasonEpisodePrefix(season?.season, episode?.episode);
      }

      return null;
    });

    return inner;
  },
  areEqual,
);
