import { atom } from "jotai";

import { isLegacyBackendAtom } from "@sunrise/backend-core";
import type { ChannelGroupId } from "@sunrise/backend-types-core";
import { currentLanguageAtom, getLocalizedValue } from "@sunrise/i18n";

import { selectableChannelGroupsLegacyAtom } from "./legacy/selectable-channel-groups.legacy.atom";
import { allSelectableChannelGroupsNgAtom } from "./ng/all-selectable-channel-groups.ng.atom";

export type SelectableChannelGroupItem = {
  id: ChannelGroupId;
  name: string;
  isUser: boolean;
};

/**
 * These are all the channel groups how the user sees them in the UI.
 *
 * NOTE: Paging is not something we will support in the UI for this.
 *       Given that the content is so simple and that the user will probably not have a ton of channels.
 *       So we will just load all pages if there is any paging at all.
 */
export const selectableChannelGroupsAtom = atom<
  Promise<SelectableChannelGroupItem[]>
>(async (get) => {
  if (get(isLegacyBackendAtom)) {
    const items = await get(selectableChannelGroupsLegacyAtom);

    // Remove channels from the items + translate backend values correctly.
    return items.map((item) => ({
      id: item.id,
      name:
        getLocalizedValue(item.display_name, get(currentLanguageAtom)) ??
        item.name,
      isUser: item.type === "user",
    }));
  }

  return (await get(allSelectableChannelGroupsNgAtom)).map((item) => ({
    id: item.id as ChannelGroupId,
    name: item.name,
    isUser: item.type === "user",
  }));
});
