import Add from "../../icons/add.svg";
import AllEpisodes from "../../icons/all-episodes.svg";
import ArrowDown from "../../icons/arrow-down.svg";
import ArrowLeft from "../../icons/arrow-left.svg";
import ArrowRight from "../../icons/arrow-right.svg";
import ArrowUp from "../../icons/arrow-up.svg";
import Calendar from "../../icons/calendar.svg";
import Cast from "../../icons/cast.svg";
import ChannelList from "../../icons/channel-list.svg";
import CheckMark from "../../icons/check-mark.svg";
import ChevronDown from "../../icons/chevron-down.svg";
import ChevronLeft from "../../icons/chevron-left.svg";
import ChevronRight from "../../icons/chevron-right.svg";
import ChevronUp from "../../icons/chevron-up.svg";
import Close from "../../icons/close.svg";
import CloseRound from "../../icons/close-round.svg";
import Copy from "../../icons/copy.svg";
import Cycle from "../../icons/cycle.svg";
import Delete from "../../icons/delete.svg";
import DeleteForever from "../../icons/delete-forever.svg";
import Directory from "../../icons/directory-white.svg";
import Download from "../../icons/download.svg";
import DragHandle from "../../icons/drag-handle.svg";
import DropDown from "../../icons/drop-down.svg";
import Edit from "../../icons/edit.svg";
import Feedback from "../../icons/feedback.svg";
import Filters from "../../icons/filters.svg";
import FiltersActive from "../../icons/filters-active.svg";
import FullScreen from "../../icons/fullscreen.svg";
import FullScreenExit from "../../icons/fullscreen-exit.svg";
import History from "../../icons/history.svg";
import Info from "../../icons/info.svg";
import Language from "../../icons/language.svg";
import Live from "../../icons/live.svg";
import LiveBroadcast from "../../icons/live-broadcast.svg";
import Lock from "../../icons/lock.svg";
import Login from "../../icons/login.svg";
import More from "../../icons/more.svg";
import MoreHorizontal from "../../icons/more-horizontal.svg";
import Guide from "../../icons/navigation/guide.svg";
import Home from "../../icons/navigation/home.svg";
import LiveTV from "../../icons/navigation/live-tv.svg";
import Recordings from "../../icons/navigation/recordings.svg";
import Next from "../../icons/next.svg";
import Next10 from "../../icons/next-10.svg";
import Next30 from "../../icons/next-30.svg";
import NotificationCheck from "../../icons/notification-check.svg";
import NotificationError from "../../icons/notification-error.svg";
import NotificationInfo from "../../icons/notification-info.svg";
import NotificationNotification from "../../icons/notification-notification.svg";
import NotificationWarning from "../../icons/notification-warning.svg";
import OpenInNew from "../../icons/open-in-new.svg";
import Pause from "../../icons/pause.svg";
import Pip from "../../icons/pip.svg";
import PipExit from "../../icons/pip-exit.svg";
import Play from "../../icons/play.svg";
import PlayFromBeginning from "../../icons/play-from-beginning.svg";
import PlayInCircle from "../../icons/play-in-circle.svg";
import Previous from "../../icons/previous.svg";
import Previous10 from "../../icons/previous-10.svg";
import Previous15 from "../../icons/previous-15.svg";
import Record from "../../icons/record.svg";
import Recorded from "../../icons/recorded.svg";
import Recording from "../../icons/recording.svg";
import RecordingGray from "../../icons/recording-gray.svg";
import ReplayTv from "../../icons/replay-tv.svg";
import Resume from "../../icons/resume.svg";
import Search from "../../icons/search.svg";
import Select from "../../icons/select.svg";
import Settings from "../../icons/settings.svg";
import Share from "../../icons/share.svg";
import Sort from "../../icons/sort.svg";
import Subscriptions from "../../icons/subscriptions.svg";
import SubtitlesAndAudio from "../../icons/subtitles-and-audio.svg";
import Support from "../../icons/support.svg";
import TermsOfUse from "../../icons/terms-of-use.svg";
import Theater from "../../icons/theater.svg";
import VolumeDown from "../../icons/volume-down.svg";
import VolumeOff from "../../icons/volume-off.svg";
import VolumeUp from "../../icons/volume-up.svg";
import ZappingDown from "../../icons/zapping-down.svg";
import ZappingUp from "../../icons/zapping-up.svg";

export const icons = {
  add: Add,
  allEpisodes: AllEpisodes,
  arrowDown: ArrowDown,
  arrowLeft: ArrowLeft,
  arrowRight: ArrowRight,
  arrowUp: ArrowUp,
  calendar: Calendar,
  cast: Cast,
  channelList: ChannelList,
  checkMark: CheckMark,
  chevronDown: ChevronDown,
  chevronLeft: ChevronLeft,
  chevronRight: ChevronRight,
  chevronUp: ChevronUp,
  closeRound: CloseRound,
  close: Close,
  copy: Copy,
  cycle: Cycle,
  deleteForever: DeleteForever,
  delete: Delete,
  download: Download,
  dragHandle: DragHandle,
  dropDown: DropDown,
  edit: Edit,
  feedback: Feedback,
  filtersActive: FiltersActive,
  filters: Filters,
  fullscreenExit: FullScreenExit,
  fullscreen: FullScreen,
  guide: Guide,
  history: History,
  home: Home,
  info: Info,
  language: Language,
  live: Live,
  liveBroadcast: LiveBroadcast,
  liveTV: LiveTV,
  lock: Lock,
  login: Login,
  moreHorizontal: MoreHorizontal,
  more: More,
  next10: Next10,
  next30: Next30,
  next: Next,
  notificationCheck: NotificationCheck,
  notificationError: NotificationError,
  notificationInfo: NotificationInfo,
  notificationNotification: NotificationNotification,
  notificationWarning: NotificationWarning,
  openInNew: OpenInNew,
  pause: Pause,
  pip: Pip,
  pipExit: PipExit,
  playFromBeginning: PlayFromBeginning,
  playInCircle: PlayInCircle,
  play: Play,
  previous10: Previous10,
  previous15: Previous15,
  previous: Previous,
  record: Record,
  recorded: Recorded,
  recording: Recording,
  recordings: Recordings,
  replayTv: ReplayTv,
  resume: Resume,
  search: Search,
  select: Select,
  settings: Settings,
  share: Share,
  sort: Sort,
  subscriptions: Subscriptions,
  subtitlesAndAudio: SubtitlesAndAudio,
  support: Support,
  termsOfUse: TermsOfUse,
  theater: Theater,
  volumeDown: VolumeDown,
  volumeOff: VolumeOff,
  directory: Directory,
  volumeUp: VolumeUp,
  zappingDown: ZappingDown,
  zappingUp: ZappingUp,
  recordingGray: RecordingGray,
};

export type Icons = keyof typeof icons;
