import { isWithinInterval } from "date-fns";

import type { TimeISOString } from "@sunrise/backend-types-core";

import type { ReplayWindow } from "./get-replay-window";
import { getReplayWindow } from "./get-replay-window";

/**
 * An item is in the replay window as long as the start is within the window.
 * @param start
 * @param now
 * @returns
 */
export function isWithinReplayWindow(
  start: Date | TimeISOString,
  now: Date,
): { isInReplayWindow: boolean; replayWindow: ReplayWindow } {
  const replayWindow = getReplayWindow(now);

  return {
    replayWindow,
    isInReplayWindow: isWithinInterval(start, replayWindow),
  };
}
