import type { ComponentProps } from "react";
import clsx from "clsx";
import { useAtomValue } from "jotai";

import type { ChannelId } from "@sunrise/backend-types-core";
import { simplifiedChannelInfoAtom } from "@sunrise/yallo-channel";
import { Text } from "@sunrise/yallo-web-components";

import styles from "./channel-item-epg-data.module.css";

type ChannelItemEpgDataProps = {
  channelId: ChannelId;
  showProgress?: boolean;
} & Pick<CommonProps, "className"> &
  Partial<Pick<ComponentProps<typeof Text>, "size">>;
export function ChannelItemEpgData({
  channelId,
  showProgress,
  className,
  size = "medium",
}: ChannelItemEpgDataProps) {
  const epgData = useAtomValue(simplifiedChannelInfoAtom(channelId));

  return (
    <ol className={clsx([styles.schedule, className])}>
      <li className={styles.current}>
        <Text size={size} variant="body">
          <time>{epgData?.currentEpg.start}</time> {epgData?.currentEpg.title}
        </Text>
        {showProgress && <progress max={100} value={epgData?.progress} />}
      </li>
      <li className={styles.upcoming}>
        <Text size={size} variant="body">
          <time>{epgData?.nextEpg.start}</time> {epgData?.nextEpg.title}
        </Text>
      </li>
    </ol>
  );
}
