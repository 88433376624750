import { atom } from "jotai";

import { selectedChannelGroupWithPaginatedChannels } from "./selected-channel-group-with-paginated-channels.atom";

/**
 * A list of channels we expect to be playable for the enduser.
 * So in effect a list of channels the user should be able to zap over.
 */
export const playableChannelsInCurrentChannelGroupAtom = atom(async (get) => {
  return (
    (await get(selectedChannelGroupWithPaginatedChannels))?.channels ?? []
  ).filter((c) => !c.locked);
});
