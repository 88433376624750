import {
  RecordingGroupStatus,
  RecordingStatus,
} from "@sunrise/backend-ng-recordings";

import type { SimplifiedRecordingStatus } from "../types";

export function recordingStatusToSimplifiedRecording(
  status: RecordingStatus | RecordingGroupStatus | string,
): SimplifiedRecordingStatus | null {
  switch (status) {
    case "recorded":
    case RecordingStatus.Recorded:
    case RecordingGroupStatus.AllRecorded:
      return "recorded";
    case "planned":
    case RecordingGroupStatus.AllPlanned:
    case RecordingStatus.Planned:
      return "planned";
    default:
      return null;
  }
}
