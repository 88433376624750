import type { InfiniteData } from "@tanstack/react-query";
import { atomFamily } from "jotai/utils";
import { atomWithInfiniteQuery } from "jotai-tanstack-query";

import { type PageWithItems } from "@sunrise/backend-ng-core";
import { queryKeys } from "@sunrise/backend-types";
import type { RecordingGroupId } from "@sunrise/backend-types-core";
import { currentLanguageAtom } from "@sunrise/i18n";
import { selectJwtUserToken } from "@sunrise/jwt";
import { isNil } from "@sunrise/utils";

import {
  mappedAvailabilityAtom,
  recordingDetailEpisodesAvailabilityAtom,
} from "./filters/asset-details-availability.atom";
import { assetDetailEpisodesChannelAtom } from "./filters/asset-details-channels.atom";
import { assetDetailEpisodesSeasonAtom } from "./filters/asset-details-season.atom";
import { assetDetailEpisodesSortAtom } from "./filters/asset-details-sort.atom";
import { SortingOption } from "./filters/asset-episodes-sorting-options";
import type { BaseRecordingSchema, RecordingStatus } from "./GeneratedApi";
import { RecordingEpisodeSortingOption } from "./GeneratedApi";
import { ngRecordingsApiAtom } from "./ng-recordings-api.atom";
import { recordingsStaleTimeAtom } from "./recordings-stale-time.atom";

const genericToRecordingSortingOptions = {
  [SortingOption.DateAscending]:
    RecordingEpisodeSortingOption.EpgStartAscending,
  [SortingOption.DateDescending]:
    RecordingEpisodeSortingOption.EpgStartDescending,
  [SortingOption.TitleAscending]: RecordingEpisodeSortingOption.TitleAscending,
  [SortingOption.TitleDescending]:
    RecordingEpisodeSortingOption.TitleDescending,
};

/**
 * The recording episodes of a recording-group.
 *
 */
export const recordingGroupsEpisodesAtom = atomFamily(
  (recordingGroupId: RecordingGroupId) => {
    return atomWithInfiniteQuery<
      PageWithItems<BaseRecordingSchema>,
      unknown,
      InfiniteData<PageWithItems<BaseRecordingSchema>>,
      ReturnType<typeof queryKeys.recordingGroupEpisodes>,
      number
    >((get) => {
      const api = get(ngRecordingsApiAtom);
      const language = get(currentLanguageAtom);
      const sortBy = get(assetDetailEpisodesSortAtom(recordingGroupId));
      const availability = get(mappedAvailabilityAtom(recordingGroupId));
      const channel = get(assetDetailEpisodesChannelAtom(recordingGroupId));
      const season = get(assetDetailEpisodesSeasonAtom(recordingGroupId));

      const queryKey = queryKeys.recordingGroupEpisodes(
        get(selectJwtUserToken),
        recordingGroupId,
        language,
        sortBy,
        availability,
        channel,
        season,
      );

      return {
        initialPageParam: 1,
        queryKey,
        queryFn: async ({ pageParam = 1 }) => {
          const available = await get(
            recordingDetailEpisodesAvailabilityAtom(recordingGroupId),
          );
          const { data } =
            await api.recording.getEpisodesOfRecordingGroupRecordingV1RecordingGroupsRecordingGroupIdEpisodesGet(
              recordingGroupId,
              {
                page: pageParam,
                size: 20,
                sort_by: sortBy
                  ? genericToRecordingSortingOptions[sortBy]
                  : undefined,
                channel_id: channel,
                season_number: season,
                status: available ? (available as RecordingStatus) : undefined,
              },
            );

          return data;
        },
        getNextPageParam: (lastPage) => {
          if (isNil(lastPage) || !lastPage.pages || !lastPage.page)
            return undefined;

          const { page, pages } = lastPage;
          return page < pages ? page + 1 : undefined;
        },
        staleTime: get(recordingsStaleTimeAtom),
        gcTime: get(recordingsStaleTimeAtom),
      };
    });
  },
);

export const unfilteredRecordingGroupsEpisodesAtom = atomFamily(
  (recordingGroupId: RecordingGroupId) => {
    return atomWithInfiniteQuery<
      PageWithItems<BaseRecordingSchema>,
      unknown,
      InfiniteData<PageWithItems<BaseRecordingSchema>>,
      ReturnType<typeof queryKeys.unfilteredRecordingGroupEpisodes>,
      number
    >((get) => {
      const api = get(ngRecordingsApiAtom);
      const language = get(currentLanguageAtom);

      const queryKey = queryKeys.unfilteredRecordingGroupEpisodes(
        get(selectJwtUserToken),
        recordingGroupId,
        language,
      );

      return {
        initialPageParam: 1,
        queryKey,
        queryFn: async ({ pageParam = 1 }) => {
          const { data } =
            await api.recording.getEpisodesOfRecordingGroupRecordingV1RecordingGroupsRecordingGroupIdEpisodesGet(
              recordingGroupId,
              {
                page: pageParam,
                size: 1,
              },
            );

          return data;
        },
        getNextPageParam: (lastPage) => {
          if (isNil(lastPage) || !lastPage.pages || !lastPage.page)
            return undefined;

          const { page, pages } = lastPage;
          return page < pages ? page + 1 : undefined;
        },
        staleTime: get(recordingsStaleTimeAtom),
        gcTime: get(recordingsStaleTimeAtom),
      };
    });
  },
);
