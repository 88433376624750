import { atom } from "jotai";

import { currentLanguageAtom, getLocalizedValue } from "@sunrise/i18n";

import { currentChannelGroupAtom } from "../current-channel-group.atom";
import type { MappedChannel } from "../types";
import { getChannelLogo, getStreamUrl } from "./channel.utils";
import { allChannelsQueryLegacyAtom } from "./queries/all-channels.query.legacy.atom";
import { availableChannelPackagesLegacyAtom } from "./queries/channel-packages.atom";
import { fullChannelGroupByIdLegacyAtom } from "./queries/full-channel-group-by-id.legacy.atom";

/**
 * Use this to determine the current channel group.
 * If you want to set a different channel group, please use @see preferredChannelGroupIdAtom.
 *
 * It contains a fallback to the select the first channel group if none is selected. It will select first from the static channel groups.
 * If none are present it would fall back to the user channel groups.
 *
 * The returned channels are sorted.
 *
 * @deprecated
 */
export const selectedChannelGroupLegacyAtom = atom(async (get) => {
  const current = await get(currentChannelGroupAtom);

  if (!current) {
    return null;
  }

  const [all, group, packages] = await Promise.all([
    get(allChannelsQueryLegacyAtom),
    get(fullChannelGroupByIdLegacyAtom(current.id)),
    get(availableChannelPackagesLegacyAtom),
  ]);

  if (!group) {
    return null;
  }

  const language = get(currentLanguageAtom);

  return {
    id: group.id,
    name: group.name,
    channels: group.channels
      .sort((a, b) => a.order - b.order)
      .map((channel, index) => {
        const found = all.data.result.find((c) => c.id === channel.channel.id);

        if (!found) {
          return null;
        }

        const stream = getStreamUrl(found);
        if (!stream) {
          return null;
        }

        const logo = getChannelLogo(found);
        if (!logo) {
          return null;
        }

        return {
          id: channel.channel.id,
          name:
            getLocalizedValue(found.long_name, language) ?? found.short_name,
          order: index + 1,
          stream,
          logo: logo.url,
          locked: !packages[found.id],
          providerName: found.default_stream_provider_name,
        } satisfies MappedChannel;
      })
      .filter((v) => v !== null),
  };
});
selectedChannelGroupLegacyAtom.debugLabel = "selectedChannelGroupLegacyAtom";
