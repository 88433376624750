import type { ComponentProps, ReactElement } from "react";
import React from "react";

import { Toggle } from "./toggle";

type Props = Omit<
  ComponentProps<typeof Toggle>,
  "showIcon" | "type" | "variant"
>;

function Radio(props: Props): ReactElement {
  return <Toggle {...props} type="radio" />;
}

export { Radio };
