import { atom, useAtomValue } from "jotai";

import type { ChannelId } from "@sunrise/backend-types-core";
import type { Nullable } from "@sunrise/utils";
import { channelByIdAtom } from "@sunrise/yallo-channel-group";

const NO_DATA_ATOM = atom(null);

/**
 * Loads the channel details if they are missing.
 * When they are passed in we will not attempt to load the channel.
 */
export function useChannelDetails({
  channelId,
  name,
  logo,
}: {
  channelId: Nullable<ChannelId>;
  name: Nullable<string>;
  logo: Nullable<string>;
}) {
  const channel = useAtomValue(
    channelId && (!name || !logo) ? channelByIdAtom(channelId) : NO_DATA_ATOM,
  );

  return {
    channelLogo: logo ?? channel?.logo,
    channelName: name ?? channel?.name,
  };
}
