import { useCallback } from "react";
import { useAtomCallback } from "jotai/utils";
import { debounce } from "lodash";

import {
  actionPlayerSetSeekTime,
  playerAtom,
  selectPlayerCurrentTimeIncludingSeekTime,
} from "@sunrise/player";
import { isNil } from "@sunrise/utils";
import { getPlayerManager } from "@sunrise/yallo-common-player-manager";

const DEBOUNCE_TIME = 200;

type QuickSeekOptions = {
  forwardSeconds: number;
  backwardSeconds: number;
  debounceTime?: number;
};

export function useQuickSeek(options: QuickSeekOptions) {
  const debounceActualSeek = useCallback(
    debounce(
      (time: number) => {
        getPlayerManager().seekToInCurrentPlayRequest(time);
      },
      options.debounceTime ?? DEBOUNCE_TIME,
      {
        leading: false,
        trailing: true,
      },
    ),
    [],
  );

  const seek = useAtomCallback(
    useCallback(
      async (get, set, seconds: number) => {
        const currentTime = get(selectPlayerCurrentTimeIncludingSeekTime);

        if (isNil(currentTime)) {
          return;
        }

        const newTime = currentTime + seconds;

        const time = await getPlayerManager().couldSeekToInCurrentPlayRequest(
          newTime,
        );

        if (!time) {
          return;
        }

        // seek to time, and then later on update the actual player
        set(playerAtom, actionPlayerSetSeekTime(time));
        debounceActualSeek(time);
      },
      [debounceActualSeek],
    ),
  );

  return {
    forward: useCallback(
      () => seek(options.forwardSeconds),
      [seek, options.forwardSeconds],
    ),
    backward: useCallback(
      () => seek(-options.backwardSeconds),
      [seek, options.backwardSeconds],
    ),
  };
}
