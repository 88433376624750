import { atom } from "jotai";
import { atomFamily } from "jotai/utils";
import { isEqual, isNil } from "lodash";

import type { EPGEntryId } from "@sunrise/backend-types-core";
import { hostsAtom } from "@sunrise/http-client";

import { fetchEPGEntry } from "./legacy/epg.service";

/**
 * Atom that fetches EPG details for a given EPG entry ID.
 *
 * @deprecated Use `epgEntryByIdAtom` from `@sunrise/yallo-epg` instead.
 */
export const epgDetailsForEpgEntryId = atomFamily(
  (param: { epgId: EPGEntryId }) => {
    const innerAtom = atom(async (get) => {
      const host = get(hostsAtom).data;
      if (isNil(host)) throw new Error("Host is not set");

      return fetchEPGEntry(host, param.epgId);
    });
    innerAtom.debugLabel = `epgDetailsForEpgEntryId(${param.epgId})`;
    return innerAtom;
  },
  isEqual,
);
