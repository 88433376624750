import type { InfiniteData } from "@tanstack/react-query";
import { atomFamily } from "jotai/utils";
import { atomWithInfiniteQuery } from "jotai-tanstack-query";

import type { PageWithItems } from "@sunrise/backend-ng-core";
import type { HTTPValidationError } from "@sunrise/backend-ng-epg";
import { ngEpgApiAtom } from "@sunrise/backend-ng-epg";
import { queryKeys } from "@sunrise/backend-types";
import type { EPGEntryId } from "@sunrise/backend-types-core";
import { currentLanguageAtom } from "@sunrise/i18n";
import { isNil } from "@sunrise/utils";
import type { MappedEpg } from "@sunrise/yallo-epg";
import { mapBackendEpgNg } from "@sunrise/yallo-epg";

/**
 * Alternate airings of an episode of a seriesepisode or program
 *
 */

export const alternateAiringsByEpgIdAtom = atomFamily((epgId: EPGEntryId) => {
  return atomWithInfiniteQuery<
    PageWithItems<MappedEpg>,
    HTTPValidationError,
    InfiniteData<PageWithItems<MappedEpg>>,
    ReturnType<typeof queryKeys.alternateAiringsById>,
    number
  >((get) => {
    const api = get(ngEpgApiAtom);
    const language = get(currentLanguageAtom);

    return {
      initialPageParam: 1,
      queryKey: queryKeys.alternateAiringsById(epgId, language),
      queryFn: async ({ pageParam = 1 }) => {
        const { data } =
          await api.epg.getEpgEntryByAssetEpgV1EpgEntriesEpgEntryIdAiringsGet(
            epgId,
            {
              page: pageParam,
              size: 20,
            },
          );

        return {
          ...data,
          items: data.items.map(mapBackendEpgNg),
        };
      },
      getNextPageParam: (lastPage) => {
        if (isNil(lastPage) || !lastPage.pages || !lastPage.page)
          return undefined;

        const { page, pages } = lastPage;
        return page < pages ? page + 1 : undefined;
      },
    };
  });
});
