import { useMemo } from "react";
import { useAtomValue } from "jotai";

import type { TranslateFn } from "@sunrise/i18n";
import { currentLanguageAtom } from "@sunrise/i18n";

import { createTranslator } from "./create-translator";
import { useTranslationContext } from "./translation-provider";

export const useTranslator = (): TranslateFn => {
  const translations = useTranslationContext();
  const language = useAtomValue(currentLanguageAtom);

  return useMemo(
    () => createTranslator(language, lowerCaseObject(translations)),
    [language, translations],
  );
};

function lowerCaseObject<T extends Record<string, unknown>>(value: T): T {
  return Object.fromEntries(
    Object.entries(value).map(([key, v]) => [key.toLowerCase(), v]),
  ) as T;
}
