import { isNil } from "lodash";

import { VIDEO_ELEMENT_ID } from "./constants";

export function getVideoElement(): HTMLVideoElement {
  const videoElement = document.getElementById(VIDEO_ELEMENT_ID);

  if (isNil(videoElement)) {
    throw new Error("Video element does not exist");
  }
  if (!(videoElement instanceof HTMLVideoElement)) {
    throw new Error("Video element is not an HTMLVideoElement");
  }

  return videoElement;
}
