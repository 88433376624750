import type { ReactElement, ReactNode } from "react";
import React, { createContext, useContext, useState } from "react";

import type { Translatable } from "@sunrise/i18n";

type Route<T extends string> = {
  id: T;
  title?: Translatable;
  component: ReactElement;
};

type MemoryRouterType<T extends string> = {
  routes: Route<T>[];
  currentRoute: Route<T> | null;
  navigate: (id: T) => void;
};

const RouterContext = createContext<MemoryRouterType<string> | undefined>(
  undefined,
);

export const useCreateMemoryRouter = <T extends string>(
  routes: Route<T>[],
  initialRouteId: T,
): MemoryRouterType<T> => {
  const [currentRouteId, setCurrentRouteId] = useState(initialRouteId);
  const [currentRoute, setCurrentRoute] = useState(
    routes.find((route) => route.id === currentRouteId) || null,
  );

  const navigate = (id: T) => {
    const route = routes.find((route) => route.id === id);
    if (route) {
      setCurrentRoute(route);
      setCurrentRouteId(id);
    }
  };

  return {
    routes,
    currentRoute,
    navigate,
  };
};

export const MemoryRouter = () => {
  const context = useContext(RouterContext);
  return context?.currentRoute ? context.currentRoute.component : null;
};

export const MemoryRouterProvider = <T extends string>({
  router,
  children,
}: {
  router: MemoryRouterType<T>;
  children: ReactNode;
}) => (
  // @ts-expect-error cannot infer the type of the context value correctly
  <RouterContext.Provider value={router}>{children}</RouterContext.Provider>
);

export const useMemoryRouter = <T extends string>(): MemoryRouterType<T> => {
  const context = useContext(RouterContext);
  if (!context) {
    throw new Error(
      "useMemoryRouter must be used within a MemoryRouterProvider",
    );
  }
  // @ts-expect-error cannot infer the type of the context value correctly
  return context;
};
