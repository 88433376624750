import { AxiosError } from "axios";

import {
  endpoints,
  type StreamErrorCode,
  type StreamResponse,
} from "@sunrise/backend-types";
import type { PrivateApiClient } from "@sunrise/http-client";

import { StreamBlockedByBackendError } from "./stream-blocked-by-backend.error";

type StreamErrorResponse = {
  message: string;
  first_error_code: StreamErrorCode;
};

export async function fetchStreamRequest<T = StreamResponse>(
  httpClient: PrivateApiClient,
  host: string,
  url: string,
): Promise<T> {
  try {
    const r = await httpClient.get<T>(
      url.startsWith("http") ? url : endpoints.stream(host, url),
      {
        retries: 5,
      },
    );
    return r.data;
  } catch (err) {
    const axiosError =
      err instanceof AxiosError &&
      err.response?.status === 412 &&
      (err as AxiosError<StreamErrorResponse>);

    if (axiosError && axiosError.response?.data.first_error_code) {
      const code = axiosError.response.data.first_error_code;
      throw new StreamBlockedByBackendError(code);
    }

    throw err;
  }
}
