import { atomFamily } from "jotai/utils";
import { atomWithSuspenseQuery } from "jotai-tanstack-query";
import { isNil } from "lodash";

import { queryKeys } from "@sunrise/backend-types";
import type { EPGEntryId } from "@sunrise/backend-types-core";
import { hostsAtom } from "@sunrise/http-client";
import { currentLanguageAtom } from "@sunrise/i18n";
import { type Nullable } from "@sunrise/utils";

import type { MappedEpg } from "../types";
import { fetchEPGEntry } from "./epg.service";
import { mapBackendEpgLegacy } from "./map-backend-epg.legacy";

/**
 * Fetches and caches {@link EPGEntry} that's being previewed,
 * not necessary currently playing.
 *
 */
export const epgEntryByIdLegacyAtom = atomFamily((epgId: EPGEntryId) =>
  atomWithSuspenseQuery<Nullable<MappedEpg>>((get) => {
    const host = get(hostsAtom).data;
    if (isNil(host)) throw new Error("Host is not set");
    const language = get(currentLanguageAtom);

    return {
      enabled: !isNil(epgId),
      queryKey: queryKeys.epgById(epgId, language),
      queryFn: async () => {
        if (!epgId) return null;
        const epg = await fetchEPGEntry(host, epgId);
        return mapBackendEpgLegacy(epg, language);
      },
      staleTime: Infinity,
      cacheTime: Infinity,
    };
  }),
);
