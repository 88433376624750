import { selectAtom } from "jotai/utils";

import { MIN_SEARCH_QUERY_LENGTH } from "./search.const";
import { selectCurrentSearchQuery } from "./select-current-search-query";

/**
 * We do not search actively when we have less than a certain amount of search characters.
 */
export const selectSearchEnabled = selectAtom(
  selectCurrentSearchQuery,
  (query) => !!(query && query.length >= MIN_SEARCH_QUERY_LENGTH),
);
