import { atomWithReducer, selectAtom } from "jotai/utils";

import type { PauseAdConfig, PauseAdJsonConfig } from "@sunrise/backend-types";
import { type Nullable } from "@sunrise/utils";

const PAUSE_ADS_RESUME_PLAYOUT_ACTION = "pause-ads/resume-playout-action";

const ALLOWED_ACTIONS_ON_TERMINATING: PauseAdsAction["type"][] = [
  PAUSE_ADS_RESUME_PLAYOUT_ACTION,
];

export type PauseAdsAtomState = {
  /**
   * The state is needed to know what work has already been done for the pause ads.
   * When it is disabled, we don't want to do anything except listen for when the player goes into pause mode.
   * When the state is active, it means we should attempt to load the adConfig and after that the jsonConfig. We need both present.
   * Then the UI can put it into the showing state at which we start the analytics.
   * The UI can also put it in error state if there's an issue loading the image.
   * When the UI is destroyed, we go into a terminating state at which point we
   * We can also go directly go from showing to disabled if we detect that the player started playing again.
   * The UI's involvement is not needed here. terminating just exists for the UI to start the completion process.
   * will send the end analytics events if necessary and we will clear out the config to go back to disabled.
   */
  state: "active" | "showing" | "empty" | "error" | "terminating" | "disabled";
  hasShown: boolean;
  adConfig: Nullable<PauseAdConfig>;
  jsonConfig: Nullable<PauseAdJsonConfig>;
};

type SetAdConfigAction = {
  type: "pause-ads/set-ad-config";
  payload: {
    config: PauseAdConfig;
  };
};

type SetAdJsonConfigAction = {
  type: "pause-ads/set-ad-json-config";
  payload: {
    config: PauseAdJsonConfig;
  };
};

type SetShowingAction = {
  type: "pause-ads/set-showing";
};

type SetErrorAction = {
  type: "pause-ads/set-error";
};

type SetEmptyAction = {
  type: "pause-ads/set-empty";
};

type ResumePlayoutAction = {
  type: typeof PAUSE_ADS_RESUME_PLAYOUT_ACTION;
};

type SetActiveAction = {
  type: "pause-ads/activate";
};

type SetTerminateAction = {
  type: "pause-ads/terminate";
};

type PauseAdsAction =
  | SetAdConfigAction
  | SetAdJsonConfigAction
  | SetShowingAction
  | SetErrorAction
  | ResumePlayoutAction
  | SetActiveAction
  | SetEmptyAction
  | SetTerminateAction;

const DEFAULT_STATE: PauseAdsAtomState = {
  adConfig: null,
  hasShown: false,
  jsonConfig: null,
  state: "disabled",
};

export const pauseAdsAtom = atomWithReducer<PauseAdsAtomState, PauseAdsAction>(
  DEFAULT_STATE,
  function (state, action) {
    if (
      !action ||
      (state.state === "terminating" &&
        !ALLOWED_ACTIONS_ON_TERMINATING.includes(action.type))
    ) {
      return state;
    }

    switch (action.type) {
      case "pause-ads/activate":
        return {
          ...DEFAULT_STATE,
          state: "active",
        };
      case "pause-ads/set-ad-config":
        return {
          state: "active",
          hasShown: false,
          adConfig: action.payload.config,
          jsonConfig: null,
        };
      case "pause-ads/set-ad-json-config":
        if (state.state !== "active") {
          return state;
        }

        return {
          ...state,
          jsonConfig: action.payload.config,
        };
      case "pause-ads/set-showing":
        return {
          ...state,
          hasShown: true,
          state: "showing",
        };
      case "pause-ads/set-error":
        return {
          ...state,
          state: "error",
        };
      case "pause-ads/resume-playout-action":
        return DEFAULT_STATE;
      case "pause-ads/set-empty":
        return {
          ...state,
          state: "empty",
        };
      case "pause-ads/terminate":
        return {
          ...state,
          state: "terminating",
        };
      default:
        return state;
    }
  },
);
pauseAdsAtom.debugLabel = "pauseAdsAtom";

export const actionPauseAdsSetAdConfig = (
  config: Readonly<PauseAdConfig>,
): SetAdConfigAction => ({
  type: "pause-ads/set-ad-config",
  payload: {
    config,
  },
});

export const actionPauseAdsSetAdJsonConfig = (
  config: PauseAdJsonConfig,
): SetAdJsonConfigAction => ({
  type: "pause-ads/set-ad-json-config",
  payload: {
    config,
  },
});

export const actionPauseAdsActivate = (): SetActiveAction => ({
  type: "pause-ads/activate",
});

export const actionPauseAdsResumePlayout = (): ResumePlayoutAction => ({
  type: PAUSE_ADS_RESUME_PLAYOUT_ACTION,
});

export const actionPauseAdsSetShowing = (): SetShowingAction => ({
  type: "pause-ads/set-showing",
});

export const actionPauseAdsTerminate = (): SetTerminateAction => ({
  type: "pause-ads/terminate",
});

export const actionPauseAdsErrored = (): SetErrorAction => ({
  type: "pause-ads/set-error",
});

export const actionPauseAdsEmpty = (): SetEmptyAction => ({
  type: "pause-ads/set-empty",
});

const STATES_WITH_URL: PauseAdsAtomState["state"][] = ["active", "showing"];
export const selectPauseAdImage = selectAtom(pauseAdsAtom, (atom) => {
  return STATES_WITH_URL.includes(atom.state)
    ? atom.jsonConfig?.url ?? null
    : null;
});

export const selectPauseAdState = selectAtom(pauseAdsAtom, (atom) => {
  return atom.state;
});
