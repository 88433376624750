import { atom } from "jotai";

import { isLegacyBackendAtom } from "@sunrise/backend-core";
import { type Nullable } from "@sunrise/utils";

import { currentChannelGroupAtom } from "./current-channel-group.atom";
import { selectedChannelGroupLegacyAtom } from "./legacy/selected-channel-group-with-channels.legacy.atom";
import { pagedChannelsForGivenChannelGroupIdNgAtom } from "./ng/paged-channels-for-given-channel-group-id.ng.atom";
import { ngChannelToSimpleChannel } from "./ng/utils/ng-channel-to-simple-channel";
import type { MappedChannelGroupWithChannels } from "./types";

/**
 * Use this to determine the current channel group.
 * If you want to set a different channel group, please use @see currentChannelGroupIdAtom.
 *
 * It contains a fallback to the select the first channel group if none is selected. It will select first from the static channel groups.
 * If none are present it would fall back to the user channel groups.
 *
 * The returned channels are sorted.
 */
export const selectedChannelGroupWithPaginatedChannels = atom<
  Promise<Nullable<MappedChannelGroupWithChannels>>
>(async (get) => {
  if (get(isLegacyBackendAtom)) {
    return await get(selectedChannelGroupLegacyAtom);
  }

  const selected = await get(currentChannelGroupAtom);
  if (!selected) {
    return null;
  }

  const data = get(pagedChannelsForGivenChannelGroupIdNgAtom(selected.id));
  return {
    id: selected.id,
    name: selected.name,
    channels: data.items.map(ngChannelToSimpleChannel),
    fetchNextPage: data.fetchNextPage,
  };
});
selectedChannelGroupWithPaginatedChannels.debugLabel =
  "selectedChannelGroupWithPaginatedChannels";
