import { millisecondsToSeconds } from "date-fns";
import { minutesToMilliseconds } from "date-fns/fp";

import { getLiveProgress } from "@sunrise/time";
import { isDefined, type Nullable } from "@sunrise/utils";

import type { ContinueWatchingStatus } from "../types";

/**
 * TODO: Change API so we can pass entirely different types for recordings vs replays.
 */
export function getContinueWatchingProgress({
  epgStartTime,
  epgEndTime,
  status,
  paddingTimesInMinutes,
}: {
  epgStartTime: Date;
  epgEndTime: Date;
  status: Nullable<ContinueWatchingStatus>;
  paddingTimesInMinutes: Nullable<{ start: number; end: number }>;
}) {
  if (status?.isFullyWatched) {
    return 100;
  }

  if (
    paddingTimesInMinutes &&
    status?.type === "recording" &&
    isDefined(status.playPositionSecond)
  ) {
    // When it's a recording, we need to know the duration of the recording and include the padding.
    const totalLengthInSeconds = millisecondsToSeconds(
      epgEndTime.getTime() -
        epgStartTime.getTime() +
        minutesToMilliseconds(paddingTimesInMinutes.start) +
        minutesToMilliseconds(paddingTimesInMinutes.end),
    );
    const currentTime = status.playPositionSecond;

    return Math.floor((currentTime / totalLengthInSeconds) * 100);
  } else if (status?.type === "replay" && status.moment) {
    return (
      getLiveProgress(epgStartTime, epgEndTime, new Date(status.moment)) ?? null
    );
  } else {
    return null;
  }
}
