import { atom } from "jotai";
import { atomWithReducer } from "jotai/utils";

import { nowSecondAtom } from "@sunrise/time";

import { playerControlsIdleMarginAtom } from "./flags/player-controls-idle-margin-flag.atom";
import { shouldNotShowPlayerControlsAtom } from "./should-not-show-player-controls.atom";

type PlayerControlsAtomState = {
  isVisible: boolean;
};

export function makeMenuAtomDefaultState(
  state?: Partial<PlayerControlsAtomState>,
): PlayerControlsAtomState {
  return {
    isVisible: state?.isVisible ?? false,
  };
}

type ActionHide = {
  type: "player-controls/hide";
};
type ActionShow = {
  type: "player-controls/show";
};

type PlayerControlsAction = ActionHide | ActionShow;

export const playerControlsAtom = atomWithReducer<
  PlayerControlsAtomState,
  PlayerControlsAction
>(makeMenuAtomDefaultState(), menuAtomReducer);

export function menuAtomReducer(
  ps: PlayerControlsAtomState,
  action: PlayerControlsAction,
): PlayerControlsAtomState {
  switch (action.type) {
    case "player-controls/hide": {
      return { ...ps, isVisible: false };
    }
    case "player-controls/show": {
      return { ...ps, isVisible: true };
    }
    case null:
    case undefined: {
      return ps;
    }
  }
}

// if the player controls are closed by idle timeout, save that moment so we can determine how much time has passed to for example prevent unwanted actions
export const lastDateOfIdleTimeoutAtom = atom<Date | null>(null);

export const isAfterIdleMarginAtom = atom((get) => {
  const timeout = get(playerControlsIdleMarginAtom);
  const lastIdleDate = get(lastDateOfIdleTimeoutAtom);
  const now = get(nowSecondAtom);

  return lastIdleDate ? now.getTime() - lastIdleDate.getTime() > timeout : true;
});

/*
 *
 * ACTIONS
 *
 */

export function actionPlayerControlsHide(): ActionHide {
  return {
    type: "player-controls/hide",
  };
}

export function actionPlayerControlsShow(): ActionShow {
  return {
    type: "player-controls/show",
  };
}

export const playerControlsVisibleAtom = atom((get) => {
  return (
    get(playerControlsAtom).isVisible && !get(shouldNotShowPlayerControlsAtom)
  );
});
