import { atomFamily } from "jotai/utils";
import { atomWithSuspenseQuery } from "jotai-tanstack-query";
import { isNil } from "lodash";

import type { Asset } from "@sunrise/backend-types";
import { queryKeys } from "@sunrise/backend-types";
import type { AssetId } from "@sunrise/backend-types-core";
import { hostsAtom } from "@sunrise/http-client";
import { type Nullable } from "@sunrise/utils";

import { fetchAsset } from "./queries/asset.service";

export const assetDetailsByIdLegacyAtom = atomFamily(
  (assetId: Nullable<AssetId>) =>
    atomWithSuspenseQuery<Nullable<Asset>>((get) => {
      const host = get(hostsAtom).data;
      if (isNil(host)) throw new Error("Host is not set");

      return {
        queryKey: queryKeys.assetByIdLegacy(assetId),
        queryFn: async () => {
          if (!assetId) return null;
          return fetchAsset(host, assetId);
        },
        staleTime: Infinity,
        cacheTime: Infinity,
      };
    }),
);
