import type { ReactNode } from "react";
import { useAtomValue } from "jotai";

import { useChannelDetails } from "@sunrise/yallo-channel";
import { useContinueWatchingProgressByRecordingId } from "@sunrise/yallo-continue-watching";
import { useEpgRelativeTime } from "@sunrise/yallo-epg";
import {
  type GenericSingleRecording,
  recordingStatusByRecordingIdAtom,
} from "@sunrise/yallo-recordings";
import { ProgramBox } from "@sunrise/yallo-web-components";

import { route } from "@/config/route";
import { usePlayRecording } from "@/hooks/use-play-recording";

type RecordingItemSingleProps = Pick<
  GenericSingleRecording,
  | "id"
  | "posterUrl"
  | "epg_end"
  | "epg_start"
  | "subtitle"
  | "title"
  | "channelId"
  | "assetId"
  | "expiresAt"
  | "paddingEndMinutes"
  | "paddingStartMinutes"
> & {
  // cover NG only values since we cant pick them from the GenericSingleRecording union
  channelLogo?: string | null;
  channelName?: string | null;
  forceLiveProgress?: boolean;
};

export function RecordingItemSingle(
  props: RecordingItemSingleProps,
): ReactNode {
  const { formattedStart, formattedEnd, airTime, expiresIn } =
    useEpgRelativeTime({
      startDate: props.epg_start,
      endDate: props.epg_end,
      expiresAt: props.expiresAt,
      forceLiveProgress: props.forceLiveProgress,
    });

  const { channelLogo, channelName } = useChannelDetails({
    channelId: props.channelId,
    name: "channelName" in props ? props.channelName : undefined,
    logo: "channelLogo" in props ? props.channelLogo : undefined,
  });

  // TODO: We do not need to use the icon here?
  const { play, label: playLabel } = usePlayRecording({
    recordingId: props.id,
  });

  const url = route.recordings.single({
    assetId: props.assetId,
    recordingId: props.id,
  });

  const recordingStatus = useAtomValue(
    recordingStatusByRecordingIdAtom(props.id),
  );

  const { liveProgress, replayProgress } =
    useContinueWatchingProgressByRecordingId({
      recordingId: props.id,
      start: props.epg_start,
      end: props.epg_end,
      paddingStartMinutes: props.paddingStartMinutes,
      paddingEndMinutes: props.paddingEndMinutes,
    });

  return (
    <ProgramBox
      airTime={airTime}
      channelLogo={channelLogo}
      channelName={channelName}
      coverImageUrl={props.posterUrl}
      endDate={props.epg_end}
      expiresIn={expiresIn}
      formattedEnd={formattedEnd}
      formattedStart={formattedStart}
      liveProgress={liveProgress}
      playLabel={playLabel}
      recordingState={recordingStatus}
      redirectUrl={url}
      replayProgress={replayProgress}
      startDate={props.epg_start}
      subtitle={props.subtitle}
      title={props.title}
      variant="rowbox"
      onPlay={play}
    />
  );
}
