import type { Channel, ChannelLogo } from "@sunrise/backend-types";
import type { Language } from "@sunrise/backend-types-core";
import { getLocalizedValue } from "@sunrise/i18n";
import type { Nullable } from "@sunrise/utils";

import type { MappedChannel } from "../types";

/**
 * @deprecated
 */
export function getStreamUrl(channel: Channel): string | null {
  return channel.naboo_urls?.streams ?? null;
}

type LogosContainer = {
  logos: ChannelLogo[];
};

/**
 * @deprecated
 */
export function getChannelLogo(
  channel: Channel | LogosContainer,
  preferred: "black" | "white" = "black",
): ChannelLogo | null {
  return (
    channel.logos.find((logo) => logo.type === preferred) ??
    channel.logos[0] ??
    null
  );
}

export const selectChannelLogo = (
  channel: Nullable<Channel>,
): Nullable<string> => {
  return channel ? getChannelLogo(channel)?.url : null;
};

export function legacyChannelToSimpleChannel(
  channel: Channel,
  language: Language,
): Omit<MappedChannel, "locked" | "order"> {
  return {
    id: channel.id,
    name: getLocalizedValue(channel.long_name, language) ?? channel.short_name,
    stream: getStreamUrl(channel),
    // Needs to go anyway but basically there will always be at least one logo. So this should not return null ever.
    logo: getChannelLogo(channel)?.url ?? "",
    providerName: channel.default_stream_provider_name,
  };
}
