import { atomFamily } from "jotai/utils";
import { atomWithSuspenseQuery } from "jotai-tanstack-query";

import {
  ngUpsellApiAtom,
  type UpsellLinkContext,
} from "@sunrise/backend-ng-upsell";
import { currentLanguageAtom } from "@sunrise/i18n";
import { isNil } from "@sunrise/utils";

export const upsellLinkQueryAtom = atomFamily((context: UpsellLinkContext) =>
  atomWithSuspenseQuery((get) => {
    const api = get(ngUpsellApiAtom);
    const language = get(currentLanguageAtom);

    if (isNil(api)) {
      throw new Error("ngUpsellApiAtom is not initialized");
    }

    return {
      queryKey: ["upsell-link", language, context],
      queryFn: async () => {
        const result = await api.getUpsellLink(
          {},
          {
            context,
          },
        );

        return result.data;
      },
    };
  }),
);
