import type { Stream } from "@sunrise/backend-types";
import type { Nullable } from "@sunrise/utils";
import {
  deviceInfo,
  fromBase64ToUint8Array,
  stringToUint16Array,
  toBase64FromUint8Array,
  uint16ArrayToString,
} from "@sunrise/utils";

import type {
  AudioTrack,
  ExtendedHtmlVideoElement,
  MSMediaKeyMessageEvent,
  MSMediaKeyNeededEvent,
  WebkitMediaKeySession,
} from "./html-player.types";
import {
  type PlayerAudioTrack,
  type PlayerSubtitleTrack,
  type TrackId,
} from "./player.types";
import type {
  PlayerWrapper,
  PlayerWrapperConfigureProps,
} from "./player.wrapper";

export class HtmlPlayerWrapper implements PlayerWrapper {
  readonly name = "HtmlPlayer";

  private readonly player: ExtendedHtmlVideoElement;

  /**
   * Used for DRM FairPlay.
   */
  protected session: WebkitMediaKeySession | undefined;
  protected licenseRequest: XMLHttpRequest | undefined;
  protected fairplayServerCertificate: Uint8Array = new Uint8Array();
  protected drmKeyChangeInProgress = false;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected WebKitMediaKeys = (window as any).WebKitMediaKeys;

  private streamProvider: Nullable<Stream["provider"]>;
  private licenseUrl: Nullable<string>;

  private errorCallback?: (e: unknown) => void;
  private tracksChanged?: () => void;
  private isDetached?: () => boolean;

  constructor(videoElement: HTMLVideoElement) {
    this.player = videoElement as ExtendedHtmlVideoElement;

    this.player.addEventListener(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      "webkitneedkey" as any,
      this.onWebkitNeedKey,
    );

    this.player.addEventListener("loadeddata", this.onDataLoaded);
  }

  configure = (props: PlayerWrapperConfigureProps): void => {
    const { stream, fairPlayCertificate } = props;

    this.streamProvider = stream.provider;
    this.licenseUrl = stream.licenseUrl;
    this.fairplayServerCertificate = fairPlayCertificate ?? new Uint8Array();
  };

  load = async (url: string, startTime?: number) => {
    this.player.src = url;
    this.player.currentTime = startTime ?? 0;

    this.player.load();

    try {
      await this.player.play();
    } catch (e: unknown) {
      console.error(e);
      // TODO player controls dispatch a new request on unpause, so we listen to that atom but after the ads another loadStreamUrl happens
      // TODO and the videoElement.play() is called again. The previous request throws an AbortError because its src got overwritten
      // TODO NotAllowedError is autoplay error, may be needed to handle on web
      if (
        e instanceof Error &&
        (e.name === "AbortError" || e.name === "NotAllowedError")
      ) {
        return;
      }
      throw e;
    }
  };

  unload = (): Promise<void> => {
    this.stop();
    return Promise.resolve();
  };

  attach = (): Promise<void> => {
    // On Safari iOS, the same player is used by IMA SDK, but on Desktop, it adds a different player.
    // In that case, we need to hide the main player.
    if (!deviceInfo.isIOS) {
      this.player.style.display = "";
    }

    return Promise.resolve();
  };

  detach = (): Promise<boolean> => {
    if (!deviceInfo.isIOS) {
      this.player.style.display = "none";
    }

    this.stop();
    return Promise.resolve(true);
  };

  private stop = () => {
    try {
      this.cleanUpLicenseRequest();
      this.cleanUpDrmKeySession();
    } catch (_e) {
      // dont throw this
    }

    this.licenseUrl = null;
    this.streamProvider = null;
  };

  private onWebkitNeedKey = (event: MSMediaKeyNeededEvent): void => {
    if (!this.player) return; // Player destroyed / not runnableDebuggingMode

    try {
      if (!event.initData) {
        throw new Error("initData null");
      }

      // Create initData and then create key session
      this.initDrmKeySession(event);
    } catch (err: unknown) {
      console.error("onWebkitNeedKey", err);
      this.errorCallback?.(err);
    }
  };

  //
  // Impl DRM Session Callbacks
  //

  protected onWebkitkeymessage = (event: MSMediaKeyMessageEvent): void => {
    if (!this.player) return; // Player destroyed / no DRM session

    try {
      if (!this.licenseUrl) {
        throw new Error("Missing DRM license url");
      }

      // Send license request
      const message = event.message;
      let base64Message = toBase64FromUint8Array(message);

      if (this.streamProvider === "greenstreams") {
        const contentId = this.session?.contentId;
        base64Message = `spc=${base64Message}&assetId=${contentId ?? ""}`;
      }

      this.cleanUpLicenseRequest();

      this.licenseRequest = new XMLHttpRequest();
      this.licenseRequest.responseType = "text";
      this.licenseRequest.addEventListener(
        "load",
        this.onWebkitLicenseResponse,
        false,
      );
      this.licenseRequest.addEventListener(
        "error",
        this.onWebkitLicenseResponseError,
        false,
      );
      this.licenseRequest.open("POST", this.licenseUrl, true);
      this.licenseRequest.setRequestHeader("Content-type", "application/data");
      this.licenseRequest.send(base64Message);
    } catch (err: unknown) {
      console.error("onWebkitkeymessage", err);
      this.errorCallback?.(err);
    }
  };

  protected onWebkitLicenseResponse = (
    event: ProgressEvent<XMLHttpRequestEventTarget>,
  ): void => {
    try {
      const request = event.target as XMLHttpRequest;

      if (!this.player) return;

      const key = fromBase64ToUint8Array(request.responseText);
      this.drmKeyChangeInProgress = true;
      this.session?.update(key);
    } catch (err: unknown) {
      console.error("onWebkitLicenseResponse", err);
      this.errorCallback?.(err);
    }
  };

  protected onWebkitLicenseResponseError = (
    event: ProgressEvent<XMLHttpRequestEventTarget>,
  ): void => {
    // const request = event.target as ExtendedXMLHttpRequest;
    // const session = request.session;

    if (!this.player) return;
    console.error("onWebkitLicenseResponseError", event);
    this.errorCallback?.(new Error("onLicenseResponse error"));
  };

  /**
   * event: MessageEvent
   */
  protected onWebkitkeyadded = (): void => {
    if (!this.player) return; // Player destroyed / no DRM session

    this.drmKeyChangeInProgress = false;
  };

  protected onWebkitkeyerror = (event: MessageEvent): void => {
    if (!this.player) return; // Player destroyed / no DRM session

    this.drmKeyChangeInProgress = false;

    const err = new Error("DRM key update failed");
    console.error("onWebkitKeyError", err, event, event.currentTarget);
    this.errorCallback?.(err);
  };

  //
  // Fairplay helpers
  //

  /**
   * Get the DRM key system
   */
  private getDrmKeySystem = (): string => {
    if (
      this.WebKitMediaKeys &&
      this.WebKitMediaKeys.isTypeSupported("com.apple.fps.1_0", "video/mp4")
    ) {
      return "com.apple.fps.1_0";
    } else {
      throw new Error("Key System not supported");
    }
  };

  /**
   * Extract the content ID from the request initData
   *
   * @param initData
   */
  private drmExtractContentIdFromInitData = (initData: Uint8Array): string => {
    const initData16 = new Uint16Array(initData.subarray(4));
    const rawContentId = uint16ArrayToString(initData16);
    const contentId = rawContentId.substring(6);
    return contentId;
  };

  /**
   * Create the final initData to pass to decoder system
   *
   * @param initData
   * @param id
   * @param cert
   */
  private drmConcatInitDataIdAndCertificate = (
    initData: Uint8Array,
    id: string | Uint16Array,
    cert: Uint8Array,
  ): Uint8Array => {
    if (typeof id == "string") id = stringToUint16Array(id);

    // layout is [initData][4 byte: idLength][idLength byte: id][4 byte:certLength][certLength byte: cert]
    let offset = 0;
    const buffer = new ArrayBuffer(
      initData.byteLength + 4 + id.byteLength + 4 + cert.byteLength,
    );
    const dataView = new DataView(buffer);

    const initDataArray = new Uint8Array(buffer, offset, initData.byteLength);
    initDataArray.set(initData);
    offset += initData.byteLength;

    dataView.setUint32(offset, id.byteLength, true);
    offset += 4;

    const idArray = new Uint16Array(buffer, offset, id.length);
    idArray.set(id);
    offset += idArray.byteLength;

    dataView.setUint32(offset, cert.byteLength, true);
    offset += 4;

    const certArray = new Uint8Array(buffer, offset, cert.byteLength);
    certArray.set(cert);

    const responseInitData = new Uint8Array(buffer, 0, buffer.byteLength);

    return responseInitData;
  };

  private initDrmKeySession = (event: MSMediaKeyNeededEvent): void => {
    if (!this.player || !event.initData) return;

    if (this.drmKeyChangeInProgress) {
      return; // DRM key change in progress, ignore new initialization
    }

    // Clean up any existing keySession
    this.cleanUpDrmKeySession();

    this.drmKeyChangeInProgress = true;

    const initData = event.initData;
    const contentId = this.drmExtractContentIdFromInitData(initData);

    const initDataFinal = this.drmConcatInitDataIdAndCertificate(
      initData,
      contentId,
      this.fairplayServerCertificate,
    );

    if (!this.player.webkitKeys) {
      const keySystem = this.getDrmKeySystem();
      this.player.webkitSetMediaKeys(new this.WebKitMediaKeys(keySystem));
    }

    if (!this.player.webkitKeys) throw new Error("Could not create MediaKeys");

    this.session = this.player.webkitKeys.createSession(
      "video/mp4",
      initDataFinal,
    );

    if (!this.session) throw new Error("Could not create key session");

    this.session.contentId = contentId;

    // Add event listeners to key session
    this.session.addEventListener(
      "webkitkeymessage",
      this.onWebkitkeymessage,
      false,
    );
    this.session.addEventListener(
      "webkitkeyadded",
      this.onWebkitkeyadded,
      false,
    );
    this.session.addEventListener(
      "webkitkeyerror",
      this.onWebkitkeyerror,
      false,
    );
  };

  private cleanUpDrmKeySession = (): void => {
    this.drmKeyChangeInProgress = false;

    if (this.session) {
      this.session.removeEventListener(
        "webkitkeymessage",
        this.onWebkitkeymessage,
        false,
      );
      this.session.removeEventListener(
        "webkitkeyadded",
        this.onWebkitkeyadded,
        false,
      );
      this.session.removeEventListener(
        "webkitkeyerror",
        this.onWebkitkeyerror,
        false,
      );
      this.session.close();
      this.session = undefined;
    }
  };

  private cleanUpLicenseRequest = (): void => {
    if (this.licenseRequest) {
      this.licenseRequest.removeEventListener(
        "load",
        this.onWebkitLicenseResponse,
        false,
      );
      this.licenseRequest.removeEventListener(
        "error",
        this.onWebkitLicenseResponseError,
        false,
      );
      this.licenseRequest.abort();
      this.licenseRequest = undefined;
    }
  };

  private onDataLoaded = (): void => {
    this.tracksChanged?.();
  };

  onTracksChanged = (tracksChanged: () => void): void => {
    this.tracksChanged = tracksChanged;
    // NOTE: no not listen to change event, because PlayerController will set the audio based on this, which creates a change event again and therefore an infinite loop
  };

  private getRawAudioTracks(): AudioTrack[] {
    if (!this.player?.audioTracks) return [];

    // kind is always 'main'?
    return Object.values(this.player.audioTracks); //.filter((t: AudioTrack) => t.kind === 'main');
  }

  private getRawSubtitleTracks(): TextTrack[] {
    if (!this.player?.textTracks) return [];

    // kind is 'subtitles'
    return Object.values(this.player.textTracks).filter(
      (t: TextTrack) => t.kind === "subtitles",
    );
  }

  getSubtitleTracks = (): {
    activeId: Nullable<TrackId>;
    options: PlayerSubtitleTrack[];
  } => {
    const textTracks = this.getRawSubtitleTracks();
    let activeId;

    const options = textTracks.map((track: TextTrack) => {
      if (track.mode === "showing") {
        activeId = track.id as TrackId;
      }
      return {
        id: track.id as TrackId,
        label: track.label || track.language,
        lang: track.language,
      } satisfies PlayerSubtitleTrack;
    });

    return {
      activeId,
      options,
    };
  };

  getAudioTracks = (): {
    activeId: Nullable<TrackId>;
    options: PlayerAudioTrack[];
  } => {
    let activeId;

    const audioTracks = this.getRawAudioTracks();

    if (!audioTracks.length) return { activeId: "0" as TrackId, options: [] };

    const options = audioTracks.reduce(
      (acc: PlayerAudioTrack[], track: AudioTrack): PlayerAudioTrack[] => {
        // Filter out duplicate languages, as they will not be selectable
        // (as we don't pass index up, maybe that should be changed)
        // as is if 2 tracks are in german, only one will be selectable!
        // However in this case one should be of a different format, so when implemented this would make it unique
        if (acc.findIndex((o) => o.lang === track.language) >= 0) return acc;

        if (track.enabled) {
          activeId = track.id as TrackId;
        }

        acc.push({
          id: track.id as TrackId,
          lang: track.language,
          label: track.label || track.language,
          format: "Stereo", // TODO no support for 5.1
          channelsCount: 2,
        });

        return acc;
      },
      [],
    );

    return {
      activeId,
      options: options,
    };
  };

  selectAudioLanguage = (lang: string): void => {
    const tracks = this.getRawAudioTracks();

    // Deactivate all tracks
    for (const track of tracks) {
      track.enabled = false;
    }

    // Find first matching track and activate it
    let foundTrack = false;
    for (const track of tracks) {
      if (track.language === lang) {
        track.enabled = true;
        foundTrack = true;
        break;
      }
    }

    if (!foundTrack) {
      // Track not found, enable first track available
      if (tracks.length > 0) {
        tracks[0]!.enabled = true;
      }
    }
  };

  onBuffering = (onBufferingStart: () => void, onBufferingStop: () => void) => {
    this.player.addEventListener("buffering", (event: unknown) => {
      if (this.isDetached?.()) {
        return;
      }

      if (
        event === null ||
        typeof event !== "object" ||
        !("buffering" in event)
      ) {
        return;
      }

      if (event.buffering === true) {
        onBufferingStart();
      } else {
        onBufferingStop();
      }
    });
  };

  setTextTrack = (lang: Nullable<string>, visible: boolean): void => {
    // Get raw subtitle tracks
    const tracks = this.getRawSubtitleTracks();

    // Deactivate all tracks
    for (const track of tracks) {
      track.mode = "disabled";
    }

    if (!lang || !visible) {
      // If not enabled, return early before setting selected track
      return;
    }

    // Find first matching track and activate it
    for (const track of tracks) {
      if (track.language === lang) {
        track.mode = "showing";
        break;
      }
    }
  };

  onError = (cb: (e: unknown) => void): void => {
    this.errorCallback = cb;

    this.player.addEventListener("error", () => {
      if (this.isDetached?.()) {
        return;
      }

      // NOTE: this is a workaround for IMA SDK on Safari, which throws an error sometimes when the stream is already playing and isDetached is false
      // if media src is not supported on start, the error is already thrown in load()
      if (this.player.error?.code === MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED) {
        return;
      }

      cb(this.player.error ?? new Error("Player error"));
    });
  };

  setIsDetachedFn(isDetached: () => boolean): void {
    this.isDetached = isDetached;
  }
}
