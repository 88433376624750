import { atom } from "jotai";
import { atomFamily } from "jotai/utils";

import type { ChannelGroupId } from "@sunrise/backend-types-core";

import { channelGroupsLegacyAtom } from "./channel-groups.legacy.atom";
import { userChannelGroupsQueryLegacyAtom } from "./user-channel-groups.legacy.atom";

/**
 * @deprecated This is a legacy atom. Use `fullChannelGroupByIdNgAtom` from `packages/yallo-channel-group/src/ng/full-channel-group-by-id.ng.atom.ts` instead.
 */
export const fullChannelGroupByIdLegacyAtom = atomFamily(
  (id: ChannelGroupId) => {
    const inner = atom(async (get) => {
      const [channelGroups, userChannelGroups] = await Promise.all([
        get(channelGroupsLegacyAtom),
        get(userChannelGroupsQueryLegacyAtom),
      ]);

      return (
        channelGroups.data?.find((group) => group.id === id) ??
        userChannelGroups.data?.find((group) => group.id === id) ??
        channelGroups.data?.[0] ??
        userChannelGroups.data?.[0] ??
        null
      );
    });

    inner.debugLabel = `fullChannelGroupByIdLegacyAtom(${id})`;

    return inner;
  },
);
