import { type ReactElement, Suspense, useCallback, useState } from "react";
import { useAtomValue, useSetAtom } from "jotai";

import {
  actionPlayerTogglePictureInPicture,
  playerAtom,
  playerCurrentEpgItemAtom,
  selectPlayerCurrentPlayRequest,
} from "@sunrise/player";
import {
  MemoryRouter,
  MemoryRouterProvider,
  useCreateMemoryRouter,
  useMemoryRouter,
} from "@sunrise/routing";
import { useTranslatable, useTranslator } from "@sunrise/translator";
import { usePlayChannel } from "@sunrise/yallo-common-player-manager";
import { usePlayFromBeginning } from "@sunrise/yallo-player-controls";
import { Button, SlideOut } from "@sunrise/yallo-web-components";

import { route } from "@/config/route";

import { PlayerAudioAndSubtitles } from "./player-more-options-audio-and-subtitles";
import { PlayerRecordProgramButton } from "./player-record-program-button";

const PlayerMoreOptions = (): ReactElement => {
  const [open, setOpen] = useState(false);
  const t = useTranslatable();

  const router = useCreateMemoryRouter(
    [
      {
        id: "root",
        title: { key: "player_more_options_title" },
        component: <BaseOptions setOpen={setOpen} />,
      },
      {
        id: "subtitles-audio",
        title: { key: "player_more_options_audio_and_subtitles" },
        component: <PlayerAudioAndSubtitles />,
      },
    ],
    "root",
  );

  const isRoot = router.currentRoute?.id === "root";

  return (
    <SlideOut.Root
      open={open}
      title={t(router.currentRoute?.title)}
      titlePrefix={
        !isRoot && (
          <Button
            icon="arrowLeft"
            variant="text"
            hideLabel
            onClick={() => router.navigate("root")}
          >
            {t("button_go_back")}
          </Button>
        )
      }
      trigger={
        <Button icon="more" variant="text" hideLabel>
          {t("more_player_actions_button")}
        </Button>
      }
      onOpenChange={(nextOpen) => {
        // NOTE: reset to root when opening (not closing, because then it would be visible during the close animation)
        if (!open && nextOpen && !isRoot) {
          router.navigate("root");
        }
        setOpen(nextOpen);
      }}
    >
      <MemoryRouterProvider router={router}>
        <MemoryRouter />
      </MemoryRouterProvider>
    </SlideOut.Root>
  );
};

const BaseOptions = ({
  setOpen,
}: {
  setOpen: (open: boolean) => void;
}): ReactElement => {
  const playFromBeginning = usePlayFromBeginning();
  const currentChannelId = useAtomValue(
    selectPlayerCurrentPlayRequest,
  )?.channelId;
  const live = usePlayChannel({ channelId: currentChannelId });
  const epgEntry = useAtomValue(playerCurrentEpgItemAtom);
  const dispatchPlayer = useSetAtom(playerAtom);
  const togglePictureInPicture = useCallback(() => {
    dispatchPlayer(actionPlayerTogglePictureInPicture());
  }, [dispatchPlayer]);
  const t = useTranslator();
  const { navigate } = useMemoryRouter();

  return (
    <>
      {epgEntry && (
        <SlideOut.Link
          href={route.details.root({
            assetId: epgEntry?.asset.id,
            epgId: epgEntry?.id,
          })}
          icon="info"
          showMore
          onClick={() => setOpen(false)}
        >
          {t("player_more_options_more_info")}
        </SlideOut.Link>
      )}
      <SlideOut.Button
        icon="subtitlesAndAudio"
        showMore
        onClick={() => navigate("subtitles-audio")}
      >
        {t("player_more_options_audio_and_subtitles")}
      </SlideOut.Button>
      <Suspense
        fallback={
          <SlideOut.Button icon="record" disabled>
            {t("program_detail_page_button_record")}
          </SlideOut.Button>
        }
      >
        <PlayerRecordProgramButton />
      </Suspense>
      <SlideOut.Button icon="pip" onClick={togglePictureInPicture}>
        {t("player_more_options_pip_picture_in_picture")}
      </SlideOut.Button>
      <SlideOut.Button icon="live" onClick={() => live.play()}>
        {t("player_more_options_watch_channel_live")}
      </SlideOut.Button>
      <SlideOut.Button icon="playFromBeginning" onClick={playFromBeginning}>
        {t("player_more_options_play_from_the_beginning")}
      </SlideOut.Button>
    </>
  );
};

export { PlayerMoreOptions };
