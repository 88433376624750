import type { Nullable } from "@sunrise/utils";
import type { MappedDisplayType } from "@sunrise/yallo-recommendations";

function makeDefaultImgUrl(url: string): URL {
  const result = new URL(url);
  result.searchParams.set(
    "dpr",
    Math.min(window.devicePixelRatio, 2).toString(),
  );
  result.searchParams.set("auto", "format");
  return result;
}

export function makeDetailImgUrl(
  url: Nullable<string>,
  w: number,
): Nullable<string> {
  if (!url || !URL.canParse(url)) return null;

  const result = makeDefaultImgUrl(url);
  result.searchParams.set("facepad", "10");
  result.searchParams.set("fit", "crop");
  result.searchParams.set("crop", "focalpoint,faces");
  result.searchParams.set("w", w.toString());
  result.searchParams.set("ar", "16:9");

  return result.href;
}

export function makeProgramBoxCoverImgUrl(
  url: string,
  variant: MappedDisplayType,
): string {
  if (!URL.canParse(url)) return "";
  const result = makeDefaultImgUrl(url);

  result.searchParams.set("facepad", "10");
  result.searchParams.set("fit", "crop");
  result.searchParams.set("crop", "focalpoint,faces");

  result.searchParams.set("w", "256");
  result.searchParams.set("ar", "0.65:1");

  if (variant === "search") {
    result.searchParams.set("w", "256");
  }

  if (["box", "search"].includes(variant)) {
    result.searchParams.set("ar", "16:9");
  }

  return result.href;
}

export function makeChannelImgUrl(url: string): string {
  if (!URL.canParse(url)) return "";
  const result = makeDefaultImgUrl(url);

  result.searchParams.set("h", "24");

  return result.href;
}

export function makeChannelListItemChannelLogoImgUrl(url: string): string {
  if (!URL.canParse(url)) return "";
  const result = makeDefaultImgUrl(url);

  result.searchParams.set("w", "48");

  return result.href;
}
