import { atom } from "jotai";

/**
 * The URL on which the legacy websocket should connect.
 *
 * @deprecated
 *  Please try to use the NG websocket instead.
 *  Controlled through socketUrlAtom.
 */
export const legacySocketUrlAtom = atom<string | null>(null);
