import type { PropsWithChildren } from "react";
import clsx from "clsx";

import styles from "./scrollable-list.module.css";

const ScrollableList = ({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) => (
  <div className={clsx(styles.scrollableList, className)}>{children}</div>
);

export { ScrollableList };
