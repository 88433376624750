import areEqual from "fast-deep-equal";
import { atom } from "jotai";
import { atomFamily } from "jotai/utils";

import type {
  AssetId,
  RecordingGroupId,
  RecordingId,
} from "@sunrise/backend-types-core";
import { recordingByIdNgAtom } from "@sunrise/yallo-recordings";

import { assetDetailsByIdNgAtom } from "./ng/asset-details-by-id.ng.atom";

type StaffAtomArgs =
  | {
      assetId: AssetId;
    }
  | {
      recordingId: RecordingGroupId | RecordingId;
    };

export const assetStaffByIdAtom = atomFamily((props: StaffAtomArgs) => {
  const innerAtom = atom(async (get) => {
    let cast: string[] = [];
    let directors: string[] = [];

    if ("assetId" in props) {
      const details = await get(assetDetailsByIdNgAtom(props.assetId));

      cast = details.data?.cast ?? [];
      directors = details.data?.directors ?? [];
    }

    if ("recordingId" in props) {
      const details = await get(recordingByIdNgAtom(props.recordingId));
      cast =
        details.data?.asset.cast
          ?.map((castMember) => castMember.full_name)
          .filter((v) => v != null) ?? [];
      directors =
        details.data?.asset.directors?.filter((v) => v !== null) ?? [];
    }

    return {
      cast,
      directors,
    };
  });

  innerAtom.debugLabel = `assetStaffByIdAtom(${
    "assetId" in props ? props.assetId : props.recordingId
  })`;

  return innerAtom;
}, areEqual);
